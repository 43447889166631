/* Tables
================================== */
.flex-table {
}
.flex-table-row {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid rgba(#dfdfdf, 0.5);
}
.flex-table-row--thead {
  @include medium;
  color: $black-light;
  align-items: flex-end;
}
.flex-table-cell {
  flex-grow: 1;
  width: 100%; // Default to full width
  padding: 1em 1.2em 0.8em;
  overflow: hidden; // Or flex might break
  list-style: none;
  font-size: 1.5em;
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: 0;
  }
}

/* Table column sizing
================================== */
.flex-table--2cols .flex-table-cell {
  width: 50%;
}
.flex-table--3cols .flex-table-cell {
  width: 33.33%;
}
.flex-table--4cols .flex-table-cell {
  width: 25%;
}
.flex-table--5cols .flex-table-cell {
  width: 20%;
}
.flex-table--6cols .flex-table-cell {
  width: 16.6%;
}
.flex-table--7cols .flex-table-cell {
  width: 14.285714285714286%;
}
.flex-table--8cols .flex-table-cell {
  width: 12.5%;
}

/* Hover
================================== */
@include mq(min, $mq-md) {
  .flex-table--hover > .flex-table-row:not(.flex-table-row--thead):hover,
  .flex-table--hover > .contacts_info .flex-table-row:not(.flex-table-row--thead):hover {
    background-color: #f5f5f5;
  }
}

/* Responsive
==================================== */
@include mq(max, $mq-md) {
  .flex-table--collapse {
    display: block;
  }
}

// Non-Flex modernizer fallback
.no-flexbox .flex-table {
  display: block;
  .flex-table-cell {
    width: 100%;
  }
  .flex-table-cell--foot {
    margin-bottom: 1em;
  }
}
