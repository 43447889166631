.section-billing {
  .billing--section {
    border-top: 1px solid #ddd;
    padding-top: 1.8em;
    margin-bottom: 3.2em;
    address {
      font-size: 1.6em;
    }
  }
  .billing--partner-section {
    p {
      margin-bottom: 0.5em;
    }
  }
  .billing--section--appsumo {
    .horizontal-form--1-field {
      max-width: 50em;
    }
  }
  .billing--section--flex {
    @include mq(max, $mq-md) {
      .col {
        margin-bottom: 3.2em;
      }
    }
    @include mq(min, $mq-md) {
      display: flex;
      & > h2 {
        flex: none;
      }
      .col {
        flex: 1 auto;
        margin-right: 3.2em;
      }
    }
  }
  .billing--section__ul {
    list-style: none;
    margin-bottom: 1.6em;
    li {
      margin-bottom: 0.25em;
    }
  }
  .card-data {
    span {
      @include inline-block;
      font-size: 1.6em;
      margin-right: 0.4em;
      i {
        font-size: 2em;
      }
    }
  }
  .link--edit-billing {
    color: $black-lighter;
    @include medium;
    font-size: 0.8em;
  }
  .upsell-enterprise {
    background-image: linear-gradient(48deg, #d11330 0%, #f57a51 100%);
    border-radius: 4px;
    padding: 4em 5.8em;
    color: #fff;
    position: relative;
    h3 {
      font-size: 2.2em;
      @include medium;
      margin-bottom: 0.3em;
      position: relative;
      z-index: 1;
    }
    p {
      font-size: 1.4em;
      margin: 0;
      position: relative;
      z-index: 1;
    }
    .btn {
      position: absolute;
      z-index: 1;
      right: 5.8em;
      @include vertical-align;
      color: #fff;
      border: 2px solid #fff;
    }
    .crm-logos {
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: url(../images/upgrade/crm-logos.png);
      background-repeat: no-repeat;
      background-position: 60% 100%;
    }
  }
}

.table--invoices {
  margin-bottom: 3.2em;
  border-collapse: separate;
  border-spacing: 0 1em;
  & > thead > tr > th,
  & > thead > tr > td,
  & > tbody > tr > th,
  & > tbody > tr > td,
  & > tfoot > tr > th,
  & > tfoot > tr > td {
    background-color: #f5f5f5;
    font-size: 1.4em;
    padding: 1.3em 1em 1.1em 1em;
    line-height: 1;
    border: none;
  }
  .col--plan {
    @include medium;
  }
  .col--action {
    text-align: right;
  }
  a {
    color: $blue;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.billing-progress-container {
  max-width: 30em;
  .custom-progress {
    margin-right: 0;
    margin-bottom: 1em;
    margin-top: 1em;
  }
  .custom-progress__bar {
    min-width: auto;
  }
}
