.body--dashboard {
    background-color: #f1f1f1;
}

.dashboard__panel {
    padding: 1.6em;
    background-color: #fff;
}

.dashboard__panel__header {
    border-bottom: 1px solid #f1f1f1;
    padding: 1.6em;
    margin: -1.6em -1.6em 1.6em -1.6em;
    margin-bottom: $spacer;
    position: relative;
    z-index: 9;
    h3 {
        font-size: 1.4em;
        line-height: 1;
        margin: 0;
        @include medium;
        @include uppercase;
    }
    & + .dashboard__panel__row {
        margin-top: -($spacer);
    }
}

.dashboard__panel__header__actions {
    position: absolute;
    z-index: 1;
    right: 1em;
    @include vertical-align;
}

.dashboard__panel__row {
    border-bottom: 1px solid #f1f1f1;
    padding: 1.6em;
    margin: 0 -1.6em;
}

.dashboard__panel__actions {
    .btn {
        margin-right: 0.5em;
        svg.plus-2 {
            @include wh(0.8em, 0.8em);
            position: relative;
            top: -1px;
            margin-right: 0.4em;
        }
        svg.table-columns {
            position: relative;
            top: -1px;
            margin-right: 0.4em;
        }
    }
}

.dashboard__panel__options {
    padding: $spacer;
    background-color: #f5f5f5;
    h4 {
        font-size: 1.3em;
        line-height: 1;
        margin: 0 0 1em;
        @include medium;
        @include uppercase;
    }
    label.column-option {
        @include inline-block;
        margin-right: 1.2em;
        font-size: 1.3em;
        input[type="checkbox"] {
            margin-right: 0.1em;
        }
    }
}

.dashboard__panel__footer {
    border-top: 1px solid #f1f1f1;
    padding: 1.6em;
    margin: 1.6em -1.6em -1.6em -1.6em;
}

.dashboard__panel__filter {
    .btn {
        border: none;
        background-color: #f5f5f5;
        font-size: 1.1em;
        padding: 0.5em 1.3em;
        margin-right: 0.2em;
        @include uppercase;
        &.active {
            background-color: $black-light;
            color: #fff;
        }
    }
}

.dashboard__panel__filter__search,
.filter-search {
    position: relative;
    margin-bottom: 0;
    text-align: right;
    form {
        display: inline-block;
        fieldset {
            margin: 0;
            input {
                padding-right: 2em;
                font-size: 1.3em;
            }
        }
    }
    .filter-count {
        font-size: 1.1em;
        position: absolute;
        right: 1.2em;
        @include vertical-align;
        margin-top: 0.1em;
        color: $black-lighter;
    }
}

.body--all-users {
    .dashboard__panel__row {
        position: relative;
    }
    .dashboard__panel__filter {
        @include clearfix;
    }
    .filter-search {
        @include inline-block;
        margin-right: 1em;
    }
    form.delete-users {
        display: none;
        &.active {
            @include inline-block;
        }
        .btn {
            padding: 1em 1.3em;
        }
    }
}

.dashboard__panel__no-results,
.filter-search-no-results {
    text-align: center;
    margin: $spacer * 3 auto;
    font-size: 1.8em;
}

.table--dashboard {
    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th,
    & > thead > tr > td,
    & > tbody > tr > td,
    & > tfoot > tr > td {
        vertical-align: top;
        padding: 0.75em 3em 0.5em 0.5em;
        font-size: 1.5em;
        &.col-avatar {
            max-width: 3em;
            padding-top: 0.5em;
            padding-right: 0.5em;
        }
        &.col-checkbox {
            max-width: 2em;
        }
    }
    td.status {
        max-width: 1.4em;
        .flag {
            font-size: 0.6em;
            position: relative;
            top: -2px;
        }
    }
}
