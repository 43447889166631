.body--conversations-list {
  background-color: #f1f1f1;
}

.sortable-chosen {
  clear: both;
  background-color: transparent;
  border: 1px dashed #000;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 3.2em;
  cursor: move;
  cursor: -webkit-grabbing;
}

.section--all-conversations__banner {
  background-image: linear-gradient(to right, #1848e6, #00dcb8);
  color: #fff;
  padding: 4em 0;
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
  h1 {
    @include bold;
    font-size: 3.2em;
    margin-bottom: 0.25em;
    @include mq(max, $mq-md) {
      text-align: center;
    }
  }
  p.lead {
    font-size: 2em;
    max-width: 30em;
    @include mq(max, $mq-md) {
      display: none;
    }
  }
  .banner__actions {
    margin-top: 1em;
    display: none;
    @include mq(min, $mq-md) {
      display: block;
      margin: 0;
      position: absolute;
      z-index: 2;
      right: 1.6em;
      @include vertical-align;
    }
  }
  .btn--new-conversation {
    background-color: #fff;
    border-color: #fff;
  }
}

.templates-teaser {
  margin: -2.5em 0 5em;
  & > .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.templates-teaser__inner {
  position: relative;
  background-color: $blue-dark;
  border-radius: 4px;
  padding: 4em 6em;
  color: #fff;
  h3 {
    @include medium;
    font-size: 2em;
    margin-bottom: 0.25em;
  }
  p {
    font-size: 1.5em;
  }
  .btn--link {
    color: #fff;
    text-decoration: underline;
  }
}

.list--all-conversations {
  margin: 2.5em 0;
  & > .container {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.list--all-conversations__item {
  position: relative;
  text-decoration: none;
  color: $black;
  padding-bottom: 2.5em;
  @include transition;
  user-select: none;
  .sortable-chosen {
    clear: both;
    background-color: transparent;
    border: 1px dashed #000;
    border-radius: 2px;
    width: 100%;
    margin-bottom: 3.2em;
    cursor: move;
    cursor: -webkit-grabbing;
  }
  &.collapsed,
  &.sortable-ghost {
    height: 70px;
    .outside,
    .outside *,
    .list--all-conversations__item__body,
    .list--all-conversations__item__body * {
      visibility: hidden;
    }
  }
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    * {
      pointer-events: none;
    }
  }
  .handle-cursor {
    cursor: move;
    flex-grow: 1;
  }
  .handle {
    position: absolute;
    z-index: 1;
    right: 100%;
    text-align: center;
    color: #9b9b9b;
    padding: 0.5em 0.6em;
    padding-top: 2em;
    font-size: 1.6em;
    top: 0;
    @include mq(max, $mq-md) {
      display: none;
    }
  }
  .actions__btn {
    @include invisible;
  }
  @include mq(min, $mq-md) {
    &:hover {
      .actions__btn {
        @include visible;
      }
    }
  }
  .last-updated {
    padding: 0 0.5em;
    span {
      @include medium-italic;
      font-size: 1.4em;
      color: $black-light;
    }
  }
  .outside {
    display: flex;
    align-items: center;
    clear: both;
    margin-bottom: 1em;
    justify-content: center;
    @include mq(min, $mq-md) {
      justify-content: space-between;
    }
    .actions {
      padding: 0 0.5em;
      display: none;
      @include mq(min, $mq-md) {
        display: block;
      }
      .pulldown-select {
        font-size: 1em;
        color: #7e7e7e;
        padding: 0.425em 2em 0.25em 0.55em;
        height: 100%;
        @include medium;
      }
      .pulldown-arrow {
        &:after {
          width: 0;
          height: 0;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          border-top: 5px solid #7e7e7e;
          background: none;
        }
      }
      .tooltip {
        width: 18em;
        opacity: 0;
        animation: none;
        &::after {
          border-top-color: white;
          border-width: 10px;
          margin-left: -5px;
        }
        &__bubble {
          background: white;
          padding-left: 0;
          padding-right: 0;
          padding-top: 1em;
          padding-bottom: 1em;
          box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.18);
        }
        &__heading {
          font-size: 1em;
          color: $black-light;
          text-transform: uppercase;
          border-bottom: 1px solid #d8d8d8;
          padding-bottom: 0.5em;
          margin-bottom: 0.5em;
          @include medium;
        }
        &__label {
          font-size: 1em;
          color: $black-light;
          margin-right: 0.5em;
          @include medium;
        }
        &__btn {
          margin-top: 1em;
          font-size: 1em;
          padding: 0.5em 1.8em;
          line-height: 1;
        }
      }
      &__btn {
        margin-left: 1em;
        text-transform: uppercase;
        color: $black-light;
        font-size: 1.2em;
        position: relative;
        @include transition(color);
        &--show-tooltip {
          .tooltip {
            opacity: 1;
            animation: showTooltip 0.1s ease;
            pointer-events: auto;
          }
        }
        span {
          @include medium;
          margin-left: 0.25em;
        }
        svg {
          height: 13px;
          vertical-align: sub;
          margin-right: -0.2em;
          g {
            @include transition(fill);
          }
        }
        &:hover {
          color: $black;
          g {
            fill: $black;
          }
        }
      }
    }
  }
  header {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 2em 2.5em;
    @include mq(max, $mq-md) {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      padding-top: 3em;
    }
    .flex-col {
      & > div {
        @include inline-block;
      }
    }
    .flex-col--name {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    .flex-col--actions {
      white-space: nowrap;
      flex-grow: 0;
      @include mq(max, $mq-md) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 2em 2em 0;
        border-top: 1px solid #ddd;
      }
    }
    .conversation-name {
      @include mq(max, $mq-md) {
        padding-left: 1em;
        padding-right: 1em;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        text-align: center;
      }
      a {
        color: $black;
        text-decoration: none;
        font-size: 2em;
        @include medium;
        @include mq(min, $mq-md) {
          font-size: 1.8em;
        }
        &:hover {
          color: $brand-green;
          text-decoration: underline;
        }
      }
      .favourite-icon-stack {
        margin-left: -0.5em;
        margin-top: -0.5em;
      }
    }
    .live-msg {
      font-size: 1.6em;
      color: $black;
      @include mq(min, $mq-md) {
        color: $black-light;
        font-size: 1.3em;
      }
    }
    .live-toggle {
      margin-left: 1.6em;
      .conversation-status.processing {
        opacity: 0.5;
        // cursor: wait;
      }
    }
    .link-preview {
      text-align: center;
      margin-top: 3em;
      margin-bottom: 3em;
      @include mq(min, $mq-md) {
        text-align: left;
        margin-top: 1em;
        margin-bottom: 0;
      }
      i {
        margin-left: 0.3em;
        @include inline-block;
        font-size: 1.3em;
        color: $black-light;
        @include transition;
      }
      a {
        color: $black-light;
        text-decoration: none;
        @include mq(max, $mq-md) {
          background: #eee;
          color: $black;
          padding: 1em;
          text-align: center;
          border-radius: 2px;
          i {
            color: $black;
          }
        }
        .label {
          @include inline-block;
          font-size: 1.3em;
          @include transition;
        }
        &:hover {
          color: $black;
          i {
            color: $black;
          }
        }
      }
    }
  }
  .conversation-status {
    display: inline-block;
    background-color: #ddd;
    cursor: pointer;
    border: 1px solid darken(#ddd, 10%);
    border-radius: 10em;
    padding: 0.7em 2em 0.3em;
    position: relative;
    width: 7em;
    height: 2.2em;
    overflow: hidden;
    @include transition(background-color);
    span {
      visibility: hidden !important;
    }
    &:hover {
      background-color: darken(#ddd, 5%);
    }
    &:before {
      content: "DRAFT";
      color: $black-light;
      font-size: 1.2em;
      line-height: 1;
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      right: 0.8em;
      margin-top: 1px;
    }
    &:after {
      content: "";
      border-radius: 10em;
      width: calc(2.2em - 4px);
      height: calc(2.2em - 4px);
      background-color: #fff;
      position: absolute;
      z-index: 1;
      left: 1px;
      top: 1px;
      box-shadow: 0 0 10px 0 rgba(#000, 0.2);
    }
    &.live {
      background-color: $green;
      border-color: darken($green, 10%);
      &:hover {
        background-color: darken($green, 5%);
      }
      &:before {
        content: "LIVE";
        color: #fff;
        right: auto;
        left: 1.5em;
      }
      &:after {
        right: 1px;
        left: auto;
      }
    }
  }
}

.list--all-conversations__item__body {
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex-direction: column;
  @include mq(min, $mq-md) {
    flex-direction: row;
    .col--appearance {
      width: 30%;
    }
    .col--summary {
      width: 25%;
    }
    .col--livechat {
      width: 20%;
    }
    .col--targeting {
      width: 25%;
    }
  }
  @include mq(min, $mq-lg) {
    .col--appearance {
      width: 30%;
    }
    .col--summary {
      width: 25%;
    }
    .col--livechat {
      width: 20%;
    }
    .col--targeting {
      width: 25%;
    }
  }
  @include mq(max, $mq-lg) {
    font-size: 6.4px;
  }
  & > .col {
    cursor: pointer;
    text-decoration: none;
    padding: 1em 2.5em 2em;
    position: relative;
    word-break: break-word;
    width: 100%;
    transition: 0.2s padding;
    @include mq(max, $mq-md) {
      border-bottom: 1px solid #ddd;
    }
    @include mq(min, $mq-md) {
      border-right: 1px solid #ddd;
    }
    &:hover {
      background-color: #fafafa;
      padding-top: 2em;
      .tooltip-wrap,
      .btn--edit {
        @include visible;
      }
    }
    a {
      pointer-events: none;
    }
  }
  .col__title {
    position: relative;
    padding-right: 2em;
    margin-bottom: 1.4em;
    @include mq(max, $mq-md) {
      display: none;
    }
    @include mq(min, $mq-lg) {
      margin-bottom: 1.6em;
    }
    .label {
      @include inline-block;
      @include uppercase;
      @include medium;
      color: $black-light;
      margin-right: 0.3em;
      font-size: 1.6em;
      @include mq(min, $mq-md) {
        font-size: 1.2em;
      }
    }
    .tooltip-wrap {
      @include invisible;
      @include inline-block;
      position: relative;
      i {
        color: $black-light;
      }
    }
    .btn--edit {
      @include invisible;
      position: absolute;
      top: -1em;
      right: -0.8em;
      @include medium;
      @include font-smoothing;
      text-transform: uppercase;
      background-color: #fff;
      color: $brand-blue;
      border: 1px solid $brand-blue;
      border-radius: 2px;
      text-decoration: none;
      line-height: 1;
      font-size: 1.1em;
      padding: 0.3em 0.6em 0.1em 0.5em;
      display: inline-block;
      @include transition;
      &:hover {
        background-color: $brand-blue;
        color: #fff;
      }
    }
  }
  .col--livechat {
    position: relative;
    border-right: 0;
    padding-top: 2em;
    @include mq(max, $mq-md) {
      order: -1;
      padding-top: 2.5em;
      padding-bottom: 2.5em;
      padding-right: 3.5em;
      .col__title {
        display: none;
      }
    }
    .lc-switch {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      @include mq(max, $mq-md) {
        justify-content: space-between;
      }
      &__radio {
        margin-right: 1.5em;
        margin-left: auto;
        background: #eee;
        height: 1.4em;
        @include mq(max, $mq-md) {
          height: 2em;
          width: 5em;
        }
      }
      &__circle {
        background: #b3b3b3;
        @include mq(max, $mq-md) {
          width: 3em;
          height: 3em;
        }
      }
      &__input {
        &:checked {
          + .lc-switch__radio .lc-switch__circle {
            background-color: #43d5c1;
          }
        }
      }
      em {
        color: $black;
        margin-right: 1em;
        font-size: 2em;
        font-style: normal;
        @include mq(min, $mq-md) {
          font-size: 1.2em;
          font-style: italic;
        }
      }
      a {
        pointer-events: auto;
        font-size: 1.6em;
        @include mq(min, $mq-md) {
          font-size: 1.2em;
        }
      }
    }
  }
  .col--targeting {
    border-bottom: 0;
    .rules-logic {
      font-style: italic;
      font-weight: 500;
      font-size: 1.2em;
      margin-bottom: 0.2em;
      color: $black;
    }
    .tag {
      .label {
        span {
          color: #989898;
          display: inline-block;
          margin-top: 0.3em;
          font-weight: 400;
        }
      }
      @include mq(max, $mq-md) {
        background: none;
        padding: 0;
        .label {
          font-weight: 400;
          font-size: 2em;
        }
      }
      code {
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        color: $black-light;
      }
    }
  }
  .col--appearance {
    @include mq(max, $mq-md) {
      order: 0;
    }
    .continually-message-container {
      user-select: none;
      display: flex;
      align-items: flex-end;
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
      &.pos-left {
        .continually-avatar {
          margin-left: 0;
          margin-right: 1.6em;
          order: -1;
        }
        .flex-media-and-bubble {
          margin-left: 0;
          margin-right: auto;
        }
      }
      .flex-media-and-bubble {
        margin-left: auto;
      }
      .avatar {
        @include avatar(4.8em, 2.5em, 50%);
        border-color: transparent;
        background-color: #fff;
      }
      .continually-avatar {
        margin-left: 1.6em;
      }
      .continually-message-bubble {
        color: #fff;
        background-color: $brand-primary;
        box-shadow: 0 0 4px 0 rgba(#000, 0.25);
        border-radius: 8px;
        min-width: 10em;
        max-width: 26em;
        width: max-content;
        &.continually-message-bubble--image {
          border-radius: 10px 10px 8px 8px;
          .continually-message-image {
            border-radius: 8px 8px 0 0;
            overflow: hidden;
            img {
              display: block;
              margin-left: auto;
              margin-right: auto;
            }
          }
          .continually-message-text {
            padding-top: 1.2625em;
          }
        }
      }
      .continually-message-text {
        font-size: 1.4em;
        padding: 1.5625em;
        position: relative;
        z-index: 2;
        color: #fff;
        line-height: 1.3;
        font-family: $font-family-sans-serif;
        strong {
          font-family: $font-family-sans-serif;
          font-weight: 700;
          letter-spacing: -0.03em;
        }
        em {
          font-family: $font-family-sans-serif;
          font-style: italic;
        }
        strong em,
        em strong {
          font-family: $font-family-sans-serif;
          font-weight: 700;
          font-style: italic;
        }
        a:not(.rich-media-card) {
          color: #fff;
        }
        .media-container--video {
          @include mq(min, $mq-md) {
            min-width: 10em;
          }
          @include mq(min, $mq-xl) {
            min-width: 13em;
          }
        }
        .rich-media-card__summary {
          h2 {
            @include medium;
            font-size: 13px;
          }
          p.desc,
          .site-name {
            @include regular;
            font-size: 11px;
          }
        }
        .ql-image {
          display: block;
          margin: 5px 0;
        }
      }
    }
    .continually-message-container--link {
      .continually-message-link {
        display: block;
        border-radius: 8px;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0 0 8px 0 rgba(#000, 0.25);
        text-decoration: none;
        margin-bottom: 1em;
        max-width: 26em;
        .continually-message-link-image {
          display: block;
          width: 100%;
        }
        .continually-message-link-title {
          padding: 1em;
          font-family: $font-family-sans-serif;
          font-weight: bold;
          line-height: 1.3;
          color: #333;
          font-size: 1.6em;
        }
      }
    }
    .continually-message-container--video {
      .continually-message-video {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 1em;
        max-width: 26em;
        iframe,
        video {
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
    }
    .continually-button {
      display: flex;
      align-items: center;
      transition: transform 0.2s;
      background-color: $brand-primary;
      &.continually-button--i {
        width: 62px;
        height: 62px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .continually-button-icon {
          width: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          img {
            flex-shrink: 0;
          }
        }
      }
      &.continually-button--it {
        display: inline-flex;
        border-radius: 2em;
        padding: 1em 1.6em;
        max-width: 100%;
        .continually-button-icon {
          color: #fff;
          margin-right: 1em;
          width: 24px;
        }
        .continually-button-text {
          margin: 0;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &.continually-button--t {
        display: inline-block;
        border-radius: 2em;
        max-width: 100%;
        .continually-button-text {
          margin: 0.75em 1.6em;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .continually-button-text {
      color: #fff;
      line-height: 1;
      font-size: 1.6em;
      font-family: $font-family-sans-serif;
      font-weight: 700;
    }
    .continually-autostart-container {
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: flex-end;
      &.pos-left {
        .continually-avatar {
          margin-left: 0;
          margin-right: 1.6em;
          order: -1;
        }
        .continually-autostart-message {
          &--multiple_choice,
          &--gdpr {
            .continually-autostart-actions[data-answer-layout="horizontal"] {
              margin-right: -10%;
              margin-left: 0;
              justify-content: flex-start;
              .continually-autostart-actions__option,
              .continually-autostart-actions__check {
                margin-left: 0;
                &:not(:last-child) {
                  margin-right: 0.8em;
                }
              }
            }
          }
        }
      }
      .avatar {
        @include avatar(4.8em, 2.5em, 50%);
        border-color: transparent;
        background-color: #fff;
      }
      .continually-avatar {
        margin-left: 1.6em;
      }
      .continually-autostart-message {
        max-width: 26em;

        &--multiple_choice,
        &--gdpr {
          & + .continually-avatar {
            align-self: flex-start;
          }
          .continually-message-bubble {
            margin-bottom: 1em;
            border-radius: 8px;
          }
          .continually-message-text {
            padding: 17.5px 20px;
          }
          .continually-autostart-actions {
            background: none;
            border: none;
            &[data-answer-layout="horizontal"] {
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: flex-end;
              width: 110%;
              margin-left: -10%;
              .continually-autostart-actions__option {
                padding-left: 15px;
                padding-right: 15px;
              }
              .continually-autostart-actions__option,
              .continually-autostart-actions__check {
                margin-left: 0.8em;
              }
              .continually-autostart-actions__send {
                width: 86.25%;
              }
            }
          }
        }
      }
      .continually-autostart-message--message,
      .continually-autostart-message--video,
      .continually-autostart-message--link {
        .continually-message-bubble {
          border-radius: 8px;
        }
      }
      .continually-message--gdpr {
        padding: 0 !important;
        .rich-media-card {
          background: transparent;
          border: none !important;
          margin-top: 0;
          &:hover {
            border-color: none;
          }
          .rich-media-card__summary {
            h2 {
              color: #333 !important;
              font-size: 16px !important;
            }
          }
          .desc,
          .site-name {
            display: none !important;
          }
          &__figure {
            background-color: transparent;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 0;
            border: none;
          }
          &__summary {
            padding: 1em !important;
          }
        }
      }
      .continually-autostart-message--image {
        .continually-message-bubble {
          border-radius: 0 0 8px 8px;
        }
        .continually-message-text {
          padding-top: 1.0625em;
        }
      }
      .continually-message-bubble {
        background-color: $brand-primary;
        // box-shadow: 0 0 4px 0 rgba(#000,0.25);
        border-radius: 8px 8px 0 0;
        min-width: 21em;
        max-width: 26em;
      }
      .continually-message-text {
        font-size: 1.4em;
        padding: 1.5625em;
        position: relative;
        z-index: 2;
        color: #fff;
        line-height: 1.3;
        font-family: $font-family-sans-serif;
        strong {
          font-family: $font-family-sans-serif;
          font-weight: 700;
          letter-spacing: -0.03em;
        }
        em {
          font-family: $font-family-sans-serif;
          font-style: italic;
        }
        strong em,
        em strong {
          font-family: $font-family-sans-serif;
          font-weight: 700;
          font-style: italic;
        }
        a:not(.rich-media-card) {
          color: #fff;
        }
        .media-container--video {
          @include mq(min, $mq-md) {
            min-width: 10em;
          }
          @include mq(min, $mq-xl) {
            min-width: 13em;
          }
        }
        .rich-media-card__summary {
          h2 {
            @include medium;
            font-size: 14px;
          }
          p.desc,
          .site-name {
            @include regular;
            font-size: 12px;
          }
        }
      }
      .continually-message-video {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        border-radius: 8px;
        overflow: hidden;
        margin-bottom: 1em;
        max-width: 26em;
        iframe,
        video {
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
      .continually-message-image {
        margin-top: -8px;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
        }
      }
      .continually-message-link {
        display: block;
        border-radius: 8px;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0 0 8px 0 rgba(#000, 0.25);
        text-decoration: none;
        margin-bottom: 1em;
        max-width: 26em;
        .continually-message-link-image {
          display: block;
          width: 100%;
        }
        .continually-message-link-title {
          padding: 1em;
          font-family: $font-family-sans-serif;
          font-weight: bold;
          line-height: 1.3;
          color: #333;
          font-size: 1.6em;
        }
        &:hover {
          .continually-message-link-title {
            text-decoration: underline !important;
          }
        }
      }
      .continually-autostart-actions {
        background-color: #fff;
        border-radius: 0 0 8px 8px;
        border: 1px solid #ddd;

        &__fa {
          margin-right: 0.5em;
          min-width: 1.3em;
        }
      }
      .continually-autostart-actions--single-btn {
        display: flex;
        align-items: center;
        padding: 2em;
      }
      .continually-autostart-actions--multiple-btn {
        .continually-autostart-actions__send {
          text-align: center;
          padding: 1em;
          .continually-autostart-actions__label {
            font-weight: bold;
          }
        }
      }
      .continually-autostart-actions__label {
        color: #747474;
        font-size: 1.4em;
        flex: 1;
        font-family: $font-family-sans-serif;
      }
      .continually-autostart-actions__icon {
        flex-grow: 0;
        margin-left: 1em;
      }
      .continually-autostart-actions--file-upload {
        padding: 1em 1.2em;
      }
      .continually-autostart-actions__upload-box {
        background: #f4f4f4;
        border: 1px dashed #afafaf;
        border-radius: 3px;
        text-align: center;
        padding: 1.5em;
        .continually-autostart-actions__label {
          text-transform: uppercase;
          font-weight: 500;
        }
      }
      .continually-autostart-actions--dropdown {
        padding: 1em 1.2em;
      }
      .continually-autostart-actions__pulldown {
        background: #ffffff;
        border: 1px solid #afafaf;
        border-radius: 3px;
        padding: 1.5em;
        padding-right: 3.6em;
        position: relative;
        &:after {
          position: absolute;
          z-index: 1;
          top: 50%;
          transform: translateY(-33%);
          right: 1.6em;
          content: " ";
          border: solid transparent;
          height: 0;
          width: 0;
          pointer-events: none;
          border-color: rgba($black, 0);
          border-top-color: $black;
          border-width: 0.5em;
        }
      }
      .continually-autostart-actions--appt-btn {
        padding: 1em;
      }
      .continually-autostart-actions__btn {
        background: #ffffff;
        border: 1px solid #afafaf;
        border-radius: 3px;
        padding: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        .continually-autostart-actions__icon
          + .continually-autostart-actions__label {
          margin-left: 0.66em;
        }
      }
      .continually-autostart-actions__option {
        border-bottom: 1px solid #ddd;
        padding: 1em 2em;
        position: relative;
      }
      .continually-autostart-actions__check {
        border-bottom: 1px solid #ddd;
        padding: 1em 2em 1em 3.6em;
        position: relative;
        .continually-autostart-actions__check-icon {
          position: absolute;
          z-index: 1;
          @include vertical-align;
          left: 1.6em;
          pointer-events: none;
        }
      }
      .continually-autostart-actions__check.checked {
        background-color: #f2f2f2;
      }
      .close-continually-message-button {
        position: absolute;
        left: -26px;
        top: 0;
        background-color: #9b9b9b;
        color: #fff;
        font-weight: 700;
        line-height: 1;
        @include wh(16px);
        padding: 4px;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0 0 8px 0 rgba(#000, 0.2);
        &:hover {
          opacity: 0.75;
        }
        img {
          display: block;
          @include wh(8px);
        }
      }
    }
    .hidden-appearance {
      &--bots {
        background: #f6ae28;
        padding: 1em;
        border-radius: 3px;
        margin-top: 1.5em;
        font-size: 1.2em;
        font-weight: 500;
      }
      .heading {
        margin-bottom: 1em;
        i {
          @include inline-block;
          margin-right: 0.3em;
          font-size: 2em;
          color: $black-light;
        }
        .label {
          @include inline-block;
          @include medium;
          font-size: 1.4em;
        }
      }
      .tooltip-container {
        display: inline-block;
        i {
          font-size: 1em;
          margin-right: 0;
          vertical-align: top;
          color: $black;
        }
        .tooltip__bubble {
          width: 16em;
          padding: 1em;
          text-align: center;
        }
        a {
          color: $brand-primary;
          pointer-events: auto;
        }
      }
      p {
        color: $black;
        font-size: 1.2em;
      }
    }
  }
  .col--summary {
    @include mq(max, $mq-md) {
      .dropdown-wrap,
      .summary-tags {
        display: none;
      }
    }
    .dropdown-wrap {
      margin: 1em 0;
    }
    .toggle-dropdown-options {
      .name {
        color: $black;
      }
    }
    .summary-tags {
      &__messages {
        min-width: 1.1em;
        text-align: center;
        display: inline-block;
        margin-right: 0.5em;
      }
      .tag i {
        font-size: 1.15em;
        @include font-smoothing;
      }
      // .tag--object-count {
      //     background-color: #A4CFFF;
      //     color: #fff;
      // }
      // .tag--object-type {
      //     background-color: #FF6868;
      //     color: #fff;
      // }
      // .tag--object-appt {
      //     background-color: #FFA502;
      //     color: #fff;
      // }
    }
  }
}

.slider-backdrop {
  opacity: 0;
  @include transition(opacity);
}

.slider-new-conversation {
  opacity: 0;
  pointer-events: none;
  left: -40em;
  &.slider-active {
    // animation-name: showSlider;
    // animation-duration: 0.2s;
    // animation-timing-function: ease;
    // animation-fill-mode: forwards;
    left: 0;
    opacity: 1;
    pointer-events: auto;
  }
  background-color: #fff;
  border: 1px solid #d0d0d0;
  border-top: none;
  box-shadow: 0 2px 4px 0 rgba(107, 107, 107, 0.5);
  position: fixed;
  z-index: $zindex-modal;
  top: 0;
  // left: 0;
  bottom: 0;
  width: 100%;
  @include transition;
  @include mq(max-height, $mq-md) {
    overflow: auto;
  }
  @include mq(min, 300px) {
    width: 32em;
  }
  .close-slider {
    position: absolute;
    top: 0.8em;
    right: 0.8em;
    text-decoration: none;
    color: $black-lighter;
    &:hover {
      color: $brand-green;
    }
    i {
      font-size: 1.4em;
    }
  }
  .templates {
    @include mq(min-height, $mq-md) {
      display: flex;
      flex-direction: column;
      height: 100vh;
    }
    header {
      padding: 3em 3.4em;
      border-bottom: 1px solid #ddd;
      .label {
        @include bold;
        font-size: 1.4em;
        text-transform: uppercase;
      }
    }
    .template {
      @include mq(min-height, $mq-md) {
        flex: 1;
      }
      position: relative;
      border-bottom: 1px solid #ddd;
      .btn--spinner {
        @include spinner(2.4em, 2px, $blue);
      }
    }
    .template .inner {
      display: flex;
      align-items: center;
      padding: 1.6em 2.8em;
      @include mq(max-height, $mq-md) {
        min-height: 10em;
      }
      @include mq(min-height, $mq-md) {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0 2.8em;
      }
      figure {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 6em;
        margin-right: 2em;
        @include mq(max-height, $mq-mbp) {
          width: 4em;
        }
        img {
          display: block;
          margin: 0 auto;
        }
      }
      .template-body {
        flex: 1;
        h3 {
          color: $blue-dark;
          @include medium;
          font-size: 1.6em;
          margin: 0;
          @include transition;
          @include mq(max-height, $mq-mbp) {
            font-size: 1.4em;
          }
        }
        p {
          color: $black-light;
          font-size: 1.2em;
          @include mq(max-height, $mq-mbp) {
            font-size: 1.1em;
          }
          margin: 0.5em 0 0;
        }
      }
      .template-actions {
        opacity: 0;
        height: 0;
        overflow: hidden;
      }
      &:hover {
        background-color: rgba(#b0b7d7, 0.12);
        .template-copy {
          opacity: 0;
          height: 0;
          overflow: hidden;
        }
        .template-actions {
          opacity: 1;
          height: auto;
          overflow: auto;
        }
      }
      &.disabled {
        pointer-events: none;
        figure,
        .template-body {
          opacity: 0.8;
        }
      }
      &.btn--processing {
        figure,
        .template-body {
          opacity: 0.1;
        }
      }
    }
    .template-actions {
      .btn-wrap {
        width: 100%;
      }
      .btn {
        width: 100%;
        display: block;
        margin: 0.3em 0;
        padding: 0.4em 1em 0.3em;
        font-size: 1.3em;
        @include medium;
        @include font-smoothing;
      }
      .btn--spinner {
        @include spinner(1.4em, 1px, #fff);
        margin-left: -0.7em;
        margin-top: -0.7em;
      }
    }
  }
}

.continually-sidebar--template-preview {
  background-color: #fff;
  position: fixed;
  z-index: $zindex-modal;
  top: 1.6em;
  right: 1.6em;
  bottom: 1.6em;
  border-radius: 5px;
  box-shadow: 0 -1px 14px 0 rgba(0, 0, 0, 0.2);
  animation-name: cxappear;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
  width: 0;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
  .conversation-js-preview {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .continually-close-button {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: rgba(#000, 0.1);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 14px;
    border-radius: 0 0 0 5px;
    @include transition;
    &:hover {
      background-color: rgba(#000, 0.2);
    }
  }
}

body[data-slider="true"] {
  .slider-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(white, 0.6);
    z-index: $zindex-modal-backdrop;
    opacity: 1;
  }
  .continually-sidebar--template-preview[data-cx-open="true"] {
    overflow: auto;
    opacity: 1;
    width: 100%;
    @include mq(min, 450px) {
      width: 370px;
    }
  }
}

.modal-confirm-duplicate-bot {
  .dropdown-wrap {
    position: relative;
    margin: 0 auto;
    max-width: 24em;
  }
  .toggle-dropdown-options {
    padding: 1em 3em 1em 1em;
    border: 1px solid $black-lighter;
    border-radius: 3px;
    @include transition;
    background-color: #fff;
    &:hover {
      border-color: $brand-blue;
    }
    &.dropdown-active {
      border-radius: 3px 3px 0 0;
    }
    .name-avatar-group {
      display: flex;
      align-items: center;
    }
    .avatar {
      flex-shrink: 0;
      margin-right: 0.8em;
      @include avatar(2.4em, 1.5em);
    }
    .name {
      flex: 1;
      font-size: 1.6em;
      @include truncate;
    }
    .dropdown-icon {
      flex: 0;
      position: absolute;
      right: 1em;
      @include vertical-align;
      @include transition(opacity);
      i {
        font-size: 1.4em;
      }
    }
  }
}

.warning-message {
  display: none;
  background: #ffffff;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 15px 22px;
  width: 44em;
  margin-left: auto;
  position: relative;
  &__title {
    @include medium;
    font-size: 1.8em;
    color: #000000;
    margin-bottom: 10px;
  }
  &__text {
    font-size: 1.3em;
    color: #9b9b9b;
    margin-bottom: 0;
  }
  &__arrow {
    position: absolute;
    top: 105%;
    right: 30%;
    width: 20px;
    height: 55px;
    z-index: 1;
  }
  .conversation-status {
    font-size: 0.77em;
    margin: 0 5px;
    vertical-align: bottom;
  }
}

div.warning-message[data-active-bots-cnt="0"] {
  display: block !important;
}

.conversations {
  &:not(.conversations--search-active) {
    .conversations__search {
      input[type="search"] {
        display: none;
      }
      button[type="submit"] {
        display: none;
      }
      .conversations__search-btn {
        display: flex;
      }
    }
  }
  &--search-active {
    @include mq(max, $mq-md) {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: -2.5em;
        right: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 3;
      }
      .conversations__filter {
        width: 100%;
        z-index: 3;
        position: relative;
        .conversations__search {
          width: 100%;
          input[type="search"] {
            display: block;
          }
          button {
            background: $brand-primary;
            .fa-search {
              color: white;
            }
          }
        }
        .conversations__search-btn-wrap {
          right: 1em;
        }
        .conversations__search-dropdown {
          border-right: 0;
          border-left: 0;
          border-radius: 0;
          display: block !important;
          opacity: 1 !important;
        }
        .conversations__filter-pulldowns,
        [data-role="new-conversation-button"] {
          display: none;
        }
        .container {
          padding: 0;
        }
      }
      .conversations__search {
        margin-right: 0;
      }
      .conversations__search-wrap {
        flex: 1 auto;
      }
      .conversations__custom-fields-btn-wrap,
      button[type="submit"] {
        display: none;
      }
      .conversations__search-btn {
        display: flex;
      }
    }
    .conversations__search {
      .tooltip {
        display: none;
      }
    }
  }
  &__filter {
    border-bottom: 1px solid #d8d8d8;
    margin-top: -2.5em;
    margin-bottom: 1em;
    background: white;
    @include mq(min, $mq-md) {
      margin-bottom: 3em;
    }
    .pulldown.pulldown--minimal {
      margin-right: 3em;
    }

    .pulldown-select {
      font-size: 1.6em;
      color: #6a6a6a;
      padding-right: 1.5em;
      @include medium;
      &--fixed {
        max-width: 6em;
      }
    }

    .pulldown-arrow {
      top: 45%;
      right: 1em;
      &:after {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid #787878;
        background: none;
      }
    }
  }
  &__filter-pulldowns {
    @include mq(max, $mq-md) {
      flex: 1 auto;
      display: flex;
      align-items: center;
    }
  }
  &__filter-in {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__search {
    position: relative;
    @include mq(min, $mq-md) {
      flex-basis: 23em;
    }
    input[type="search"] {
      font-size: 1.4em;
      width: 100%;
      padding-right: 2em;
      color: #797979;
      padding-top: 0.8em;
      padding-bottom: 0.8em;
      @include mq(max, $mq-md) {
        padding: 1.27em 5em 1.2em 1.2em;
        font-size: 16px;
        border: none;
        border-radius: 0;
        @include placeholder() {
          color: $black;
        }
      }
    }

    button {
      background: transparent;
      appearance: none;
      cursor: pointer;
      &:not(.btn--search) {
        border: 0;
        padding: 0 14px 0 0;
        height: 100%;
      }
    }
    .fa-search {
      color: #b6b6b6;
      font-size: 1.3em;
    }
    &-mobile-btn {
      @include mq(min, $mq-md) {
        display: none !important;
      }
    }
  }
  &__search-btn-wrap,
  button[type="submit"] {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__search-dropdown {
    position: absolute;
    top: 100%;
    background: #ffffff;
    border: 1px solid #c3c3c3;
    border-radius: 3px;
    width: 100%;
    z-index: 3;
    padding: 15px 15px 0 15px;
    label {
      cursor: pointer;
    }
    input[type="radio"] {
      margin-right: 6px;
    }
  }

  &__badge {
    font-size: 1.2em;
    display: inline-block;
    cursor: pointer;
    @include medium;
    @include mq(max, $mq-md) {
      margin-left: auto;
    }

    .fa-times {
      margin-left: 0.5em;
    }
  }

  &__no-results {
    text-align: center;
    margin-top: 7em;
    @include mq(max, $mq-md) {
      margin-top: 4em;
      padding: 0 4em;
      h3 {
        line-height: 1.5;
      }
      & ~ .helper-panel {
        display: none !important;
      }
    }
    h3 {
      font-size: 2.8em;
      color: $black;
      @include medium;
    }
    p {
      font-size: 1.6em;
      color: $black;
    }
  }
  &__helper {
    margin-top: 4em;
    background: #182e59;
    border: 1px solid #dddddd;
    border-radius: 6px;
    padding: 5em 4em 4em 6em;
    display: flex;
    @include mq(max, $mq-lg) {
      flex-direction: column;
    }
    .smartvideo-player {
      width: 100% !important;
    }
    .faq-links {
      a {
        background: rgba(#fff, 0.13);
        border: none;
        border-radius: 5px;
        color: white;
        margin-bottom: 0.625em;
        &:hover {
          background: rgba(#fff, 0.23);
        }
      }
    }
  }
  &__helper-first-col,
  &__helper-second-col {
    flex-grow: 1;
    @include mq(max, $mq-lg) {
      flex-basis: 100%;
      margin-bottom: 2em;
    }
    h2 {
      font-weight: 500;
      font-size: 2.2em;
      color: white;
    }
  }
  &__helper-first-col {
    flex-basis: 48%;
    @include mq(min, $mq-lg) {
      margin-right: 5em;
    }
  }
  &__helper-second-col {
    flex-basis: 37.5%;
  }
  &__search-btn {
    display: none;
    line-height: 1;
  }
  .btn--search {
    i[class^="fa"] {
      font-size: 0.9em;
      height: 1em;
    }
  }
}

.bot-helper {
  position: fixed;
  z-index: $zindex-tooltip;
  right: 2em;
  bottom: 1.5em;
  display: flex;
  justify-content: space-between;
  width: 46em;
  background: white;
  border-radius: 4px;
  &__icon {
    background-color: #7b1a8f;
    padding: 1em 0.5em;
    font-size: 2em;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    .far {
      color: white;
    }
  }
  &__close {
    padding: 2em 1em;
    button {
      background: transparent;
      border: none;
      &:hover {
        .fas {
          color: black;
        }
      }
    }
    .fas {
      color: #d8d8d8;
      font-size: 1.5em;
    }
  }
  &__content {
    padding: 2em;
  }
  &__title {
    font-size: 2em;
    color: #4f4f4f;
  }
  &__text {
    p,
    a {
      font-size: 1.4em;
    }
  }
}

.video-notification {
  background: #22559a;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  position: fixed;
  z-index: 2;
  right: 25px;
  bottom: 25px;
  width: 327px;
  height: 234px;
  padding: 15px 14px;
  @include mq(max, $mq-md) {
    display: none !important;
  }
}

.bot-agent {
  .toggle-dropdown-options {
    background: transparent;
    padding-right: 2em;
    .avatar {
      @include avatar(3em);
      border-radius: 50%;
    }
  }
  .conversation-author__select {
    width: 20em;
    right: 0;
    left: auto;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border: none;
    .option {
      padding-top: 0.8em;
      padding-bottom: 0.8em;
      .avatar {
        @include avatar(3em);
        border-radius: 50%;
      }
      .name {
        font-size: 1.4em;
      }
      .active-icon {
        width: 1.5em;
        height: 1.5em;
        line-height: 1.5em;

        i {
          font-size: 0.8em;
        }
      }
    }
  }
}
