// Avatars

.avatar {
  @include avatar;
  position: relative;
  overflow: hidden;
  text-align: center;
  @include inline-block;
  img {
    display: block;
    position: relative;
    z-index: 2;
    max-width: none;
  }
  &--circle {
    border-radius: 50%;
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
  &--35 {
    width: 3.5em !important;
    height: 3.5em !important;
    line-height: 3.5em;
    img {
      width: 3.5em !important;
      height: 3.5em !important;
    }
  }
}

.avatar--initials {
  background-image: linear-gradient(136deg, #1848e6 0%, #00dcb8 100%);
  &:before {
    content: attr(data-initials);
    @include bold;
    position: absolute;
    z-index: 1;
    color: white;
    text-transform: uppercase;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    vertical-align: middle;
  }
}

a.avatar {
  &:before {
    color: $brand-primary;
  }
  &:hover:before {
    color: darken($brand-primary, 10%);
  }
}

// .avatar-1 { @include avatar(40px,2em); }
// .avatar-2 { @include avatar(48px,2.4em); }
// .avatar-3 { @include avatar(56px,2.8em); }
// .avatar-4 { @include avatar(64px,3.2em); }
// .avatar-5 { @include avatar(72px,4.4em); }
// .avatar-6 { @include avatar(80px,4.8em); }
// .avatar-7 { @include avatar(88px,5.2em); }
// .avatar-8 { @include avatar(96px,5.6em); }
// .avatar-9 { @include avatar(104px,6em); }
