.body--onboarding {
  background-color: #f1f1f1;
  .modal-header-body {
    align-items: stretch;
  }
}

.onboarding-panel {
  position: relative;
  border: 1px solid rgba(#979797, 0.43);
  background-color: rgba(#f6f6f6, 0.43);
  border-radius: 4px;
  text-align: center;
  padding: 2.8em;
  margin: ($spacer-y * 2) auto;
  max-width: 96em;
  a.close-onboarding-panel {
    position: absolute;
    right: 1.6em;
    top: 1.6em;
    text-decoration: none;
    color: $black;
    svg {
      @include wh(16px, 16px);
    }
    &:hover svg {
      fill: $brand-primary;
    }
  }
  p.kick {
    font-size: 1.8em;
  }
  h2 {
    font-size: 3.6em;
  }
  h3 {
    font-size: 2.1em;
    color: $black-light;
  }
}

#modal-anonymous-onboarding {
  .host {
    margin-bottom: 3.6em;
    .avatar {
      @include inline-block;
      @include avatar(64px, 3.2em);
      margin-right: 2em;
    }
    .bio {
      @include inline-block;
    }
    .name {
      font-size: 2.5em;
      @include medium;
    }
    .role {
      font-size: 2.1em;
      @include medium;
      color: $black-light;
    }
  }
  h2,
  h3 {
    margin-bottom: 0.5em;
    @include medium;
  }
  p {
    font-size: 1.8em;
    margin-bottom: 1.5em;
    strong {
      @include medium;
    }
  }
  form {
    fieldset {
      input[type="text"],
      input[type="email"] {
        max-width: none;
      }
    }
  }
  form.logout {
    text-align: center;
  }
  .modal-footer {
    background-color: #f1f1f1;
    padding: 2em;
    margin-left: -3em;
    margin-right: -3em;
    margin-bottom: -3em;
    margin-top: 2em;
    text-align: center;
    @include mq(min, $mq-md) {
      margin-left: -4em;
      margin-right: -4em;
      margin-bottom: -4em;
    }
    svg {
      @include wh(31px, 32px);
      fill: #9b9b9b;
    }
  }
  &.modal {
    @include mq(min, $mq-md) {
      width: 550px;
      top: 5%;
      padding: 4em;
    }
  }
}

#modal-update-profile.modal {
  padding: 0;
  border-top: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 0;
  @include mq(min, $mq-sm) {
    left: 50%;
  }
  @include mq(min, $mq-lg) {
    width: 960px;
    top: 5%;
    width: 97%;
    height: auto;
    margin-bottom: 3em;
  }
  @include mq(min, $mq-xl) {
    width: 1145px;
  }
  > section {
    height: 100%;
    @include mq(min, $mq-lg) {
      height: auto;
    }
  }
  .complete-profile {
    .modal-header-body {
      align-items: center;
    }
    .modal-header {
      background: none;
      flex-basis: 37em;
      display: flex;
      @include mq(inside, $mq-md, $mq-lg) {
        padding-right: 5.5em;
      }
    }
    @include mq(max, $mq-md) {
      .modal-header-body {
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
      .modal-header {
        order: 2;
        padding-top: 1em;
        flex-shrink: 0;
        flex-basis: auto;
      }
      .modal-body {
        padding-top: 4em;
        flex-shrink: 0;
        flex-basis: auto !important;
      }
    }
    @include mq(max, $mq-sm) {
      .modal-header {
        display: none;
      }
      .modal-body {
        padding-top: 7em;
        &__wrap {
          justify-content: flex-start;
        }
      }
      h2 {
        font-weight: 400;
      }
      .login-form {
        height: 100%;
        display: flex;
        flex-direction: column;
        form {
          display: flex;
          flex-direction: column;
          flex: 1 auto;
          margin-left: 0;
          margin-right: 0;
          input[type="text"],
          input[type="email"],
          input[type="password"],
          input[type="url"],
          input[type="tel"],
          input[type="search"],
          input[type="number"],
          input[type="time"],
          input[type="date"] {
            font-size: 16px;
          }
        }
        .btn-wrap {
          margin-top: auto;
          margin-left: 0;
          margin-right: 0;
        }
      }
      .agree-terms {
        max-width: 34em;
        text-align: left;
        .check {
          display: flex;
          align-items: center;
          line-height: 1.5;
          &__icon {
            background: #f7f7f7;
            padding: 1.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1em;
            border-radius: 4px;
          }
        }
        input {
          margin: 0;
          transform: scale(1.3);
        }
      }
      input[type="submit"] {
        display: block;
        width: 100%;
        background: $blue-secondary;
        border: $blue;
        font-size: 1.8em;
        padding: 0.9em 2.2em 0.7em;
      }
    }
    .header--auth {
      display: none;
    }
    .sidebar-preview {
      max-width: 370px;
      margin: auto;
      &__in {
        background: #ffffff;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.14);
        border-radius: 4px 5px 5px 5px;
      }
      .title {
        font-size: 1.3em;
      }
      .desc {
        font-size: 1.4em;
        color: white;
      }
      .continually-conversations-list-header {
        background-image: linear-gradient(129deg, #303dc8 0%, #4c7fff 100%);
        padding-top: 3em;
        padding-bottom: 2.5em;
        padding-left: 1.5em;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      .continually-chat-team {
        min-height: 40em;
      }
      .post--admin {
        padding-left: 3.5em;
      }
      .message {
        &__content {
          font-size: 1.5em;
        }
      }
      .message--agent-takeover {
        pointer-events: none;
        textarea {
          font-size: 1.5em;
        }
        button {
          width: 4em;
          height: 4em;
          i {
            font-size: 1.6em;
          }
        }
      }
    }
  }
  .modal-header-body {
    height: 100%;
    @include mq(min, $mq-md) {
      display: flex;
      flex-direction: row-reverse;
    }
    @include mq(min, $mq-lg) {
      height: auto;
    }
  }
  .modal-header {
    background-color: $blue;
    background-image: linear-gradient(214deg, #1848e6 0%, #00dcb8 115%);
    background-size: cover;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-basis: 30em;
    padding: 5em 3em 3em 3em;
    display: none;
    height: 100%;
    @include mq(min, $mq-md) {
      padding: 3em;
      display: flex;
    }
    @include mq(min, $mq-lg) {
      min-height: 600px;
      height: auto;
    }
    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1em;
      margin-left: auto;
      margin-right: auto;
      img {
        border-radius: 50%;
        width: 40px;
      }
    }
    figcaption {
      font-size: 1.4em;
      color: #ffffff;
      line-height: 1.5em;
      text-align: left;
      margin-left: 0.7em;
      font-style: normal;
    }
    blockquote {
      p {
        @include italic;
        color: #ffffff;
        text-align: center;
        line-height: 1.5em;
        &:before {
          content: "“";
        }
        &:after {
          content: "”";
        }
      }
    }
    h2 {
      margin-bottom: 0.5em;
      font-weight: 500;
      text-align: left;
    }
  }
  .modal-body {
    padding: 3em;
    flex: 3 auto;
    background-color: #fff;
    @include mq(min, $mq-md) {
      padding: 6em;
      height: 100%;
    }
    &__wrap {
      max-width: 53em;
      margin: 0 auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .upload-new-avatar {
    text-align: left;
  }
  .avatar--current {
    @include avatar(12em, 6em);
    display: block;
    margin: 0 auto 1em;
    border: 1px solid #ffffff;
    box-shadow: 0 0 2px 0 rgba(65, 65, 65, 0.5);
    border-radius: 2px;
  }
  fieldset.upload-new-avatar {
    text-align: center;
    input[type="file"] {
      @include offscreen;
    }
    .btn--upload-input {
      @include regular;
      display: inline-block;
      color: $black-light;
      font-size: 1.2em;
      cursor: pointer;
      text-decoration: underline;
      @include transition;
      &:hover {
        color: $black;
      }
    }
  }
  p.quiet {
    font-size: 1.5em;
    color: #8d8d8d;
  }
  @include mq(min, $mq-sm) {
    .grid-wrap {
      margin-bottom: 1.6em;
    }
  }
  .modal-body--confirmation {
    text-align: center;
    padding-top: 10em;
    height: 100%;
    @include mq(min, $mq-lg) {
      padding-top: 14em;
      padding-bottom: 14em;
    }
    .check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.8em;
      height: 3.8em;
      border-radius: 3.8em;
      background-color: $brand-green;
      color: #fff;
      margin: 0 auto 2em;
      i {
        font-size: 2em;
      }
    }
    h2 {
      font-size: 2.8em;
      font-weight: 400;
      margin-bottom: 0.5em;
      @include mq(min, $mq-md) {
        font-size: 2.6em;
      }
    }
    h3 {
      @include regular;
      font-size: 1.8em;
      line-height: 1.68;
      margin-bottom: 1em;
      margin-left: auto;
      margin-right: auto;
      @include mq(min, $mq-md) {
        font-size: 1.6em;
      }
      strong {
        @include medium;
      }
    }
    .btn--primary {
      padding-left: 2.8em;
      padding-right: 2.8em;
      @include mq(max, $mq-sm) {
        margin-top: auto;
        font-size: 1.8em;
        padding: 0.9em 2.2em 0.7em;
        display: block;
        width: 100%;
      }
    }
  }
}

#modal-update-profile.modal-update-profile--anonymous.modal {
  @include mq(min, $mq-md) {
    width: 700px;
  }
}
