section#embed-code {
    section {
        margin-bottom: 6em;
    }
}

.lead-capture {
    section {
        // margin-bottom: $spacer * 2;
    }
    .preview-slice {
        background-color: #f7f7f7;
        padding: 2em 0;
        margin-bottom: 2em;
    }
    .form-preview {
        position: relative;
        padding: $spacer;
        iframe { min-height: 50vh; }
    }
    .embed-code {
        position: relative;
        overflow: auto;
    }
    .embed-code pre {
        margin-bottom: $spacer;
        max-height: 300px;
        overflow: auto;
    }
    .title--legend {
        position: absolute;
        left: 1em;
        top: -1em;
        background-color: $brand-primary;
        padding: .5em;
        font-size: 1.2em;
        @include uppercase;
        @include medium;
        color: #fff;
    }
    h3 {
        @include medium;
        font-size: 2em;
    }
}

.table-docs>tbody>tr>td,
.table-docs>tbody>tr>th,
.table-docs>tfoot>tr>td,
.table-docs>tfoot>tr>th,
.table-docs>thead>tr>td,
.table-docs>thead>tr>th {
    font-size: 1.5em;
}