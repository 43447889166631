.conversation-author__select {
    opacity: 0;
    pointer-events: none;
    &.dropdown-active {
        animation: showDropdown 0.1s ease;
        opacity: 1;
        pointer-events: auto;
    }
    .conversation-js-preview & {
        font-size: 115%;
        max-width: 35em;
    }
    text-align: left;
    padding: 1.6em;
    border-radius: 8px;
    background-color: #fff;
    position: absolute;
    z-index: 9;
    top: calc(100% + 1em);
    left: -0.5em;
    border: 1px solid #ddd;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
    &:after, &:before {
        left: 2.5em;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    &:after {
        bottom: calc(100% - 1px);
        border-color: rgba(#fff, 0);
        border-bottom-color: #fff;
        border-width: 7px;
        margin-left: -7px;
    }
    &:before {
        bottom: 100%;
        border-color: rgba(#ddd, 0);
        border-bottom-color: #ddd;
        border-width: 7px;
        margin-left: -7px;
    }
    .option {
        padding: 0.7em 1.2em 0.7em 0.8em;
        color: $black;
        border: 1px solid rgba(#979797,0.6);
        border-radius: 3px;
        margin-bottom: 1em;
        white-space: nowrap;
        cursor: pointer;
        position: relative;
        width: 30em;
        &:hover {
            border-color: $black;
        }
        &.active {
            border-color: #a1a1a1;
        }
        .member-info {
            display: flex;
            align-items: center;
        }
        .avatar, .name, .link--change-avatar {
            @include inline-block;
        }
        .avatar {
            flex-shrink: 0;
            margin-right: .8em;
            @include avatar(2.4em, 1.5em);
        }
        .name {
            flex: 1;
            @include truncate;
            font-size: 1.6em;
            margin-right: 0.5em;
        }
        .link--change-avatar {
            font-size: 1.1em;
            color: $black-light;
            &:hover {
                color: $brand-primary;
            }
        }
        .active-icon {
            position: absolute;
            right: 0.7em;
            top: 50%;
            transform: translateY(-50%);
            @include circle(2em);
            background-color: $brand-primary;
            i {
                color: #fff;
                position: relative;
                top: 0.2em;
                font-size: 1em;
            }
        }
    }
    .option--pending {
        cursor: not-allowed;
        color: $black;
        border: 1px dashed rgba(#979797,0.6);
        background-color: transparent;
        padding-right: 7em;
        &:hover {
            color: $black;
            border-color: inherit;
        }
        .avatar, .name {
            opacity: 0.33;
        }
        .pending {
            position: absolute;
            right: 0.7em;
            top: 1.2em;
            background-color: #ffe144;
            line-height: 1;
            border-radius: 5px;
            padding: 0.4em 0.6em 0.2em;
            span {
                @include uppercase;
                font-size: 1em;
                color: $black;
                line-height: 1;
            }
        }
    }
    .add-team-mate {
        position: relative;
        [role="link"] {
            display: inline-block;
            color: $black-light;
            border-bottom: 1px solid $black-light;
            font-size: 1.4em;
            @inclue medium;
            text-decoration: none;
            &:hover {
                color: $brand-green;
                border-bottom: 1px solid $brand-primary;
            }
        }
        &:after {
            position: absolute;
            z-index: 2;
            left: 0;
            right: 0;
            bottom: calc(100% + 1em);
            height: 2em;
            content: "";
            background-image: linear-gradient(to top, #fff, rgba(#fff,0));
            pointer-events: none;
        }
    }
}

.conversation-author__select__header {
    position: relative;
    border-bottom: 1px solid #ddd;
    padding: 0 1.6em 0.8em;
    margin-bottom: 1em;
    text-align: center;
    .heading {
        font-size: 1.5em;
        color: $black-light;
    }
    .close-dropdown {
        position: absolute;
        right: 0;
        top: 0;
        color: $black-lighter;
        @include transition;
        &:hover { color: $black; }
        i { font-size: 1.3em; }
    }
}

.conversation-author__select__options {
    position: relative;
    max-height: 22em;
    overflow: auto;
    margin-bottom: 1em;
    padding-bottom: 0.5em;
}

.modal-confirm-duplicate-bot,
.list--all-conversations__item__body {
    .conversation-author__select {
        &:after, &:before { display: none; }
        box-shadow: none;
        top: 100%;
        left: 0;
        right: 0;
        transform: translateX(0);
        border-radius: 0;
        border-top: none;
        padding: 0;
        .option {
            padding: 1em;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid $black-lighter;
            margin: 0;
            width: 100%;
            &:hover { background-color: #FAFAFA; }
        }
        .option--pending:hover { background-color: #fff; }
        .add-team-mate {
            position: relative;
            padding: 1em;
            &:after {
                display: none;
            }
        }
    }
    .conversation-author__select__options {
        margin-bottom: 0;
    }
}