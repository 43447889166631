#modal-invite-participants {
    form.form-inline {
        .fieldset-rows {
            margin-bottom: 2em;
        }
        fieldset {
            margin-bottom: .6em;
            input[type=text],
            input[type=email],
            input[type=password],
            input[type=url],
            input[type=tel],
            input[type=search],
            input[type=number],
            input[type=time] {
                display: inline-block;
                width: calc(50% - 0.4em);
                margin-right: 0.2em;
                max-width: none;
                font-size: 1.4em;
            }
            &.link {
                margin-top: 1em;
            }
        }
    }
}