.header--auth {
  background-image: url(../images/shared/flow.png);
  background-size: cover;
  background-position: 50% 60%;
  color: #fff;
  text-align: center;
  padding: 6em 0;
  &--tall {
    background-position: 50% 100%;
    padding-bottom: 16em;
  }
  .modal & {
    padding: 2em 0;
  }
  [data-status="confirmed"] & {
    display: none;
  }
  @include mq(min, $mq-md) {
    display: none;
  }
  figure {
    margin: 0 auto;
    max-width: 14em;
  }
  h2 {
    margin: 1em 0 0;
    font-size: 2.8em;
    @include regular;
    @include font-smoothing;
  }
  p {
    margin: 1em 0 0;
    font-size: 1.8em;
    @include font-smoothing;
  }
}

.login-appsumo {
  h2 {
    margin: 1em 0 0;
    font-size: 2.8em;
    @include regular;
    @include font-smoothing;
  }
  p {
    margin-top: 1em;
    font-size: 1.8em;
    @include font-smoothing;
  }
}

.body {
  &--auth {
    @include mq(min, $mq-md) {
      min-height: auto;
      height: 100%;
    }
  }
  &--register {
    .header--auth {
      padding-bottom: 2em;
      padding-top: 2em;
      flex-shrink: 0;
      display: none;
    }
    @include mq(min, $mq-md) {
      .login-auth__top {
        display: none;
      }
    }
    .login-auth__sidebar {
      background-image: linear-gradient(
        149deg,
        #1848e6 0%,
        #00dcb8 100%
      ) !important;
    }
  }
}
.login-auth {
  @include mq(min, $mq-md) {
    height: 100%;
    min-height: 600px;
  }
  &--slider {
    .login-auth__sidebar {
      padding-top: 3em;
      padding-bottom: 3em;
    }
    @include mq(max, $mq-md) {
      height: 100%;
      flex: 1 auto;
    }
  }

  &__top {
    display: none;
    text-align: center;
    @include mq(min, $mq-md) {
      display: block;
    }
  }
  &__form {
    color: $black;
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include mq(max, $mq-md) {
      position: absolute;
      margin-top: auto;
      top: 16em;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      input[type="text"],
      input[type="email"],
      input[type="password"],
      input[type="url"],
      input[type="tel"],
      input[type="search"],
      input[type="number"],
      input[type="time"],
      input[type="date"] {
        font-size: 16px;
      }
    }
    figure {
      margin: 3em auto;
      max-width: 14em;
    }
  }
  &__sidebar {
    flex-basis: 33%;
    flex-shrink: 0;
    background-image: url(../images/shared/login-sidebar-bg.svg) !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    height: 100%;
    flex-direction: column;
    padding: 4em 2.5em;
    color: white;
    display: none;
    @include mq(min, $mq-md) {
      display: flex;
    }
    @include mq(min, $mq-lg) {
      padding: 5em;
    }
    .flexslider {
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0;
      border-radius: 0;
      height: 100%;
      &__subtitle {
        font-size: 1.8em;
      }
      .flex-viewport,
      .slides,
      .slides > li {
        height: 100%;
      }
      .slides {
        max-width: 350px;
        margin: auto;
        > li {
          font-size: 1em;
          display: flex !important;
          flex-direction: column;
          justify-content: center;
          h4 {
            font-size: 2.2em;
            font-weight: 500;
          }
          p {
            font-size: 1.6em;
          }
        }
        blockquote {
          margin-top: 0;
          img {
            width: 48px;
            height: 48px;
          }
        }
      }
      .flex-control-nav {
        bottom: 0;
        z-index: 2;
        li {
          a {
            background: rgba(255, 255, 255, 0.53);
            &.flex-active {
              background-color: white;
            }
          }
        }
      }
    }
    .marketing-blog-post {
      margin: auto;
      @include mq(min, $mq-lg) {
        max-width: 90%;
      }
      figure {
        background-color: #4287d7;
      }

      figure img {
        width: 100%;
        border-radius: 15px 15px 0 0;
      }

      figure a {
        display: block;
      }

      .post__content {
        border-top: 1.5em solid #4287d7;
        background-color: #fff;
        border-radius: 0 0 15px 15px;
        padding-bottom: 0.1em;
      }

      .post__content .post-title {
        margin-top: 4em;
        padding: 0 1.8em;
      }

      .post__content .post-title h2 {
        font-size: 2em;
        margin-bottom: 0.75em;
        font-weight: 500;
      }

      .post__content .post-title h2 a {
        color: #22314d;
        text-decoration: none;
      }

      .post__content .post-title h2 a:hover {
        text-decoration: underline;
      }

      .post__content .post-excerpt {
        padding: 0 1.8em;
        margin-bottom: 2.4em;
      }

      .post__content .post-excerpt p {
        font-size: 1.6em;
        color: #595959;
      }
    }
    header {
      p {
        font-size: 1.8em;
      }
    }
    blockquote {
      margin-top: auto;
      > p {
        font-style: italic;
        span {
          font-style: normal;
        }
      }
      strong {
        text-transform: uppercase;
      }
      img {
        width: 48px;
        height: 48px;
      }
      a {
        color: #7fffef;
      }
    }
  }
}

.auth__footer {
  margin: 0 auto;
  max-width: 38em;
  text-align: center;
  padding-top: 3em;
  p {
    font-size: 1.4em;
  }
  a {
    display: inline-block;
    margin: 0 0.3em;
    color: $black;
    &:hover {
      color: $brand-primary;
    }
  }
}

.auth__form {
  &-wrap {
    margin: 0 auto;
    width: 100%;
    max-width: 40em;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.3);
    padding: 2em;
    @include mq(min, $mq-md) {
      padding: 3em;
      width: 40em;
      margin: 0 auto;
    }
    @include mq(min, $mq-lg) {
      padding: 4em;
    }
    &--lg {
      max-width: 53em;
      text-align: center;
      box-shadow: none;
      width: auto;
      background: none;
      margin-top: 2em;
      @include mq(min, $mq-md) {
        padding: 0;
        margin-top: 0;
      }
      input {
        margin: auto;
      }
    }
    h1 {
      text-align: center;
      font-size: 2.8em;
      @include regular;
      margin: 0 0 0.7em;
    }
    p.lead {
      text-align: center;
      font-size: 1.7em;
      color: $black-light;
    }
    fieldset {
      margin-bottom: 1.5em;
      position: relative;
      &.check label.check {
        margin-bottom: 0;
      }
    }
    .btn-wrap {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .btn {
      padding-top: 0.9em;
      padding-bottom: 0.8em;
    }
    .btn--link {
      margin-top: 1em;
    }
  }

  &-help-text {
    font-size: 1.6em;
    color: #b1b1b1;
    margin-top: 2em;
    .body--reset & {
      margin-top: 1em;
      margin-bottom: 0;
    }
    .fa {
      color: #d8d8d8;
      margin-right: 0.2em;
      font-size: 1.1em;
    }
    &--success {
      color: $brand-green;
      .fa {
        color: inherit;
      }
    }
    &--fail {
      color: $red;
      @include medium;
      .fa {
        color: inherit;
      }
    }
    &--fail-bg {
      color: white;
      background: $red;
      padding: 0.5em 1em;
      border-radius: 4px;
    }
  }
}

.password-success {
  .container {
    padding-top: 3em;
    @include mq(min, $mq-md) {
      padding-top: 5em;
    }
    @include mq(min, $mq-lg) {
      padding-top: 7em;
    }
  }
}

.register__met-before__welcome {
  background-color: #f1f1f1;
  text-align: center;
  border-radius: 3px 3px 0 0;
  padding: 2.4em;
  margin: -2em -2em 3.2em;
  @include mq(min, $mq-md) {
    margin: -3em -3em 3.2em;
  }
  @include mq(min, $mq-lg) {
    margin: -4em -4em 3.2em;
  }
  h2 {
    font-size: 1.8em;
    margin-bottom: 0.3em;
    @include medium;
  }
  p {
    max-width: 20em;
    margin: 0 auto;
  }
}

.passport-authorize__request {
  margin-top: 3.2em;
  text-align: center;
  h1 {
    font-size: 2.8em;
    @include medium;
    margin-bottom: 1em;
  }
  h2 {
    font-size: 1.4em;
    @include uppercase;
    @include medium;
    color: $black-light;
  }
  .scopes {
    margin: 3.2em 0;
    ul {
      margin: 0 auto;
      max-width: 24em;
      text-align: left;
    }
    li {
      margin-bottom: 0.4em;
    }
  }
  .buttons {
    padding-top: 1.6em;
    form {
      @include inline-block;
      margin: 0 0.2em;
    }
  }
}

.headway-item {
  display: block;
  clear: both;
  line-height: 19px;
  padding: 7px 0px;
  color: white;
  text-decoration: none;
  margin-bottom: 1.5em;
  &:hover {
    color: inherit;
    text-decoration: none;
    .headway-item__title {
      text-decoration: underline;
    }
  }
  &__label {
    border-radius: 10px;
    font-size: 1.2em;
    padding: 0 0.5em;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
    &.new {
      background: #3778ff;
      &:before {
        content: "New";
      }
    }
    &.fix {
      background: #ff4722;
      &:before {
        content: "Fix";
      }
    }
    &.improvement {
      background: #b26cee;
      &:before {
        content: "Improvement";
      }
    }
    &.request-for-feedback {
      background: #3aaa55;
      &:before {
        content: "Request for feedback";
      }
    }
    &.coming-soon {
      background: #ffc505;
      &:before {
        content: "Coming soon";
      }
    }
  }
  &__short {
    display: block;
    font-size: 1.4em;
    line-height: 1.4;
    margin-top: 0.2em;
    opacity: 0.8;
  }
  &__title {
    color: white;
    font-size: 1.6em;
    line-height: 1.5;
    vertical-align: middle;
  }
}
