.body--conversation {
  overflow: hidden;
  .continually-timeline {
    transform: translateZ(0);
  }
}

.conversation--empty,
.conversation--failed {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation--failed {
  .failure-message {
    padding: 0 3.2em;
    text-align: center;

    .emoji {
      font-size: 3.2em;
      margin-bottom: 0.5em;
    }

    h3 {
      // @include medium;
      font-weight: 500;
    }

    p {
      font-size: 1.8em;
    }

    a:not(.btn) {
      color: $black;

      &:hover {
        color: $black-light;
      }
    }

    .btn-wrap {
      margin-bottom: 1em;
    }
  }
}

.loading-conversation-preview {
  text-align: center;
  padding: 8em 4em;
  // margin-bottom: 4em;

  .spinner {
    @include spinner(2em, 1px);
    margin: 0 auto 1em;
  }

  figure {
    max-width: 6.4em;
    margin: 0 auto 1em;
    opacity: 0.5;
  }

  p {
    margin-bottom: 0;
    @include italic;
    font-size: 1.3em;
  }
}

.continually-container {
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: flex-start;
  position: absolute;
  overflow: hidden;
  &--full {
    font-size: 80%;
    overflow: visible;
    border-radius: 0;
    .continually-conversation-header {
      display: none;
    }
    .continually-conversation-body {
      &:after {
        content: none;
      }
    }
    .continually-chat,
    .continually-chat-team {
      .skip--question {
        a:not(:empty) {
          background: white;
          padding: 0.5em 1em;
          border-radius: 20px;
        }
      }
      .message--text-input,
      .message--textarea {
        input,
        textarea {
          background: white;
        }
      }

      .continually-timeline {
        .message .btn--white:hover,
        .message--checkbox fieldset label:hover,
        .message--radio fieldset label:hover,
        .message--radio input:checked + label,
        .message--radio input:checked + .validationMessage + label,
        .message--checkbox input:checked + label,
        .message--checkbox input:checked + .validationMessage + label {
          background: white !important;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
          }
        }
        &--rounded {
          .message .btn--white:hover,
          .message--checkbox fieldset label:hover,
          .message--radio fieldset label:hover,
          .message--radio input:checked + label,
          .message--radio input:checked + .validationMessage + label,
          .message--checkbox input:checked + label,
          .message--checkbox input:checked + .validationMessage + label {
            &:before {
              border-radius: 25px;
            }
          }
        }
      }
    }

    .chat--notification {
      border: none;
      border-radius: 26.5px;
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      &__join-leave {
        padding: 1.5em 2.3em;
        justify-content: flex-start;
        .avatar {
          width: 30px !important;
          height: 30px !important;
          border-radius: 30px;
          min-width: 30px;
          img {
            width: 100% !important;
            height: 100% !important;
            border-radius: 30px;
          }
        }
        .text {
          color: $black;
        }
      }
    }
    .continually-conversation-chat-body {
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 1.2em 1.8em 3.2em 1.2em;
      overflow-anchor: none;
    }
    .continually-timeline {
      width: 370px;
      margin: auto;
      overflow: visible !important;
      @include mq(max, 450px) {
        width: 100%;
      }
    }
    .continually-conversation-footer {
      max-width: 370px;
      width: 100%;
      margin: auto;
    }
    .continually-conversation-footer-branding {
      display: none;
    }
    .message--agent-takeover {
      background: none;
      border-top: 0;
      textarea {
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.5);
        color: white;
        @include placeholder {
          color: white;
        }
      }
      button {
        &:disabled {
          background-color: rgba(0, 0, 0, 0.2) !important;
        }
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.continually-conversation-header-team,
.continually-conversation-header {
  position: relative;
  background-color: $brand-green;
  color: #fff;
  padding: 1em 11em 1em 2em;
  min-height: 75px;
  display: flex;
  align-items: center;
  &--padding-left {
    padding-left: 7.5em !important;
  }
  &--padding {
    padding-top: 4.3em;
  }
  &--multiple-avatars {
    background: none;
    .avatar {
      & + .avatar {
        margin-left: -12px;
        z-index: 1;
      }
    }
    .conversation-header__info {
      .desc {
        display: none;
      }
    }
  }

  .conversation-header__host {
    flex: 0 auto;
    margin-right: 1.6em;
    position: relative;

    .avatar {
      @include avatar(40px, 16px, 40px);

      @include mq(max-height, 668px) {
        @include avatar(30px, 16px, 30px);
      }
    }
  }

  .conversation-header__host-status {
    position: absolute;
    right: 0;
    bottom: 0;
    background: #6dd400;
    z-index: 2;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    &--offline {
      background: #b3b3b3;
    }
  }

  .conversation-header__info {
    flex: 1;
    font-family: $font-family-sans-serif;

    .title {
      font-size: 1.75em;
      word-break: break-word;
      font-weight: 500;
      margin-bottom: 2px;
      @include mq(max-height, 668px) {
        font-size: 1.5em;
      }
    }

    .desc {
      font-size: 1.75em;
      @include mq(max-height, 668px) {
        font-size: 1.5em;
      }
      font-weight: 500;
      color: rgba(#fff, 0.7);
    }
  }

  .live-chat-active {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    right: 0.8em;
    top: calc(100% + 0.8em);
    background-color: #56e255;
    border-radius: 2px;
    line-height: 1;
    padding: 0.4em 0.8em;

    .label {
      @include uppercase;
      // @include bold;
      font-weight: 700;
      @include font-smoothing;
      font-size: 1.2em;
      line-height: 1;
    }
  }

  .restart-bot {
    position: absolute;
    z-index: 1;
    right: 40px;
    top: 0;
    @media (max-device-width: 450px) {
      right: 40px;
      top: 7px;
    }

    a {
      border-radius: 4px;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: white;
      @include transition(background-color);

      @media (max-device-width: 450px) {
        width: 30px;
        height: 30px;
      }

      &:hover {
        background-color: rgba(black, 0.2);
        color: #fff;
      }

      svg {
        width: 13px;
        height: 13px;
        fill: currentColor;
      }
    }
  }
}

.continually-conversation-body {
  position: relative;
  flex: 1;

  &:after {
    background-image: linear-gradient(
      to top,
      rgba(#fff, 1) 0%,
      rgba(#fff, 0) 100%
    );
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    pointer-events: none;
    height: 4.8em;
  }

  .continually-conversation-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .continually-conversation-chat-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .continually-timeline {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 1.2em 1.8em 3.2em 1.2em;
      overflow-anchor: none;
    }
  }
}

.continually-conversation-footer {
  flex: 0 auto;
  min-height: 1px;
  max-height: 100%;
  transition: max-height 1s;
  &--font-sm {
    .message--agent-takeover textarea {
      font-size: 13px;
    }
  }
  &--font-md {
    .message--agent-takeover textarea {
      font-size: 15px;
    }
  }
  &--font-lg {
    .message--agent-takeover {
      textarea {
        font-size: 17px;
      }
    }
  }
  &.hidden,
  &.hidden-live-chat-object {
    max-height: 0;
  }

  .message--agent-takeover {
    textarea {
      .body--ios & {
        font-size: 16px;
      }
    }
    button {
      .body--ios & {
        width: 42px;
        height: 42px;
        i {
          font-size: 14px;
        }
      }
    }
  }
}

.continually-conversation-footer-branding {
  background-color: #f1f1f1;
  padding: 2em;
  border-radius: 0 0 5px 5px;
  text-align: center;
  position: relative;
  z-index: 2;
  font-family: $font-family-base;

  @include mq(max-height, 668px) {
    padding: 1em;
  }

  a,
  .a {
    text-decoration: none;
    color: $black;
    font-size: 1.4em;
  }

  span,
  figure {
    @include inline-block;
    margin: 0 0.15em;
  }

  figure {
    margin-top: -2px;
    img {
      max-width: 30px;
      max-height: 30px;
    }
  }

  span {
    font-size: 1.3em;
  }
}

.conversation-after {
  p.lead {
    margin-bottom: 1.618em;
  }

  form.logout {
    text-align: center;
  }
}

.continually-mobile-hidden {
  @include mq(max, 450px) {
    display: none !important;
  }
}
