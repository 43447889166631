.body--opportunities {
  @include mq(max, $mq-md) {
    background: #f1f1f1;
  }
  .main {
    @include mq(max, $mq-md) {
      overflow-x: hidden;
      flex: 1 auto;
      &--filters-shown {
        &:before {
          content: "";
          background: rgba(0, 0, 0, 0.75);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .leads-filters-flyout {
          visibility: visible;
          opacity: 1;
          transform: translateX(0);
          height: 100%;
          position: fixed;
          z-index: 2;
          right: 0;
          overflow: visible;
          overflow-y: auto;
        }
      }
    }
    &--app {
      @include mq(min, $mq-md) {
        display: flex;
        flex: 1 auto;
      }
    }
  }
  .main--app--no-flex {
    @include mq(min, $mq-md) {
      display: block !important;
    }
  }
  .app--content {
    flex: 1;
    position: relative;
  }

  .btn--dropdown-options__dropdown {
    left: auto;
  }

  .no-results {
    position: relative;
    margin-top: 5em;
    margin-bottom: 30em;
    text-align: center;
    // &__arrow {
    //     position: absolute;
    //     bottom: 120%;
    //     left: 10%;
    //     width: 37px;
    //     height: 62px;
    //     transform: scaleX(-1);
    //     @include mq(min, 1315px) {
    //         left: auto;
    //         right: 20%;
    //         transform: none;
    //     }
    //     @include mq(min, 1700px) {
    //         right: 14%;
    //     }
    //     @include mq(min, 2300px) {
    //         right: 10%;
    //     }
    // }
  }
}

.close-filters-btn {
  padding: 0;
  font-size: 4em;
  line-height: 1;
  position: fixed;
  left: 0.3em;
  top: 2em;
  display: none;
  color: white;
  z-index: 1;
}

.aside-opportunities {
  background-color: $black;
  padding: 2em 1em;
  transition: width 0.2s ease-in-out;
  width: 26em;
  position: relative;
  z-index: 1;
  display: none;
  @include mq(max, $mq-md) {
    width: 100%;
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
    padding: 0;
    height: 0;
    width: 0;
    transition: transform 0.3s;
    overflow: hidden;
    &__header {
      display: none;
    }
    .aside-opportunities__header-icon {
      display: none;
    }
  }
  &[data-sidebar="collapsed"] {
    width: 7em;
    .js_conversations_filters {
      visibility: hidden;
      opacity: 0;
    }
    .aside-opportunities__header {
      flex-direction: column-reverse;
    }
    .aside-opportunities__header-text {
      text-align: center;
      span {
        display: inline-block;
      }
    }
    .aside-opportunities__header-icon {
      transform: rotate(180deg);
      margin-bottom: 1em;
    }
  }
  .js_conversations_filters {
    transition: 0.2s ease-in-out;
  }

  &__header {
    margin-bottom: 2.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__header-text {
    font-size: 1.6em;
    color: #ffffff;
    @include medium;
    span {
      display: none;
    }
  }
  &__header-icon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d8d8d8;
    padding: 0;
    background: transparent;
    cursor: pointer;
    &:hover {
      border-color: white;
      svg {
        fill: white;
        stroke: white;
      }
    }
    svg {
      width: 14px;
      height: 5px;
    }
  }

  &__panel {
    padding: 3.2em 1.6em;
    h3 {
      @include uppercase;
      @include medium;
      font-size: 1.4em;
      color: #fff;
      margin-bottom: 1em;
    }
  }

  &__link {
    font-size: 1.4em;
    text-decoration: none;
    color: #fff;
    padding: 0.8em 1em 0.6em;
    border-radius: 3px;
    background-color: rgba(#000, 0.2);
    border: 1px solid transparent;
    margin-bottom: 0.4em;
    @include medium;
    display: flex;
    align-items: center;
    user-select: none;
    @include mq(min, $mq-md) {
      max-width: 16em;
    }
    &:hover {
      color: #fff;
      background-color: rgba(#000, 0.1);
      text-decoration: none;
    }
    &.active {
      background-color: #6c6c6c;
      border-color: #7a7a7a;
    }
    &.empty {
      opacity: 0.66;
      pointer-events: none;
    }
    .label {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .count {
      white-space: nowrap;
      text-align: right;
      margin-left: 1em;
    }
  }
}

.header-opportunities {
  padding: 2em 2.5em 0;
  @include mq(max, $mq-md) {
    background: $black;
    padding-left: 0;
    padding-right: 0;

    h3 {
      color: white;
    }
  }
  &--blank-state {
    background-color: #f1f1f1;
    h2 {
      margin-bottom: 0.25em;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  &__row {
    padding-top: 2em;
    display: flex;
    justify-content: space-between;
    @include mq(max, $mq-md) {
      margin-bottom: 1em;
      align-items: center;
      padding: 2em 2.5em 1em 2.5em;
    }
    &.search-active {
      @include mq(max, $mq-md) {
        input[type="search"] {
          display: block;
        }
        .header-opportunities__search {
          width: 100%;
        }
        .filter-opportunities--segments,
        .filter-opportunities-right > .btn {
          display: none;
        }
        .filter-opportunities-right {
          flex-grow: 1;
        }
        .header-opportunities__search-btn {
          background: $brand-primary;
          border-color: $brand-primary;
          i {
            color: white;
          }
        }
      }
    }
    &--segments {
      align-items: center;
      flex-wrap: wrap;
      padding: 0.3em 2em 0.2em 2em;
      background: white;
      @include mq(min, $mq-md) {
        border-radius: 4px 4px 0 0;
        padding: 1.4em 2em;
        background: #f5f5f5;
      }
    }
  }
  &__active-text {
    color: white;
    font-size: 2em;

    @include mq(min, $mq-md) {
      font-weight: 500;
      color: #848484;
      font-size: 1.6em;
    }
  }
  &__actions {
    @include mq(min, $mq-md) {
      display: none;
    }
  }
  &__search {
    position: relative;
    fieldset {
      margin: 0;
    }
    label {
      display: flex;
      align-items: baseline;
      margin-bottom: 0;
      font-size: 1em;
      i {
        font-size: 1.8em;
        color: #9b9b9b;
        margin: 0.5em;
        @include mq(min, $mq-md) {
          display: none;
        }
      }
    }
    input[type="search"] {
      font-size: 1.3em;
      padding: 0.7em 1em 0.6em 1em;
      @include mq(max, $mq-md) {
        font-size: 2em;
        line-height: 2.4;
        border: 0;
        display: none;
        padding: 0 0.5em 0 0;
      }
    }
    .filter-count {
      position: absolute;
      z-index: 2;
      right: 1.6em;
      line-height: 1;
      margin-top: 0.2em;
      @include vertical-align;
    }
  }
  &__search-btn {
    background: #fff;
    border: 1px solid #ddd;
    height: auto;
    border-radius: 6px;
    padding: 0.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    color: #000;
  }
  &__leads-btn {
    @include mq(min, $mq-md) {
      display: none;
    }
  }
  &__label {
    font-size: 1.6em;
    background: #efefef;
    border-radius: 4px;
    padding: 0.7em 1em;
    display: inline-block;
    margin-bottom: 0.5em;
    @include regular;
    i {
      line-height: 1;
    }
  }
  h2 {
    font-size: 2.8em;
    @include medium;
  }
  .filter-opportunities--segments {
    display: flex;
    align-items: center;
    @include mq(max, $mq-md) {
      align-items: baseline;
    }
    span {
      font-size: 2em;
      flex-shrink: 0;
    }
    .btn {
      background-color: #e2e2e2;
      color: #8b8b8b;
      font-size: 1.4em;
      line-height: 1.2;
      margin-right: 0.3em;
      margin-bottom: 0.5em;
      @include regular;
      small {
        display: inline-block;
        margin-left: 0.33em;
        font-size: 100%;
      }
      &:hover {
        background-color: darken(#e2e2e2, 5%);
      }
      &.disabled {
        opacity: 1;
      }
      &.active {
        background-color: $blue;
        color: #fff;
        @include medium;
      }
    }
  }
  .filter-opportunities-right {
    display: flex;
    @include mq(max, $mq-md) {
      margin: 1em 0;
      justify-content: flex-end;
      .btn-wrap {
        display: none;
      }
      .btn--dropdown-options {
        display: none;
      }
    }

    .table-columns {
      @include mq(max, $mq-lg) {
        width: 1em;
        height: 1em;
      }
    }
  }
  .btn--filter {
    @include mq(min, $mq-md) {
      display: none;
    }
  }
  .object-tip {
    @include mq(max, $mq-md) {
      color: white;
    }
  }
}

.flex-table--opportunities {
  margin: 0 2.5em;
  .flex-table-cell {
    width: 10%;
    padding: 0.7em 1.2em 0.5em;
    .flag--tag {
      font-size: 0.8em;
    }
  }
  .flex-table-cell.col--avatar {
    flex-grow: 0;
    width: auto;
    padding-right: 0;
  }
  .flex-table-cell--head {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    &.sortable {
      cursor: pointer;
      &:hover {
        color: $black;
      }
    }
    &.col--avatar .avatar {
      height: 0 !important;
    }
    &.col--flag.col--flag {
      max-width: 8em;
    }
    span {
      margin-right: 0.75em;
    }
    a.btn--sort {
      display: inline-block;
      color: inherit;
      &:hover {
        color: $black;
      }
    }
  }
  .flex-table-row--thead {
    border-bottom: 0;
    border-top: 0;
    padding: 0.8em 0;
    align-items: center;
  }
  .flex-table-row.disabled {
    opacity: 0.4;
    pointer-events: none;
    * {
      pointer-events: none;
    }
  }
  .flex-table-row {
    border: 1px solid #f5f5f5;
    border-top: 0;
    text-decoration: none;
    color: #333;
    align-items: center;
    cursor: pointer;
  }
  .flex-table-row.new-opportunity,
  .flex-table-row.updated-opportunity {
    background-color: lighten(#fdf9de, 3%);
    .col--name {
      @include medium;
    }
  }
  .flex-table-cell.col--flag {
    font-size: 1em;
    max-width: 7em;
    padding-right: 0.7em;
    display: flex;
  }
  .col--name {
    small {
      color: $black-light;
      display: inline-block;
      margin-left: 0.2em;
    }
  }
  .col--last-activity {
    time {
      display: block;
      color: $black-light;
    }
  }
  .col--appointment-details {
    padding-left: 2.6em;
    position: relative;
    span {
      display: inline-block;
      @include mq(min, $mq-md) {
        i {
          position: absolute;
          font-size: 1.1em;
          left: 1em;
          top: 0.6em;
        }
      }
    }
  }
  .col--actions {
    text-align: right;
    max-width: 4em;
    .btn--delete {
      font-size: 0.9em;
      background-color: transparent;
      border: none;
    }
  }
  .responsive-icon {
    display: none;
  }
  .additional-objects {
    margin-top: 0.4em;
    font-size: 0.9em;
    text-decoration: underline;
    color: $black-light;
    @include medium-italic;
    &:hover {
      color: $black;
    }
  }
  .avatar {
    border-radius: 20px;
    background-color: #43d5c1;
    &:before {
      color: white;
    }
  }
}

.leads-checkbox-container {
  @include mq(min, $mq-md) {
    padding: 0;
    margin-right: 1em;
    width: auto;
  }
  input[type="checkbox"] {
    margin-right: 0.5em;
  }
}

@include mq(max, $mq-md) {
  .flex-table--opportunities {
    display: block;
    margin-left: 2em;
    margin-right: 2em;

    .flex-table-row {
      padding: 1em 0;
      align-items: stretch;
      margin: 2em 0;
      background: white;
      border: 1px solid #dedede;
      border-radius: 3px;

      &--thead {
        display: none;
      }
    }

    .flex-table-cell {
      padding: 0.5em 1.2em 0.4em;
      width: auto;
      display: flex;
      align-items: baseline;
      font-size: 1.75em;
      flex: 1 100%;
      &.col--flag {
        max-width: none;
        align-items: center;
        position: relative;
        overflow: visible;
        padding-right: 0;
        flex: 0 auto;
        border-bottom: 1px solid #dedede;
        padding-bottom: 2.4em;
        margin-top: 1em;
        padding-left: 3em;
        .flag {
          font-size: 1.25em;
          margin-left: 1em;
        }
      }
      &.col--username {
        flex: 1 0%;
        border-bottom: 1px solid #dedede;
        margin-top: 0em;
        padding-bottom: 0.85em;
      }
    }

    .responsive-icon {
      display: inline-block;
      margin-right: 0.5em;
      font-size: 1.1em;
    }

    .col--appointment-details {
      span {
        display: flex;
        i {
          display: none;
        }
      }
    }
    .col--actions {
      display: none;
    }
    .col--name {
      font-size: 2.25em;
      padding-left: 0.5em;
      font-weight: 500;
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
      align-items: center;
      time {
        display: none;
      }
      i {
        display: none;
      }
    }
    .col--tags {
      order: 1;
    }
    .additional-objects {
      margin-top: 0;
      font-size: 1em;
      margin-left: 0.5em;
    }
  }
  .leads-checkbox-container {
    $self: &;
    position: relative;
    overflow: visible;
    display: flex;
    align-items: center;
    max-width: none;
    padding-right: 0;
    width: auto;
    .filter-opportunities--segments & {
      padding-left: 0.9em;
      margin-right: 0.5em;
      #{$self}__label {
        left: 0;
      }
    }
    &__label {
      border: 1px solid #eeeeee;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: inline-block;
      position: absolute;
      left: 1.6em;
    }
    &__input {
      font-size: 2em;
      flex-shrink: 0;
      transform: scale(0.8);
      &:checked + #{$self}__label {
        background: rgba(66, 135, 215, 0.1);
        border: 1px solid #4287d7;
      }
    }
  }
  .leads-actions-panel {
    font-size: 1.6em;
    &__text {
      font-size: 1.75em;
    }
  }
}

.dashboard__panel--all-opportunities-blank {
  .flex-table-row--thead,
  .flex-table-row:not(.in-focus) {
    opacity: 0.05;
    @include mq(min, $mq-sm) {
      opacity: 0.2;
    }
    pointer-events: none;
  }
  .col--name {
    position: relative;
    overflow: visible;
    .explainer {
      position: absolute;
      z-index: 2;
      svg {
        @include wh(8em, 3.6em);
        position: absolute;
        z-index: 1;
      }
      h3 {
        @include caveat;
        font-size: 1.8em;
        margin-bottom: 0.33em;
      }
      p {
        font-size: 1em;
        @include regular;
      }
      .btn--primary {
        color: #fff;
        text-decoration: none;
      }
    }
    .explainer--opportunity-example {
      padding: 9em 0 0 2em;
      left: -3em;
      top: 100%;
      max-width: 18em;
      @include mq(min, $mq-sm) {
        padding: 7em 0 0 7em;
        width: 26em;
        max-width: none;
      }
      svg {
        transform: rotate(-90deg);
        left: 0;
        top: 1.8em;
      }
    }
    .explainer--anonymous-example {
      padding: 6em 0 0;
      left: -3em;
      top: calc(100% - 0.3em);
      max-width: 18em;
      @include mq(min, $mq-sm) {
        padding: 6em 0 0;
        left: 5em;
        width: 26em;
        max-width: none;
      }
      svg {
        transform: scaleY(-1) rotate(150deg);
        filter: FlipV;
        -ms-filter: "FlipV";
        left: 8em;
        top: 0;
        @include mq(min, $mq-sm) {
          left: 0;
        }
      }
    }
  }
  .placeholder-text {
    display: inline-block;
    background-color: rgba($black, 0.2);
    width: 12em;
    height: 1em;
    &.placeholder-text--10 {
      width: 10em !important;
    }
    &.placeholder-text--8 {
      width: 8em !important;
    }
  }
}

.example-opportunity-detail {
  header .kick,
  header h1,
  .opportunity-body {
    opacity: 0.9;
  }
  header {
    display: inline-block;
  }
  .explainer {
    position: absolute;
    z-index: 9;
    background-color: #fff;
    border: 1px dashed $black-light;
    outline: 3px solid #fff;
    padding: 1em;
    svg {
      @include wh(8em, 3.6em);
      position: absolute;
      z-index: 1;
    }
    h3 {
      @include caveat;
      font-size: 2.4em;
      margin-bottom: 0.33em;
    }
    p {
      font-size: 1.4em;
      @include regular;
    }
    .btn--primary {
      color: #fff;
      text-decoration: none;
    }
  }
  .explainer--opportunity-detail-example {
    left: calc(100% + 4em);
    top: -2em;
    max-width: 36em;
    svg {
      transform: rotate(180deg);
      left: -9em;
      top: 4em;
    }
  }
}

.leads-actions-panel {
  position: fixed;
  width: 100%;
  padding: 2em;
  box-shadow: 0 -1px 5px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  color: black;
  bottom: 0;
  display: none;
  z-index: 2;
  .btn {
    margin-right: 0.5em;
    font-size: 1.5em;
    border: none;
  }
  &__text {
    font-size: 1.6em;
    display: inline-block;
    line-height: 2;
    margin-left: 0.5em;
  }
}

.leads-desktop-search {
  background: #fff;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  padding: 1em 1.25em 0.9em 1.25em;
  display: flex;
  position: relative;
  min-height: 4em;
  min-width: 4em;
  &.active {
    padding-right: 3em;
    min-width: 21em;
    border-color: #4a4a4a;
    .leads-desktop-search__input {
      display: inline-block;
    }
  }
  &__input {
    &#{&} {
      border: none;
      display: none;
      font-size: 1.5em;
      padding: 0;
      border-radius: 0;
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4em;
    color: #000;
    padding: 0;
    border: none;
    position: absolute;
    right: -0.1em;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.9em;
    background: transparent;
    i {
      font-size: 1em;
      color: $black;
    }
  }
}

.leads-filters-flyout {
  width: 26em;
  background: #333333;
  padding: 2.5em 1.5em;
  position: fixed;
  transform: translateX(-100%);
  visibility: hidden;
  opacity: 0;
  transition: 0.25s;
  z-index: 1;
  height: 100vh;
  @include mq(max, $mq-md) {
    transform: translateX(100%);
    width: calc(100% - 7em);
  }
  &--active {
    transform: none;
    opacity: 1;
    visibility: visible;
  }
  .dropdown-wrap {
    max-width: none;
    .dropdown-icon {
      right: 1em;
      top: 50%;
      transform: translateY(-50%);
    }
    &__select {
      left: 0;
    }
    &__options {
      padding: 1.5em 1em 1em 1em;
      background: #f5f5f5;
      max-height: 30em;
      overflow: auto;
      &--full-height {
        max-height: none;
      }
    }
    &__buttons {
      background: white;
      padding: 1em;
      .btn {
        margin-left: 0.5em;
      }
      .btn-grey {
        box-shadow: none;
        @include regular;
      }
    }
    &__select {
      width: 32em;
      padding: 0;
    }
    &__label {
      font-size: 1.6em;
      margin-bottom: 0.3em;
      color: #ffffff;
      @include regular;
    }
    &__close-icon {
      margin-left: 1em;
      opacity: 0.5;
    }

    .option {
      border: 1px solid #d9d9d9;
      font-size: 1.5em;
      margin-bottom: 0.7em;
      background: white;
      white-space: normal;
      display: flex;
      justify-content: space-between;
      &:hover {
        @include regular;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      }
      &--active {
        background-color: #4a90e2;
        border-color: #4a90e2;
        color: white;
        span {
          color: white;
        }
        .dropdown-wrap__text {
          font-size: 1.2em;
        }
      }
      &__count {
        flex-shrink: 0;
      }
      label {
        display: flex;
        align-items: baseline;
        input[type="checkbox"] {
          pointer-events: none;
        }
      }
      span {
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        margin-left: 0.5em;
      }
    }

    .toggle-dropdown-options {
      max-width: none;
      background-color: #646464;
      color: white;
      border: none;
      padding: 1.9em 1em 1.4em 1.5em;
      &.active {
        border-top: 5px solid $blue;
      }
      &:hover {
        background-color: #727272;
      }
    }
    .close-aside {
      display: none;
      @include mq(max, $mq-md) {
        display: block;
        position: absolute;
        z-index: 1;
        font-size: 1.6em;
        top: 1.5em;
        right: 1.5em;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
