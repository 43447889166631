.tab-pane {
    display: none;
    position: relative;
    &.active { display: block; }
}

.tabs--switcher {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    vertical-align: middle;
    & > a[data-toggle="tab"] {
        position: relative;
        -webkit-box-flex: 0;
        -webkit-flex: 0 1 auto;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        margin-left: -1px;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        vertical-align: middle;
        user-select: none;
        border: 1px solid $black-lighter;
        padding: .5em 1em;
        font-size: 1.6em;
        border-radius: 4px;
        @include transition;
        cursor: pointer;
        @include regular;
        &.active {
            background-color: $brand-primary;
            border-color: $brand-primary;
            color: #fff;
        }
        &:first-child {
            margin-left: 0;
        }
        &:first-child:not(:last-child) {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
        &:last-child:not(:first-child) {
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }
    }
}