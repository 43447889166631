.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.2em;
  flex-wrap: wrap;
}

.pagination__link,
.pagination .page-item {
  display: block;
  text-decoration: none;
  color: $black;
  padding: 0.5em 0.8em 0.4em;
  font-size: 1.6em;
  flex-grow: 0;
  border-radius: 3px;
  @include medium;
  @include transition;
  &:hover {
    color: $black;
    background-color: #eee;
    text-decoration: none;
  }
  &.current,
  &.active {
    margin: 0 0.1em;
    color: #fff;
    background-color: $brand-green;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  a {
    color: $black;
    text-decoration: none;
  }
}

.pagination__link--ellipsis {
  pointer-events: none;
  padding-left: 0.2em;
  padding-right: 0.2em;
}
