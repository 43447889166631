// This will be shared by the edit Landing Pages and the actual Landing Pages
.body--landing-page {
  background: #f1f1f1;
}

.continually-landing-page {
  .header--landing-page {
    position: relative;
    padding: 10em 3.2em;
    background-color: #333;
    background-size: cover;
    color: #fff;
    figure.logo {
      margin-bottom: 2em;
      img {
        max-height: 50px;
      }
    }
    h1 {
      .body--docs & {
        font-family: $font-family-sans-serif;
      }
      font-weight: 700;
      font-size: 3.6em;
      margin-bottom: 0.25em;
      max-width: 24em;
    }
    h2 {
      .body--docs & {
        font-family: $font-family-sans-serif;
      }
      font-weight: 400;
      font-size: 2em;
      line-height: 1.3;
      margin: 0;
      max-width: 24em;
    }
    [contenteditable="true"] {
      border-bottom: 1px dotted #fff;
    }
    [contenteditable="true"]:active,
    [contenteditable="true"]:focus {
      border-bottom-color: $blue;
      outline: none;
    }
  }
  &[data-logo="true"] {
    .header--landing-page {
      padding-top: 6em;
    }
  }
}

.landings-overview {
  padding-bottom: 5em;
  &__table.table {
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 3px;
    .tooltip-container {
      cursor: pointer;
    }
    .tooltip__bubble {
      width: auto;
      max-width: 30em;
      text-align: center;
      padding: 0.8em 0.5em;
      min-width: 8em;
      &--wide {
        min-width: 13em;
      }
    }
    .tooltip__content {
      font-size: 100%;
    }
    .avatar {
      width: 30px !important;
      height: 30px !important;
      border-radius: 50%;
      img {
        width: auto !important;
        height: auto !important;
        max-width: 100%;
      }
    }
    th {
      font-weight: 500;
      color: #8a8a8a;
      padding-top: 1em;
      &:first-child {
        width: 3em;
      }
      &:nth-child(2) {
        width: 6em;
      }
      &:last-child {
        width: 7em;
      }
      i {
        font-size: 12px;
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background: #f9f9f9;
        }
      }
    }
    th,
    td {
      font-size: 1.5em;
      vertical-align: middle;
      padding: 1em 0.5em;
      &:first-child {
        .tooltip--top {
          left: 73%;
          @include mq(max, $mq-md) {
            left: 18%;
            transform: none;
            &:after {
              left: 8%;
            }
          }
        }
      }
    }
    td {
      &:nth-child(4),
      &:nth-child(3) span {
        word-break: break-all;
        max-width: 26em;
        white-space: break-spaces;
      }
      &:nth-child(3) span {
        display: inline-block;
      }
      &[data-bind],
      span {
        cursor: pointer;
      }
      i {
        font-size: 0.9em;
      }
      .fa-star {
        color: #f7b500;
        &:hover {
          font-weight: 700;
        }
      }
      a {
        &:hover {
          color: $brand-primary;
        }
      }
      a,
      em {
        color: #9d9e9d;
        font-size: 0.8em;
      }
      svg {
        width: 40px;
        height: 28px;
      }
    }
    .col-actions {
      text-align: center;
      i {
        font-size: 0.8em;
      }
      .tooltip-container {
        display: inline-block;
        &:first-child {
          margin-right: 2em;
        }
      }
      span {
        &:hover {
          i {
            color: black;
          }
        }
      }
    }
    svg {
      width: auto;
      height: auto;
    }
  }
  &__modal.modal {
    width: auto;
    text-align: center;
    @include mq(min, $mq-sm) {
      width: 520px;
    }
    @include mq(min, $mq-md) {
      width: 88em;
    }

    .modal-body {
      padding: 4em;
      @include mq(min, $mq-md) {
        padding: 7em 9em;
      }

      h3 {
        font-size: 2.8em;
        margin-bottom: 0.5em;
      }
    }
    form {
      text-align: left;
      max-width: 41em;
      margin-left: auto;
      margin-right: auto;
      label {
        margin-bottom: 0.8em;
      }
      input {
        max-width: none;
      }
      select {
        width: 100%;
      }
      .form-helper {
        color: #b0b0b0;
      }
      .custom-input {
        font-size: 1.6em;
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 1em 1em 0.8em;
        background-color: #fff;
        color: #4a4a4a;
        line-height: 1.15;
        &::before {
          content: attr(data-url);
          color: #9b9b9b;
          pointer-events: none;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .pulldown-inner {
      height: auto;
    }
    .pulldown-select {
      height: 3em;
    }
  }
  &__type {
    width: 30em;
    cursor: pointer;
    flex: 1;
    padding: 3em 3em 2em 3em;
    border-radius: 4px;
    &:hover {
      background: #f4f4f4;
    }
    h3 {
      margin-bottom: 3em;
    }
    figure {
      margin-bottom: 2em;
    }
    img {
      box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
    }
  }
  .domain-input-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 1.5em;
    input[type="text"] {
      background-color: transparent;
      padding: 0 0.25em 0 0;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $black-lighter;
      text-align: right;
      font-size: 2em;
    }
    .tld {
      flex: 1;
      font-size: 1.5em;
      padding-left: 0.25em;
      padding-right: 1em;
      color: $black-light;
    }
  }
  .object-tip {
    a {
      color: inherit;
    }
  }
  .modal-wrap {
    background: rgba(0, 0, 0, 0.75);
  }
}

.landings-banner {
  background-image: linear-gradient(136deg, #181159 0%, #5071ce 100%);
}

.validationMessage {
}

.change-bg-color {
  .input-group {
    input {
      flex-grow: 0;
      width: auto;
    }
  }
}
