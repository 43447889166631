.show-benefits-action {
  margin-bottom: 2em;
  label {
    font-size: 1.6em;
  }
}

fieldset.color {
  .color-preview {
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    position: relative;
    border-radius: 5px;
  }
}
