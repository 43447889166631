.settings-banner {
  padding: 4em 0;
  .container {
    padding-top: 0;
    padding-bottom: 0;
  }
  h1 {
    @include bold;
    font-size: 3.2em;
    margin-bottom: 0.15em;
    @include font-smoothing;
  }
  p.lead {
    font-size: 2em;
  }
  p {
    @include font-smoothing;
    font-size: 1.8em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &--blue {
    background-image: linear-gradient(to bottom right, #3344c6, #4f82fb);
    color: #fff;
  }

  &--black {
    background-color: #252525;
    color: #fff;
  }
}

.settings-section {
  padding-bottom: 3.2em;
  position: relative;
}
