$conv-builder-header-height: 7em;
$conv-builder-header-height-sm: 12em;

.header--app-conv-builder {
  position: fixed;
  z-index: $zindex-fixed-header;
  left: 0;
  right: 0;
  top: 0;
  display: block;
  @include mq(max, $mq-md) {
    height: $conv-builder-header-height-sm;
  }
  @include mq(min, $mq-md) {
    height: $conv-builder-header-height;
  }
  .conv-builder-tabs {
    position: absolute;
    @include mq(max, $mq-md) {
      left: 0;
      right: 0;
      bottom: 1.5em;
      text-align: center;
    }
    @include mq(min, $mq-md) {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    input[type="radio"] {
      @include offscreen;
      &:checked + label,
      &:checked + .validationMessage + label {
        background-color: #eee;
        color: $black;
        &:after,
        &:before {
          top: calc(100% - 1px);
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &:after {
          border-color: rgba(#eee, 0);
          border-top-color: #eee;
          border-width: 7px;
          margin-left: -7px;
        }
        &:before {
          border-color: rgba(#d8d8d8, 0);
          border-width: 8px;
          margin-left: -8px;
        }
      }
    }
    label {
      color: $black-light;
      @include medium;
      font-size: 1.6em;
      text-decoration: none;
      @include inline-block;
      margin: 0 0.2em;
      border: 1px solid transparent;
      padding: 0.7em 1em 0.6em;
      line-height: 1;
      border-radius: 5em;
      position: relative;
      cursor: pointer;
      @include transition(color);
      &:hover {
        color: $black;
      }
    }
    .tooltip {
      &__bubble {
        width: 12em;
        white-space: normal;
        padding-left: 0.8em;
        padding-right: 0.8em;
      }
      &__content {
        line-height: 1.2;
      }
    }
  }
  .conv-builder-meta {
    position: absolute;
    left: 1.6em;
    display: inline-flex;
    align-items: center;
    max-width: 30%;
    @include mq(max, $mq-md) {
      top: 2em;
    }
    @include mq(min, $mq-md) {
      @include vertical-align;
    }
    .back-btn {
      display: inline-flex;
      color: #4d4d4d;
      text-decoration: none;
      margin-right: 2em;
      vertical-align: middle;
      background: rgba(0, 0, 0, 0.04);
      border-radius: 20px;
      padding: 1.2em;
      align-items: center;
      justify-content: center;
      transition: 0.2s;
      &:hover {
        color: #fff;
        background: $blue;
      }
      svg {
        fill: currentColor;
        @include inline-block;
      }
      .label {
        font-size: 1.4em;
        text-transform: uppercase;
        @include medium;
        @include inline-block;
        margin-left: 0.4em;
      }
    }
    .conversation-name {
      @include inline-block;
      .input-wrap {
        position: relative;
        display: inline-grid;
        align-items: center;
        max-width: 100%;
        &::after,
        input {
          width: auto;
          min-width: 10em;
          grid-area: 1 / 2;
          margin: 0;
          font-size: 1.6em;
        }
        &::after {
          content: attr(data-value) " ";
          visibility: hidden;
          white-space: pre-wrap;
        }
        input {
          display: inline-block;
          padding-left: 1.2em;
          width: calc(100% + 1.2em);
        }
        i {
          position: absolute;
          top: 45%;
          transform: translateY(-50%);
          left: 0.2em;
          pointer-events: none;
          color: #cfcfcf;
        }
        .validationMessage {
          position: absolute;
          left: 0;
          top: 100%;
          background-color: transparent;
          color: $error;
          padding: 0.5em 0;
          margin: 0;
          font-size: 1.3em;
          @include medium;
        }
      }
      .conversation-name__input {
        border-color: transparent;
        background-color: transparent;
        border-radius: 0;
        padding: 1px 0 0 0;
        border-bottom: 1px dashed transparent;
        font-size: 1.6em;
        &:hover {
          border-bottom-color: $black-light;
        }
        &:focus {
          outline: none;
          border-bottom-color: $black;
        }
      }
    }
  }
  .save-actions {
    user-select: none;
    position: absolute;
    z-index: 2;
    right: 1.4em;
    @include mq(max, $mq-md) {
      top: 1.5em;
    }
    @include mq(min, $mq-md) {
      @include vertical-align;
    }
    .publish-hint {
      position: absolute;
      right: 0;
      top: calc(100% + 0.8em);
      background-color: #fff;
      border: 1px solid #ddd;
      padding: 1em;
      border-radius: 4px;
      width: 20em;
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.05);
      &:after,
      &:before {
        left: 75%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }
      &:after {
        bottom: calc(100% - 1px);
        border-color: rgba(#fff, 0);
        border-bottom-color: #fff;
        border-width: 6px;
        margin-left: -6px;
      }
      &:before {
        bottom: 100%;
        border-color: rgba(#ddd, 0);
        border-bottom-color: #ddd;
        border-width: 6px;
        margin-left: -6px;
      }
      span {
        @include medium-italic;
        font-size: 1.4em;
      }
    }
    .btn-wrap {
      @include inline-block;
      position: relative;
    }
    .conversation-live-toggle {
      @include inline-block;
      margin-right: 2em;
      input,
      label span {
        @include offscreen;
      }
      label {
        display: inline-block;
        background-color: #ddd;
        cursor: pointer;
        border: 1px solid darken(#ddd, 10%);
        border-radius: 10em;
        padding: 0.7em 2em 0.3em;
        position: relative;
        width: 8.4em;
        height: 2.2em;
        overflow: hidden;
        @include transition(background-color);
        &:hover {
          background-color: darken(#ddd, 5%);
        }
        &:before {
          content: "DRAFT";
          color: $black-light;
          @include font-smoothing;
          @include medium;
          font-size: 1.4em;
          line-height: 1;
          position: absolute;
          z-index: 2;
          top: 50%;
          transform: translateY(-50%);
          left: 1em;
          margin-top: 1px;
        }
        &:after {
          content: "";
          border-radius: 10em;
          width: calc(2.2em - 4px);
          height: calc(2.2em - 4px);
          background-color: #fff;
          position: absolute;
          z-index: 1;
          right: 1px;
          top: 1px;
          box-shadow: 0 0 10px 0 rgba(#000, 0.2);
        }
      }
      input:checked + label {
        background-color: $green;
        border-color: darken($green, 10%);
        &:hover {
          background-color: darken($green, 5%);
        }
        &:before {
          content: "LIVE";
          color: #fff;
          left: auto;
          right: 1.5em;
        }
        &:after {
          left: 1px;
          right: auto;
        }
      }
    }
    .preview-btn {
      margin-right: 1.5em;
      a {
        color: $black-light;
        text-decoration: none;
        .label {
          @include inline-block;
          font-size: 1.5em;
          @include transition;
        }
        i {
          margin-left: 0.3em;
          @include inline-block;
          font-size: 1.5em;
          color: $black-light;
          @include transition;
        }
        &:hover {
          color: $black;
          i {
            color: $black;
          }
        }
      }
    }
    .publish-btn {
      .btn.disabled {
        background-color: $grey;
        border-color: $grey;
        cursor: disabled;
        opacity: 1;
        pointer-events: none;
        color: rgba($black, 0.1);
      }
    }
  }
}

.admin-banner {
  padding: 1em;
  text-align: center;
  background-color: $black;
  color: #fff;
  span {
    font-size: 1.3em;
    margin-right: 1em;
  }
  span,
  .btn {
    @include inline-block;
  }
}

.conversation-preview--blank {
  display: flex;
  min-height: 50vh;
  align-items: center;
  justify-content: center;
}

.conv-builder {
  &__section {
    background-color: $grey-light;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    @include mq(max, $mq-md) {
      top: $conv-builder-header-height-sm;
    }
    @include mq(min, $mq-md) {
      top: $conv-builder-header-height;
    }
    &.popover-active {
      opacity: 0.2;
    }
    .conv-builder__section-inner {
      height: calc(100vh - #{$conv-builder-header-height});
      position: relative;
      display: flex;
      h2 {
        @include bold;
        font-size: 1.6em;
        i {
          font-size: 0.8em;
        }
      }
    }
  }
  &__main {
    flex: 1 auto;
  }
  &__collapsable {
    background: white;
    border: 1px solid #dadada;
    border-radius: 5px;
    padding: 2em 1.7em 1em 1.7em;
    position: relative;
    .radio {
      .sub-label {
        text-transform: none;
        font-size: 1.6em;
        display: flex;
      }
      ul {
        label {
          display: flex;
        }
        input {
          flex-shrink: 0;
        }
      }
    }
    .tooltip {
      &__bubble {
        white-space: normal;
        width: 16em;
      }
    }
    .widget-radio--button-shape {
      .widget-radio__item {
        margin-right: 0.5em;
      }
      .widget-radio__label {
        min-height: 52px;
        padding: 8px 10px 8px 10px;
        img {
          max-width: 36px;
        }
      }
    }
    .widget-radio--button-icon {
      .widget-radio__item {
        margin-right: 1em;
      }
    }
  }
  &__collapsable-revert-btns {
    display: flex;
    align-items: center;
    label {
      display: none;
    }
    input:checked + label,
    input:checked + span + label,
    .color .widget-radio__label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    .widget-radio__label {
      width: 48px;
      height: 48px;
      min-height: 48px;
      padding: 8px;
      margin-right: 1em;
      margin-bottom: 0;
      .color-preview {
        font-size: 1.2em;
      }
    }
    .color .widget-radio__label {
      background: #e9ecef;
    }
  }
  &__collapsable-btn {
    font-size: 1.6em;
    position: absolute;
    top: 1.2em;
    right: 1.5em;
    color: #aaa;
    &:hover {
      color: $black;
    }
  }
  &__help-btn {
    position: absolute;
    right: 1em;
    bottom: 1em;
    z-index: 15;
    padding: 0.5em 1.2em;
    font-size: 1.4em;
    line-height: 1;
  }
  &__checkbox {
    border: 2px solid black;
    height: 1.2em;
    width: 1.2em;
    border-radius: 2px;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.4em;
    &--selected {
      background: black;
    }
  }
  &__action {
    background: #e6e6e6;
    border: none;
    font-size: 1.4em;
  }
  &__alert {
    position: absolute;
    top: 2em;
    background: $orange-dark;
    z-index: 999;
    left: 50%;
    text-align: center;
    padding: 1.2em 2em;
    border-radius: 100px;
    color: white;
    &--static {
      position: static;
      display: inline-block;
    }
    &--blue {
      color: $blue-new;
      background: #e2e7f6;
    }
    &--purple {
      background: $purple-new;
    }
  }
  &__search-obj-flyout {
    max-width: 30em;
    padding: 0;
    text-align: left;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    border: none;
    right: 1em;
    left: auto;
    transform: none;
    top: 100%;
  }
  .flyout {
    &__search {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ececec;
      input {
        flex: 1 auto;
        font-size: 1.4em;
        border: none;
        border-radius: 3px 3px 0 0;
        padding: 1.2em 3em 1.1em 1em;
      }
      button {
        color: #d1d1d1;
        position: absolute;
        right: 1.5em;
      }
    }
    &__obj-list {
      list-style-type: none;
      overflow: hidden;
      overflow-y: auto;
      height: 25em;
      &--canvas {
        li:nth-child(n + 7) {
          display: none;
        }
      }
      li {
        display: flex;
        align-items: baseline;
        font-size: 1.3em;
        padding: 0.6em 1em 0.65em 0.5em;
        cursor: pointer;
        &:not(:first-child) {
          border-top: 1px solid #ececec;
        }
        &:hover {
          background: #f9f9f9;
          i {
            color: $black;
          }
        }
        i {
          color: rgba($black, 0.5);
          margin-right: 1em;
        }
        span {
          @include truncate();
          color: $black;
          small,
          br,
          img {
            display: none;
          }
        }
        .item--label-tag {
          align-self: center;
          .flag {
            margin-bottom: 0;
          }
        }
        .conditional-logic__tags {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .tag {
            padding: 0;
            margin: 0;
            background: none;
            display: inline;
          }
        }
      }
      .goto-obj-outline {
        display: none;
      }
      .outline-item-answer {
        display: inline !important;
      }
      .tooltip-container {
        display: none;
      }
    }
    .flyout__obj-list-label {
      display: inline-block;
    }
    .conversation-item-number {
      margin-right: 0.5em;
    }
  }
  &__sidebar {
    position: relative;
    width: 30em;
    flex-grow: 0;
    background-color: $black;
    color: #fff;
    transition: 0.3s transform ease-out;
    @include mq(max-height, $mq-mbp) {
      font-size: 0.8em;
    }
    header {
      background: #0f47d7;
      .btn--toggle-display {
        width: 5em;
        height: 5em;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: rgba(255, 255, 255, 0.7);
        &:hover {
          background: #0836ac;
        }
      }
    }
    .btn--conv-object {
      width: 100%;
      text-align: left;
      margin-bottom: 1em;
      color: #fff;
      cursor: move;
      position: relative;
      font-size: 1.6em;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 8px;
      padding: 0.8em 1.5em 0.8em 0.8em;
      @include font-smoothing;
      svg {
        fill: #fff;
        top: -0.1em;
        position: relative;
        @include wh(1em);
      }
      &:hover {
        background-color: #fff;
        color: $black;
        svg {
          fill: $black;
        }
      }
      .draggable-handle {
        position: absolute;
        right: 0.75em;
        @include vertical-align;
        cursor: move;
        cursor: -webkit-grabbing;
        border-left: 1px solid #6f6f6f;
        border-right: 1px solid #6f6f6f;
        width: 3px;
        height: 0.6em;
      }
      .fa-phone-volume {
        transform: rotate(-45deg);
      }
    }
    .btn--conv-object-app {
      padding: 0;
      overflow: hidden;
    }
    .btn--conv-variables {
      font-size: 1.3em;
      width: 100%;
      white-space: normal;
      padding-left: 0.5em;
      padding-right: 0.5em;
      .collapsed-shown {
        display: none;
      }
    }
    .other-conv-objects {
      position: relative;
      z-index: 102;
      display: none;
      &.active {
        display: block;
      }
      &.animated {
        animation-duration: 0.5s;
      }
    }
    .show-other-conv-objects {
      display: inline-block;
      margin-top: 0.5em;
      font-size: 1.4em;
      padding-left: 1em;
      position: relative;
      text-decoration: none;
      &:hover {
        color: #fff;
      }
      &:before {
        position: absolute;
        content: url(../images/icons/show-other-conv-objects.svg);
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
      &.active:before {
        transform: translateY(-50%) rotate(90deg);
      }
    }
    .conv-builder__sidebar-title {
      padding: 0.8em 1em;
      font-size: 1.6em;
      @include medium;
      @include font-smoothing;
    }
    .btn--conv-object {
      .tooltip {
        display: none;
      }
    }
    .conv-builder__sidebar-objects {
      padding: 1.6em 1.6em 10em;
      overflow: hidden;
      overflow-y: auto;
      height: 100%;
    }
  }
  &__info {
    position: relative;
    background-color: transparent;
    padding: 1em;
    display: flex;
    justify-content: space-between;
    .reverted-confirm {
      position: relative;
      @include inline-block;
      padding: 0.7em 0;
      margin-left: 1em;
      margin-right: 1em;
      span.label {
        font-size: 1.4em;
        @include medium-italic;
        color: #6e6060;
      }
    }
    a.confirm-revert {
      margin-right: 1em;
    }
    .status {
      position: relative;
      @include inline-block;
      padding: 0.7em 0;
      margin-left: 1em;
      span.label {
        font-size: 1.3em;
        color: #6e6060;
        @include medium-italic;
        @include mq(min, $mq-xxl) {
          font-size: 1.4em;
        }
      }
      &.save-failed {
        span.label {
          color: $error;
        }
      }
    }
    .revert-to-published {
      @include inline-block;
      text-decoration: none;
      position: relative;
      font-size: 1em;
      padding-top: 0.8em;
      &:hover {
        background: #f05c5c;
        color: white;
      }
      &--undo {
        &:hover {
          background: $blue;
        }
      }
      span.label {
        @include inline-block;
        font-size: 1.4em;
        @include medium;
      }
      .tooltip {
        font-size: 0.9em;
      }
    }
    .zoom-toggle-wrap {
      text-align: right;
      display: flex;
      align-items: center;
    }
  }
  &__toggle-sidebar {
    position: absolute;
    width: 9.375em;
    height: 3.125em;
    left: 0;
    top: 11.25em;
    background: #0f47d7;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    z-index: 999;
    border: none;
    color: white;
    border-radius: 0 75px 75px 0;
    font-size: 1.6em;
    transform: translateX(-100%);
    transition: 0.3s transform ease-out;
    cursor: pointer;
    i {
      font-size: 0.75em;
    }
  }
  &__obj-items {
    > p {
      color: #dadada;
    }
  }
  &__errors {
    ul {
      padding: 0 1.6em;
      background-color: $error;
      color: #fff;
    }
    li {
      list-style: none;
      line-height: 1.3;
      font-size: 1.4em;
      padding: 0.2em 0;
    }
  }
  &__outline {
    position: relative;
    flex: 1;
    div.wrap {
      margin: 2.6em auto 0;
      max-width: 36em;
    }

    .draggable-chosen {
      clear: both;
      background-color: transparent;
      border: 1px dashed $black-light;
      border-radius: 2px;
      width: 100%;
      margin-bottom: 3.2em;
      cursor: move;
      cursor: -webkit-grabbing;
      svg,
      span {
        opacity: 0;
      }
    }
  }

  &__preview {
    width: 37em;
    background-color: #fff;
    position: absolute;
    right: 1em;
    top: 5.8em;
    bottom: 1.8em;
    z-index: 101;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @include transition(width);
    & > h2 {
      margin: 2em 1.5em;
    }
    .conversation-js-preview {
      flex: 1;
    }
    .continually-conversation-footer-branding {
      display: none;
    }
    .continually-conversation-header,
    .continually-conversation-header-team {
      border-radius: 0;
      padding-top: 2em;
    }
    .col-header {
      position: relative;
      height: 5em;
      padding: 1.8em 2em;
    }
    .tooltip-container {
      display: inline-block;
      cursor: pointer;
      .tooltip__bubble {
        width: 18em;
        text-align: center;
      }
    }
    .refresh-btn {
      position: absolute;
      z-index: 2;
      top: 1.5em;
      right: 2em;
      font-size: 0.66em;
      @include mq(min, $mq-md) {
        right: 3em;
      }
      @include transition(right);
      .btn {
        padding-left: 1em;
        padding-right: 1em;
      }
    }
    .conversation-preview-feedback {
      background: white;
      border-top: 1px solid #eaeaea;
      .tooltip {
        &__bubble {
          width: 13em;
          padding: 1em;
          display: block;
        }
      }
    }
  }
  &__conversation-item {
    clear: both;
    background-color: #fcfcfc;
    border: 1px solid #e5dede;
    box-shadow: 0 1px 5px 0 rgba(181, 181, 181, 0.5);
    border-radius: 2px;
    position: absolute;
    z-index: 1;
    display: block;
    max-width: 36em;
    cursor: pointer;
    width: 100%;
    margin-bottom: 0;
    &:hover {
      border: 1px solid $black;
      box-shadow: 0 0 0 1px $black;
      .conv-builder-actions {
        opacity: 1;
      }
    }
    &:not(:last-of-type) {
      margin-bottom: 5.4em;
    }
    &:focus,
    &.focus {
      border: 1px solid $black;
      box-shadow: 0 0 0 1px $black;
      outline: none;
      animation-delay: 1s;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: cbwarmdown;
    }
    &.looking-up-sp {
      border: 2px solid $black;
      box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
    }
    &--opacity {
      opacity: 0.6;
    }
    &--has-child {
      > .item--header {
        .item--icon,
        .item--label {
          display: none;
        }
      }
      .item--header,
      .item--answers {
        background: #f0f7ff !important;
      }
      .conv-builder__conversation-item {
        position: static;
        border: none;
        box-shadow: none;
        &:hover {
          border: none;
          box-shadow: none;
        }
      }
    }
    &.starting-point-class,
    &.ending-point-class {
      padding-top: 2em;
    }
    &.multiple {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        border-right: 1px solid #000;
        border-bottom: 1px solid black;
        height: 100%;
        bottom: -1px;
        right: -1px;
        box-shadow: 1px 1px 0 0 #4a4a4a;
      }
      .multiple__item {
        content: "";
        width: 100%;
        height: 100%;
        border: 1px solid black;
        box-shadow: 0 0 0 1px #4a4a4a;
        position: absolute;
        background: white;
      }
      .multiple__before {
        z-index: -2;
        top: 15px;
        left: 10px;
      }
      .multiple__after {
        z-index: -3;
        top: 30px;
        left: 20px;
      }
    }
    &.selected-item {
      border: 1px solid $black;
      box-shadow: 0 0 0 1px $black;
      .conv-builder__conversation-item__actions {
        opacity: 1;
      }
    }
    &.hovered {
      border: 2px solid black;
    }
    &.cb-item-added {
      border: 2px solid black;
      .item--header {
        background: #fefcec;
      }
    }
    &.notes-item {
      .item--header {
        background: #fefcec;
      }
    }
    &.conversation-item--question {
      .item--label {
        .font-roboto & {
          @include medium-roboto;
        }
      }
    }
    &.js-object-duplicated {
      animation-delay: 1s;
      animation-duration: 1s;
      animation-fill-mode: both;
      animation-name: dulpicateWarmDown;
    }
    .item--header {
      background-color: #fff;
      display: flex;
      border-bottom: 1px solid #e5dede;
      &.item--header-selected {
        background: #f0f7ff;
      }
    }
    .item--icon {
      border-right: 1px solid #e5dede;
      padding: 1.6em 1.6em 1.3em;
      svg {
        fill: $black;
      }
      .apps-error-icon {
        position: absolute;
        top: -0.6em;
        left: -0.6em;
      }
      i {
        font-size: 1.6em;
        color: $black;
        &.fa-phone-volume {
          transform: rotate(-45deg);
        }
      }
    }
    .item--label {
      font-size: 1.6em;
      padding: 1em 2em 1em 1em;
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
      user-select: none;
      &-tag {
        padding-bottom: 0.5em;
      }
      &-truncated {
        @include truncate;
        & + .item--label {
          display: none;
        }
      }
      .font-roboto & {
        @include regular-roboto;
        strong {
          @include bold-roboto;
        }
        small {
          @include regular-roboto;
        }
      }
      small {
        color: $black-light;
        display: inline-block;
        margin-left: 0.25em;
      }
      .flag {
        &--tag {
          font-size: 0.75em;
        }
      }
    }
    .item--label-lc {
      & + .item--label {
        display: none;
      }
    }
    .conditional-logic__tags {
      padding: 1em 1em 0.5em;
      .tag {
        font-size: 1.3em;
      }
    }
    .item--answers {
      padding: 1.6em;
      .answer {
        margin-bottom: 0.75em;
        position: relative;
        display: block;
        border: 1px solid $brand-primary;
        border-radius: 4px;
        color: $black;
        background-color: white;
        padding: 0.8em 1.6em 0.7em;
        text-align: center;
        text-decoration: none;
        user-select: none;
        i {
          margin-right: 4px;
        }
        span.label {
          font-size: 1.3em;
        }
      }
    }
    .item--answers--media {
      padding: 0;
      .media-container {
        background-size: cover;
        background-position: 50%;
      }
      .message--loading {
        padding: 1em 1.5em;
        text-align: center;
        font-size: 1.3em;
        @include italic;
      }
      a.rich-media-card {
        border: none;
        margin-top: 0;
        border-radius: 0;
        &:hover .rich-media-card__summary h2 {
          text-decoration: none;
        }
        .rich-media-card__figure {
          border-radius: 0;
        }
        .rich-media-card__summary {
          font-size: 120%;
        }
      }
    }
    img.ql-image {
      display: block;
      width: 100%;
      margin-bottom: 1em;
    }
  }
  &__obj-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2em;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 2em;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background: #fff;
      h2,
      p {
        color: $black;
      }
    }
    > h2 {
      display: block;
      flex-basis: 100%;
    }
    > p {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.4em;
      margin-bottom: 0;
    }
  }

  &__blank-state {
    text-align: center;
    padding: 5em 2em;
    .emoji {
      font-size: 3em;
    }
    h3 {
      margin-bottom: 0.25em;
    }
    p {
      font-size: 1.6em;
      margin-bottom: 0;
    }
    .drop-area {
      margin-top: 3em;
      padding: 6em 2em;
      background-color: #fff;
      border: 1px dashed #ddd;
      outline: 3px solid #fff;
      position: relative;
      &:after {
        position: absolute;
        @include vertical-align;
        left: 2em;
        right: 2em;
        font-size: 1.4em;
        content: "Try dragging a message object from the left and dropping here";
        @include italic;
        color: $black-light;
      }
      .draggable-chosen:after {
        display: none;
        margin-bottom: 0;
      }
    }
  }
  &__obj-icon-container {
    display: inline-block;
    background: white;
    margin-right: 0.6em;
    padding: 0.5em;
  }
  &__sub-sidebar {
    background: $grey-light;
    color: #4a4a4a;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &__sub-sidebar-header {
    background: #212429;
    padding: 1.5em;
    position: sticky;
    top: 0;
    z-index: 2;
  }
  &__sub-sidebar-back-btn {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    font-size: 1em;
    width: 4em;
    height: 4em;
    transition: 0.2s;
    border: none;
    color: white;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
    i {
      font-size: 1.2em;
    }
  }
}

.conv-builder-sub-sidebar {
  form {
    padding: 3em;
  }
  #emoji-palette {
    left: 0 !important;
  }
  .radio {
    ul li {
      & > label {
        font-size: 0.87em;
        @include regular;
      }
    }
  }
  .launch-button-settings {
    input[type="radio"] {
      @include offscreen;
    }
    input[type="radio"]:checked + label,
    input[type="radio"]:checked + .validationMessage + label {
      opacity: 1;
    }
    li {
      list-style: none;
      @include inline-block;
      font-size: 1em;
      margin-right: 1em;
      label {
        margin: 0;
        opacity: 0.33;
        cursor: pointer;
        font-size: 0.8em;
      }
    }
    .continually-button {
      display: flex;
      align-items: center;
      transition: transform 0.2s;
      background: #222;
      &.continually-button--i {
        width: 5em;
        height: 5em;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        .continually-button-icon {
          max-width: 3em;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          img {
            flex-shrink: 0;
          }
        }
      }
      &.continually-button--it {
        border-radius: 2em;
        padding: 1em 1.6em;
        .continually-button-icon,
        .continually-button-text {
          @include inline-block;
        }
        .continually-button-icon {
          color: #fff;
          margin-right: 1em;
          max-width: 2.4em;
        }
        .continually-button-text {
          margin: 0;
        }
      }
      &.continually-button--t {
        border-radius: 2em;
        .continually-button-text {
          margin: 0.75em 1.6em;
        }
      }
    }
    .continually-button-text {
      color: #fff;
      line-height: 1;
      font-size: 1.6em;
      font-family: $font-family-sans-serif;
      font-weight: 700;
    }
  }
  .first-bot-message {
    margin: 2.5em 0;
    .conv-builder__conversation-item {
      position: relative;
    }
  }
  .add-media-content {
    position: relative;
    margin-top: 1.7em;
    .media-input {
      margin-top: 1.6em;
    }
    .image-preview {
      &__replace {
        background: rgba(0, 0, 0, 0.85);
        color: white;
        font-size: 1.4em;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        padding: 0.75em;
        line-height: 1;
        cursor: pointer;
      }
      img {
        max-height: 20em;
      }
    }
    .btn--remove-image {
      color: $black-light;
      font-size: 1.3em;
      display: inline-block;
      margin-bottom: 1em;
    }
    .input-wrap {
      position: relative;
      input {
        padding-right: 3em;
        line-height: 1.5;
        border-radius: 5px;
        max-width: none;
      }
      .btn--remove-x {
        position: absolute;
        z-index: 1;
        @include vertical-align;
        right: 1em;
        color: $black;
        font-size: 1.6em;
        &:hover {
          color: $error;
        }
      }
    }
    input[type="file"] {
      @include offscreen;
    }
    .btn--upload-input {
      display: block;
      border: 1px dashed $black-lighter;
      color: $black-lighter;
      padding: 1.3em;
      font-size: 1.5em;
      @include uppercase;
      @include medium;
      cursor: pointer;
      @include transition;
      margin-bottom: 0.5em;
      text-align: center;
      &:hover {
        border-color: $black;
        color: $black;
      }
    }
    .save-overlay {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba($grey-light, 0.9);
    }
  }
  .fieldset--save {
    margin-top: 1em;
    .validationMessage {
      display: block;
      background-color: transparent;
      color: $error;
      padding: 0;
      font-size: 1.4em;
      @include medium;
    }
  }
}

.body--conversation-builder {
  .btn--toggle-shortcut {
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    padding: 0.65em 0.7em;
    justify-content: center;
    text-align: center;
    margin-right: 1em;
    i {
      line-height: 0.75;
    }
  }
  .btn--toggle-preview {
    border: none;
    padding-top: 0.6em;
    padding-bottom: 0.5em;
    min-width: 8.5em;
    font-size: 1.4em;
    line-height: 1;
  }
}

.conversation-builder--container {
  position: absolute;
  left: 0;
  right: 0;
  top: 4em;
  bottom: 0;
}

.conversation-starting-point,
.conversation-ending-point {
  background-color: #ffa500;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 2em;
  border-radius: 2px 2px 0 0;
  text-align: center;
  @include uppercase;
  color: #fff;
  padding: 0.5em 0.5em 0.3em;
  font-size: 1.1em;
  line-height: 1;
  display: block;
  user-select: none;
}

@keyframes dulpicateWarmDown {
  0% {
    border: 1px solid $black;
    box-shadow: 0 0 0 1px $black;
  }
  100% {
    border: 1px solid #e5dede;
    box-shadow: 0 1px 5px 0 rgba(181, 181, 181, 0.5);
  }
}

.conv-builder-actions {
  $self: &;
  opacity: 0;
  position: absolute;
  right: 0;
  bottom: calc(100% + 0.4em);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @include transition(opacity);
  &__select {
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__right {
    #{$self}__btn {
      margin-left: 0.5em;
    }
  }
  &__btn {
    font-size: 1.4em;
    .tooltip {
      &__bubble {
        border-radius: 4px;
        padding: 0.6em 1em;
      }
    }
  }
  &__btn {
    font-size: 1.4em;
  }
  a {
    color: $black-light;
    text-decoration: none;
    @include transition;
    &:hover {
      color: $black;
    }
  }
}

.conversation-item--message {
  .item--answers {
    display: none;
  }
}

.conversation-js-preview {
  .conversation-preview-changes {
    position: absolute;
    z-index: 10;
    background-color: rgba(#fff, 0.95);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    padding: $spacer-y * 10 $spacer-x 0;
  }
  &--inline {
    border: 1px solid #cbcbcb;
    max-width: 510px !important;
    height: 470px;
    position: relative !important;
    top: auto;
    bottom: auto;
    right: auto;
    left: auto;
    margin: auto;
    width: 100% !important;
    background: white;
    border-radius: 10px !important;
    .continually-container {
      position: static !important;
      height: 100%;
      font-size: 100% !important;
    }
    .restart-bot {
      position: absolute;
      z-index: 1;
      right: 2em;
      top: 3em;
      a {
        background-color: #e6e6e6;
        border-radius: 50%;
        width: 3.25em;
        height: 3.25em;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: #000;
        @include transition(background-color);
        &:hover {
          background-color: $blue;
          color: #fff;
        }
        svg {
          width: 12px;
          height: 12px;
          fill: currentColor;
        }
      }
    }
    .continually-timeline {
      width: 370px;
      margin: auto;
      overflow: visible !important;
      padding-top: 4em !important;
    }
    .continually-conversation-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
    }
    .continually-conversation-footer-branding {
      display: block;
      background: none;
    }
    .conversation-preview-feedback {
      display: none;
    }
    .conversation-preview-changes {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }
  .conversation-header__info {
    max-width: 72%;
    .inline-edit {
      position: relative;
      textarea {
        width: 100%;
        margin: 0;
        font-size: 1.75em;
        font-weight: 500;
        display: inline-block;
        max-height: 7em;
      }
    }
  }
  .btn--change-avatar {
    position: relative;
    display: inline-block;
    border-radius: 2px;
    z-index: 2;
    &:after {
      display: none;
      position: absolute;
      left: -3px;
      right: -3px;
      top: 100%;
      content: "Change photo";
      @include uppercase;
      color: #fff;
      text-shadow: -0.1em 0.1em 0 rgba($black, 0.2);
      font-size: 1.2em;
      padding: 0.4em 0.3em 0.3em;
      text-align: center;
      background-color: $brand-primary;
      border-radius: 0 0 4px 4px;
    }
    &:hover {
      border-color: $brand-primary;
      box-shadow: 0 0 0 3px $brand-primary;
      background-color: $brand-primary;
      .avatar {
        border-color: $brand-primary;
      }
      &:after {
        display: block;
      }
    }
  }
  .btn--add-avatar {
    border-color: $brand-primary;
    box-shadow: 0 0 0 3px $brand-primary;
    background-color: $brand-primary;
    &:hover:after {
      display: block;
      content: "Add photo";
    }
  }

  .continually-close-button {
    position: absolute;
    z-index: 4;
    top: 0;
    right: 0;
    color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.4em;
    border-radius: 0 0 0 5px;
    &:hover {
      background-color: rgba(#000, 0.2);
    }
  }
  .continually-conversation-footer-branding {
    border-radius: 0;
  }
}

.edit-item-modal {
  text-align: center;
  overflow: auto;
  width: auto;
  margin-left: 0;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
  @include mq(min, $mq-sm) {
    top: 1em;
    left: 1em;
    right: 1em;
    bottom: 1em;
  }
  @include mq(min, $mq-md) {
    top: 2em;
    left: 2em;
    right: 2em;
    bottom: 2em;
  }
  header {
    position: fixed;
    z-index: 3;
    top: 0.4em;
    left: 0;
    right: 0;
    @include mq(min, $mq-sm) {
      top: 1.4em;
      left: 1em;
      right: 1em;
    }
    @include mq(min, $mq-md) {
      top: 2.4em;
      left: 2em;
      right: 2em;
    }
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d3d3d3;
    text-align: left;
    .header__object-switcher {
      padding: 1em;
    }
    a.modal--close {
      @include inline-block;
      border-left: 1px solid #d3d3d3;
      padding: 2em;
      line-height: 1;
      margin-left: 2em;
      color: $black;
      @include transition;
      i {
        font-size: 2em;
      }
      &:hover {
        color: $brand-green;
      }
    }
  }
  .modal-body {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @include mq(min, $mq-md) {
      display: flex;
    }
  }
  .editor-pane {
    background-color: $grey-light;
    padding: 8em 3em 3em;
    text-align: left;
    @include mq(min, $mq-md) {
      padding-top: 10em;
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &__loading {
      z-index: $zindex-progress;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: white;
        opacity: 0.7;
      }
    }
    fieldset {
      position: relative;
      @include mq(min, $mq-xxxl) {
        .label-side {
          position: absolute;
          right: calc(100% + 1.6em);
          top: 0.5em;
          text-align: right;
        }
        .label-video-url {
          position: initial;
          text-align: left;
        }
      }
      input:not(.input-max-width) {
        max-width: none;
      }
      input[type="text"]:not([class*="fz-"]) {
        font-size: 1.8em;
        &.input--description {
          font-size: 1.4em;
        }
      }
      .input-no-bg-no-border {
        border-color: transparent;
        background: none;
        &:hover,
        &:focus {
          border-color: $black;
          background: white;
        }
      }
    }
    .custom-input {
      &--default {
        font-size: 1.6em;
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 1em 3em 0.8em 1em;
        background-color: #fff;
        color: $black;
        line-height: 1.15;
        overflow: auto;
        &:focus {
          border-color: $black;
          outline: none;
        }
      }
      &--textarea {
        margin-bottom: 1em;
        font-size: 1.6em;
        height: 150px;
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 1em 1em 0.8em;
        background-color: #fff;
        color: $black;
        line-height: 1.15;
        overflow: auto;
        .rte-svariable,
        .rte-cvariable {
          &:before {
            content: "";
            display: block;
          }
        }
      }
      .rte-svariable,
      .rte-cvariable {
        background: #dcdcdc;
        display: inline-block;
      }
    }
    textarea {
      max-width: none;
      &:not([class*="fz-"]) {
        font-size: 2em;
      }
      &.dropdown-answers {
        font-size: 1.6em;
        margin-bottom: 1em;
      }
    }
    .fieldset__upload-image {
      margin-bottom: 3.2em;
    }
    .btn-show-description {
      display: inline-block;
      color: $black-light;
      margin: 0 0 1em;
      font-size: 1.2em;
      text-decoration: none;
      border-bottom: 1px solid $black-light;
      top: -1em;
      position: relative;
    }
    .radio--message-source {
      label.inline {
        @include regular;
        @include inline-block;
        margin-right: 1em;
      }
    }
    .optional-btn--skip {
      .input-number {
        padding: 0.5em 0.2em 0.5em 0.5em;
        width: 5em;
        font-size: 1.1em;
        &::-webkit-inner-spin-button {
          -webkit-appearance: inner-spin-button;
          opacity: 1;
          font-size: 1.6em;
        }
      }
    }
    .optional-input {
      &--sm {
        padding: 0.5em 0.2em 0.5em 0.5em;
      }
    }
    .store-variable {
      margin-bottom: 1em;
      position: relative;
      label {
        font-size: 1.3em;
      }
      a {
        margin-left: 0.5em;
      }
      &--inputs {
        display: flex;
        margin-top: 2em;
        margin-bottom: 2em;
        label {
          font-size: 1.6em;
        }
        fieldset {
          flex: 1 auto;
        }
        fieldset:not(:last-of-type) {
          margin-right: 1.6em;
        }
      }

      &--short {
        width: 80%;
        margin-top: 0;
        margin-bottom: 0;
        fieldset {
          flex: 1 50%;
          input {
            font-size: 1.5em;
            padding-top: 1em;
            padding-bottom: 1em;
          }
        }
        .logic-pulldown {
          width: 100%;
          padding-top: 1.5em;
          padding-bottom: 1.5em;
          .selected-option {
            font-size: 1.5em;
            font-weight: 400;
          }
          .available-options {
            width: 100%;
            max-height: 45em;
            overflow: hidden;
            overflow-y: auto;
          }
          .option {
            font-size: 1.4em;
            padding-left: 1.5em;
            padding-right: 1.5em;
            overflow: hidden;
            text-overflow: ellipsis;
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
      &__remove {
        position: absolute;
        right: -3em;
        bottom: 3.5em;
      }
    }
    fieldset.optional-cta {
      margin-bottom: 0;
    }
    fieldset.side-label--check {
      @include mq(min, $mq-lg) {
        padding-top: 1.1em;
      }
    }
    .fieldset--question {
      .pulldown {
        float: right;
        margin-top: -1em;
      }
    }
    .last-item-hint {
      @include invisible;
      display: none;
    }
    .answers--checkboxes {
      .answer {
        .custom-input {
          flex: 1;
          border-radius: 0 3px 3px 0;
          width: 100%;
          font-size: 1.85em;
          border: 1px solid #ddd;
          padding: 1em 4em 0.8em 1em;
          background-color: #fff;
          color: #4a4a4a;
          line-height: 1.15;
          word-break: break-word;
          word-wrap: break-word;
          overflow-wrap: break-word;
          &:focus {
            outline: none;
            border-color: #666;
          }
          &--google-sheet {
            font-size: 1.6em;
            border: none;
            padding-top: 1em;
            padding-bottom: 1em;
            border-radius: 3px;
          }
        }
        input[type="text"].has-focus,
        .custom-input.has-focus {
          & ~ .flyout-wrapper {
            display: block;
            opacity: 1;
            visibility: visible;
            z-index: 2;
          }
        }
        .flyout-wrapper {
          opacity: 0;
          visibility: hidden;
          display: none;
          .link--add-personalisation {
            top: 50%;
            transform: translateY(-50%);
            text-decoration: underline;
          }
          .flyout {
            left: 1em;
          }
        }
      }
      .answer:last-of-type {
        position: relative;
        input[type="text"]:focus,
        input[type="text"].has-focus,
        .custom-input {
          &.has-focus {
            padding-right: 9.5em;
            & + .last-item-hint,
            & + .validationMessage + .last-item-hint {
              display: block;
              opacity: 1;
              visibility: visible;
              position: absolute;
              z-index: 2;
              right: 9em;
              top: 1.5em;
              font-size: 1.1em;
              color: $black-lighter;
              max-width: 7em;
              text-align: right;
              & + .flyout-wrapper {
                .link--add-personalisation {
                  right: 10em;
                }
              }
            }
          }
        }
        .custom-input--google-sheet {
          padding-right: 5em !important;
          font-weight: 500;
          &:empty:before {
            content: "Enter value";
            color: #b3b3b3;
            position: absolute;
            left: 1em;
            font-weight: 400;
          }
        }
      }
    }
    .answer {
      margin-bottom: 1em;
      position: relative;
      padding-right: 4em;
      .delete {
        opacity: 1;
        position: absolute;
        right: 0;
        top: 1.5em;
        z-index: 1;
        @include transition;
        svg {
          fill: $black;
        }
        &:hover {
          svg {
            fill: $red;
          }
        }
      }
    }
    .answer-handle {
      cursor: move;
    }
    .answer-handle--notch {
      position: absolute;
      z-index: 1;
      right: 100%;
      text-align: center;
      padding: 0 0.3em;
      font-size: 3em;
      color: $black;
      @include vertical-align;
      i + i {
        margin-left: 0.1em;
      }
    }
    .add-multiple-answers {
      textarea {
        max-width: none;
        margin-bottom: 1em;
      }
      .link--add-personalisation {
        right: 2.5em;
        left: auto;
        .flyout {
          right: -3em;
          left: auto;
          transform: none;
          &::before,
          &::after {
            right: 10%;
          }
        }
      }
    }
    .pulldown {
      position: relative;
      z-index: 1;
      &.editor-pane__pulldown {
        .pulldown-inner {
          height: 4.3em;
        }
        .pulldown-select {
          color: $black;
          height: 100%;
          font-size: 1.4em;
        }
      }
      .pulldown-inner {
        border-color: #9b9b9b;
      }
      .pulldown-select {
        font-size: 1.3em;
      }
      &--google-sheet {
        width: 33em;
        .pulldown-inner {
          border-color: #cfcfcf;
        }
        .pulldown-select {
          height: 100%;
          font-size: 1.6em;
          &.text-medium {
            color: $black;
          }
        }
      }
    }
    .pulldown--layout {
      .pulldown-select {
        height: 2.4em;
      }
    }
    .info-panel {
      margin-top: $spacer-y * 2;
      background-color: rgba(#fff, 0.5);
      border-color: #ececec;
    }
    .input-group .input-group-addon {
      svg {
        fill: $gray-700;
      }
    }
    .validationMessage {
      display: block;
      background-color: transparent;
      color: $error;
      font-size: 1.5em;
      @include medium;
      padding: 0;
      margin-top: 1em;
      margin-bottom: 0;
      text-align: left;
      max-width: 11em;
      &--sm {
        max-width: none;
        margin-bottom: 1em;
        font-size: 1.1em;
        font-style: italic;
        margin-top: 0.7em;
      }
    }
    @include mq(max-height, $mq-mbp) {
      .fieldset--save {
        position: fixed;
        z-index: 3;
        top: 1.4em;
        right: 7em;
        @include mq(min, $mq-sm) {
          top: 2.5em;
          right: 7.5em;
        }
        @include mq(min, $mq-md) {
          top: 3.5em;
          right: 9em;
        }
      }
    }
  }
  .editor-pane--full-width {
    @include mq(min, $mq-md) {
      width: 100%;
    }
  }
  .editor-pane__inner {
    @include mq(min, $mq-lg) {
      max-width: 100em;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .preview-pane {
    background-color: #fff;
    padding: 2em;
    @include mq(min, $mq-md) {
      padding: 12em 1.5em 2em;
      flex-basis: 40em;
      overflow: auto;
    }
    .continually-chat-team,
    .continually-chat {
      font-size: 80%;
    }
    .continually-chat-team,
    .continually-chat,
    .continually-chat * {
      pointer-events: none;
      user-select: none;
    }
    fieldset.schedule_demo {
      min-width: 0;
    }
    a.rich-media-card .rich-media-card__summary .site-name {
      font-size: 0.8em;
    }
    .post--restart {
      .btn {
        font-size: 1.65em;
      }
    }
  }
  .calendar-not-connected-not-owner {
    background-color: #dbdbdb;
    color: #686868;
    border-radius: 6px;
    padding: 1.5em 1.5em 0.4em 1.5em;
    margin-bottom: 2em;
    position: relative;
    p {
      font-size: 1.6em;
      @include medium;
      @include font-smoothing;
    }
  }
  .calendar-not-connected {
    background-image: linear-gradient(to right, $brand-blue, $brand-green);
    color: #fff;
    border-radius: 6px;
    padding: 2.4em 3.2em 2.4em 10.2em;
    margin-bottom: 2em;
    position: relative;
    figure {
      position: absolute;
      z-index: 1;
      left: 1.8em;
      top: 2.4em;
    }
    p {
      font-size: 1.6em;
      @include medium;
      @include font-smoothing;
    }
    .btn {
      padding: 0;
      margin-right: 1em !important;
      @include medium;
      color: #fff;
      font-size: 1.4em;
      figure {
        position: static;
        @include inline-block;
        margin-right: 0.3em;
        svg {
          fill: #fff;
        }
      }
      .label {
        @include inline-block;
        text-decoration: underline;
        color: white;
      }
      &:hover .label {
        text-decoration: none;
      }
    }
  }
  .calendar-connected-tabs {
    margin-bottom: 2em;
    &__section {
      display: flex;
      align-items: baseline;
      margin-bottom: 2em;
    }
    .tabs {
      input[type="radio"] {
        @include offscreen;
        &:checked + label,
        &:checked + .validationMessage + label {
          background-color: #fff;
          border-bottom-color: #fff;
          color: $black;
          @include medium;
        }
      }
      label {
        display: inline-block;
        background-color: transparent;
        border-radius: 4px 4px 0 0;
        text-align: center;
        padding: 1em 2em 0.8em;
        font-size: 1.5em;
        border: 1px solid #ddd;
        color: $black-light;
        cursor: pointer;
      }
    }
    .tab-panels {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 0 4px 4px 4px;
      margin-top: -1px;
      padding: 3.2em;
      .notification {
        background-color: $grey-light;
        border-radius: 4px;
        border: 1px solid #ddd;
        padding: 1em 2em;
        margin-bottom: 1.6em;
        display: flex;
        .icon {
          margin-right: 1em;
          background-color: $brand-green;
          width: 2em;
          height: 2em;
          border-radius: 2em;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          i {
            color: #fff;
          }
        }
        .text {
          flex: 1;
          font-size: 1.5em;
          margin-top: 0.2em;
        }
      }
      .preview {
        position: relative;
        padding-top: 1.6em;
        display: flex;
        .continually-chat-team,
        .continually-chat {
          margin-left: auto;
          margin-right: 0;
          pointer-events: none;
          user-select: none;
          font-size: 80%;
          max-width: 40em;
          flex-grow: 1;
          .post {
            padding-left: 2em !important;
          }
          .group.day.radio.times {
            min-width: 15em;
          }
        }
        a {
          color: $black;
          cursor: pointer;
        }
        .preview-explainer {
          // position: absolute;
          // top: 1.6em;
          // left: 0;
          width: calc(100% - 40em);
          flex-grow: 1;
          span.subheader {
            font-size: 1.6em;
            font-family: MundoSansPro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            font-weight: 400;
            font-style: normal;
            margin: 0;
          }
          .form-help {
            color: $black !important;
            span {
              vertical-align: baseline;
              margin-right: 0;
            }
          }
          label.specific-range {
            display: inline-block;
          }
          input.error {
            border: 1px solid $red;
          }
          .condition--row {
            margin-top: 0.6em !important;
          }
          .word {
            color: $black;
          }
          .datepicker-here {
            border-color: $black;
            height: 2.8em;
            max-width: 10em;
          }
          .pulldown-select {
            @include medium;
            color: $black;
            padding-right: 2.4em;
            font-size: 1.6em;
          }
          .interval {
            margin-top: 1.6em;
          }
          .pulldown-days {
            margin-left: 1em;
          }
          .column--value--between {
            justify-content: left;
          }
          .pulldown--disabled {
            background-color: $grey;
          }
          select:disabled {
            cursor: not-allowed;
          }
          .pulldown-arrow {
            right: 1em;
            &:after {
              opacity: 0.3;
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              background-size: 10px;
              background-position: center;
              background-repeat: no-repeat;
              background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTE2IDUuNWwtMi0yLTYgNi02LTYtMiAyIDggOCA4LTh6Ij48L3BhdGg+PC9zdmc+);
            }
          }
        }
      }
    }
    .label-side {
      position: static !important;
      text-align: left !important;
    }
    .notify-teammember__list {
      li {
        padding-top: 1em;
        padding-bottom: 1em;
      }
    }
  }
}

.conversation-object-switcher {
  text-align: left;
  position: relative;
  & > .options {
    opacity: 0;
    pointer-events: none;
    &.dropdown-active {
      animation: showDropdown 0.1s ease;
      opacity: 1;
      pointer-events: auto;
    }
    position: absolute;
    z-index: 10;
    left: 0;
    top: calc(100% + 0.8em);
    .btn {
      box-shadow: 0 0 5px 5px rgba($grey-light, 0.75);
      &:hover {
        border-color: $brand-primary;
        box-shadow: inset 0 0 0 1px $brand-primary, 0 0 5px 5px rgba($grey-light, 0.75);
      }
    }
  }
  .btn {
    display: block;
    width: 16em;
    text-align: left;
    margin-bottom: 0.4em;
    padding-left: 1em;
    padding-right: 1em;
    border-color: #d3d3d3;
    background-color: #fff;
    color: #5c5c5c;
    svg {
      fill: $black;
      top: -0.1em;
      position: relative;
    }
    .arrow-down5 {
      opacity: 0.3;
    }
    &:hover {
      background-color: #fcfcfc;
      color: $black;
      .arrow-down5 {
        opacity: 1;
      }
    }
  }
  & > .btn {
    padding-left: 2.7em;
    position: relative;
    margin-bottom: 0;
    border-color: rgba(#d3d3d3, 0.3);
    &:hover {
      border-color: #d3d3d3;
    }
    &.dropdown-active {
      opacity: 0.5;
    }
    svg:not(.arrow-down5) {
      display: none;
      position: absolute;
      left: 1em;
      @include vertical-align;
      margin: 0 !important;
    }
    svg.arrow-down5 {
      position: absolute;
      right: 1em;
      @include vertical-align;
      margin: 0 !important;
    }
    &[data-object-type="Message"] svg.bubbles2,
    &[data-object-type^="Multi"] svg.list,
    &[data-object-type^="Single"] svg.radio-checked,
    &[data-object-type^="Drop"] svg.circle-down2,
    &[data-object-type^="Text"] svg.typography,
    &[data-object-type^="Likert"] svg.thumbs-up3,
    &[data-object-type="Appointment"] svg.calendar-day,
    &[data-object-type^="Email"] svg.envelop3,
    &[data-object-type="Phone"] svg.phone2 {
      display: block;
    }
  }
}

.conversation-object-editor.dropdown-active {
  opacity: 0.2;
  pointer-events: none;
}

.conversation-object-editor {
  .wysiwyg-wrapper {
    position: relative;
    &--no-toolbar {
      .ql-toolbar {
        display: none;
      }
      .ql-container.ql-snow {
        border-top: 1px solid #ccc;
      }
    }
  }
  .ql-tooltip {
    z-index: 999;
  }
  .ql-tooltip.ql-editing {
    input[type="text"] {
      font-size: 1em !important;
    }
  }
  .link--add-personalisation {
    position: absolute;
    left: 195px;
    top: 1em;
    text-decoration: none;
    font-size: 10px;
    padding: 3px 5px;
    border-radius: 2px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    @include mq(min, $mq-xl) {
      left: 20em;
    }
    span {
      font-size: 1.4em;
      line-height: 1;
      color: $black;
      @include medium;
    }
    .tooltip__bubble {
      white-space: nowrap;
      width: auto;
    }
  }
  .link--add-personalisation-border {
    right: 1em;
    left: auto;
    top: 0.8em;
    text-decoration: none;
    border: 1px solid #ddd;
    padding: 0.8em 1em;
    border-radius: 3px;
    line-height: 1;
  }
  .multiple-choice-answer {
    .link--add-personalisation {
      left: auto;
      right: 1%;
      top: 1.3em;
      border: 1px solid #ddd;
      padding: 0.8em 1em;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1em;
    }
    .flyout {
      right: -2em;
      left: auto;
      transform: none;
      max-width: 453px;
      &::before,
      &::after {
        right: 10%;
      }
    }
  }
}

.flyout--variables {
  &.flyout--variables-left {
    left: -2em;
  }
}

.flyout--gif {
  h3 {
    font-size: 1.4em;
    @include bold;
  }
  .form {
    display: flex;
    margin-bottom: 1em;
    .input {
      flex: 1;
      input {
        @include regular;
        font-size: 1.2em;
        background-color: #f7f7f7;
        &:focus {
          border-color: $brand-primary;
        }
      }
    }
    .button {
      flex-grow: 0;
      margin-left: 1em;
      button {
        float: none;
        height: auto;
        width: auto;
        padding: 0.7em 0.6em 0.5em;
        border-radius: 4px;
        @include medium;
        font-size: 1.4em;
        color: #fff;
        background-color: $brand-primary;
        border: 1px solid $brand-primary;
        @include transition;
        &:disabled {
          color: $black;
          background-color: rgba(#e0e0e0, 0.3);
          border: 1px solid rgba(#c1c1c1, 0.3);
        }
        &:hover {
          color: darken($black, 10%);
          background-color: white;
          border-color: darken(#d1d1d1, 10%);
        }
      }
    }
  }
  .help {
    font-size: 1.2em;
    color: $black-light;
    @include regular;
    margin-bottom: 0;
    img {
      @include inline-block;
      margin-right: 0.5em;
    }
    span {
      @include inline-block;
    }
    a {
      color: $black-light;
      text-decoration: underline;
    }
  }
}

.condition--row {
  @include clearfix;
  margin-top: 2em;
  &-flex {
    display: flex;
    align-items: center;
    margin-top: 0;
    .column {
      margin-bottom: 1em;
      .selected-option {
        font-weight: 500;
      }
    }
    .column.column--value {
      width: auto !important;
    }
    .logic-pulldown {
      padding: 1em 2em 1em 1.2em;
    }
    .delete-condition {
      margin-bottom: 1em;
    }
  }
  &-space-top {
    margin-top: 2em;
  }
  &-parent {
    .column {
      &--variable {
        width: 20em;
      }
      .selected-option {
        font-weight: 400;
      }
    }
    .logic-pulldown {
      padding-top: 1.2em;
      padding-bottom: 1.2em;
    }
  }
  .column {
    float: left;
    display: block;
    margin-right: 1.2em;
  }
  .nested-column {
    float: left;
    display: block;
  }
  .nested-column + .nested-column {
    margin-left: 1.2em;
  }
  .column--variable {
    width: 18em;
    .logic-pulldown {
      width: 100%;
      .selected-option {
        font-size: 1.6em;
      }
    }
  }
  .column--operator {
    width: 18em;
    .logic-pulldown {
      width: 100%;
      .selected-option {
        font-size: 1.6em;
      }
    }
  }
  .column--value {
    float: left;
    margin-right: 1.2em;
    width: calc(100% - 61em) !important;
    input {
      padding: 0.7em 1em 0.55em 1em;
      font-size: 1.6em !important;
    }
  }
  .column--value--between {
    display: flex;
    justify-content: center;
    align-items: baseline;
    input,
    .pulldown {
      flex-grow: 1;
    }
    span.word {
      flex-grow: 0;
      font-size: 1.4em;
      text-align: center;
      margin: 0 1em;
      color: $black-light;
    }
    .pulldown + .pulldown {
      margin-left: 1em;
    }
  }
  .column--value--between-to {
    display: flex;
    justify-content: center;
    align-items: center;
    .pulldown {
      flex-grow: 1;
      height: 100%;
    }
    span.word {
      flex-grow: 0;
      font-size: 1.4em;
      text-align: center;
      margin: 0 1em;
      color: $black-light;
    }
  }
  .column--value--full-pulldown {
    .pulldown {
      width: 100%;
    }
  }
  .nested-column--day {
    width: calc(100% - 19.2em);
    .pulldown {
      width: 100%;
    }
  }
  .column--timezone,
  .nested-column--timezone {
    width: 18em;
    position: relative;
    &-inline {
      width: 22em;
      .logic-pulldown--timezone {
        display: flex;
        align-items: center;

        .label {
          position: static;
          white-space: nowrap;
          font-size: 1em;
          margin-right: 1em;
          color: $gray-500;
        }
      }
    }
    .label {
      position: absolute;
      top: -1.2em;
      left: 0;
      @include medium;
      font-size: 1.4em;
      color: #4a4a4a;
    }
  }
  .pulldown {
    &--tags {
      .pulldown-inner {
        .flag {
          margin-bottom: 0.3em;
        }
      }
    }
    .pulldown-select {
      padding-right: 1.4em;
      font-size: 1.6em !important;
      height: 2.4em;
      min-width: 7em;
      font-weight: 500;
      color: $black !important;
    }
    .pulldown-inner {
      height: auto;
      border-color: #ddd !important;
      min-height: 4em;
    }
  }
  .delete-condition {
    text-decoration: none;
    float: left;
    width: 3.8em;
    height: 3.8em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $red;
    background: white;
    border-radius: 3px;
    border: 1px solid $red;
    i {
      font-size: 1.4em;
    }
  }
}

.add-condition-row {
  margin-top: 1em;
  background: none;
  border: none;
  padding: 0;
  &:after {
    content: none;
  }
  .option {
    font-size: 1.5em;
    &--heading {
      font-weight: 500;
      font-size: 1.6em;
      &:not(:first-child) {
        margin-top: 2em;
      }
    }
  }
  .selected-option {
    font-size: 1em;
  }
  .available-options {
    overflow: hidden;
    overflow-y: auto;
    max-height: 36em;
  }
}

// Emojis

.emoji_completions {
  z-index: 10;
  & > li > button {
    padding: 5px 4px;
  }
  .ap {
    @include quill-emoji-reset;
    margin-right: 0.2em;
  }
}

.item--label,
.ql-editor {
  .emoji {
    margin-bottom: 0;
  }
}

#emoji-palette {
  #tab-panel {
    .ap {
      @include quill-emoji-reset;
    }
  }

  .conv-builder__sub-sidebar & {
    @include mq(max, $mq-lg) {
      left: 0 !important;
    }
  }
}

// Appearance

.conv-builder__section--appearance {
  .conv-builder__sidebar {
    width: 44em;
    background: #222428;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
  .conv-builder__sidebar-in {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .conv-builder__sidebar-title {
    padding: 2.5em 1.5em;
    font-size: 1.4em;
    text-transform: uppercase;
  }
  label.sub-label {
    text-transform: uppercase;
    font-size: 1.2em;
    @include medium;
    @include font-smoothing;
  }
  fieldset.color {
    .input-group {
      display: flex;
      .input-group-addon {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        bottom: 0;
        border: none;
        background: #dadada;
        border-radius: 4px 0 0 4px;
        &:first-child {
          border-radius: 4px 0 0 4px;
        }
      }
    }
    input[type="text"] {
      border-radius: 4px;
      padding-left: 3.5em;
      border-color: #dadada;
    }
  }
  .conv-builder__main {
    position: relative;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.058) 0%, rgba(0, 0, 0, 0) 50.06%), #f3f3f3;
    &--preview {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
    }
    .website-preview {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    .continually-button-container {
      position: absolute;
      z-index: 2;
      bottom: 20px;
      right: 20px;
      cursor: pointer;
      &.pos-left {
        left: 20px;
        right: auto;
      }
      .continually-button {
        display: flex;
        align-items: center;
        box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.2);
        transition: transform 0.2s;
        background-color: $brand-primary;
        &:hover {
          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            border-radius: inherit;
            background-color: black;
            opacity: 0.1;
          }
        }
        &.continually-button--i {
          width: 62px;
          height: 62px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          .continually-button-icon {
            max-width: 34px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            img {
              flex-shrink: 0;
            }
          }
        }
        &.continually-button--it {
          border-radius: 2em;
          padding: 1em 1.6em;
          .continually-button-icon,
          .continually-button-text {
            @include inline-block;
          }
          .continually-button-icon {
            color: #fff;
            margin-right: 1em;
            max-width: 2.4em;
          }
          .continually-button-text {
            margin: 0;
          }
        }
        &.continually-button--t {
          border-radius: 2em;
          .continually-button-text {
            margin: 0.75em 1.6em;
          }
        }
      }
      .continually-button-text {
        color: inherit;
        line-height: 1;
        font-size: 1.6em;
        font-family: $font-family-sans-serif;
        font-weight: 700;
      }
    }
    .continually-message-container {
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-name: showEmbeddedMsg;
      position: absolute;
      z-index: 2;
      bottom: 40px;
      right: 20px;
      user-select: none;
      cursor: pointer;
      &.pos-left {
        left: 20px;
        right: auto;
        animation-name: showEmbeddedMsgReverse;
        .continually-message-bubble {
          left: 5.8em;
          right: auto;
        }
        .avatar {
          left: 0;
          right: auto;
        }
      }
      .avatar {
        position: absolute;
        right: 0;
        bottom: 0;
        @include avatar(4.8em, 2.5em, 50%);
        border-color: transparent;
        background-color: #fff;
      }
      .continually-message-bubble {
        position: absolute;
        right: 5.8em;
        bottom: 0;
        color: #fff;
        width: max-content;
        max-width: 26em;
        background-color: $brand-primary;
        border-radius: 8px;
        &.continually-message-bubble--i,
        &.continually-message-bubble--l,
        &.continually-message-bubble--v {
          width: 26em;
        }
        &.continually-message-bubble--i {
          .continually-message-image {
            border-radius: 8px 8px 0 0;
            overflow: hidden;
            img {
              display: block;
              margin: auto;
            }
          }
          .continually-message-text {
            padding-top: 1.0625em;
          }
        }
        &.continually-message-bubble--l {
          .continually-message-link,
          .continually-message--gdpr {
            position: absolute;
            left: 0;
            right: 0;
            bottom: calc(100% + 10px);
            border-radius: 8px;
            overflow: hidden;
            background-color: #fff;
            box-shadow: 0 0 8px 0 rgba(#000, 0.25);
            text-decoration: none;
            padding: 0;
            .continually-message-link-image {
              display: block;
              width: 100%;
            }
            .continually-message-link-title {
              padding: 16px;
              font-family: $font-family-sans-serif;
              font-weight: bold;
              line-height: 1.3;
              color: #333;
              font-size: 16px;
            }
            &:hover {
              .continually-message-link-title {
                text-decoration: underline !important;
              }
            }
          }
        }
        &.continually-message-bubble--v {
          .continually-message-video {
            position: absolute;
            left: 0;
            right: 0;
            bottom: calc(100% + 10px);
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            border-radius: 8px;
            overflow: hidden;
            iframe,
            video {
              width: 100%;
              height: 100%;
              position: absolute;
            }
          }
        }
        // Placeholders
        .continually-message-image--placeholder {
          margin-top: -8px;
          border-radius: 8px 8px 0 0;
          border: 1px dashed #979797;
          border-bottom: none;
          background-color: $grey-light;
          overflow: hidden;
          height: 26em;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $black;
          i {
            display: block;
            font-size: 3em;
          }
        }
        .continually-message-video--placeholder {
          position: absolute;
          left: 0;
          right: 0;
          bottom: calc(100% + 10px);
          border-radius: 8px;
          border: 1px dashed #979797;
          background-color: $grey-light;
          overflow: hidden;
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
          color: $black;
          i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            font-size: 3em;
          }
        }
        .continually-message-link--placeholder {
          position: absolute;
          left: 0;
          right: 0;
          bottom: calc(100% + 10px);
          border-radius: 8px;
          border: 1px dashed #979797 !important;
          background-color: $grey-light !important;
          box-shadow: none !important;
          overflow: hidden;
          height: 26em;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $black;
          i {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: block;
            font-size: 3em;
          }
        }
        a.rich-media-card {
          margin-top: 0;
        }
      }
      .close-continually-message-button {
        position: absolute;
        left: -26px;
        top: 0;
        background-color: #9b9b9b;
        color: #fff;
        font-weight: 700;
        line-height: 1;
        @include wh(16px);
        padding: 4px;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0 0 8px 0 rgba(#000, 0.2);
        &:hover {
          opacity: 0.75;
        }
        img {
          display: block;
          @include wh(8px);
        }
      }
    }
    .continually-autostart-container {
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-name: showEmbeddedMsg;
      position: absolute;
      z-index: 2;
      bottom: 40px;
      right: 20px;
      user-select: none;
      cursor: pointer;
      &.pos-left {
        left: 20px;
        right: auto;
        animation-name: showEmbeddedMsgReverse;
        .avatar {
          left: 0;
          right: auto;
        }
        .continually-autostart-message {
          margin-left: 5.8em;
          margin-right: 0;
          &--multiple_choice,
          &--gdpr {
            .continually-autostart-actions[data-answer-layout="horizontal"] {
              justify-content: flex-start;
              margin-right: -15%;
              margin-left: 0;
              .continually-autostart-actions__option,
              .continually-autostart-actions__check {
                margin-left: 0;
                &:not(:last-child) {
                  margin-right: 0.8em;
                }
              }
            }
          }
        }
      }

      .avatar {
        position: absolute;
        right: 0;
        bottom: 0;
        @include avatar(4.8em, 2.5em, 50%);
        border-color: transparent;
        background-color: #fff;
      }
      .continually-autostart-message {
        color: #fff;
        width: 26em;
        margin-right: 5.8em;
        &--multiple_choice,
        &--gdpr {
          & + .avatar {
            bottom: auto;
            top: 0;
          }
          .continually-message-bubble {
            margin-bottom: 1em;
            border-radius: 8px;
          }
          .continually-message-text {
            padding: 17.5px 20px;
          }
          .continually-autostart-actions {
            background: none;
            border: none;
            &[data-answer-layout="horizontal"] {
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              justify-content: flex-end;
              width: 115%;
              margin-left: -15%;
              .continually-autostart-actions__option {
                padding-left: 15px;
                padding-right: 15px;
              }
              .continually-autostart-actions__option,
              .continually-autostart-actions__check {
                margin-left: 0.8em;
              }
              .continually-autostart-actions__send {
                width: 86.25%;
              }
            }
          }
        }
        &--message,
        &--video,
        &--link {
          .continually-message-bubble {
            border-radius: 8px;
          }
        }
        &--image {
          .continually-message-bubble {
            border-radius: 0 0 8px 8px;
          }
          .continually-message-text {
            padding-top: 1.0625em;
          }
        }
      }
      .continually-message-bubble {
        background-color: $brand-primary;
        border-radius: 8px 8px 0 0;
      }
      .continually-message-video {
        position: absolute;
        left: 0;
        right: 0;
        bottom: calc(100% + 10px);
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        border-radius: 8px;
        overflow: hidden;
        iframe,
        video {
          width: 100%;
          height: 100%;
          position: absolute;
        }
      }
      .continually-message-image {
        margin-top: -8px;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
        img {
          display: block;
          width: 100%;
        }
      }
      .continually-message-link {
        position: absolute;
        left: 0;
        right: 0;
        bottom: calc(100% + 10px);
        border-radius: 8px;
        overflow: hidden;
        background-color: #fff;
        box-shadow: 0 0 8px 0 rgba(#000, 0.25);
        text-decoration: none;
        padding: 0;
        .continually-message-link-image {
          display: block;
          width: 100%;
        }
        .continually-message-link-title {
          padding: 16px;
          font-family: $font-family-sans-serif;
          font-weight: bold;
          line-height: 1.3;
          color: #333;
          font-size: 16px;
        }
        &:hover {
          .continually-message-link-title {
            text-decoration: underline !important;
          }
        }
      }
      .continually-message--gdpr {
        .rich-media-card {
          background: transparent;
          border: none;
          margin-top: 0;
          &:hover {
            border-color: transparent;
          }
          h2 {
            text-decoration: none;
            font-size: 1em;
            color: #333;
            font-weight: 700;
          }
          .desc,
          .site-name {
            display: none;
          }
          &__figure {
            background-color: transparent;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 0;
            border: none;
          }
          &__summary {
            padding: 1em;
          }
        }
      }
      .continually-autostart-actions {
        border-radius: 0 0 8px 8px;
        background: #fff;
        border: 1px solid #ddd;
        overflow: hidden;
        &--single-btn {
          display: flex;
          align-items: center;
          padding: 2em;
        }
        &--multiple-btn {
          background: none;
          border-radius: 0;
          border: none;
        }
        &__fa {
          margin-right: 0.5em;
          min-width: 1.3em;
        }
        &__label {
          color: #747474;
          font-size: 1.5em;
          flex: 1 auto;
          font-family: $font-family-sans-serif;
          word-break: break-all;
          word-break: break-word;
        }
        &__icon {
          flex-grow: 0;
          margin-left: 1em;
        }
        &--file-upload {
          padding: 1em 1.2em;
        }
        &__upload-box {
          background: #f4f4f4;
          border: 1px dashed #afafaf;
          border-radius: 3px;
          text-align: center;
          padding: 1.5em;
          .continually-autostart-actions__label {
            text-transform: uppercase;
            font-weight: 500;
          }
        }
      }
      .continually-autostart-actions--dropdown {
        padding: 1em 1.2em;
      }
      .continually-autostart-actions__pulldown {
        background: #ffffff;
        border: 1px solid #afafaf;
        border-radius: 3px;
        padding: 1.5em 3.6em 1.5em 1.5em;
        position: relative;
        &:after {
          position: absolute;
          z-index: 1;
          top: 50%;
          transform: translateY(-33%);
          right: 1.6em;
          content: " ";
          height: 0;
          width: 0;
          pointer-events: none;
          border: 0.5em solid rgba($black, 0);
          border-top-color: $black;
        }
      }
      .continually-autostart-actions--appt-btn {
        padding: 1em;
      }
      .continually-autostart-actions__btn {
        background: #ffffff;
        border: 1px solid #afafaf;
        border-radius: 3px;
        padding: 1.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        .continually-autostart-actions__icon + .continually-autostart-actions__label {
          margin-left: 0.66em;
        }
      }
      .continually-autostart-actions__option {
        border: 1px solid currentColor;
        padding: 1em 2em;
        position: relative;
        display: flex;
        align-items: center;
      }
      .close-continually-message-button {
        position: absolute;
        left: -26px;
        top: 0;
        background-color: #9b9b9b;
        color: #fff;
        font-weight: 700;
        line-height: 1;
        @include wh(16px);
        padding: 4px;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0 0 8px 0 rgba(#000, 0.2);
        &:hover {
          opacity: 0.75;
        }
        img {
          display: block;
          @include wh(8px);
        }
      }
    }
    .continually-message-text {
      font-size: 1.6em;
      padding: 1.5625em;
      position: relative;
      z-index: 2;
      line-height: 1.3;
      font-family: $font-family-sans-serif;
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
      color: inherit !important;
      &--no-padding {
        padding: 0;
        > img,
        iframe {
          border-radius: 4px;
        }
      }
      strong {
        font-family: $font-family-sans-serif;
        font-weight: 700;
        letter-spacing: -0.03em;
      }
      em {
        font-family: $font-family-sans-serif;
        font-style: italic;
      }
      strong em,
      em strong {
        font-family: $font-family-sans-serif;
        font-weight: 700;
        font-style: italic;
      }
      a:not(.rich-media-card):not(.btn--cta) {
        color: #fff;
      }
      .btn--cta {
        font-size: 1em;
      }
      .media-container--video {
        @include mq(min, $mq-md) {
          min-width: 10em;
        }
        @include mq(min, $mq-xl) {
          min-width: 13em;
        }
      }
      .rich-media-card__summary {
        h2 {
          @include medium;
          font-size: 14px;
        }
        p.desc,
        .site-name {
          @include regular;
          font-size: 12px;
        }
      }
    }
    .continually-powered-by {
      animation-duration: 0.5s;
      animation-fill-mode: both;
      animation-name: showEmbeddedMsg;
      position: absolute;
      right: calc(5.8em + 20px);
      bottom: 14px;
      border-radius: 20px;
      text-align: right;
      background-color: rgba(#fff, 0.65);
      padding: 0.5em 0.8em;
      @include transition(background-color);
      cursor: default;
      &:hover {
        background-color: #fff;
      }
      &.pos-left {
        left: calc(5.8em + 20px);
        right: auto;
        animation-name: showEmbeddedMsgReverse;
      }
      span {
        font-size: 1.1em;
        color: $black;
      }
      * {
        font-family: $font-family-sans-serif;
        font-weight: normal;
        line-height: inherit;
        color: $black;
        vertical-align: baseline;
      }
    }
  }
  .conversation-js-preview {
    position: absolute;
    top: 1.6em;
    bottom: 1.6em;
    right: 1.6em;
    z-index: 9;
    overflow: auto;
    width: 320px;
    border-radius: 5px;
    @include mq(min, $mq-lg) {
      width: 340px;
      &.continually-sidebar-thick {
        width: 370px;
      }
    }
    @include mq(min, $mq-xl) {
      width: 370px;
      &.continually-sidebar-thick {
        width: 410px;
      }
    }
    &.pos-left {
      left: 1.6em;
      right: auto;
    }
    &.continually-sidebar-short {
      max-height: 670px;
      top: auto !important;
      height: calc(100% - 32px);
    }
    &.continually-sidebar-full-height {
      top: 0;
      bottom: 0;
      border-radius: 0;
      right: 0;
      &.pos-left {
        left: 0;
      }
    }
  }
  .hidden-instructions {
    h4 {
      font-size: 1.4em;
      &:not(:first-child) {
        margin-top: 3.2em;
      }
    }
    code {
      background-color: $blue-new;
      color: white;
    }
    pre {
      background-color: rgba(#000, 0.1);
      padding: 0.9em 1em;
      border-radius: 4px;
      font-size: 1.4em;
      margin-bottom: 1.6em;
      white-space: pre-line;
      display: inline-block;
    }
  }
  .website-preview {
    .helper {
      @include mq(max, $mq-lg) {
        display: none;
      }
      position: absolute;
      z-index: 1;
      pointer-events: none;
      &.pos-left {
        .arrow {
          right: auto;
          left: 5em;
          transform: scaleX(-1);
        }
        .desc {
          padding-left: 0.5em;
          padding-right: 0;
          text-align: left;
        }
      }
      .arrow {
        position: absolute;
        z-index: 1;
        right: 5em;
        top: 4em;
        svg {
          width: 2.6em;
          height: 3.7em;
        }
      }
      .desc {
        font-size: 1.6em;
        color: $black-light;
        font-weight: 500;
        text-align: right;
      }
      &--new {
        bottom: 7em;
        right: 40em;
        .fa-arrow-left {
          display: none;
        }
        &.pos-left {
          right: 1.6em;
          left: 40em;
          .fa-arrow-right {
            display: none;
          }
          .fa-arrow-left {
            display: inline-block;
          }
        }
      }
      &--new-button {
        bottom: 4em;
        right: 20em;
        &.pos-left {
          right: 1.6em;
          left: 20em;
        }
      }
    }
    .helper--message-preview,
    .helper--autostart-preview {
      bottom: 22em;
      left: 1.6em;
      right: 15em;
      @include mq(min, $mq-xl) {
        right: 20em;
      }
      &.pos-left {
        right: 1.6em;
        left: 20em;
      }
    }
    .helper--autostart-preview {
      bottom: 35em;
    }
    .helper--button-preview {
      bottom: 15em;
      left: 1.6em;
      right: 5em;
      &.pos-left {
        right: 1.6em;
        left: 5em;
      }
    }
    .helper--hidden-preview {
      @include mq(max, 1350px) {
        display: none;
      }
      top: 4em;
      left: 1.6em;
      right: 60em;
    }
  }
  @include mq(max-height, $mq-mbp) {
    .color-picker-sv {
      width: 120px;
      height: 140px;
    }
    .color-picker-h {
      height: 140px;
    }
  }
}

.launch-format-container {
  padding-left: 1.5em;
  padding-right: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  input[type="radio"] {
    display: none;
    &:checked ~ label {
      border: 2px solid #00c797;
      .launch-format__state-checked {
        display: inline-block;
        color: #00c797;
      }
      .label {
        color: white;
      }
    }
    &:checked ~ label:hover {
      .launch-format__state-checked-hover {
        display: inline-block;
      }
      .launch-format__state-hover {
        display: none;
      }
    }
  }
  label {
    width: 17.5em;
    min-height: 16em;
    display: flex;
    flex: 1 auto;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    color: #fff;
    cursor: pointer;
    font-size: 1em;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    margin-left: 1.5em;
    margin-right: 1em;
    margin-bottom: 3em;
    flex-direction: column;
    border: 2px solid transparent;
    &:hover {
      background: rgba(255, 255, 255, 0.2);
      .launch-format__state-hover {
        display: inline-block;
      }
    }
    .label {
      letter-spacing: 0.25px;
      font-size: 1.4em;
      color: white;
      margin-bottom: 1em;
      font-weight: 400;
    }
  }
}

// URL targeting

.conv-builder__section--targeting {
  .targeting {
    background-color: #fff;
    height: calc(100vh - #{$conv-builder-header-height});
    overflow: auto;
    header {
      padding: 4.8em 0 3.2em;
      h2 {
        @include light;
        font-size: 3em;
        margin-bottom: 0.33em;
      }
      p {
        font-size: 1.8em;
        margin-bottom: 0;
        @include regular;
        @include font-smoothing;
      }
      .paragraph {
        span {
          @include inline-block;
          font-size: 1.8em;
          @include regular;
          @include font-smoothing;
          margin-right: 0.5em;
        }
        .logic-pulldown {
          @include inline-block;
        }
      }
      .rules {
        margin-bottom: 2.2em;
        h3 {
          font-size: 1.8em;
          @include medium;
        }
        .rule {
          background: #e9e9e9;
          border-radius: 3px;
          padding: 2.3em 6em 2em 2.5em;
          margin-bottom: 1em;
          position: relative;
          display: flex;
          align-items: center;
          border: 1px solid #e9e9e9;
          &:hover {
            border: 1px solid #7b7b7b;
            cursor: pointer;
            background: #d9d9d9;
          }
          .label {
            font-size: 1.6em;
            @include medium;
            margin-right: 0.6em;
            color: #666;
          }
          .url-tags {
            span {
              display: inline-block;
              margin-right: 0.5em;
              font-size: 1.6em;
              color: #666;
            }
          }
          .btn--remove-rule {
            position: absolute;
            right: 2.5em;
            top: 2em;
            i {
              color: #a2a2a2;
              font-size: 2em;
            }
            &:hover i {
              color: $black;
            }
          }
        }
      }
      .btn--add-rule {
        @include regular;
        font-size: 1.8em;
      }
    }
  }
}

.bot-targeting {
  background-color: #fff;
  height: calc(100vh - #{$conv-builder-header-height});
  overflow: auto;
  &__title {
    font-weight: 500;
    font-size: 2.2em;
  }
  &__header {
    color: #ffffff;
    background-image: linear-gradient(134deg, #335c8f 0%, #172f56 100%);
    padding: 4em 0;
    text-align: center;
    margin-bottom: 1em;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 4.4em;
  }
  &__item {
    background: #ffffff;
    border: 1px solid #bababa;
    border-radius: 3px;
    display: flex;
    padding: 2.5em;
    margin-bottom: 2em;
    cursor: pointer;
    width: 48%;
    &:hover {
      border-color: black;
    }
    @include mq(min, $mq-md) {
      width: 31.3%;
    }
    i {
      font-size: 2.8em;
      margin-right: 0.6em;
    }
    p {
      font-size: 1.4em;
      color: #85706f;
    }
  }
  &__rules {
    margin: 0 8.4em;
    grid-template-columns: repeat(auto-fill, 175px);
    display: grid;
    grid-gap: 1em;
    justify-content: center;
  }
  &__rule {
    background: #ffffff;
    border: 1px solid #bababa;
    border-radius: 3px;
    padding: 1em;
    margin-bottom: 0.5em;
    cursor: pointer;
    &:hover {
      border-color: black;
    }
    i {
      margin-right: 0.5em;
    }
  }
}

.targeting-group {
  background: $grey-light;
  border: 1px solid #dddddd;
  margin-bottom: 3em;
  &__header {
    padding: 2.3em 3em 2em 3em;
  }
  &__actions {
    button {
      color: #979797;
      padding: 0.4em;
      font-size: 1.5em;
      &:hover {
        color: black;
      }
    }
  }
  &__content {
    padding: 2.5em 3em;
    &:not(:first-child) {
      border-top: 1px solid #e1e1e1;
    }
  }
  .bot-targeting {
    p {
      strong {
        font-weight: 500;
        color: #696969;
      }
    }
    &__item {
      padding-top: 1.7em;
      padding-bottom: 1.7em;
      align-items: center;
    }
  }
  .btn--dropdown-options__dropdown {
    overflow-y: auto;
    max-height: 44em;
    min-width: 24em;
    .button-option {
      font-size: 1.5em;
      &.text-medium {
        font-size: 1.6em;
      }
    }
  }
}

// Flow

.conv-builder__section--flow {
  .conv-builder__sidebar {
    position: absolute;
    top: 5em;
    background: #212429;
    z-index: 999;
    height: 100%;
    @include mq(max-height, $mq-mbp) {
      top: 6.2em;
    }
  }
  .conversation-builder__canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: $zindex-canvas;
    left: 0;
    top: 0;
    &--opacity {
      opacity: 0.2;
    }
    &--hover {
      opacity: 1;
    }
  }
  .conversation-builder__pane {
    position: absolute;
    z-index: $zindex-canvas-pane;
    left: 0;
    right: 0;
    top: 1em;
    bottom: 0;
    overflow: scroll;
    border-top: 1px solid #dadada;
    background-color: $grey-light;
    background-image: linear-gradient(rgba(150, 150, 150, 0.1) 1px, transparent 1px),
      linear-gradient(90deg, rgba(150, 150, 150, 0.1) 1px, transparent 1px);
    background-size: 20px 20px, 20px 20px;
    background-position: -1px -1px, -1px -1px;
    background-attachment: local;
    .pane-inner {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: -2;
    }
  }
  .output-node,
  .general-output-node {
    height: 1em;
    width: 1em;
    display: block;
    background-color: #fff;
    border: 2px solid #ffa500;
    border-radius: 10em;
    cursor: pointer;
    &.node-connected {
      background-color: #ffa500;
    }
    &:hover {
      height: 1.8em;
      width: 1.8em;
      .delete-connection {
        @include visible;
      }
    }
    .delete-connection {
      @include invisible;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @include wh(1.5em);
      svg {
        fill: #fff;
        @include wh(1.5em);
      }
    }
  }
  .general-input-node {
    position: absolute;
    left: 50%;
    top: -0.3em;
    display: block;
    transform: translate(-50%, -50%);
    height: 1em;
    width: 1em;
    background-color: transparent;
  }
  .general-output-node {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%);
  }
  .answer {
    .output-node {
      position: absolute;
      z-index: 2;
      left: 100%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &[data-sidebar="collapsed"] {
    .conv-builder__toggle-sidebar {
      transform: translate(0);
    }
    .conv-builder__sidebar {
      transform: translateX(-100%);
    }
  }
  &[data-preview="collapsed"] {
    .conv-builder__preview {
      width: 0;
      @include invisible;
    }
    .refresh-btn {
      right: -10em;
    }
  }
}

#modal-change-owner.modal {
  h3 {
    margin-bottom: 0.5em;
  }
  .modal-body {
    text-align: center;
  }
}

.editor-pane__inner .app--content__docs {
  padding: 0;
}

.editor-pane__inner .app--content__docs a:not(.btn--primary):not([data-toggle]) {
  color: #4a4a4a;
}

.no-options:after {
  content: "";
  display: none;
}

// Custom color picker
.custom-color-picker.color-picker {
  width: 20.8em;
  margin-top: 1.6em;
  margin-left: 0.4em;
  position: relative;
  .color-picker-sv {
    float: right;
  }
  .color-picker-button {
    position: absolute;
    width: 2.2em;
    height: 2.2em;
    border-radius: 3px;
    left: 0.7em;
    cursor: pointer;
  }
  .color-picker-button--color-1 {
    background-color: #f9a825;
    top: 0;
  }
  .color-picker-button--color-2 {
    background-color: #f50057;
    top: 3.2em;
  }
  .color-picker-button--color-3 {
    background-color: #0050e8;
    top: 6.4em;
  }
  .color-picker-button--color-4 {
    background-color: #00008b;
    top: 9.6em;
  }
  .color-picker-button--color-5 {
    background-color: #28ca42;
    top: 12.8em;
  }
}
// End custom color picker

// ZOOM
$zoom-track-length: 10.5em;
.zoom-controls {
  position: relative;
  @include inline-block;
  margin-right: 1em;
  &__percent {
    min-width: 2.5em;
    display: inline-block;
  }
  .btn--zoom-canvas {
    border: 1px solid #ddd;
    background-color: #fff;
    @include wh(2.5em);
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: $black;
    @include inline-block;
    display: inline-flex;
    &:hover {
      border-color: darken(#ddd, 10%);
    }
    i {
      font-size: 0.8em;
    }
  }
  input[type="range"] {
    cursor: pointer;
    @include inline-block;
    /*removes default webkit styles*/
    -webkit-appearance: none;

    /*fix for FF unable to apply focus style bug */
    border: none;
    background: none;

    /*required for proper track sizing in FF*/
    width: $zoom-track-length;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: $zoom-track-length;
    height: 7px;
    background: rgba($black, 0.36);
    border: none;
    border-radius: 3px;
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    @include wh(2em, 2em);
    border-radius: 50%;
    background: #fff;
    margin-top: -0.7em;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-moz-range-track {
    width: $zoom-track-length;
    height: 7px;
    background: rgba($black, 0.36);
    border: none;
    border-radius: 3px;
  }
  input[type="range"]::-moz-range-thumb {
    border: none;
    @include wh(2em, 2em);
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
    background: #fff;
  }

  /*hide the outline behind the border*/
  input[type="range"]:-moz-focusring {
    outline: none;
    outline-offset: -1px;
  }

  input[type="range"]::-moz-focus-outer {
    border: 0;
  }

  input[type="range"]::-ms-track {
    width: $zoom-track-length;
    height: 7px;

    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;

    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 6px 0;

    /*remove default tick marks*/
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: rgba($black, 0.36);
    border: none;
    border-radius: 10px;
  }
  input[type="range"]::-ms-fill-upper {
    border-radius: 10px;
    background: rgba($black, 0.36);
    border: none;
  }
  input[type="range"]::-ms-thumb {
    border: none;
    @include wh(2em, 2em);
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  }
}
.conversation-builder__pane {
  .zoomable-canvas {
    left: 0;
    top: 0;
    position: absolute;
    transform-origin: top left;
    width: 20000px;
    height: 20000px;
    border-bottom: 2px solid red;
    border-right: 2px solid red;
    cursor: grab;
    &--grabbing {
      cursor: grabbing;
    }
    &--selecting {
      cursor: crosshair;
    }
  }
}
// END ZOOM

#multiple-items-selection {
  pointer-events: none;
  border: 2px dashed black;
  display: none;
  width: 0;
  height: 0;
  left: 0;
  top: 0;
  position: absolute;
}

.link-content-image {
  &__input {
    font-size: 1.4em;
    line-height: 1.5;
  }
  &__text {
    display: flex;
    align-items: baseline;
    p,
    button {
      font-size: 1.4em;
    }

    button {
      color: $red;
      text-decoration: underline;
      cursor: pointer;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        color: $red;
        text-decoration: none;
      }
    }
  }
}

.variables-panel {
  &--back {
    font-size: 1.6em;
    margin: 0 1em 1em 0;
  }
  .custom-variable-validation-error {
    padding: 0;
    width: 30em !important;
    max-width: 30em !important;
  }
  .custom-variables--details {
    padding: 1em 1.5em;
    label {
      font-size: 1.4em;
      margin-bottom: 0.5em;
      @include regular;
    }

    input[type="text"] {
      font-size: 1.4em !important;
    }
  }

  .variable-row {
    position: relative;
    min-height: 6em;
    font-size: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #dadada;
    border-radius: 6px;
    margin-bottom: 2em;
    font-weight: 500;
    flex-wrap: wrap;
    &:hover {
      .variable-row__footer {
        border-top: 1px solid #dadada;
        padding-top: 2em;
        padding-bottom: 2em;
        height: auto;
      }
    }
    &__front {
      font-size: 1.6em;
      padding: 1.2em;
    }
    &__footer {
      display: flex;
      height: 0;
      transition: 0.3s;
      overflow: hidden;
      div {
        padding-left: 2em;
        padding-right: 2em;
        flex-basis: 50%;
      }
    }
    .transformation {
      font-size: 1.6em;
      font-weight: 400;
    }
    &__title {
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: rgba($black, 0.5);
      font-size: 1em;
      font-weight: 500;
    }
    &__value {
      font-weight: 400;
    }
    .delete-area {
      background-color: $grey-light;
      width: 2em;
      padding: 1em;
      font-size: 1em;
      right: -2em;
      position: absolute;
      top: 0.2em;
      &:hover {
        color: $brand-red;
      }
    }

    &:hover {
      border-color: #333333;
      .delete-area {
        @include visible;
      }
      .custom-variables--actions {
        span i {
          opacity: 1;
        }
      }
    }
    &:not(:hover) {
      .delete-area {
        @include invisible;
      }
    }

    .custom-variables--actions {
      text-align: right;
      span {
        font-size: 1em;
        @include inline-block;
        margin-left: 0.3em;
        @include regular;
        text-decoration: underline;
        i {
          opacity: 0.5;
          color: $black;
        }
        &.transformation {
          text-decoration: none;
          border: 1px solid #bbb;
          min-width: 1.75em;
          min-height: 1.75em;
          padding: 0.1em;
          margin-right: 1em;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
        }
      }
    }
  }
}

.btn--create-new-variable {
  color: #fff !important;
  margin: 1em 0;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
}

.pulldown-select-variable {
  font-size: 1.7em !important;
}
.create-variable-tip {
  margin-top: 1.4em !important;
  a {
    color: $brand-green !important;
  }
  span.tip-line {
    display: block;
    width: 100%;
  }

  span.second-line {
    margin-top: 0.4em;
  }
}
.store-variables {
  border-top: 1px solid #e1e1e1;
}
.store-variable {
  &--api {
    &.mb-5 {
      margin-bottom: 5em !important;
    }
    label {
      color: #858585;
      font-size: 1.4em !important;
      text-transform: uppercase;
    }
    fieldset {
      margin-bottom: 0;
    }
    input {
      font-size: 1.7em !important;
    }
  }
  &__equal {
    font-size: 22px;
    font-weight: 500;
    margin-right: 0.6em;
    display: flex;
    align-items: flex-end;
    padding-bottom: 0.5em;
  }
  &__remove {
    display: inline-block;
    position: absolute;
    bottom: 1.6em;
    &:hover {
      svg {
        fill: $red;
      }
    }
  }
  &__btn {
    font-size: 1.5em;
    font-weight: 400;
    padding: 0.6em 1.5em;
    line-height: 1;
  }
  &__status {
    font-size: 1.7em;
    padding: 0.8em 3em 0.8em 1em;
    background: #f0f0f0;
    border: 1px solid #d0d0d0;
    display: inline-block;
    border-radius: 3px;
    color: #a9a9a9;
  }
}
.store-variable-btn-add {
  font-size: 1.3em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-weight: 500;
  padding-top: 0.8em;
  padding-bottom: 0.7em;
  background: #333;
  border-radius: 3px;
  &:hover {
    background: lighten(#333, 5%);
  }
}

.bot-builder-flyout {
  transform: none;
  left: auto;
  right: -1em;
  min-width: 35em;
  padding: 3.5em;
  text-align: left;
  &:before,
  &:after {
    right: 2.5em;
  }
  &__title {
    font-size: 2.2em;
    i {
      margin-right: 0.5em;
    }
  }
}

.shortcuts {
  list-style-type: none;
}

.shortcut {
  font-size: 1.6em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;
  &__key {
    background: #1e59ca;
    border-radius: 6px;
    color: white;
    padding: 0.4em 0.6em;
    width: 30px;
    height: 30px;
    line-height: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 11px;
      height: 16px;
    }
    &--modifier {
      background: rgba(#1e59ca, 0.7);
      margin-right: 0.28em;
    }
    &--meta {
      .mac & {
        display: none;
      }
      &.shortcut__key--mac {
        display: none;
        .mac & {
          display: inline-flex;
        }
      }
    }
  }
}

.object-format-settings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5em;
  margin-right: 4em;
  .btn {
    background: none;
    border: 1px solid #b3b3b3;
    border-radius: 4px;
    padding: 0.2em;
    line-height: 1;
    min-width: 2.5em;
    height: 2.5em;
    color: #b3b3b3;
    &:not(:last-child) {
      margin-right: 0.3em;
    }
    &:hover,
    &--active {
      background: white;
      border-color: $black;
      color: $black;
    }
  }
  &--lg {
    input[type="radio"] {
      display: none;
      &:checked {
        & + .btn,
        & + span + .btn {
          background: white;
        }
      }
    }
    .btn {
      color: $black;
      font-weight: 400;
      font-size: 1.3em;
      margin-bottom: 0;
      padding: 1em;
      min-width: 6.5em;
      margin-right: 0.8em;
      height: auto;
    }
  }
  .tooltip-container {
    &:first-child {
      .tooltip--top {
        @include mq(max, 1560px) {
          left: 0;
          transform: none;
          &::after {
            left: 2em;
          }
        }
      }
    }
  }
}

.builder-toggle {
  $self: &;
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
  &,
  &__in {
    min-height: 3.5em;
  }
  &--multiline {
    align-items: flex-start;
    margin-top: 1.5em;
    min-height: 2.5em;
    .builder-toggle__in {
      margin-top: 0.2em;
      min-height: auto;
    }
    .builder-toggle__label {
      min-width: 6.5em;
    }
  }
  &__checkbox {
    display: none;
    &:checked ~ #{$self}__switch {
      div {
        background-color: $blue;
        left: calc(100% - 2.2em);
      }
    }
  }
  &__switch {
    display: inline-block;
    cursor: pointer;
    background-color: #e5e5e5;
    border-radius: 10em;
    margin-right: 1em;
    margin-bottom: 0;
    position: relative;
    width: 4em;
    height: 1.6em;
    font-size: 1em !important;
    transition: background-color 0.2s ease-in-out;
    div {
      border-radius: 50%;
      width: 2.2em;
      height: 2.2em;
      background-color: #a2a2a2;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.2s ease-in-out;
    }
  }
  &__label {
    font-size: 1.4em;
    display: flex;
    align-items: center;
    margin-right: 1em;
    i {
      font-size: 1.2em;
      margin-right: 0.4em;
    }
  }
  &__form-control {
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 3px;
    font-size: 1.4em;
    padding: 0.6em 1em 0.5em !important;
    margin-right: 0.8em;
    width: 12.5em;
    text-align: left;
    &--short {
      width: 50px;
      text-align: center;
      padding-left: 0.3em !important;
      padding-right: 0.3em !important;
    }
  }

  &__pulldown {
    width: 17em;
    margin-right: 1em;
    .pulldown-inner {
      height: 3.4em;
    }
    .pulldown-select {
      height: 100%;
      padding-left: 0.7em;
      outline: none;
      font-size: 1.4em !important;
    }
  }
  &__dropdown-wrap {
    min-width: 18em;
    .dropdown-wrap__label {
      background: none;
      padding: 0;
    }
    .dropdown-wrap__select {
      top: 100%;
      max-width: 100%;
    }
  }
}

.builder-panel {
  background: white;
  border: 1px solid #d7d7d7;
  padding: 3em 5em;
  position: relative;
  &__title {
    font-weight: 500;
    font-size: 2.5em;
    margin-bottom: 0.3em;
  }
  &__collapse {
    position: absolute;
    right: 3em;
    top: 3.5em;
  }
  &__avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 1em;
  }
}

.google-sheet-form-control {
  background: #ffffff;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
  width: 33em;
  .link--add-personalisation {
    border: 1px solid #ddd;
    padding: 0.8em 1em;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1em;
    top: 1em;
    left: 87%;
  }
  .flyout {
    max-height: 452px;
  }
}

.google-sheet-svg {
  width: 41px;
  height: 21px;
  margin-right: 0.5em;
}

.table-google-sheet {
  .answer {
    padding-right: 0 !important;
    display: inline-block;
    vertical-align: middle;
  }
  tr {
    display: grid;
    grid-template-columns: 1fr 4em 1fr;
  }
  td {
    min-width: 37em;
    label {
      display: flex;
      align-items: center;
      margin-bottom: 0.7em;
    }
  }
  .tooltip {
    &__bubble {
      width: 19em;
      white-space: normal;
    }
  }
}

.rule-config {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1em;
  &__btn {
    padding-top: 0.7em;
    padding-bottom: 0.55em;
  }
  &__add-or {
    border: 1px solid $black;
    background: white;
  }
  .label {
    font-size: 1.2em;
    margin-left: 1em;
    margin-top: -1em;
    @include medium;
  }
  .pulldown {
    margin: 0 1em;
    flex-basis: 25em;

    &--short {
      flex-basis: 13.5em;
    }
    & + .pulldown {
      margin-left: 0;
    }
    &--flex-auto {
      flex-basis: auto;
    }
    .pulldown-inner {
      height: auto;
      min-height: 3.65em;
      select {
        height: 2.2em;
        font-weight: 500;
        padding-left: 0.5em;
      }
    }
  }

  .flyout {
    top: 100%;
    bottom: auto;
    left: 0;
  }

  input[type="number"] {
    font-size: 1.6em;
    padding: 0.5em 0 0.5em 0.9em;
    width: 5em;
    &::-webkit-inner-spin-button {
      -webkit-appearance: inner-spin-button;
      opacity: 1;
      font-size: 1.6em;
      height: 1.3em;
      width: 1.3em;
    }
  }

  input[type="text"] {
    font-size: 1.6em;
    padding: 0.5em 2.5em 0.5em 0.9em;
    width: 20em;
    &.datepicker-here {
      padding-right: 1em;
      width: 13em;
    }
  }
  &__rule {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    display: inline-block;
    padding: 0.5em 0.7em;
    font-size: 1.6em;
    width: 10em;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &--transparent {
      background: transparent;
      border: none;
      font-weight: 400;
      padding-right: 0.3em;
    }
  }
  &__input-wrap {
    position: relative;
  }
  &__clear {
    position: absolute;
    right: 1%;
    top: 50%;
    transform: translateY(-50%);
    height: 95%;
    border-left: 1px solid #ddd !important;
    background: white !important;
    border-radius: 0 !important;
    color: #bababa;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: black;
    }
  }
  .column--value--between-to {
    .pulldown:last-child {
      margin-right: 0;
    }
  }
}

.standard-buttons {
  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__item {
    list-style-type: none;
  }
}

.builder-variables-panel {
  display: flex;
  &__delete {
    padding: 0.9em 1.1em;
  }
  .editor-pane__inner {
    flex: 1 auto;
    max-width: 80em;
  }
}

.builder-back-btn {
  &--absolute {
    position: absolute;
    left: 2em;
    top: 7em;
  }
  a {
    background: #e1e1e1;
    border-radius: 3px;
    color: black;
    padding: 0.8em 1em;
    text-decoration: none;
    &:hover {
      background: darken(#e1e1e1, 10%);
    }
  }
}

.apps-obj {
  max-width: 80em;
  @include mq(max, $mq-xl) {
    margin-left: 10em;
  }
  &__top {
    .fa-stack {
      font-size: 2.3em;
    }
    .fa-circle {
      color: #fa6400;
    }
  }
  &__app {
    display: inline-flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    width: 27em;
    padding: 2em;
    margin-bottom: 2em;
    cursor: pointer;
    margin-right: 3em;
  }
  &__app-img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  &__app-name {
    font-size: 1.6em;
    font-weight: 500;
    margin-left: 0.65em;
  }
  &__app-name-lg {
    font-size: 3em;
    font-weight: 500;
    margin-left: 0.5em;
  }
  &__action {
    border: 2px solid #dfdfdf;
    padding: 1.5em 2em;
    color: #2d2d2d;
    border-radius: 5px;
    background: #fff;
    margin-right: 2em;
    cursor: pointer;
    display: inline-block;
    min-width: 25em;

    i {
      background: #f85e1f;
      color: #fff;
      padding: 10px;
      border-radius: 50%;
      font-size: 20px;
    }
  }
  &__action-title {
    font-size: 1.6em;
    font-weight: 500;
  }
  &__pulldown {
    .pulldown-select {
      font-size: 1.6em !important;
      color: black !important;
    }
  }
}

.apps-obj-item-img {
  max-width: 28px;
  max-height: 28px;
  & + .app.obj-item-img {
    position: absolute;
    left: -0.5em;
    right: -0.5em;
  }
}

.notify-teammember {
  background: white;
  border: 1px solid #ddd;
  border-radius: 3px;
  &__header {
    padding: 1em 2.4em;
    border-bottom: 1px solid #ddd;
  }
  &__list {
    list-style-type: none;
    li {
      padding: 0.5em 1.5em;
      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
      &:hover,
      &.active {
        background: #eefaf8;
      }
      &.active {
        i.fa-check-circle {
          display: inline-block;
        }
      }
      i.fa-check-circle {
        display: none;
      }
      img {
        border-radius: 4px;
      }
    }
  }
  &__selected {
    background: #ececec;
    font-size: 1.6em;
    padding: 0.5em;
    i.fa-times {
      margin-left: 1em;
    }
    img {
      border-radius: 4px;
    }
  }
  &__custom-email[type="email"] {
    border: none;
    border-radius: 0;
    margin-bottom: -1px;
    &,
    &:focus {
      border-bottom: 1px solid #ddd;
    }
    & + .validationMessage {
      margin-left: 1em;
      max-width: none !important;
    }
  }
  &__custom-email-list {
    list-style-type: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1em 1.5em 0.5em 1.5em;
    margin-bottom: -1em;
  }
}

.conversation-preview-as {
  .tooltip-container {
    img {
      border-radius: 50%;
    }
  }
  .dropdown-wrap {
    &__header {
      padding: 2em;
      p {
        color: $black;
      }
    }
    &__header-title {
      font-size: 2.2em;
      margin-bottom: 0.5em;
      font-weight: 500;
    }
    &__header-text {
      font-size: 1.8em;
    }
    &__select {
      top: auto;
      bottom: calc(100% + 3em);
      z-index: 99;
      padding-top: 0;
      padding-bottom: 0;
      min-height: 51.2em;
    }
    &__search {
      background: white;
      padding: 0;
      position: sticky;
      top: 0;
      margin-bottom: 0;
      input {
        width: 100%;
        font-size: 1.6em;
        line-height: 3.25em;
        border: none;
        border-bottom: 1px solid #e3e3e3;
        border-top: 1px solid #e3e3e3;
        padding-right: 3em;
        padding-left: 1.5em;
      }
      button {
        right: 1em;
        font-size: 1.6em;
      }
    }
    &__options {
      .option {
        border-radius: 0;
        overflow: visible;
        white-space: normal;
        display: flex;
        align-items: center;
        font-size: 1.75em;
        &:not(:last-child) {
          border-bottom: 1px solid #e3e3e3;
        }
        &:hover {
          background: #f9f9f9;
          font-weight: 400;
        }
        img {
          border-radius: 50%;
        }
        span {
          @include truncate;
        }
      }
    }
  }
}

.flyout__obj-list-label {
  display: none;
}

.swatch-wrapper {
  position: absolute;
}

.conv-builder-goto-obj {
  .conv-builder__search-obj-flyout {
    right: auto !important;
    width: 100%;
    max-width: none;
  }
  .flyout-wrapper {
    max-width: 40em;
  }
  &__selected-obj {
    display: flex;
    align-items: center;
    font-size: 1.3em;
    padding: 1.2em 2.5em 1.1em 0.5em;
    background: white;
    width: 100%;
    .conversation-item-number {
      margin-right: 0.5em;
      margin-top: 0;
    }
    i {
      color: rgba($black, 0.5);
      margin-right: 1em;
    }
    span {
      @include truncate();
      color: $black;
      small,
      br,
      img {
        display: none;
      }
    }
    .tooltip-container {
      display: none;
    }
    .conditional-logic__tags {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .tag {
        padding: 0;
        margin: 0;
        background: none;
        display: inline;
      }
    }
  }
}
.conv-builder-notes {
  .ql-link,
  .ql-image,
  .ql-giphy,
  .link--add-personalisation {
    display: none !important;
  }
}

.goto-obj-outline {
  display: flex;
  align-items: baseline;
  flex: 1 100%;
  background: #6eb1e2;
  padding: 0.2em 0.3em;
  color: white;
  &__text {
    margin: 0 auto;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 500;
  }
}

.conv-item-color {
  width: 0.6em;
  flex-shrink: 0;
}

.conv-builder-obj-color {
  .flyout {
    min-width: 12em;
    padding: 1em 0;
    border-radius: 5px;
    border: none;
  }
  .conv-builder__action {
    padding: 0.5em;
    display: flex;
    span {
      border: none;
      border-radius: 50%;
      width: 1em;
      height: 1em;
      margin-right: 0.5em;
    }
  }
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    flex-wrap: wrap;
    li {
      padding: 0.5em;
      border-radius: 50%;
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active {
        border: 1px solid rgba(0, 0, 0, 0.13);
      }
    }
    button {
      border: none;
      border-radius: 50%;
      width: 1em;
      height: 1em;
      cursor: pointer;
    }
  }
}

.sidebar-panel {
  &--branding {
    padding: 1em 3em;
    margin-top: auto;
    .pane {
      margin: 1em;
      padding: 1.5em 1em 2em;
      background-color: #555;
      border: 1px solid #6a6a6a;
      border-radius: 8px;
      position: relative;
      span {
        font-size: 1.4em;
        color: #fff;
        @include medium;
      }
      .btn {
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%, -50%);
        padding: 0.45em 1.2em 0.25em;
        font-size: 1.2em;
      }
    }
    .btn {
      border: none;
      text-align: center;
      border-radius: 4px;
      @include bold;
      @include font-smoothing;
      margin-bottom: 0;
      color: #fff;
      font-size: 1.3em;
      padding-top: 0.8em;
      padding-bottom: 0.7em;
      + .pane {
        margin-top: 1.5em;
      }
    }
  }
  &__link {
    font-size: 1.6em;
    color: rgba(#ffffff, 0.7);
    display: inline-block;
    margin-bottom: 1em;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.launch-format {
  &--hidden,
  &--inline {
    .btn {
      font-size: 1.2em;
      background: #222;
      color: white;
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    }
  }
  &--hidden {
    .btn {
      border-radius: 100px;
    }
  }
  &--inline {
    .btn {
      width: 5em;
      height: 5em;
      border-radius: 5px;
      font-size: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__center {
    margin-top: auto;
    margin-bottom: 3em;
  }
  &__state-hover,
  &__state-checked,
  &__state-checked-hover {
    display: none;
    font-weight: 500;
  }
  &__state-text {
    position: absolute;
    top: 1em;
    right: 1em;
  }
  &__preview {
    margin-top: auto;
    display: flex;
    align-items: flex-end;
    margin-bottom: 3em;
    max-width: 90%;
    .continually-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;
      border-radius: 50%;
      width: 5.6em;
      height: 5.6em;
      &.continually-button--t {
        width: auto;
        height: auto;
        padding: 0;
        border-radius: 2em;
        font-size: 1.2em;
        min-width: 0;
        .continually-button-text {
          margin: 0.75em 1.6em;
          @include truncate;
        }
      }
    }
    .continually-avatar {
      @include avatar;
      &,
      img {
        border-radius: 50%;
      }
    }
  }
  &__preview-quick-reply {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5em;
  }
  &__preview-bubble {
    margin-right: 0.5em;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    display: inline-block;
    padding: 1em 1.2em;
    font-size: 1.2em;
    line-height: 1;
  }
}

.widget-radio-btns {
}

.widget-radio-btn {
  label {
    font-weight: 400;
    flex: 1 auto;
    font-size: 1.4em;
    padding: 1.2em 1em;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid #dadada;
    border-radius: 5px;
    background: white;
    margin: 0 0.7em 0 0;
    i {
      color: #222;
    }
  }
  &--transparent {
    pointer-events: none;
    label {
      background: none;
    }
  }
  &--icon-none {
    label {
      height: 3.2em;
      span {
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
    }
  }
  &--icon {
    label {
      width: 3.2em;
      height: 3.2em;
      padding: 0.5em 0.8em;
      svg {
        width: auto;
        height: auto;
      }
    }
  }
  input[type="radio"] {
    display: none;
  }
  &:hover label,
  input[type="radio"]:checked + label,
  input[type="radio"]:checked + .validationMessage + label {
    background-color: $blue-new;
    border-color: $blue-new;
    color: white;
    i {
      color: white;
    }
  }
}

.inline-additional-input {
  display: flex;
  align-items: center;
  margin-top: 1em;
  input[type="number"] {
    font-size: 1.4em;
    padding: 0.5em 0.5em 0.5em;
    text-align: center;
    max-width: 5em;
    line-height: 1.375;
  }
  &__text {
    margin-left: 0.5em;
    font-size: 1.2em;
    color: rgba(#222, 0.5);
  }
}
