.body--mobile-nav-active {
  position: relative;
  @include mq(max-height, 500px) {
    overflow: hidden;
  }
  &:before {
    content: "";
    background: rgba(0, 0, 0, 0.53);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
  }
}

.header--app {
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: relative;
  position: sticky;
  z-index: $zindex-fixed-header;
  left: 0;
  right: 0;
  top: 0;
  height: $header-height;

  &--fixed {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    @include mq(max, $mq-md) {
      // -60 - mobile header offset on scroll
      &[style*="-60"] + .main .page-tabs-out--apps {
        top: 0;
      }
    }
  }

  &__branding {
    color: $black;
    padding: 1.3em;
    border-right: 1px solid #ddd;
    text-decoration: none;
    svg {
      fill: $black;
      @include wh(4em, 4em);
    }
  }
}

.header-back-btn {
  display: inline-flex;
  color: #4d4d4d;
  text-decoration: none;
  padding-right: 1.2em;
  vertical-align: middle;
  margin-left: 1.5em;
  svg {
    @include wh(1.4em);
    fill: #4d4d4d;
    @include inline-block;
  }
  .label {
    font-size: 1.4em;
    text-transform: uppercase;
    @include medium;
    @include inline-block;
    margin-left: 0.4em;
  }
}

.nav--app {
  @include mq(max, $mq-md) {
    display: none;
  }
  display: flex;
  align-items: center;
  .tooltip-container {
    z-index: 2;
  }
  .tooltip {
    top: 100%;
    &__bubble {
      margin-top: 2em;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
      padding: 0;
      width: 14em;
      background: white;
    }
    &__content {
      font-weight: 400;
      font-size: 16px;
      text-align: left;
      a {
        padding: 0.7em 1.2em 0.7em;
        text-decoration: none;
        color: black;
        display: block;
        &:first-child {
          padding-top: 1em;
        }
        &:last-child {
          padding-bottom: 1em;
        }
        &:hover {
          color: $brand-primary;
        }
        &.active {
          // text-decoration: underline;
        }
      }
    }
    &:after {
      bottom: calc(100% - 3em);
      border: none;
      content: "";
      transform: rotate(45deg);
      display: inline-block;
      height: 1.5em;
      width: 1.5em;
      position: absolute;
      background: #fff;
      box-shadow: -3px -3px 3px 0 rgba(0, 0, 0, 0.03);
    }
  }
}

.nav--app__link {
  display: flex;
  align-items: center;
  padding: 0 0 0 1em;
  font-size: 1.4em;
  line-height: 1;
  text-decoration: none;
  color: $black;
  position: relative;
  z-index: 1;
  height: 100%;
  @include mq(min, $mq-lg) {
    padding: 0 1em;
    font-size: 1.6em;
  }
  &:hover {
    color: $brand-primary;
    text-decoration: none;
  }
  &.active {
    @include medium;
  }
  &:not(.nav--app__link--opportunities) {
    &.active {
      &:after,
      &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        z-index: 9;
        pointer-events: none;
      }
      &:after {
        border-color: rgba(#fff, 0);
        border-top-color: #fff;
        border-width: 10px;
        margin-left: -10px;
      }
      &:before {
        border-color: rgba(#ddd, 0);
        border-top-color: #ddd;
        border-width: 11px;
        margin-left: -11px;
      }
    }
  }
}

.header--app__auth {
  position: absolute;
  z-index: 1;
  right: 1em;
  top: 0;
  bottom: 0;
  padding: 1.8em;
  a {
    @include inline-block;
    font-size: 1.6em;
    margin-left: 1em;
    line-height: 2.1;
    text-decoration: none;
    color: $black;
    &:hover {
      color: $brand-primary;
    }
  }
}

.header--app__actions {
  // @include mq(max, $mq-md) { display: none; }
  white-space: nowrap;
  user-select: none;
  margin-left: auto;
  position: relative;
  .btn--icon {
    position: relative;
    @include inline-block;
    width: 1.6em;
    text-align: center;
    @include transition(color);
    color: $black;
    margin-right: 2em;
    i {
      font-size: 1.6em;
    }
    &:hover {
      color: $brand-green;
    }
    #HW_badge {
      // background: #43d5c1 !important;
      line-height: 19px !important;
    }
    #HW_badge_cont {
      position: absolute;
      z-index: 1;
      right: -16px;
      top: -16px;
    }
  }
  .current-plan {
    margin-right: 2em;
    position: relative;
    @include inline-block;
    @include mq(max, $mq-md) {
      display: none;
    }
    span,
    a {
      @include inline-block;
    }
    .flag {
      @include inline-block;
      text-decoration: none;
      color: #fff;
      &:hover {
        color: #fff;
        opacity: 0.8;
      }
    }
    .days-left {
      text-transform: uppercase;
      @include bold;
      color: #5d5d5d;
      line-height: 1;
      user-select: none;
      margin-right: 1em;
    }
    .btn--upgrade {
      background-color: $brand-green;
      color: #fff;
      text-transform: uppercase;
      @include bold;
      @include font-smoothing;
      text-decoration: none;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      padding: 0.6em 1em 0.5em;
      border-radius: 2px;
      @include transition(background-color);
      cursor: pointer;
      margin-left: 1em;
      &:hover {
        background-color: darken($brand-green, 10%);
      }
    }
    span {
      @include mq(max, $mq-md) {
        display: none;
      }
    }
  }
}

.header--app__user {
  @include mq(max, $mq-md) {
    display: none;
  }
  @include inline-block;
  height: 7.1em;
  white-space: nowrap;
  position: relative;
  padding: 1.8em;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  cursor: pointer;
  &:hover,
  &--active {
    .header--app__user__panel {
      display: block;
    }
    svg {
      fill: $brand-primary;
    }
  }
  .avatar {
    @include avatar(3.4em, 1.8em);
    margin-right: 1em;
  }
  .name {
    @include inline-block;
    font-size: 1.6em;
    white-space: nowrap;
    @include mq(inside, $mq-lg, $mq-xl) {
      display: none;
    }
  }
  svg {
    @include inline-block;
    @include wh(1em, 1em);
    margin-left: 1em;
    fill: $black-light;
  }
}

.header--app__user__btn {
  @include mq(max, $mq-sm) {
    display: none;
  }
  @include inline-block;
  margin-right: 1.5em;
  svg {
    @include wh(0.8em, 0.8em);
    position: relative;
    top: -1px;
  }
}

.header--app__user__panel {
  position: absolute;
  z-index: $zindex-dropdown;
  right: -1px;
  top: 100%;
  display: none;
  background-color: #fff;
  max-width: 27em;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  &:before {
    content: "";
    transform: rotate(45deg);
    display: inline-block;
    height: 2em;
    width: 2em;
    position: absolute;
    right: 2em;
    top: -1em;
    background: white;
    box-shadow: -3px -3px 3px 0px rgba(0, 0, 0, 0.05);
  }
}

.nav {
  $nav: &;
  &--user {
    max-height: 48.55em;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    width: 27em;

    #{$nav}__link {
      display: block;
      text-decoration: none;
      color: $black;
      padding: 0.7em 2.2em 0.7em;
      @include font-smoothing;
      &:hover {
        color: $brand-primary;
        text-decoration: none;
      }
      &--dropdown {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:after {
          content: "";
          border: 1px solid transparent;
          border-top: 1px solid black;
          border-right: 1px solid black;
          transform: rotate(45deg);
          width: 8px;
          height: 8px;
          display: inline-block;
        }
      }
      &--with-bg {
        background: #f3f3f3;
        padding: 1em 2.2em;
        margin-top: -1.5em;
        margin-bottom: 1em;
      }
      .label {
        font-size: 1.4em;
      }
      i,
      svg {
        font-size: 1.4em;
        margin-left: 0;
        margin-right: 0.5em;
        width: 17px;
        height: 12px;
      }
    }
  }
  @each $menu in "sub", "teammember" {
    &--show-#{$menu} {
      #{$nav}__in {
        display: none;
      }
      #{$nav}__out {
        visibility: visible;
        transform: translateX(0);
        position: static;
        pointer-events: auto;
      }
      #{$nav}__sub-menu[data-menu="#{$menu}"] {
        display: block;
      }
    }
  }

  &__in {
    padding: 1.2em 0;
  }

  &__out {
    width: 100%;
    transition: 0.2s;
    transform: translateX(100%);
    visibility: hidden;
    position: absolute;
    top: 0;
    pointer-events: none;
  }

  &__header {
    background: #f3f3f3;
    padding: 0.5em 0;
  }

  &__back {
    display: flex;
    align-items: center;
    &:before {
      content: "";
      border: 1px solid transparent;
      border-top: 1px solid black;
      border-left: 1px solid black;
      transform: rotate(-45deg);
      width: 8px;
      height: 8px;
      display: inline-block;
      margin-right: 0.5em;
    }
  }

  &__sub-menu {
    list-style-type: none;
    padding: 2em;
    display: none;
    width: 27em;
    li {
      &:not(:last-child) {
        margin-bottom: 0.8em;
      }
    }
    a {
      color: $black;
      text-decoration: none;
      display: flex;
      align-items: center;
      &:hover {
        color: $brand-primary;
      }
    }
    span {
      font-size: 1.4em;
      @include truncate();
    }
    .avatar {
      border-radius: 50%;
      flex-shrink: 0;
    }
  }

  &__footer {
    padding-top: 1.5em;
    border-top: 1px solid #e1e1e1;
    margin-top: 1.5em;
  }

  &__switch-account {
    padding: 1.6em 2.4em 0.8em;
    border-bottom: 1px solid #ddd;
    .switch-account-label {
      @include medium;
      color: $black;
      @include font-smoothing;
      font-size: 1.5em;
      margin-bottom: 0.5em;
    }
    .switch-account__link {
      display: block;
      @include clearfix;
      padding: 0.6em 0;
      text-decoration: none;
      color: $black;
      &:hover {
        color: $brand-primary;
      }
      .avatar {
        float: left;
      }
      .name {
        display: block;
        float: left;
        font-size: 1.5em;
        margin-top: 0.5em;
        width: calc(100% - 3.2em);
        @include truncate;
      }
    }
  }

  &__super-admin {
    display: block;
    text-decoration: none;
    background-color: $gray-700;
    #{$nav}__link {
      color: #fff;
      &:hover {
        color: rgba(#fff, 0.5);
      }
    }
  }
}

// Mobile nav

.header--app__mobile-actions {
  @include mq(min, $mq-md) {
    display: none;
  }
}

.header--app__mobile-actions__toggle {
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;
  bottom: 0;
  width: 6em;
  i {
    font-size: 2em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $black;
  }
  &:hover i {
    fill: $brand-primary;
  }
}

.nav--app-mobile {
  position: absolute;
  z-index: 12;
  display: none;
  opacity: 0;
  left: 0;
  top: $header-height;
  overflow: hidden;
  overflow-y: auto;
  @include mq(max-height, 500px) {
    height: 100vh;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 7em;
  }
  @include mq(min, $mq-xs) {
    left: 50%;
  }
  right: 0;
  background-color: #fff;
  border: 1px solid $grey;
  border-bottom: none;
  &.active {
    display: block;
    opacity: 1;
  }
  .nav__in {
    padding: 0;
  }
  .nav__header {
    padding: 2em 3em;
    position: sticky;
    z-index: 3;
    top: 0;
  }
  .nav__back {
    font-size: 1.8em;
    color: $black;
    text-decoration: none;
  }
  .nav__sub-menu {
    padding: 0;
    width: auto;
    max-height: 71vh;
    overflow: hidden;
    overflow-y: auto;
    li {
      border-bottom: 1px solid #dedede;
      margin-bottom: 0;
      padding: 0;
    }
    .switch-account__link {
      padding: 0.95em 1.9em;
    }
    span {
      font-size: 1.8em;
    }
    .avatar {
      margin-right: 1em;
    }
    .avatar,
    .avatar img {
      width: 35px !important;
      height: 35px !important;
      line-height: 35px;
    }
  }
}

.nav--app-mobile__link {
  display: block;
  font-size: 1.8em;
  padding: 1.15em 1.74em;
  text-decoration: none;
  color: #333;
  background-color: #f4f4f4;
  border-bottom: 1px solid #dedede;
  font-weight: 500;
  @include mq(min, $mq-md) {
    padding: 1.15em 1em;
  }
  &:hover {
    color: $brand-primary;
    text-decoration: none;
  }
  &.highlight {
    background-color: #fff;
  }
}

.help-button {
  padding-top: 0.5em;
  padding-bottom: 0.4em;
  padding-left: 1em;
  padding-right: 1em;
  color: #fff;
  background-image: linear-gradient(135deg, #c63f7f 0%, #7139a8 100%);
  border: none;
  display: inline-block;
  @include transition;
  &:hover {
    color: white;
    background-image: linear-gradient(135deg, #97326d 0, #59348c 100%);
  }
  &--black {
    background: #333333;
    &:hover {
      background: lighten(#333333, 5%);
    }
  }
}

.getting-started-header-action {
  span {
    display: none;
  }
}

.header-coupon-banner {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 100%;
  font-size: 1.5em;
  padding: 1.3em 1em;
  background-image: linear-gradient(136deg, #f2994a 0%, #f2c94c 100%);
  display: none;
  &--expired {
    background-image: linear-gradient(-48deg, #f57a51 0%, #d11330 100%);
    i {
      display: none;
    }
  }
}
