.section--live-chat-settings {
  header {
    background-color: $blue;
    color: #fff;
  }
  .slack-logo {
    position: relative;
    width: 6.8em !important;
    padding: 1em;
    background: #411442;
    border-radius: 4px;
    .tick--connected {
      left: -0.5em !important;
      top: 1em !important;
    }
  }
}
.settings-section {
  &__header {
    .slack-logo {
      margin-left: 5em;
    }
  }
  &__connect {
    margin-bottom: 2em;
  }
}

.setting--float-label {
  border-bottom: 1px solid $grey;
  position: relative;
  padding: 3.2em;
  padding-left: 22em;
  h3 {
    position: absolute;
    left: 0;
    top: 2em;
    font-size: 1.6em;
    max-width: 12em;
    @include medium;
    color: $black;
  }
}

.live-chat-setting {
  margin: 3em 0 7em;
  &--big-margin {
    .live-chat-setting {
      margin: 7em 0 10em 0;
    }
  }

  &--notify-names {
    margin-bottom: 1em !important;
  }

  &--getting-started {
    margin-bottom: 0;
  }

  &--no-margin-top {
    margin-top: 0;
  }

  &--connect {
    background-color: #f7f7f7;
    padding: 3.2em;
    margin-bottom: 2.4em;
  }

  &--features {
    padding: 4em;
    background-image: linear-gradient(146deg, #1848e6 0%, #00dcb8 100%);
    color: #fff;
    p {
      color: #fff;
    }
    section {
      &:not(:last-of-type) {
        margin-bottom: 3.2em;
      }
      @include mq(min, $mq-sm) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copy {
          flex: 1;
        }
        figure {
          width: calc(55% - 4em);
        }
        &.img--left {
          .copy {
            order: 2;
          }
          figure {
            order: 1;
            margin-right: 4em;
          }
        }
        &.img--right {
          figure {
            margin-left: 4em;
          }
        }
      }
      @include mq(min, $mq-lg) {
        figure {
          width: auto;
        }
      }
    }
  }

  &--slack-connected {
    .slack-status {
      padding: 3.2em;
      background-color: #f7f7f7;
      border-radius: 2px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .prefs {
        margin-top: 1em;
        a {
          font-size: 1.5em;
          color: $black;
        }
      }
      .details {
        margin: 0 3em;
        p {
          color: $black;
        }
        p.workspace {
          font-size: 2em;
        }
      }
      .btn--delete {
        background-color: transparent;
      }
      .slack-logo,
      figure {
        position: relative;
        width: 4.8em;
        .tick--connected {
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          background-color: $brand-primary;
          border: 1px solid #fff;
          width: 2em;
          height: 2em;
          line-height: 2.5;
          text-align: center;
          border-radius: 50%;
          margin-left: -0.5em;
          margin-top: -0.5em;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 1em;
            color: #fff;
          }
        }
      }
    }
  }

  &--new {
    .slack-status {
      padding: 3em;
      .details {
        margin: 0;
      }
      .workspace {
        margin-bottom: 0.5em;
      }
      .prefs {
        a {
          color: $brand-primary;
          &:last-child {
            margin-left: 1em;
            color: $red;
          }
        }
      }
    }
    h3 {
      border-bottom: 0;
      margin-bottom: 0.5em;
      padding-bottom: 0;
      font-size: 1.8em;
      color: black;
    }
  }

  &--toggle {
    input.checkbox-toggle-offscreen,
    input#toggle-live-chat,
    input#toggle-start-new-conversation,
    input#toggle-automatic-close-chat,
    input#toggle-automatic-reply {
      @include offscreen;
    }
    .toggle-label {
      @include inline-block;
      @include medium;
      @include font-smoothing;
      color: #696969;
      font-size: 1.6em;
    }
    .toggle-live-chat,
    .toggle-automatic-reply {
      @include inline-block;
      cursor: pointer;
      background-color: #e5e5e5;
      border-radius: 10em;
      margin-right: 1em;
      position: relative;
      width: 4em;
      height: 1.6em;
      font-size: 1em;
      @include transition(background-color);
      span {
        @include offscreen;
      }
      &:hover {
        background-color: darken(#e5e5e5, 5%);
      }
      div {
        border-radius: 10em;
        width: 2.2em;
        height: 2.2em;
        background-color: #a2a2a2;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        @include transition;
      }
    }

    input#toggle-live-chat-rating:checked + .toggle-automatic-reply,
    input#toggle-live-chat:checked + .toggle-live-chat,
    input#toggle-automatic-reply:checked + .toggle-automatic-reply,
    input#toggle-start-new-conversation:checked + .toggle-automatic-reply,
    input.checkbox-toggle-offscreen:checked + .toggle-automatic-reply,
    input#toggle-automatic-close-chat:checked + .toggle-automatic-reply {
      div {
        background-color: $green;
        left: calc(100% - 2.2em);
      }
    }
    .help {
      @include inline-block;
      font-size: 1.5em;
      a {
        color: $black;
        &:hover {
          color: $brand-green;
        }
      }
    }
    .live-chat-auto-response {
      margin-top: 2em;
      .live-chat-auto-response--fieldset {
        margin-bottom: 3.2em;
      }
      h4 {
        font-size: 1.6em;
      }
    }
    .help-text-with-arrow {
      &--notification {
        margin-left: 0;
        margin-bottom: 2em;
        text-align: right;
        svg {
          width: 41px;
          height: 16px;
          left: 20em;
        }
      }
      &--new-conv {
        margin-left: 18em;
        margin-bottom: 1em;
        text-align: left;
        svg {
          width: 41px;
          height: 16px;
          left: -5em;
        }
      }
    }
  }

  &--request-email {
    .live-chat-auto-email-question {
      margin-top: 3.2em;
      h4 {
        @include medium;
        font-size: 1.6em;
      }
    }
  }

  &--availability {
    .label {
      font-size: 1.4em;
      margin-right: 1em;
    }
    .pulldown {
      font-size: 80%;
    }
    .continually-conversation-header-team,
    .continually-conversation-header {
      position: relative;
      border-radius: 5px;
    }
  }
  &__in {
    position: relative;
    .preview-pane {
      max-width: 30em;
      .avatar img {
        object-fit: cover;
      }
    }
  }
  &__helpers {
    position: absolute;
    right: 0;
    .lc-conversation {
      margin-bottom: 3em;
      &__author {
        font-size: 1.4em;
        font-weight: 500;
      }
      &__header {
        background: transparent;
        color: inherit;
        align-items: flex-start;
      }
      &__body {
        margin-top: -0.5em;
      }
      .avatar {
        background: $blue;
        &:before {
          color: white;
          line-height: 1;
        }
      }
    }
    .btn--start-new-conversation {
      margin-bottom: 1.5em;
      color: $blue;
      border: 1px solid $blue;
    }
  }
  &__helper {
    background: #f3f3f3;
    border-radius: 3px;
    width: 42em;
    padding: 2em 2.2em;
    color: #505050;
    & + & {
      margin-top: 2em;
    }
    i {
      font-size: 1.9em;
      margin-right: 0.5em;
      color: #3c3d3c;
      opacity: 0.63;
    }
    p {
      margin-bottom: 0;
    }
  }
  &__hiding {
    margin-top: 3.5em;
    .widget-radio {
      &__label {
        padding: 0;
        font-size: 1em;
        border: none;
        border-radius: none;
        div {
          border: 1px solid #cdcdcd;
          border-radius: 3px;
        }
        img {
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }
      }
      input:checked {
        + div {
          border: 1px solid black;
          .widget-radio__text {
            font-weight: 500;
            border-top-color: #000;
          }
        }
      }
      &__text {
        padding: 1em 0.8em;
        font-size: 1.4em;
        border-top: 1px solid #ddd;
      }
    }
  }
  &__per-bot {
    .widget-radio {
      &__text {
        min-height: 4.1em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.8em;
        padding-bottom: 0.8em;
      }
    }
  }
  h3 {
    font-size: 2em;
    @include medium;
    @include font-smoothing;
    border-bottom: 1px solid #cecece;
    padding-bottom: 0.3em;
    margin-bottom: 1em;
  }
  &__header {
    border-bottom: 1px solid #cecece;
    margin-bottom: 3em;
    h3 {
      margin-bottom: 0em;
      border-bottom: 0;
    }
    p {
      max-width: 28em;
    }
  }
  h4 {
    font-size: 1.5em;
    margin: 0 0 0.6em;
  }
  .input-group {
    + p {
      margin-top: 1em;
    }
  }
  p {
    color: $black;
    a:not(.btn) {
      color: $black;
    }
  }
  .toggle-label-wrap {
    margin: 3.2em 0;
    & + .toggle-label-wrap {
      margin-top: -1.5em;
    }
  }
  p.notify-names {
    margin-bottom: 0;
    margin: 0;
  }
  .label-pulldown-wrap {
    label {
      @include inline-block;
      font-size: 1.6em;
      margin-right: 0.5em;
      @include medium;
      @include font-smoothing;
    }
    .pulldown {
      @include inline-block;
    }
  }
  .pulldown + p.form-help {
    margin-top: 0.5em;
  }
  .pulldown-select {
    @include medium;
    color: $black;
    padding-right: 2.4em;
  }
  .pulldown-arrow {
    right: 1em;
    &:after {
      opacity: 0.3;
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-size: 10px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTE2IDUuNWwtMi0yLTYgNi02LTYtMiAyIDggOCA4LTh6Ij48L3BhdGg+PC9zdmc+);
    }
  }
  textarea,
  .input-text {
    display: block;
    width: 100%;
    @include mq(min, $mq-md) {
      max-width: 480px;
    }
    font-size: 1.6em;
    appearance: none;
    &:focus,
    &.active {
      outline: none;
      border-color: #666;
    }
  }
  fieldset {
    margin: 0;
    + fieldset {
      margin-top: 1em;
    }
  }
  .radio {
    ul {
      margin-bottom: 2em;
      list-style: none;
      li {
        margin: 0 0 5px 0;
        p.form-help {
          margin: 0 0 0 20px;
        }
        &:last-child {
          margin: 0;
        }
      }
      label {
        display: inline;
        @include regular;
        font-size: 1em;
      }
      textarea {
        display: block;
        width: 100%;
        @include mq(min, $mq-md) {
          max-width: 480px;
        }
        font-size: 1.6em;
        appearance: none;
        &:focus,
        &.active {
          outline: none;
          border-color: #666;
        }
      }
    }
  }
}

.live-chat-setting-action {
  margin-top: 3.2em;
}

.modal.modal--connect-slack {
  .modal-body {
    text-align: center;
  }
  h3 {
    @include medium;
    font-size: 2.2em;
  }
  figure {
    margin: 6.4em auto;
  }
  .btn {
    font-size: 1em;
    margin-bottom: 2em;
  }
}
