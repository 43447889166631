:root {
  --safe-area-inset-t: calc(10px + env(safe-area-inset-top));
  --safe-area-inset-b: env(safe-area-inset-bottom);
  --sticky-top: calc(590px + env(safe-area-inset-top));
}

.body--live-chat-app {
  position: relative;
  height: 100%;
  min-height: auto;
  .main {
    display: flex;
    flex: 1;
    height: calc(100% - $header-height);
    min-height: 0; // fix firefox overflow issue
  }
  &-mobile {
    position: fixed;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .main {
      height: 100%;
    }
    .alert {
      @supports (padding-top: max(0px)) {
        padding-top: max(2em, var(--safe-area-inset-t));
      }
    }
  }
  .app--content {
    width: 100%;

    .avatar--initials {
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: visible;
      &:before {
        position: static;
        font-size: 1.5em;
      }
    }
  }
}

.lc-app-mobile {
  background-image: linear-gradient(149deg, #1848e6 0%, #00dcb8 100%);
  color: white;
  height: 100%;
  overflow: auto;
  h2 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  p {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  iframe,
  .video-js {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  @include mq(min, $mq-md) {
    display: none;
  }
}

.lc-app {
  display: flex;
  height: 100%;
  &.loading {
    &:before {
      z-index: 5;
    }
    &:after {
      z-index: 5;
    }
  }
  &--desktop {
    display: none;
    @include mq(min, $mq-md) {
      display: flex;
    }
  }
  .modal--mobile {
    @include mq(max, $mq-sm) {
      transform: translateY(-50%);
      right: 3em;
      left: 3em;
      top: 50%;
    }
  }
}

.lc-sidebar {
  $lc-sidebar: &;
  background-color: #353535;
  padding: 2em 1em;
  transition: width 0.2s ease-in-out;
  flex-direction: column;
  display: flex;
  width: 24em;
  flex: 0 1 24em;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &--loading {
    .lc-sidebar__item {
      pointer-events: none;
    }
  }
  &__header {
    margin-bottom: 2.5em;
    width: 100%;
    padding: 0 1em;
    .logic-pulldown {
      width: 100%;
      z-index: 2;
    }
    .tooltip {
      z-index: 1;
    }
  }
  &__section {
    margin: 0 -1em;
    &--expanded {
      .lc-sidebar__list {
        padding: 2em 2em 2em 2.5em;
        opacity: 1;
        visibility: visible;
        height: auto;
      }
    }
  }
  &__section-title {
    color: #ffffff;
    display: flex;
    align-items: baseline;
    margin: 0;
    padding: 1em 1.5em;
    cursor: pointer;
    &:hover,
    .lc-sidebar__section--expanded & {
      background-color: #464646;
    }
    &:after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      border-color: #979797 transparent transparent transparent;
      margin-left: auto;
      align-self: center;
    }
    &--link {
      text-decoration: none;
      font-size: 1.6em;
      &:hover {
        text-decoration: none;
        i {
          color: inherit;
        }
      }
      &:after {
        content: none;
      }
    }
    i {
      color: #999999;
      margin-right: 0.5em;
    }
  }
  &__list {
    background: #272727;
    list-style-type: none;
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    transition: visibility 0.2s, opacity 0.2s, padding-top 0.2s, padding-bottom 0.2s;
    padding-left: 5em;
  }
  &__item {
    &,
    a {
      opacity: 0.6;
      color: #ffffff;
      text-decoration: none;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
    }
    & + li {
      margin-top: 1em;
    }
    &--active,
    &--active a,
    &:hover,
    &:hover a {
      opacity: 1;
      font-weight: 500;
    }
  }
  .lc-switch {
    padding-left: 0;
    padding-right: 0;
    &__label {
      flex-wrap: wrap;
    }
    &__radio {
      margin-bottom: 1em;
    }
  }
  .logic-pulldown {
    &__link {
      background: white;
      display: inline-block;
      width: 100%;
      padding: 0.7em 1em;
      border-top: 1px solid #ccc;
      text-align: center;
      font-size: 1.4em;
      color: $blue;
    }
  }
}

.lc-inbox {
  flex-basis: 35em;
  border-right: 1px solid #d8d8d8;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  &__header {
    border-bottom: 1px solid #d8d8d8;
    padding: 2.5em 0 0;
    position: sticky;
    top: 0;
    background: white;
    z-index: 5;
    h5 {
      margin-bottom: 0;
      color: #353535;
    }
    p {
      margin-bottom: 0;
    }
  }
  &__in {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &--loading {
      &:after {
        position: absolute;
        content: "";
        background: rgba(255, 255, 255, 0.5);
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;
      }
      &:before {
        content: "";
        position: sticky;
        z-index: 3;
        width: 3.2em;
        height: 3.2em;
        border-radius: 50%;
        transform: translateZ(0);
        border: 1px solid #000;
        border-left: 1px solid transparent;
        animation: spinner 0.5s infinite linear;
        left: calc(50% - 1.6em);
        top: 50%;
        margin-top: -3.2em;
      }
    }
  }
  &__empty {
    margin: 2em;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    svg {
      display: block;
      width: 39px;
      height: 34px;
      margin-bottom: 1em;
    }
  }
  &__no-messages {
    font-size: 2em;
    display: block;
    margin-bottom: 0.5em;
  }
  &__helper {
    margin-top: auto;
  }

  .dropdown-wrap {
    display: inline-block;
    max-width: none;
    margin: 0;
    padding: 0 2.5em;
    &__options {
      padding: 1.5em 1em 1em 1em;
      background: white;
      max-height: 40em;
      overflow: auto;
    }
    &__select {
      width: 100%;
      padding: 0;
      left: 0;
      top: 100%;
      display: none;
      &.dropdown-active {
        display: block;
      }
    }
    &__label {
      font-size: 1.6em;
      margin-bottom: 0.3em;
      color: #ffffff;
      @include regular;
    }
    &__close-icon {
      margin-left: 1em;
      opacity: 0.5;
    }
    &__text {
      font-size: 1.4em;
      color: white;
      background: #898989;
      padding: 0.4em 0.6em;
      display: inline-flex;
      margin-bottom: 0.5em;
      border-radius: 2px;
      justify-content: space-between;
      align-items: center;
      &:hover {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
        .dropdown-wrap__close-icon {
          opacity: 1;
        }
      }
      span {
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    }
    .option {
      border: 1px solid #d9d9d9;
      font-size: 1.6em;
      background: white;
      white-space: normal;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      line-height: 1.5;
      border-radius: 4px;
      &:hover {
        @include regular;
      }
      & + .option {
        margin-top: 0.7em;
      }
      &--active {
        background-color: #4a90e2;
        border-color: #4a90e2;
        color: white;
        &.option--tag {
          background-color: rgba($blue-secondary, 0.06);
          border-color: #d6d6d6;
        }
        span {
          color: white;
        }
      }
      &--tag {
        align-items: center;
        justify-content: flex-start;
        input[type="checkbox"] {
          margin-right: 1em;
        }
        input {
          padding: 0.3em 1em 0.3em 0;
          border: none;
          background: transparent;
        }
        i {
          margin-right: 0.8em;
          color: rgba(#353535, 0.34);
        }
      }
      span {
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
    }

    .toggle-dropdown-options {
      border: none;
      padding-left: 0;
      cursor: pointer;
      span {
        font-size: 2em;
        white-space: normal;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        margin-bottom: 0;
        color: #353535;
        font-weight: 500;
      }
    }
    .close-aside {
      display: none;
      @include mq(max, $mq-md) {
        display: block;
        position: absolute;
        z-index: 1;
        font-size: 1.6em;
        top: 1.5em;
        right: 1.5em;
        color: #fff;
        text-decoration: none;
      }
    }
  }
  &__search {
    width: 100%;
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__search-input[type="search"] {
    width: 100%;
    border-left: 0;
    border-bottom: 0;
    border-right: 0;
    border-radius: 0;
    font-size: 1.4em;
    padding: 1.2em 1.5em;
  }
  &__search-btn,
  &__search-clear {
    position: absolute;
    right: 1em;
    top: 1.4em;
    font-size: 1.3em;
  }
  &__search-btn {
    color: #aaa;
  }
  &__search-clear + &__search-btn {
    right: 2.5em;
  }
  &__search-results {
    background: $blue;
    display: flex;
    align-items: center;
    padding: 1.2em 2em;
    z-index: 4;
    span {
      color: white;
      font-size: 1.4em;
    }
    button {
      line-height: 1;
      font-size: 1.4em;
    }
  }
}

.lc-swipe-container {
  position: relative;
  transition: 0.5s ease-out;
}

.lc-conversation {
  $self: &;
  border-bottom: 1px solid #d8d8d8;
  padding: 1.5em;
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
    #{$self}__close-btn {
      display: inline-block;
    }
    #{$self}__badge--not-closed {
      display: none;
    }
  }
  &--active {
    background-color: #e0efff;
    border-left: 7px solid $blue;
    position: relative;
    &:after {
      content: "";
      width: 16px;
      height: 16px;
      transform: rotate(45deg);
      background: #e0efff;
      border-top: 1px solid #dedede;
      border-right: 1px solid #dedede;
      position: absolute;
      right: -8.5px;
      top: calc(50% - 8px);
    }
  }
  &--unread {
    background-color: #fefcec;
    .lc-conversation__author,
    .lc-conversation__text {
      font-weight: 500;
    }
    .lc-conversation__text {
      &:before {
        content: "";
        display: inline-block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: $red;
        margin-right: 0.5em;
      }
    }
  }
  &--notification {
    border-bottom: none;
    width: 33em;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding-right: 2.5em;
    position: relative;
    background: white;
    margin: 0.5em 2em 1em auto;
    transform: translateY(-50%);
    &:nth-of-type(n + 6) {
      display: none;
    }
    &:hover {
      background: white;
      &::before {
        content: "";
        opacity: 0.07;
        background-image: linear-gradient(180deg, #ffffff 0%, #2c75d9 100%);
        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        border-radius: 4px;
      }
      .lc-conversation__close {
        display: inline-block;
      }
      .lc-conversation__text {
        display: none;
      }
      .lc-conversation__text--hover:not(:empty) {
        display: block;
      }
    }
    &__author {
      font-size: 1.4em;
      font-weight: 500;
    }
    &__text {
      margin-bottom: 0;
      padding-left: 2.8em;
      font-size: 1.4em;
    }
    .avatar {
      background: none;
    }
  }
  &--closing {
    #{$self}__closing {
      display: flex;
    }
    #{$self}__content {
      display: none;
    }
  }
  &__closing {
    display: none;
    align-items: center;
    padding: 1.8em;
    .loading {
      width: 3em;
      height: 3em;
      margin-right: 2em;
      &:before {
        content: none;
      }
      &:after {
        top: 0;
        left: 0;
      }
    }
  }
  &__close {
    display: inline-block;
    position: absolute;
    right: -0.5em;
    top: -0.5em;
    padding: 0;
    color: white;
    background: transparent;
    display: none;
    font-size: 1.2em;
    width: 1.5em;
    height: 1.5em;
    line-height: 1;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.5);
  }
  &__close-btn {
    display: none;
    font-size: 1.2em;
    margin-left: 0.5em;
    font-weight: 500;
    color: #a3a3a3;
    &:hover {
      color: $blue;
    }
  }
  &__header {
    display: flex;
    align-items: baseline;
    .lc-inbox & {
      flex-direction: column;
    }
    .avatar {
      width: 3em !important;
      height: 3em !important;
      &--online {
        &:after {
          background-color: #7ed321;
          content: "";
          border: 1px solid #ffffff;
          width: 10px;
          height: 10px;
          position: absolute;
          bottom: 0;
          right: 0;
          border-radius: 50%;
          z-index: 2;
        }
      }
      &--nostatus {
        &:after {
          content: none;
        }
      }
      img {
        border-radius: 50%;
        max-width: 100%;
        width: auto !important;
        height: auto !important;
        position: absolute;
        object-fit: cover;
      }
      &--appointment {
        background-color: #503bf0;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        img {
          border-radius: 0;
          max-width: 50%;
        }
      }
    }
  }
  &__top {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-bottom: 0.5em;
    .tooltip {
      p {
        font-size: 1.4em;
        margin-bottom: 0;
      }
    }
    .avatar {
      width: 2.5em !important;
      height: 2.5em !important;
      & + .avatar {
        margin-left: -1em;
      }
    }
  }
  &__img {
    display: flex;
    align-items: center;
  }
  &__view {
    margin-right: 1em;
    .fa {
      font-size: 1.2em;
    }
  }
  &__assign {
    &-in {
      border-radius: 100%;
      border: 1px solid black;
      width: 1.9em;
      height: 1.9em;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3em;
      flex-shrink: 0;
    }
    .far {
      color: black;
    }
  }
  &__private {
    margin-right: 1em;
    line-height: 1;
    svg {
      width: auto;
      height: auto;
    }
  }
  &__avatar {
    margin-right: 1em;
  }
  &__author {
    color: #353535;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin-bottom: 0.5em;
    .lc-inbox & {
      margin-right: 0.5em;
      margin-bottom: 0;
    }
  }
  &__badge {
    margin-left: 0.5em;
    min-width: 4em;
    text-align: center;
  }
  &__body {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5em;
  }
  &__text {
    padding-left: 2.9em;
    font-size: 1.4em;
    margin-bottom: 0;
    @include truncate(ellipsis);
    &--hover {
      display: none;
      color: $blue-secondary;
    }
  }
  &__time {
    flex-shrink: 0;
    font-size: 1.3em;
    margin-left: auto;
  }
}

.lc-conversation-closed {
  position: sticky;
  left: 0;
  right: 0;
  z-index: 2;
  width: 90%;
  margin: 0 auto;
  bottom: 1em;
  .btn {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    background: #363636;
    color: white;
    padding-top: 1em;
    padding-bottom: 1em;
    i {
      font-size: 0.8em;
    }
  }
}

.lc-chat {
  flex: 1 0 50em;
  &--loading {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      background: rgba(255, 255, 255, 0.7);
      width: 100%;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      width: 3.2em;
      height: 3.2em;
      border-radius: 50%;
      transform: translateZ(0);
      border: 1px solid #000;
      border-left: 1px solid transparent;
      animation: spinner 0.5s infinite linear;
      left: calc(50% - 1.6em);
      top: calc(50% - 1.6em);
    }
  }
  &__close {
    padding-left: 0.8em;
  }
  .section--live-chat-settings {
    height: calc(100vh - 70px);
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .container {
      margin-left: 4em;
      width: auto;
    }
    .settings-section {
      &__title {
        margin-top: 1em;
        margin-bottom: 0.5em;
      }
      &__subtitle {
        margin-bottom: 2em;
      }
    }
    .radio {
      .pulldown {
        margin-top: 0.5em;
      }
      .pulldown-select {
        font-size: 1em;
      }
    }
  }

  &__in {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    .message.message--link {
      max-width: 25em;
    }
    .continually-conversation-body {
      font-size: 80%;
      &:after {
        content: none;
      }
    }
    .continually-conversation-footer {
      padding: 0 2em 1em;
      position: relative;
    }
    .continually-chat {
      .rich-media-card__summary {
        max-width: 20em;
      }
      .continually-timeline {
        &--pt {
          padding-top: 50px;
        }
      }
      .post {
        &--private {
          .post__info {
            text-align: center;
            & + [data-role="item-status"]:before {
              content: "–";
            }
          }
        }
        &--user {
          float: left;
          padding-left: 4.8em;
          padding-right: 0;
          .author {
            left: 0;
            right: auto;
            text-align: left;
          }
          .message {
            background: #f1f1f1;
            color: #333;
          }
          [data-role="item-status"] {
            text-align: left;
          }
        }
        &--typing {
          margin-top: 0;
        }
        &--admin {
          float: right;
          padding-right: 4.8em;
          padding-left: 0;
          &:not(.post--teammate):not(.post--private) {
            .message {
              &:not(.message--video) {
                background-color: #43d5c1;
                border-color: rgba(0, 0, 0, 0.1);
                color: #fff;
                a {
                  color: white;
                }
                [data-role="item-status"] {
                  color: white;
                }
              }
              &--image {
                padding: 1.5em !important;
                background-color: #43d5c1;
              }
            }
          }
          .author {
            right: 0;
            left: auto;
            text-align: right;
          }
          [data-role="item-status"] {
            text-align: right;
          }
          .flex.aic {
            justify-content: flex-end;
          }
        }
      }
      .message {
        float: none;
        clear: none;
        &--image {
          overflow: visible;
        }
        &--video {
          .media-container--video {
            min-width: 35em;
            @include mq(max, $mq-md) {
              min-width: 32em;
            }
          }
        }
        [data-role="item-status"] {
          align-self: flex-end;
          margin-left: 1em;
          margin-bottom: 0.1em;
          text-align: right;
          .tooltip__bubble {
            width: auto;
            white-space: nowrap;
          }
          .tooltip__content {
            font-size: 1em;
          }
        }
        &--fileuploaded {
          background: transparent !important;
          padding: 0;
        }
        &__fileuploaded {
          background: #f1f1f1;
          padding: 1.7em;
          border-radius: 8px;
          position: relative;
          font-size: 1.1em;
          &:not(:last-child) {
            margin-bottom: 3.5em;
          }
          &:last-child {
            margin-bottom: 2.5em;
          }
          i {
            font-size: 2.2em;
          }
          .filename {
            margin: 0;
          }
          .file-uploaded {
            padding: 0;
          }
          .file-download {
            position: absolute;
            top: calc(100% + 0.5em);
            left: 50%;
            transform: translate(-50%);
            white-space: nowrap;
            font-size: 1.4em;
          }
        }

        &__info {
          position: absolute;
          left: -3em;
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
          i {
            font-size: 1.7em;
          }
        }
        &__tooltip {
          left: -0.7em;
          bottom: calc(100% + 2px);
          transform: none;
          &:after {
            border-top-color: white;
            left: 1.5em;
          }
          .tooltip__bubble {
            background: white;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
            text-align: left;
            width: 17em;
            padding-top: 1.5em;
            padding-bottom: 1.5em;
            border-radius: 4px;
            a {
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
  &__body {
    padding: 2.5em 3em;
  }
  &__header {
    border-bottom: 1px solid #d8d8d8;
    display: flex;
    align-items: center;
    padding: 2em;
    min-height: 9.6em;
    .dropdown-wrap {
      max-width: none;
      width: auto;
      margin-left: 0;
      &--user {
        .tooltip__content {
          display: inline-block;
          & + .tooltip__content:before {
            content: ", ";
          }
        }
        .button-option {
          padding: 1.2em;
        }
      }
      span {
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        margin-right: 0.3em;
      }
      .toggle-dropdown-options {
        border: none;
        cursor: pointer;
        padding-right: 2em;
        .avatar + .avatar {
          margin-left: -2em;
          & + span {
            display: none;
          }
        }
      }
      .button-option {
        .fa-check {
          color: white;
          background: $brand-primary;
          padding: 0.6em;
          border-radius: 50%;
          font-size: 0.8em;
          margin-left: auto;
          display: none;
        }
        .fa-times-circle {
          display: none;
          margin-left: auto;
        }
        &--checked {
          .fa-check {
            display: inline-block;
          }
          &:hover {
            .fa-check {
              display: none;
            }
            .fa-times-circle {
              display: inline-block;
            }
          }
        }
        span {
          @include truncate();
        }
      }
      .btn--dropdown-options__dropdown {
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.18);
        border-radius: 3px;
        border: none;
        max-height: 23em;
        overflow: hidden;
        overflow-y: auto;
        min-width: 18em;
        right: 0;
        left: auto;
        .avatar {
          flex-shrink: 0;
          overflow: visible !important;
          img {
            border-radius: 50%;
          }
          & ~ span {
            opacity: 0.7;
          }
          &--online {
            &::after {
              background-color: #7ed321;
            }
            & ~ span {
              opacity: 1;
            }
          }
          &--online,
          &--offline {
            &::after {
              content: "";
              border: 1px solid #ffffff;
              width: 10px;
              height: 10px;
              position: absolute;
              bottom: 0;
              right: 0;
              border-radius: 50%;
              z-index: 2;
            }
          }
          &--offline {
            &::after {
              background: #b3b3b3;
            }
          }
        }
      }
    }
  }
  &__avatar {
    margin-right: 1em;
    .avatar {
      width: 3.5em !important;
      height: 3.4em !important;
      border-radius: 50%;
      overflow: visible;
      &--online {
        &:after {
          background-color: #7ed321;
          content: "";
          border: 1px solid #ffffff;
          width: 12px;
          height: 12px;
          position: absolute;
          bottom: 0;
          right: 0;
          border-radius: 50%;
          z-index: 2;
        }
      }
      img {
        max-width: none;
        width: 100% !important;
        height: 100% !important;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  &__author {
    margin-right: 0.5em;
    margin-bottom: 0;
    font-size: 1.8em;
    color: #353535;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    flex: 1 20em;
    width: 13em;
    @include truncate();
    &--active {
      &:hover {
        color: $blue;
      }
    }
  }
  &__header-btns {
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  &__device {
    font-size: 1em;
    margin-right: 0.5em;
    color: #c6c6c6;
  }
  &__start {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    position: relative;
    overflow: auto;
    p {
      &:first-child {
        font-size: 2em;
        margin-bottom: 0.5em;
      }
    }
    .align-center {
      max-width: 18em;
    }
    svg {
      width: 37px;
      height: 32px;
      margin-bottom: 2em;
    }
  }
  &__message-status {
    font-size: 1.4em;
    color: black;
    border-radius: 13.5px;
    background-color: #e7e7e7;
    padding: 0.5em 1em 0.3em 1em;
    position: absolute;
    left: 50%;
    top: -2.1em;
    transform: translateX(-50%);
    display: none;
    &--active {
      display: inline-flex;
    }
    &:before {
      content: "";
      margin-right: 0.5em;
      z-index: 2;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      transform: translateZ(0);
      border: 1px solid #000;
      border-left: 1px solid transparent;
      animation: spinner 0.5s infinite linear;
      left: calc(50% - 0.5em);
      top: calc(50% - 0.5em);
    }
  }
  &__off {
    position: absolute;
    z-index: 1;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.8);
    p {
      &:first-child {
        margin-bottom: 0.5em;
        font-size: 2em;
      }
    }
  }
  &__lead-offline {
    background: #fa6400;
    color: #ffffff;
    text-align: center;
    line-height: 20px;
    padding: 7.5px 0;
    position: relative;
    z-index: 4;
    font-size: 10.2px;
    @include retina(3) {
      font-size: 12px;
    }
  }
  &__private-messages {
    display: inline-block;
    color: #353535;
  }
  &__lead-info {
    max-height: 10em;
    overflow: hidden;
    overflow-y: auto;
    a {
      text-decoration: none;
      color: $black;
      &:hover {
        color: $blue;
      }
    }
  }
}

.lc-chat-actions {
  $self: &;
  position: fixed;
  right: -100%;
  bottom: 0;
  z-index: 5;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  &--show {
    right: 0;
  }
  &__in {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 360px;
    background: #fff;
    height: 100%;
    padding: 20px;
    margin: 0;
    margin-left: auto;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.44);
    // transition: 0.3s;
    // transform: translateX(100%);
    padding-top: 25px;
    @supports (padding: max(0px)) {
      padding-top: max(25px, var(--safe-area-inset-t));
    }
  }
  &__assigned {
    border-top: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    padding: 5px 10px;
    margin-bottom: 30px;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 5px;
  }
  &__header {
    p {
      font-size: 17.85px;
      @include retina(3) {
        font-size: 21px;
      }
    }
  }
  .dropdown-wrap {
    .btn--dropdown-options__dropdown {
      right: auto;
      left: 0;
    }
  }
}

.lc-flyout {
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
  position: absolute;
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  &.flyout-active {
    animation: showFlyout 0.1s ease;
    opacity: 1;
    pointer-events: auto;
    .flyout-saving-tags & {
      &:before {
        position: absolute;
        z-index: 2;
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(#fff, 0.9);
        left: 0;
        top: 0;
        border: none;
        margin: 0;
        transform: none;
      }
      &:after {
        position: absolute;
        z-index: 3;
        left: calc(50% - 15px);
        top: calc(50% - 15px);
        @include spinner;
      }
    }
  }
  &--lg {
    width: 43em;
    bottom: 4.1em;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
  }
  &--sm {
    left: 0.3em;
    transform: none;
    bottom: 10em;
    border-radius: 4px;
    width: 28em;
    &.flyout-active {
      animation: showFlyoutDown 0.1s ease;
    }
    .lc-snippets {
      &__list {
        max-height: 17.5em;
      }
      &__item {
        padding: 1em 1.5em;
        flex-direction: column;
        &:hover,
        &--active {
          background-color: $blue;
          h5,
          p {
            color: white;
          }
        }
      }
    }
  }
  &--tags {
    .lc-flyout__actions {
      i {
        font-size: 1em;
      }
    }
    .lc-flyout__list {
      padding: 1em 0;
    }
    .flyout-search__item {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      padding-right: 1em;
    }
    .flyout-search__tag {
      background: white;
      padding-left: 1.8em;
      &--selected {
        background: rgba($blue, 0.1);
      }
      input[type="text"] {
        &:focus {
          border-bottom: 1px dashed #bbb;
        }
      }
    }
    .flyout-search__btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  &--transcription {
    max-width: 30em;
  }
  button.lc-flyout__hide {
    width: 57px;
    height: 4px;
    background: #c9c9c9;
    padding: 0;
    border-radius: 3px;
    margin: 0;
    position: absolute;
    top: 9.5px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__list {
    list-style-type: none;
    overflow: hidden;
    overflow-y: auto;
    max-height: 21.5em;
  }
  &__item {
    padding: 1.5em 2em;
    font-size: 1em;
    display: flex;
    cursor: pointer;
    &:hover {
      background-color: #ececec;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2em 1.5em 1.2em 1.5em;
    p {
      font-size: 1.8em;
      font-weight: 500;
      margin-bottom: 0;
      display: flex;
      align-items: baseline;
      i {
        font-size: 0.85em;
      }
    }
    .lc-chat__in & .btn {
      width: auto;
      height: auto;
      background: #222222;
      border-radius: 4px;
      color: white;
      padding: 0.75em 1.2em;
      line-height: 1;
      font-size: 1.4em;
      &:hover,
      &:focus {
        background-color: darken(#222, 10%);
        color: white;
      }
    }
  }
  &__search {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    .lc-chat__in & label {
      &:hover {
        background: transparent;
      }
    }
    i {
      font-size: 1.4em;
      color: #b3b3b3;
    }
    input {
      width: 100%;
      border: none;
      font-size: 1.4em;
    }
  }
  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2.5em;
  }
  &__name {
    font-size: 1.5em;
    font-weight: normal;
    margin-bottom: 0.5em;
    @include truncate();
    &.mb-0 {
      margin-bottom: 0;
    }
  }
}

.lc-snippets {
  &__reply {
    font-size: 1.3em;
    color: #8a8a8a;
    margin-bottom: 0;
    @include truncate();
  }
  &__actions {
    display: none;
    align-items: center;
    margin-left: auto;
  }
  &__text {
    margin-right: 1em;
    max-width: 90%;
  }
  .lc-chat__in &__btn {
    background: transparent;
    border: none;
    font-size: 0.8em;
    margin-right: 0;
    & + & {
      margin-left: 1em;
    }
  }
}

.lc-typing {
  padding: 0.5em 1.5em;
  font-size: 1.4em;
  min-height: 1.4em;
  margin-top: -1.2em;
  position: relative;
  z-index: 2;
  background: white;
  transition: 0.2s;
  &:empty {
    visibility: hidden;
    opacity: 1;
  }
}

.lc-commands {
  margin-left: 0;
  position: relative;
  &__heading {
    font-size: 1.4em;
    font-weight: 500;
    margin-bottom: 0.5em;
  }
  &__flyout {
    padding: 1.5em 1.5em 0 1.5em;
    width: 26em;
    bottom: calc(100% + 1.2em);
    z-index: 3;
    transition: 0.2s;
    &.flyout-active {
      animation: none;
    }
    .tooltip {
      &:after {
        border-width: 7px;
        margin-top: -7px;
      }
      &__bubble {
        padding: 1.3em;
        width: 25em;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
      }
    }
  }
  &__list {
    margin-left: -1.5em;
    margin-right: -1.5em;
    list-style-type: none;
  }
  &__item {
    font-size: 1.4em;
    border-top: 1px solid #cecfcf;
    padding: 1.1em;
    cursor: pointer;
    &:hover {
      background-color: #fafafa;
      span {
        color: $brand-green;
      }
    }
  }
  &__content {
    p {
      font-size: 1.3em;
    }
  }
  &__btn {
    background: transparent;
    padding: 0;
    font-weight: 500;
  }
}

.lc-message {
  margin-bottom: 2em;
  display: flex;
  flex-direction: row-reverse;
  &--lead {
    flex-direction: row;
    .lc-message__text {
      background-color: #9f60ac;
      color: white;
      border: none;
    }
    .lc-message__avatar {
      margin-right: 1em;
      margin-left: 0;
    }
  }
  &__avatar {
    margin-left: 1em;
    .avatar {
      width: 3em !important;
      height: 3em !important;
    }
  }
  &__text {
    font-size: 1.6em;
    border-radius: 6px;
    padding: 0.8em;
    border: 1px solid #d6d6d6;
    background-color: #f1f1f1;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
    & + & {
      margin-top: 0.6em;
    }
  }
}

.lc-lead {
  flex: 0 35em;
  padding: 3.3em 1.5em 0 1.5em;
  background-color: #f1f1f1;
  overflow: hidden;
  overflow-y: auto;
  transition: flex-basis 0.2s ease-in-out;
  &--collapsed {
    flex-basis: 0;
    padding: 0;
    overflow: visible;
    .lc-lead {
      &__in,
      &__header {
        display: none;
      }
      &__collapse {
        transform: rotate(180deg);
        margin-top: -0.5em;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    p {
      white-space: nowrap;
    }
  }
  &__in {
    padding-bottom: 3em;
  }
  &__collapse {
    background-color: transparent;
    border: none;
    cursor: pointer;
    svg {
      width: 32px;
      height: 31px;
    }
  }
  .flyout-search--tags {
    bottom: auto;
    left: -10em;
    width: calc(100% + 11em);
    min-width: auto;
    font-size: 10px;
  }

  .lc-chat__author {
    width: auto;
  }
  .conversation-activity__controls {
    display: none;
  }
}

.lc-modal {
  .modal {
    margin-bottom: 3em;
    h3 {
      margin-bottom: 0.5em !important;
    }
    .continually-chat-team,
    .continually-chat {
      font-size: 80%;
    }
    .continually-chat-team,
    .continually-chat,
    .continually-chat * {
      pointer-events: none;
      user-select: none;
    }
    @include mq(min, $mq-sm) {
      width: 600px;
      .modal-footer {
        padding: 0 4em 2em 4em;
      }
    }
  }
  &__input + &__textarea {
    margin-top: 1em;
    margin-bottom: 0.4em;
  }
  &__textarea,
  &__input {
    width: 100%;
    max-height: 6em;
    resize: none;
    font-size: 1.6em;
    padding: 0.7em;
  }
  &__preview {
    background: #f1f1f1;
    padding: 1.5em;
    min-height: 10em;
    margin: 1em 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &-img {
      max-height: 200px;
    }
    .preview-pane {
      width: 30em;
    }
    .message {
      form {
        &:not(.multiple_choice) {
          background: white;
        }
      }
      &--schedule {
        padding-left: 2em;
        padding-right: 2em;
        form {
          background-color: transparent;
        }
        .day-scroll--active {
          &:after {
            content: none !important;
          }
          fieldset.times {
            padding-top: 1.1em;
          }
        }
        a.show-more-times {
          display: none;
        }
      }
    }
  }
  &__link {
    padding: 0;
    color: black;
    text-decoration: underline;
  }
  &__helper {
    font-weight: 500;
    font-size: 1.4em;
    color: #b3b3b3;
    text-align: center;
    line-height: 1.4;
  }
  .modal-footer {
    border: none !important;
    .btn:not(.btn--sm) {
      i {
        font-size: 0.8em;
        margin-left: 0.5em;
      }
    }
  }
  .dropdown-wrap {
    margin-bottom: 1em;
    margin-left: 0;
  }
  .form-help {
    color: #959595;
  }
}

.lc-notifications {
  position: sticky;
  top: 8em;
  z-index: 10;
  height: 0;
  width: 100%;
  &__clear {
    cursor: pointer;
    position: relative;
    background: #fff;
    margin: 0.5em 1em 1em auto;
    border-radius: 20px;
    border: none;
    padding: 0.5em 1.2em;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 1.4em;
    display: flex;
    align-items: baseline;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      background: white;
      border-radius: 10em;
      z-index: -1;
      left: 0;
      top: 0;
    }
    &:hover {
      background: rgba(44, 117, 217, 0.04);
    }
  }
  .lc-conversation {
    &__header {
      .avatar--assignment {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #5830fa;
        img {
          border-radius: 0 !important;
          object-fit: contain !important;
          width: 16px !important;
          height: 16px !important;
        }
      }
    }
  }
}

.lc-chat-off {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  margin: 1em 0 0 0;
  width: 100%;
  .btn {
    pointer-events: none;
    font-weight: 400;
    border-radius: 0;
  }
}

.lc-tab-wrap {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  flex: 1;
  -webkit-overflow-scrolling: touch;
}

.lc-mobile-tabs {
  display: flex;
  align-items: center;
  margin: 25px -30px -10px;
  color: white;
  &--inbox {
    color: #fff;
    position: sticky;
    top: 0;
    background: #182e59;
    padding: 17px 0 0 0;
    z-index: 10;
    transition: 0.2s;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.17);
    margin: 0;
    flex-shrink: 0;
    @supports (padding-top: max(0px)) {
      padding-top: max(17px, var(--safe-area-inset-t));
    }
  }
}

.lc-mobile-tab {
  flex: 1;
  list-style-type: none;
  text-align: center;
  cursor: pointer;
  padding-bottom: 16px;
  border-bottom: 4px solid transparent;
  font-weight: 500;
  opacity: 0.39;
  transition: 0.2s;
  font-size: 14px;
  @include retina(3) {
    font-size: 16px;
  }
  &--active,
  &:hover {
    border-color: white;
    opacity: 1;
  }
}

.lc-settings-inner-menu {
  background: #f3f3f3;
  flex-basis: 21.5em;
  &__group {
    padding: 1.5em 0 3em;
    &:not(:last-child) {
      padding-top: 3em;
      border-bottom: 1px solid #e1e1e1;
    }
    li {
      color: #707070;
      list-style-type: none;
      font-size: 1.5em;
      margin-bottom: 0.3em;
      cursor: pointer;
      padding: 0.4em 1.34em;
      &.lc-sidebar__item--active {
        background: white;
        font-weight: 400;
      }
      &:hover {
        color: $black;
      }
    }
  }
  &__heading {
    color: #333;
    font-weight: 500;
    font-size: 1.6em;
    padding: 0 1.25em;
    margin-bottom: 0.5em;
  }
}

.lc-toggle {
  &__label {
    @include inline-block;
    cursor: pointer;
    background-color: #e5e5e5;
    border-radius: 10em;
    margin-right: 10px;
    position: relative;
    width: 40px;
    height: 16px;
    @include transition(background-color);
    &:hover {
      background-color: darken(#e5e5e5, 5%);
    }
  }
  &__input {
    display: none;
  }
  &__icon {
    border-radius: 10em;
    width: 22px;
    height: 22px;
    background-color: #a2a2a2;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    @include transition;
  }
  &__input:checked + .lc-toggle__label {
    .lc-toggle__icon {
      background-color: $green;
      left: calc(100% - 22px);
    }
  }
}

.opportunity--lead-activity {
  text-align: center;
  display: block;
  margin: 0 0 2em 0;
  font-size: 1.6em;
  @include medium;
}

/********************** EMOJI *************************/
span.emoji {
  display: -moz-inline-box;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: baseline;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  width: 1em;
  height: 1em;
  background-size: 1em;
  background-repeat: no-repeat;
  text-indent: -9999px;
}

span.emoji-sizer {
  line-height: 0.81em;
  font-size: 1em;
  margin: -2px 0;
}

span.emoji-outer {
  display: -moz-inline-box;
  display: inline-block;
  *display: inline;
  height: 1em;
  width: 1em;
}

span.emoji-inner {
  display: -moz-inline-box;
  display: inline-block;
  text-indent: -9999px;
  width: 100%;
  height: 100%;
  vertical-align: baseline;
  *vertical-align: auto;
  *zoom: 1;
}

img.emoji {
  width: 1em;
  height: 1em;
}

.emoji-wysiwyg-editor:empty:before {
  content: attr(placeholder);
  color: #9aa2ab;
}

.emoji-picker-container {
  position: relative;
}

.emoji-picker-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 20px;
  opacity: 0.7;
  z-index: 100;
  transition: none;
  color: black;
  user-select: none;
}

.emoji-picker-icon.parent-has-scroll {
  right: 28px;
}

.emoji-picker-icon:hover {
  opacity: 1;
}

/* Emoji area */
.emoji-wysiwyg-editor:empty:before {
  content: attr(placeholder);
  color: #9aa2ab;
}

.emoji-wysiwyg-editor:active:before,
.emoji-wysiwyg-editor:focus:before {
  content: none;
}

.emoji-wysiwyg-editor {
  padding: 10px;
  padding-right: 35px;
  margin-bottom: 0;
  min-height: 35px;
  height: 30px;
  max-height: 284px;
  overflow: auto;
  line-height: 17px;
  border: 1px solid #d2dbe3;
  border-radius: 2px;
  box-shadow: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  user-select: text;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.emoji-wysiwyg-editor.parent-has-scroll {
  padding-right: 40px;
}

.emoji-wysiwyg-editor.single-line-editor {
  min-height: 35px;
  height: inherit;
}

.emoji-wysiwyg-editor img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: -3px 0 0 0;
}

.emoji-menu {
  position: absolute;
  left: 2em;
  top: -26em;
  z-index: 999;
  width: 225px;
  overflow: hidden;
  border: 1px #dfdfdf solid;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.emoji-items-wrap1 {
  background: #ffffff;
  padding: 5px 2px 5px 5px;
}

.emoji-items-wrap1 .emoji-menu-tabs {
  width: 100%;
  margin-bottom: 8px;
  margin-top: 3px;
}

.emoji-items-wrap1 .emoji-menu-tabs td {
  text-align: center;
  color: white;
  line-height: 0;
}

.emoji-menu-tabs .emoji-menu-tab {
  display: inline-block;
  width: 24px;
  height: 29px;
  background: url("../img/IconsetSmiles.png") no-repeat;
  background-size: 42px 350px;
  border: 0;
}

.is_1x .emoji-menu-tabs .emoji-menu-tab {
  background-image: url("../img/IconsetSmiles_1x.png");
}

.emoji-menu-tabs .icon-recent {
  background-position: -9px -306px;
}

.emoji-menu-tabs .icon-recent-selected {
  background-position: -9px -277px;
}

.emoji-menu-tabs .icon-smile {
  background-position: -9px -34px;
}

.emoji-menu-tabs .icon-smile-selected {
  background-position: -9px -5px;
}

.emoji-menu-tabs .icon-flower {
  background-position: -9px -145px;
}

.emoji-menu-tabs .icon-flower-selected {
  background-position: -9px -118px;
}

.emoji-menu-tabs .icon-bell {
  background-position: -9px -89px;
}

.emoji-menu-tabs .icon-bell-selected {
  background-position: -9px -61px;
}

.emoji-menu-tabs .icon-car {
  background-position: -9px -196px;
}

.emoji-menu-tabs .icon-car-selected {
  background-position: -9px -170px;
}

.emoji-menu-tabs .icon-grid {
  background-position: -9px -248px;
}

.emoji-menu-tabs .icon-grid-selected {
  background-position: -9px -222px;
}

.emoji-menu-tabs .icon-smile,
.emoji-menu-tabs .icon-flower,
.emoji-menu-tabs .icon-bell,
.emoji-menu-tabs .icon-car,
.emoji-menu-tabs .icon-grid {
  opacity: 0.7;
}

.emoji-menu-tabs .icon-smile:hover,
.emoji-menu-tabs .icon-flower:hover,
.emoji-menu-tabs .icon-bell:hover,
.emoji-menu-tabs .icon-car:hover,
.emoji-menu-tabs .icon-grid:hover {
  opacity: 1;
}

.emoji-menu .emoji-items-wrap {
  position: relative;
  height: 174px;
  overflow: scroll;
}

.emoji-menu .emoji-items {
  padding-right: 8px;
  outline: 0 !important;
}

.emoji-menu img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  border: 0 none;
}

.emoji-menu .emoji-items a {
  margin: -1px 0 0 -1px;
  padding: 5px;
  display: block;
  float: left;
  border-radius: 2px;
  border: 0;
}

.emoji-menu .emoji-items a:hover {
  background-color: #edf2f5;
}

.emoji-menu:after {
  content: " ";
  display: block;
  clear: left;
}

.emoji-menu a .label {
  display: none;
}
/********************** END EMOJI *************************/

.lc-snippets-section {
  max-width: 64em;
  &__copy {
    .fa-stack {
      .fa-circle {
        color: rgba(#2c75d9, 0.25);
      }
      .fa-bookmark {
        color: #2c75d9;
      }
    }
  }
}

.lc-snippets-list {
  list-style-type: none;
  margin-top: 3em;
  &__item {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    padding: 1.25em 1.8em 1.25em 1.2em;
    display: flex;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      border-color: black;
      padding-bottom: 2em;
      .lc-snippets-list__desc {
        display: block;
      }
      .lc-snippets-list__edit {
        opacity: 1;
      }
    }
    .fa-stack {
      margin-top: -0.5em;
      &:hover {
        .fa-circle {
          color: rgba(#f7b500, 0.1);
        }
      }
      .fa-circle {
        color: white;
      }
      .fa-star {
        color: #f7b500;
      }
    }
  }
  &__name {
    font-weight: 500;
    font-size: 1.125em;
    color: #353535;
    margin-bottom: 0;
  }
  &__desc {
    display: none;
    font-size: 1em;
    margin-top: 0.8em;
    color: #353535;
    line-height: 1.33;
    margin-bottom: -1em;
  }
  &__edit {
    font-size: 1.125em;
    color: #333333;
    opacity: 0.5;
    margin-left: auto;
  }
}

.lc-snippets-form {
  max-width: 525px;
  input,
  textarea {
    max-width: 100% !important;
  }
  textarea.ql-container {
    //pointer-events: none;
    //visibility: hidden;
    //opacity: 0;
    //position: absolute;
    font-size: 1.6em;
    height: 8em;
  }
  .emoji-wysiwyg-editor {
    max-height: 125px;
    font-size: 1.6em;
    outline: none;
  }
  .ql-toolbar {
    .ql-formats {
      display: flex;
      align-items: center;
    }
    label {
      cursor: pointer;
    }
    i {
      color: #222;
    }
    .ql-emoji {
      svg {
        width: 19px;
        height: 19px;
      }
    }
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    padding-bottom: 2em;
    .ql-editor {
      padding-bottom: 2em;
    }
  }
  &__flyout-variables {
    position: absolute;
    left: 6.7em;
    top: 0.9em;
    line-height: 1;
    cursor: pointer;
    > span {
      height: 24px;
      padding: 3px 5px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  .ql-container {
    min-height: 10em;
  }
  .emoji-menu {
    top: 4em;
    left: 1em;
  }
  .flyout--variables {
    left: -1.4em;
    transform: none;
    padding-bottom: 0;
    &::before,
    &::after {
      right: auto;
      left: 0;
    }
  }
  &__file-upload,
  &__image-upload {
    position: absolute;
    left: 9.7em;
    top: 0.9em;
    label {
      margin: 0;
      height: 24px;
      padding: 3px 5px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  &__image-upload {
    left: 9.7em;
  }
  .lc-uploaded-file {
    z-index: 1;
    position: absolute;
    left: 1em;
    bottom: 1em;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.lc-default-snippets {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0.5em 2em 0;
  flex-wrap: wrap;
  &__item {
    background: rgba($blue-secondary, 0.5);
    border-radius: 13px;
    padding: 0.35em 1em;
    font-size: 1.4em;
    color: white;
    margin-right: 0.5em;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 20%;
    margin-bottom: 0.5em;
    min-width: 0;
    &:hover {
      background: $blue-secondary;
    }
  }
}

.lc-uploaded-file {
  margin: 0.5em 1.5em;
  font-size: 1.4em;
  position: relative;
  z-index: 2;
  background: #fff;
  transition: 0.2s;
  border: 1px solid #ccc;
  padding: 0.5em 1em;
  display: inline-flex;
  border-radius: 4px;
  align-self: flex-start;
  max-width: 20em;
  &--error {
    position: absolute;
    bottom: 0.5em;
    left: 0.5em;
    z-index: 11;
    background: rgba($red, 0.1);
    color: $red;
    max-width: none;
  }
  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 0.5em;
  }
  i {
    cursor: pointer;
  }
}

.lc-help {
  background: white;
  position: absolute;
  max-width: 90%;
  width: 70em;
  height: 100%;
  padding: 7em 0;
  & + .flex {
    display: none;
  }
}

.modal {
  &--test-live-chat {
    border-top: 0;
    @include mq(min, $mq-md) {
      width: 900px;
      height: 80vh;
      max-width: 100%;
    }
    @include mq(min, $mq-lg) {
      width: 1100px;
    }
    .modal-header {
      display: flex;
      justify-content: flex-end;
      border-bottom: 1px solid #ddd;
      margin-left: -6em;
      margin-right: -6em;
      margin-top: -6em;
    }
    .modal-body {
      padding-right: 15%;
      padding-left: 15%;
    }
    a.close-modal {
      position: static;
      color: $black;
      border-left: 1px solid #ddd;
      padding: 2em 3em;
    }
  }
}

.lc-message-form {
  .ql-container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    max-height: 20em;
    padding-right: 5em;
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: 1.6em;
    border-radius: 0 0 3px 3px;
    &:focus {
      outline: none;
    }
  }
  textarea.ql-container {
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    width: 93%;
  }
  div.ql-container.emoji-wysiwyg-editor {
    min-height: 2em;
  }
  .message--agent-takeover {
    border-top: 0;
    z-index: 2;
    flex: 1 auto;
    &.has-file-error {
      .emoji-wysiwyg-editor {
        min-height: 6em;
        padding-bottom: 1.5em;
      }
    }
    &.has-file {
      .emoji-wysiwyg-editor {
        min-height: 5em;
        padding-bottom: 1.5em;
      }
    }
    &.is-private {
      .ql-toolbar {
        background: #fefbe9;
      }
      .emoji-wysiwyg-editor {
        background: #fefbe9;
      }
    }
  }
  .ql-toolbar {
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    font-family: "MundoSansPro", $font-family-sans-serif;
    padding-top: 5px;
    padding-bottom: 5px;
    .ql-formats {
      display: flex;
      align-items: center;
      border: none;
    }
    .ql-tooltip {
      background-color: #fff;
      border: 1px solid #ccc;
      color: #444;
      padding: 0.5em 0.8em;
      white-space: nowrap;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
      z-index: 99999;
      left: 50%;
      top: -3.5em;
      transform: translateX(-50%);
      input {
        height: auto;
        padding: 0.5em;
      }
    }
    input[type="file"] {
      display: none;
    }
    &__btn {
      cursor: pointer;
      padding: 5px;
      margin-right: 2px;
      width: 3.4em;
      height: 3.4em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &:hover {
        background-color: rgba($blue, 0.1);
        i {
          color: $blue-secondary;
        }
      }
      i {
        font-size: 1.8em;
        color: #333;
      }
    }
    &__counter {
      position: absolute;
      top: -7px;
      right: -3px;
      font-weight: 700;
      color: white;
      display: inline-block;
      background: black;
      padding: 0.25em 0.42em 0.05em 0.42em;
      border-radius: 50%;
    }

    button {
      float: none;
    }
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 0;
    border-radius: 3px 3px 0 0;
    font-family: "MundoSansPro", $font-family-sans-serif;
    padding-top: 5px;
    padding-bottom: 5px;
    .ql-formats {
      display: flex;
      align-items: center;
      border: none;
    }
    .ql-tooltip {
      background-color: #fff;
      border: 1px solid #ccc;
      color: #444;
      padding: 0.5em 0.8em;
      white-space: nowrap;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
      z-index: 99999;
      left: 50%;
      top: -3.5em;
      transform: translateX(-50%);
      input {
        height: auto;
        padding: 0.5em;
      }
    }
    input[type="file"] {
      display: none;
    }
    &__btn {
      cursor: pointer;
      padding: 5px;
      margin-right: 2px;
      width: 3.4em;
      height: 3.4em;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      &:hover {
        background-color: rgba($blue, 0.1);
        i {
          color: $blue-secondary;
        }
      }
      i {
        font-size: 1.8em;
        color: #333;
      }
    }
    &__counter {
      position: absolute;
      top: -7px;
      right: -3px;
      font-weight: 700;
      color: white;
      display: inline-block;
      background: black;
      padding: 0.25em 0.42em 0.05em 0.42em;
      border-radius: 50%;
    }

    button {
      float: none;
    }
    button.lc-chat__send {
      height: auto;
      width: auto;
      border-radius: 3px;
      font-size: 1.4em;
      padding: 0.4em 1em 0.3em 1em;
      margin: 0 0 0 auto;
      align-items: baseline;
      background: $brand-primary;
      color: white;
      &:hover {
        color: white;
        background: $brand-primary;
      }
      i {
        font-size: 0.8em;
        margin-left: 0.5em;
      }
    }
  }
}
