.tag {
    display: inline-block;
    border-radius: 2px;
    padding: 0.6em 1em;
    text-decoration: none;
    background-color: $grey;
    color: $black;
    margin-right: 0.5em;
    margin-bottom: 0.5em;
    svg, i {
        @include inline-block;
        &:not(:last-child) { margin-right: 0.5em; }
        &:not(:first-child) { margin-left: 0.5em; }
    }
    .label {
        @include inline-block;
        @include medium;
        font-size: 1.2em;
        white-space: nowrap;
        line-height: 1;

        &.wrap-words {
            white-space: normal !important;
        }
    }
    code {
        @include inline-block;
        font-size: 100%;
        background-color: transparent;
        padding: 0;
    }
}