.form--edit-profile {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 4em;
  display: grid;
  &__info {
    grid-column-start: 1;
    grid-column-end: 3;
    fieldset {
      background: #efefef;
      padding: 1.5em 1.5em 0 1.5em;
    }
    label {
      margin-bottom: 0.5em;
    }
    .fas {
      margin-right: 0.5em;
    }
  }
  fieldset {
    margin-bottom: $spacer-y * 2;
    position: relative;
    width: 100%;
    .tooltip {
      top: 70%;
    }

    @include mq(min, $mq-sm) {
      max-width: 350px;
    }
  }
  // @include mq(min, $mq-sm) {
  .col--avatar {
    order: 2;
  }
  .col--general {
    order: 1;
  }
  // }
}

.form--edit-profile__choose-avatar {
  ul {
    display: flex;

    li {
      list-style: none;
      text-align: center;
    }

    .default-profile-avatar:not([style*="display: none"])
      ~ .default-profile-avatar,
    .js-avatar_is_uploaded:not([style*="display: none"])
      ~ .js-avatar_is_uploaded {
      display: none !important;
    }
  }
  .avatar-wrap {
    border-radius: 4px;
    @include regular;
    font-size: 1em;
    position: relative;
    div.label {
      font-size: 0.9em;
      margin: 1em 0 0;
    }
  }
  .avatar {
    @include avatar(90px, 1.8em);
  }
  input[type="radio"] {
    @include offscreen-new;
  }
}

.upload-avatar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  z-index: 2;
  input[type="file"] {
    @include offscreen-new;
  }
  &__btn {
    background: rgba(0, 0, 0, 0.6);
    color: white;
    margin: 0;
    cursor: pointer;
    @include transition;
    &:hover {
      background: rgba(0, 0, 0, 0.7);
    }
    i {
      font-size: 16px;
    }
  }
}

.imageBox {
  position: relative;
  width: 200px;
  height: 200px;
  border: 1px solid #aaa;
  background: #fff;
  overflow: hidden;
  background-repeat: no-repeat;
  cursor: move;
  margin: 0 auto 2em;
  @include mq(min, $mq-sm) {
    width: 350px;
    height: 350px;
  }
  @include mq(min, $mq-md) {
    width: 500px;
    height: 500px;
  }
}

#modal-crop-avatar.modal {
  text-align: center;
  @include mq(min, $mq-sm) {
    width: calc(350px + 8em);
  }
  @include mq(min, $mq-md) {
    width: calc(500px + 12em);
  }
  .btngroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.imageBox .thumbBox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  margin-top: -100px;
  margin-left: -100px;
  box-sizing: border-box;
  border: 1px solid rgb(102, 102, 102);
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.5);
  background: none repeat scroll 0 0 transparent;
}

.imageBox .spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  line-height: 400px;
  background: rgba(0, 0, 0, 0.7);
}

#modal-change-avatar.modal {
  @include mq(min, $mq-md) {
    width: 700px;
  }
  .form--edit-profile__choose-avatar {
    ul {
      justify-content: center;
    }
  }
  h2 {
    text-align: center;
  }
}

.profile-breadcrumb {
  font-size: 1.4em;
  font-family: MundoSansPro, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  color: $black;
  display: block;
  margin-bottom: 1.6em;
  text-decoration: none;
}

.confirm-activate-account {
  margin-right: 1em;
}
