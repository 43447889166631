body {
  &.body--integration {
    .automation-item {
      border: 1px solid #afafaf;
      border-radius: 4px;
      padding: 2.8em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 3em;
      color: currentColor;
      text-decoration: none;
    }
    .automation-item:hover {
      border: 1px solid #2969d0;
      .automation-setting i,
      .automation-setting span {
        color: #2969d0;
      }
    }
    .aut-general {
      color: #2969d0;
    }
    .aut-object {
      color: #f85e1f;
    }
    .automation-text span {
      margin-left: 15px;
    }
    .automation-setting i,
    .automation-setting span {
      font-size: 2em;
      color: #8e8e8e;
    }
    .automation-status {
      float: left;
      margin: 1em;
    }
    .automation-item .help {
      font-size: 16px;
    }
    .automation-item .help span {
      margin-left: 1em;
    }
    .help-guide-link,
    .help-guide-link:hover {
      text-decoration: none;
      width: 500px;
      display: block;
    }
    .help-guide {
      width: 500px;
      height: 50px;
      border: 1px solid #afafaf;
      border-radius: 4px;
      font-size: 16px;
      padding: 15px;
      color: #4a4a4a;
    }
    .help-head {
      float: left;
    }
    .help-arrow {
      float: right;
    }
  }
}

.apps-section {
  margin-top: 6em;
  &:first-child {
    margin-top: 0;
  }
  &:focus {
    outline: none;
  }
  .unconnected-apps__list {
    display: flex;
    margin-right: -15px;
    flex-wrap: wrap;
    .app__row {
      flex-basis: 31%;
      margin-right: 2.2%;
      align-items: flex-start;
      padding: 2em;
      position: relative;
      z-index: 1;
      cursor: pointer;
      text-decoration: none;
      color: $black;
      @include mq(max, $mq-md) {
        flex-basis: 46%;
      }
      &:hover {
        border-color: $blue-secondary;
        &::before {
          content: "";
          opacity: 0.06;
          background-image: linear-gradient(180deg, #ffffff 0%, #2c75d9 100%);
          border-radius: 6px;
          height: 44px;
          bottom: 0;
          left: 0;
          width: 100%;
          position: absolute;
          z-index: -1;
        }
      }
      &:not(:last-of-type),
      & {
        margin-bottom: 2em;
      }
    }
    .app__info {
      margin-right: 0;
      margin-left: 2em;
    }
    .app__name {
      i {
        font-size: 0.8em;
      }
    }
    .app__desc {
      color: #333;
      margin-bottom: 1.5em;
      opacity: 0.6;
    }
  }
  .connected-apps__list {
    .app-panel {
      border-radius: 8px;
      position: relative;
      z-index: 1;
      cursor: pointer;
      text-decoration: none;
      color: $black;
      &--not-clickable {
        cursor: default;
      }
      &:only-child {
        .app__desc {
          color: #333;
          opacity: 0.6;
          display: block;
        }
      }
      .app__prefs {
        a {
          color: $black;
        }
      }
      &:hover {
        border-color: $blue-secondary;
        &::before {
          content: "";
          opacity: 0.06;
          background-image: linear-gradient(180deg, #ffffff 0%, #2c75d9 100%);
          border-radius: 6px;
          height: 64px;
          bottom: 0;
          left: 0;
          width: 100%;
          position: absolute;
          z-index: -1;
        }
        .app__prefs {
          a {
            color: $blue-secondary;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.connected-apps__list {
  margin-top: 2em;
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.3em;
  margin-right: -1.3em;
  .app-panel {
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    width: calc(50% - 2.6em);
    margin: 0 1.3em 2.6em 1.3em;
    text-align: center;
    padding: 1.8em;
    @include mq(min, $mq-md) {
      width: calc(33.33% - 2.6em);
    }
    &--disabled {
      background-color: #f0f0f0;
    }
  }
  &--many {
    .app-panel {
      @include mq(min, $mq-lg) {
        width: calc(25.55% - 3.3em);
      }
    }
    .app__desc {
      display: none;
    }
    .app__is-connected {
      display: none;
    }
  }
  .app__icon {
    margin-bottom: 1.6em;
    figure {
      max-width: 4.8em;
      margin: 0 auto;
    }
  }
  .app__name {
    margin-bottom: 1em;
    .tick--connected,
    span {
      @include inline-block;
    }
    .tick {
      border: 1px solid #fff;
      width: 2em;
      height: 2em;
      line-height: 2.5;
      text-align: center;
      border-radius: 50%;
      margin-right: 0.5em;
      &--connected {
        background-color: $brand-primary;
      }
      i {
        font-size: 1em;
        color: #fff;
        position: relative;
        top: -0.1em;
      }
      &--disabled {
        display: inline-block;
        border: none;
        i {
          font-size: 2em;
          color: red;
          vertical-align: middle;
        }
      }
    }
    span {
      font-size: 1.6em;
      @include medium;
      @include font-smoothing;
      em {
        font-style: inherit;
        font-weight: inherit;
      }
    }
  }
  .app__desc {
    margin-bottom: 1.6em;
    p {
      font-size: 1.4em;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .app__prefs {
    margin-bottom: 1.6em;
    .btn--quiet {
      @include regular;
    }
  }
  .disconnect-integration {
    color: $red;
    font-size: 1.4em;
  }
}

.unconnected-apps__list {
  margin-top: 1.5em;
  .app__row {
    display: flex;
    align-items: center;
    padding: 1.8em;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    &:not(:last-of-type) {
      margin-bottom: 1.6em;
    }
  }
  .app__icon {
    flex-shrink: 0;
    position: relative;
    .tick--connected {
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      background-color: $brand-primary;
      border: 1px solid #fff;
      width: 2em;
      height: 2em;
      line-height: 2.5;
      text-align: center;
      border-radius: 50%;
      margin-left: -0.5em;
      margin-top: -0.5em;
      i {
        font-size: 1em;
        color: #fff;
        position: relative;
        top: -0.1em;
      }
    }
    figure {
      width: 4.8em;
      img {
        display: block;
      }
    }
  }
  .app__info {
    flex: 1;
    margin: 0 2.4em;
  }
  .app__action {
    flex-shrink: 0;
  }
  .app__name {
    font-size: 1.6em;
    margin-bottom: 0.5em;
    font-weight: 500;
    display: flex;
    align-items: center;
    .flag {
      margin-left: 0.7em;
      font-size: 0.7em;
    }
  }
  .app__desc {
    font-size: 1.4em;
  }
  .app__prefs {
    margin-top: 1em;
    a {
      color: $black;
      font-size: 1.3em;
      &:hover {
        color: $brand-primary;
      }
    }
  }
}

.integration-settings {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em 3em;
    background-color: #f3f3f3;
  }
  &__link {
    font-size: 1.6em;
    color: #4a4a4a;
    margin-left: 1em;
    display: block;
    margin-bottom: -1em;
    @include regular;
    @include mq(min, 1250px) {
      margin-left: 2em;
      margin-bottom: -3em;
    }
    &--back {
      background: #e1e1e1;
      border-radius: 3px;
      color: black;
      padding: 0.7em 1em;
      text-decoration: none;
      display: inline-block;
      position: relative;
      margin-top: 1em;
      @include mq(min, 1360px) {
        top: 4em;
        margin-right: 2em;
        margin-bottom: 0;
      }
      &:hover {
        background: darken(#e1e1e1, 10%);
        color: black;
        text-decoration: none;
      }
    }
  }
  header {
    position: relative;
    padding-right: 13em;
    padding-bottom: 1.6em;
    border-bottom: 1px solid #ddd;
    margin: 3.2em 0;
    display: flex;
    align-items: center;
    figure {
      width: 6.4em;
      margin-right: 3.2em;
    }
    .copy {
      flex: 1;
    }
    h1 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
    a.disconnect-integration {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  label.activities {
    margin-top: 1em;
  }
  ul.activities {
    margin-top: 2em;
  }
  .regular-activity {
    padding-bottom: 2.6em;
    margin-bottom: 3.2em;
    border-bottom: 1px solid #ddd;
  }
  .form-actions {
    display: inline-block;
  }
  .label {
    font-size: 1.4em;
    margin-right: 1em;
  }
  .pulldown {
    font-size: 90%;
  }
  .pulldown-select {
    @include medium;
    color: $black;
    padding-right: 2.4em;
  }
  .pulldown-arrow {
    right: 1em;
    &:after {
      opacity: 0.3;
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-size: 10px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTE2IDUuNWwtMi0yLTYgNi02LTYtMiAyIDggOCA4LTh6Ij48L3BhdGg+PC9zdmc+);
    }
  }
  &--tabs {
    header {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      margin-top: 0;
      figure {
        margin-right: 2em;
      }
    }
  }
  .fa-stack {
    font-size: 2.3em;
  }
}

.enterprise-integrations {
  background-image: linear-gradient(48deg, #c4001e 0%, #ff5f29 100%);
  margin-top: 3.2em;
  border-radius: 4px;
  color: #fff;
  position: relative;
  padding: 2.8em 16em 2.8em 4.2em;
  @include mq(min, $mq-md) {
    padding-right: 36em;
  }
  h3 {
    font-size: 1.6em;
    @include medium;
    margin-bottom: 0.3em;
    @include font-smoothing;
  }
  p {
    font-size: 1.6em;
    @include font-smoothing;
  }
  .btn {
    color: #fff;
    border: 2px solid #fff;
    @include font-smoothing;
  }
  .crm-logos {
    position: absolute;
    z-index: 1;
    right: 4.2em;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    @include mq(max, $mq-md) {
      width: 10em;
    }
    @include mq(min, $mq-md) {
      transform: translateY(-50%) scale(0.7);
      transform-origin: right;
    }
    @include mq(min, $mq-lg) {
      transform: translateY(-50%) scale(1);
    }
    img {
      display: block;
      margin: 0.5em auto;
      @include mq(min, $mq-md) {
        @include inline-block;
        margin: 0 1em;
      }
    }
  }
}

.apps-upgrade-notice {
  padding: 1.2em 1.3em 1.6em;
  .panel {
    background-color: #f2f2f2;
    border-radius: 4px;
    padding: 3.2em 4.8em;
    position: relative;
    h3 {
      @include medium;
      font-size: 1.9em;
    }
    p {
      max-width: 36em;
    }
  }
}

.mailchimp-edit-list-subheader {
  margin-top: -1.5em;
  margin-bottom: 2em;
}

.mailchimp-list {
  border: 1px solid #afafaf;
  .mailchimp-list-actions {
    margin-top: 0.5em;
  }
}

.app-settings {
  padding-top: 5em;
  &--variables {
    .app-settings__title {
      font-weight: 500;
      margin-bottom: 0.3em;
    }
  }
  &__header {
    max-width: 700px;
    margin-top: 1em;
    @include mq(min, $mq-xl) {
      margin: -4em auto 0;
    }
  }
  &__link {
    font-size: 1.6em;
    color: #4a4a4a;
    margin-left: 1em;
    @include regular;
    @include mq(min, $mq-md) {
      margin-left: 2em;
    }
  }
  &__form-item {
    width: 277px;
    &--short {
      width: 140px;
    }
    input[type="number"] {
      padding-right: 1em;
      -moz-appearance: spinner-textfield;
      appearance: auto;
      background: inherit;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: inner-spin-button;
      }
    }
  }
  &__form-control {
    width: 100%;
    &#{&} {
      max-width: 560px;
    }
    &[readonly] {
      background: #ececec;
    }
    &#{&}--white {
      background: white;
    }
    .dropdown-wrap--search {
      .toggle-dropdown-options {
        height: 3em;
      }
      .dropdown-icon i {
        color: $black;
      }
    }
  }
  &__form-control-type {
    width: 45%;
  }
  &__form-control-length {
    width: 37%;
  }
  fieldset {
    label {
      @include regular;
    }
  }
  &__highlight {
    label {
      span {
        @include medium;
      }
    }
    input.app-settings__form-control,
    .pulldown-inner,
    .toggle-dropdown-options {
      background: #fefce9;
      select {
        @include medium;
      }
    }
  }
}

.integration-teammates {
  &__item {
    .pulldown {
      max-width: 45em;
    }
  }
  &__arrow {
    width: 22px;
    height: 9px;
    margin-right: 1.7em;
  }
  &__name {
    min-width: 5em;
    margin-left: 1em;
  }
}

.sync-bot-name b,
.visibility-bot-name b {
  font-weight: 450;
}

.google-sheet-steps {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -1em;
  margin-right: -1em;
  &__item {
    flex-basis: 257px;
    margin: 1em;
  }
  &__content {
    display: flex;
    align-items: flex-start;
    margin-top: 1em;
  }
  &__number {
    font-size: 1.4em;
    color: #ffffff;
    border-radius: 50%;
    font-weight: 500;
    background: #44d0bd;
    padding: 0.3em 0.4em 0.1em 0.4em;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin-right: 0.3em;
  }
  &__text {
    font-size: 1.4em;
    color: #545454;
  }
}
.activate-list-link {
  color: #09b909 !important;
}
.deactivate-list-link {
  color: #ff8d00 !important;
}
.inactive-list-border {
  border-color: #ff8d00 !important;
}
.inactive-list-block {
  background-color: #ff810040 !important;
}
.default-empty {
  color: grey;
}
select option {
  color: black;
}
select option:first-child {
  color: grey;
}

.suggest-integration {
  background: #f6f6f6;
  padding: 7em;
}

.automation-error {
  flex: 1 100%;
  background: $red;
  padding: 0.6em 1em;
  border-radius: 3px;
  color: white;
  display: flex;
  font-size: 1.6em;
  margin-top: -0.8em;
  margin-left: -0.8em;
  margin-right: -0.9em;
  margin-bottom: 1em;
}

.field-mapping-banner {
  background: #1f1f1f;
  padding: 3em;
  border-radius: 8px;
  max-width: 93em;
  h4,
  p {
    color: #fff;
  }
}
.field-mapping-button {
  float: right;
  i {
    font-size: 16px;
  }
  input {
    margin-left: 0.5em;
  }
}
.fields-mapped-check {
  color: #3ec8b4;
  font-size: 2.4em;
}

.fields-mapped-banner-close {
  font-size: 2.2em;
  color: #fff;
}

.mapped-field {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  input {
    position: relative;
    z-index: 1;
    &:checked + label {
      background-color: #f8faff;
    }
  }
  label {
    border: 1px solid #ddd;
    font-size: 1.6em;
    border-radius: 4px;
    margin-left: -1.8em;
    padding: 1em 2em 1em;
    width: 100%;
  }
}

.new-field-label {
  margin-left: -3.2em;
  font-size: 1.2em;
  font-weight: 500;
  color: #f6b740;
  margin-right: 1em;
}

.field-map-errors {
  border: 1px solid #db2926;
  padding: 1em;
  border-radius: 4px;
  margin-bottom: 2em;
  color: #dc3330;
  background: #fbecec;
  font-size: 16px;
}
.field-error-row {
  color: #db2926;
  margin-left: -2em;
  font-size: 1.5em;
}
.app-settings__error .app-settings__form-control-item {
  border: 1px solid #db2926;
}

.halo-ticket-block {
  background: #efefef;
  border-radius: 10px;
  padding: 4px 10px;
  margin-right: 0.5em;
  display: inline-block;
  margin-bottom: 0.5em;
  @include mq(min, $mq-md) {
    margin-bottom: 0;
    &:nth-child(n + 3) {
      margin-top: 0.5em;
    }
  }
  i {
    color: #aeaeae;
    margin-left: 0.5em;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      color: $black;
    }
  }
}

.automation-variables-link-block:hover {
  text-decoration: none;
}
.automation-variables-link-main {
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  padding: 2em;
  max-width: 64em;
}
.automation-variables-link-block h4 {
  font-weight: 400;
}
.automation-variables-link-block p {
  margin-bottom: 0;
}
.automation-variables-link-block span {
  color: #8e8e8e;
}
.automation-variables-link-block i {
  color: #b2b2b2;
  font-size: 16px;
}
.sync-lead-errors {
  padding-left: 1.6em;
  padding-right: 1.6em;
}

.apps-dropdown {
  &__items {
    border: 1px solid #aaa;
    border-radius: 4px;
    max-height: 41em;
    overflow: hidden;
    overflow-y: auto;
    min-width: 25em;
  }
  &__item {
    border-top: 1px solid #ccc;
    padding: 0.8em 1em;
  }
}

.toggle-label {
  .flag {
    text-transform: none;
    font-size: 0.9em;
    padding: 0.2em;
    line-height: 1;
  }
}
