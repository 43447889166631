.body--landing-page-config {
  display: flex;
  flex-direction: column;
  .main--app {
    flex: 1;
    display: flex;
    flex-direction: column;
    .app--content {
      flex: 1;
      display: flex;
      flex-direction: column;
      .settings-section {
        flex: 1;
        padding-bottom: 0;
      }
    }
  }
}

.body--landing-page-config {
  .link--landing-page {
    a {
      @include inline-block;
      font-size: 0.8em;
      padding: 0.5em 1em;
      background-color: #f9f9f9;
      border-radius: 5px;
      border: 1px solid #ddd;
      font-family: $font-family-monospace;
      color: #141414;
      text-decoration: none;
      span {
        margin-right: 1em;
      }
      span,
      i {
        @include inline-block;
      }
    }
  }
  .landing-page-config {
    background-color: #f3f3f3;
    width: 40em;
    overflow: auto;
    padding: 3.2em;
    border-left: 1px solid #ddd;
    word-break: break-word;
    height: 100%;
    @include clearfix;
    fieldset.file {
      figure {
        position: relative;
        display: inline-block;
        background-image: url(../images/docs/transparent-bg.png);
        background-repeat: repeat;
      }
      input[type="file"] {
        @include offscreen;
      }
      .btn--upload-input {
        text-align: center;
        display: block;
        border: 1px dashed $black-lighter;
        color: $black-lighter;
        padding: 1.5em;
        font-size: 1.4em;
        @include uppercase;
        @include medium;
        cursor: pointer;
        @include transition;
        margin-bottom: 0.5em;
        &:hover {
          border-color: $black;
          color: $black;
        }
        i {
          font-size: 2.4em;
          margin-bottom: 0.2em;
        }
        span {
          display: block;
        }
      }
    }
    fieldset.file--logo {
      img {
        max-height: 100px;
      }
    }
    fieldset.file--cover-image {
      img {
        max-height: 15em;
      }
    }
    .selected-cover-image {
      position: relative;
      display: block;
      background-image: url(../images/docs/transparent-bg.png);
      background-repeat: repeat;
      text-align: center;
      img {
        max-height: 15em;
        display: inline-block;
      }
    }
    .unsplash-select {
      margin: 1.6em 0;
      p {
        font-size: 1.3em;
        a {
          color: $black;
        }
      }
    }
    .unsplash-thumbs {
      display: flex;
      flex-wrap: wrap;
      input[type="radio"] {
        @include offscreen;
        &:checked + label,
        &:checked + .validationMessage + label {
          opacity: 0.33;
        }
      }
      label {
        opacity: 1;
        width: 20%;
        margin: 0;
        cursor: pointer;
        img {
          width: 100%;
          display: block;
        }
      }
      &--fullscreen {
        label {
          width: 50%;
        }
      }
    }
    fieldset.file--secondary-image {
      img {
        max-height: 15em;
      }
    }
    fieldset.btn-icon {
      input[type="radio"] {
        @include offscreen;
        &:checked + label,
        &:checked + .validationMessage + label {
          background-color: #fff;
        }
      }
      .options {
        @include clearfix;
        label {
          float: left;
          padding: 0.7em 0.8em 0.5em;
          display: inline-block;
          border: 1px solid #ddd;
          border-right: none;
          cursor: pointer;
          font-size: 1.4em;
          &:first-of-type {
            border-radius: 3px 0 0 3px;
          }
          &:last-of-type {
            border-radius: 0 3px 3px 0;
            border-right: 1px solid #ddd;
          }
        }
      }
    }
    .btngroup {
      margin: $spacer-y 0;
      a {
        font-size: 1.3em;
        display: inline-block;
        margin-right: $spacer-x;
        color: $black-light;
        &:hover {
          color: $brand-primary;
        }
      }
    }
    h3 {
      @include regular;
      font-size: 1.8em;
    }
    .swatch-wrapper {
      top: 100%;
      &--padding {
        &:not(:empty) {
          padding-bottom: 10em;
        }
      }
    }
  }
  .landing-page-config__switcher {
    display: flex;
    margin-bottom: 3.2em;
    border: 1px solid #ddd;
    border-radius: 3px;
    input[type="radio"] {
      @include offscreen;
      &:checked + label,
      &:checked + .validationMessage + label {
        background-color: #fff;
      }
    }
    label {
      flex: 1;
      text-align: center;
      font-size: 1.2em;
      padding: 0.5em;
      cursor: pointer;
      line-height: 1;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      @include medium;
      @include uppercase;
      &:not(:last-of-type) {
        border-right: 1px solid #ddd;
      }
    }
  }

  form.landing-page-edit-name {
    fieldset {
      label,
      input {
        display: inline-block;
      }
      label {
        margin-right: 0.8em;
        margin-bottom: 0;
        flex-shrink: 0;
      }
      input {
        max-width: none;
        &:invalid {
          border-color: $red;
        }
      }
    }
    .input-wrap {
      width: 26em;
      position: relative;
      &:first-of-type {
        margin-right: 2.5em;
      }
      .validationMessage {
        position: absolute;
        left: 0;
        bottom: -1.9em;
        font-size: 1.2em;
        width: 40em;
      }
    }
    .btn--link {
      text-decoration: underline;
      color: $black;
      border: none;
    }
  }
}

.landing-page-settings {
  &__wrap {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 7em);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 7em;

    .tooltip-container {
      input {
        max-width: 300px;
        display: inline-block;
        margin-right: 0.5em;
      }
      .fa,
      .far {
        font-size: 2em;
      }
      .fa-check {
        color: $green;
      }
      .fa-times {
        color: $red;
      }
      .fa-hourglass {
        color: $gray-400;
      }
    }
  }
  &__slug {
    cursor: pointer;
    text-align: center;
    i {
      font-size: 0.8em;
    }
  }
  &__ko {
    max-width: none;
    flex-grow: 1;
    word-break: break-word;
    padding-top: 5em;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    header {
      margin-bottom: 0;
    }
    .preview--landing-page {
      position: relative;
      .continually-landing-page {
        min-height: 50em;
        font-size: 90%;
        display: block;
        @include mq(min, $mq-xl) {
          display: flex;
          min-height: 75vh;
        }
        &--full {
          display: flex;
          .logo {
            position: static !important;
            max-width: 6em !important;
            margin-right: 1em;
          }
        }
        .logo {
          max-width: 10em;
          @include mq(max, $mq-xl) {
            img {
              max-height: 10em;
            }
            margin: 0 auto;
            position: relative;
            left: auto;
            top: auto;
          }
          @include mq(min, $mq-xl) {
            position: absolute;
            left: 3.2em;
            top: 3.2em;
          }
        }
        .cover {
          min-height: 31em;
        }
        figure,
        section {
          width: 100%;
          @include mq(min, $mq-xl) {
            width: 50%;
          }
        }
        section {
          .content {
            padding-bottom: 10em;
          }
        }
      }
      div.powered-by {
        display: block;
        font-size: 1.4em;
        background-color: $blue-dark;
        color: #fff;
        text-align: center;
        text-decoration: none;
        transition: background-color 0.2s ease-in-out;
        padding-bottom: 1.4em;
        div {
          padding: 1.4em 1.4em 0;
        }
        .faux {
          border-bottom: 1px solid $brand-primary;
        }
        &:hover {
          background-color: lighten($blue-dark, 10%);
        }
        @include mq(min, $mq-sm) {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  &__edit-domain {
    color: #999;
    margin-top: 0.5em;
    display: block;
    background: transparent;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    @include mq(min, $mq-lg) {
      margin-top: 0;
      display: inline;
    }
  }
}

.domain-status {
  .fa {
    color: #d8d8d8;
    &--ok {
      color: $brand-green;
    }
  }
}
