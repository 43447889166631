// CSS GRID

.gc.two-col {
    @include mq(min, $mq-sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 4em;
    }
}

.gc.three-col {
    @include mq(min, $mq-sm) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 4em;
    }
}

.flex-cols {
    display: block;
    @include mq(min, $mq-sm) {
        display: flex;
    }
    .col {
        flex: 1;
    }
    .col-half {
        flex: none;
        @include mq(min, $mq-sm) { width: 50%; }
    }
    .col-third {
        flex: none;
        @include mq(min, $mq-sm) { width: 33.33%; }
    }
    .col-two-third {
        flex: none;
        @include mq(min, $mq-sm) { width: 66.66%; }
    }
    .col-quarter {
        flex: none;
        @include mq(min, $mq-sm) { width: 25%; }
    }
}

/* With gutters */
@include mq(min, $mq-sm) {
    .flex-cols-gutters {
        margin-left: -$spacer * 2;
    }
    .flex-cols-gutters > .col {
        padding-left: $spacer * 2;
    }
}

/*
 _______  ___   _______    _______  ______    ___   ______
|       ||   | |       |  |       ||    _ |  |   | |      |
|    ___||   | |_     _|  |    ___||   | ||  |   | |  _    |
|   |___ |   |   |   |    |   | __ |   |_||_ |   | | | |   |
|    ___||   |   |   |    |   ||  ||    __  ||   | | |_|   |
|   |    |   |   |   |    |   |_| ||   |  | ||   | |       |
|___|    |___|   |___|    |_______||___|  |_||___| |______|
by Dave Rupert
Read More: https://daverupert.com/2017/09/breaking-the-grid/
*/

/*
 * Remove `min-width: auto` from Grid Items
 * Fixes overflow-x items.
 */
.fit-grid > * { min-width: 0; }

/* Apply max-width to Replaced Elements and Form controls */
.fit-grid img,
.fit-grid video,
.fit-grid audio,
.fit-grid canvas,
.fit-grid input,
.fit-grid select,
.fit-grid button,
.fit-grid progress { max-width: 100%; }

/* Make file and submit inputs text-wrap */
.fit-grid input[type="file"],
.fit-grid input[type="submit"] { white-space: pre-wrap; }

/* Fix Progress and Range Inputs */
.fit-grid progress,
.fit-grid input[type="range"] { width: 100%; }

/* Fix Number Inputs in Firefox */
@supports (--moz-appearance: none) {
  .fit-grid input[type="number"] { width: 100%; }
}