.continually-chat-team,
.continually-chat {
  .font-roboto & {
    @include regular-roboto;
    line-height: 1.2;
  }
  .loading-calendar-data {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #ffffff;
    opacity: 0.6;
    z-index: 10;
    .spinner {
      position: absolute;
      top: calc(50% - 1em);
      left: calc(50% - 1em);
      border: 1px solid #333333 !important;
      border-left: 1px solid transparent !important;
      @include spinner(2em, 1px);
      margin: 0 auto 1em;
    }
  }
  textarea {
    max-width: none;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .hint {
    margin-bottom: 1em;
  }
  .blank-state {
    padding: 0 3.2em;
    text-align: center;
    .emoji {
      font-size: 3.2em;
      margin-bottom: 0.25em;
    }
    h3 {
      font-weight: 500;
    }
    p {
      font-size: 1.8em;
    }
    a:not(.btn) {
      color: $black;
      &:hover {
        color: $black-light;
      }
    }
  }
  .continually-timeline {
    &--rounded {
      .message,
      .question-text,
      .message .btn,
      .rich-media-card,
      .post--restart .btn,
      .message form textarea,
      .message form input,
      .message--checkbox label,
      .message--radio label {
        border-radius: 25px !important;
      }
      .rich-media-card__figure {
        border-radius: 24px 24px 0 0;
      }
      .message--schedule .btn,
      .message--dropdown .btn,
      .message--fileupload .btn[type="submit"],
      .message--date .btn[type="submit"] {
        border-radius: 0 0 24px 24px !important;
      }
      .message--textarea.textarea--wide {
        textarea {
          border-radius: 25px 25px 0 0 !important;
        }
        .btn {
          border-radius: 0 0 27px 27px !important;
        }
      }
    }
    &--font-sm {
      .message,
      .question-text {
        padding: 1.7em;
      }
      .message:not(.message--schedule):not(.message--text-input):not(.message--textarea) form {
        margin-left: -1.7em;
        margin-right: -1.7em;
        margin-bottom: -1.7em;
      }
      .message__content,
      .question,
      textarea,
      label,
      input,
      select,
      button,
      .add-attendee,
      .message--minimal .btn {
        font-size: 13px !important;
        line-height: 16px;
      }
      .message__validation,
      .message--fileupload .btn--replace,
      .message--fileupload .btn--remove,
      .message--fileupload .btn--add-another {
        font-size: 11.5px !important;
        line-height: 13px;
      }
    }
    &--font-md {
      .message,
      .question-text {
        padding: 2em;
      }
      .message:not(.message--schedule):not(.message--text-input):not(.message--textarea) form {
        margin-left: -2em;
        margin-right: -2em;
        margin-bottom: -2em;
      }
      .message__content,
      .question,
      textarea,
      label,
      input,
      select,
      button,
      .add-attendee,
      .message--minimal .btn {
        font-size: 15px !important;
        line-height: 19px;
      }
      .message__validation,
      .message--fileupload .btn--replace,
      .message--fileupload .btn--remove,
      .message--fileupload .btn--add-another {
        font-size: 13.5px !important;
        line-height: 15px;
      }
    }
    &--font-lg {
      .message,
      .question-text {
        padding: 2em;
      }
      .message:not(.message--schedule):not(.message--text-input):not(.message--textarea) form {
        margin-left: -2em;
        margin-right: -2em;
        margin-bottom: -2em;
      }
      .message__content,
      .question,
      textarea,
      label,
      input,
      select,
      button,
      .add-attendee,
      .message--minimal .btn {
        font-size: 17px !important;
        line-height: 21px;
      }
      .message__validation,
      .message--fileupload .btn--replace,
      .message--fileupload .btn--remove,
      .message--fileupload .btn--add-another {
        font-size: 15.5px !important;
        line-height: 19px;
      }

      .message--textarea {
        button[type="submit"],
        input[type="submit"] {
          height: 2.3em !important;
        }
      }

      .pulldown--short .pulldown-select {
        height: 2.1em !important;
      }
    }
    input,
    textarea,
    select {
      .body--ios & {
        font-size: 16px !important;
      }
    }
  }
  .post {
    position: relative;
    margin-bottom: $spacer * 0.5;
    @include clearfix;
    max-width: 90%;
    clear: both;
    &--conditional-logic {
      .btn {
        color: white;
      }
    }
    &--full-width {
      max-width: none;
    }
    &__info {
      display: none;
    }
    .author {
      position: absolute;
      left: 0;
      top: 0.6em;
      width: 4.8em;
      .bio {
        margin-top: $spacer * 0.5;
      }
      .name {
        font-size: 1.2em;
        font-weight: 500;
        @include truncate;
      }
    }
    .author.author--agent-takeover {
      opacity: 1 !important;
      display: block !important;
      pointer-events: auto;
    }
    .avatar {
      @include avatar(18px, 12px, 18px);
      @include mq(min, 300px) {
        @include avatar(28px, 12px, 28px);
      }
    }
    .timestamp {
      font-size: 0.7em;
      margin-top: 0.8em;
      color: #999;
    }
    a.edit {
      display: block;
      margin-top: 0.5em;
      color: $black-lighter;
    }
    .skip--question {
      clear: both;
      text-align: left;
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
      a {
        text-decoration: underline;
        color: $black-light;
        font-size: 1.5em;
        margin-bottom: 0.833em;
        display: inline-block;
        &:empty {
          display: inline;
        }
      }
    }
    a.skip--snooze {
      top: calc(100% + 1em);
      font-size: 1.4em;
    }
    form.snooze_message {
      margin-bottom: 4em;
      position: relative;
    }
  }
  .post--admin {
    padding-left: 3.6em;
    @include mq(min, 300px) {
      padding-left: 4.8em;
    }
    .author {
      left: 0;
    }
    .message {
      float: left;
      clear: left;
      text-align: left;
      &--image {
        text-align: right;
      }
      &--fileupload,
      &--dropdown {
        background: white;
      }
    }
  }
  .post--restart {
    text-align: left;
    margin-bottom: 1.6em !important;
    form {
      clear: both;
      margin-bottom: 1em;
    }
    .btn {
      margin: 0;
      background-color: white !important;
      border-radius: 8px;
      margin-bottom: 0.5em;
      &:first-child {
        margin-right: 0.5em;
      }
      &--blue {
        background-color: $blue !important;
        border-color: $blue;
      }
    }
  }
  .post--user {
    float: right;
    .author {
      left: auto;
      right: 0;
      text-align: right;
    }
    .message {
      background-color: $brand-primary;
      border-color: rgba(#000, 0.1);
      color: #fff;
      float: right;
      clear: right;
      text-align: left;
    }
    .message__content {
      white-space: pre-line;
    }
    [data-role="item-status"] {
      text-align: right;
    }
  }
  .post--typing {
    margin-top: 1.2em;
  }
  .post--logic {
    background-color: #fff;
    border-top: 1px dashed #ddd;
    margin: 1.6em 0;
    padding: 3.2em;
    clear: both;
    text-align: center;
    max-width: 100%;
    &-full {
      padding: 2em 0;
    }
    &-no-border-top {
      border-top: 0;
    }
    .title {
      font-weight: 500;
      font-size: 1.8em;
      margin-bottom: 0.8em;
    }
    .post__text {
      font-size: 1.6em;
    }
    .conditions {
      margin: 1.6em 0;
    }
    .tag {
      font-size: 1.4em;
    }

    input[type="radio"] {
      display: none;
    }
  }
  [data-role="item-status"] {
    font-size: 1.6em;
    color: #ccc;
    clear: both;
    margin-bottom: 1em;
    margin-top: -0.3em;
    .tooltip__bubble {
      width: auto;
      white-space: nowrap;
    }
    .tooltip__content {
      font-size: 1em;
    }
  }
  .message__content {
    font-size: 1.85em;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .message {
    position: relative;
    background-color: #f1f1f1;
    color: #333;
    border-radius: 8px;
    margin-bottom: 1.2em;
    float: left;
    clear: left;
    padding: 1.7em;
    max-width: 100%;
    &--schedule,
    &--dropdown,
    &--fileupload,
    &--date {
      border: 1px solid #dcdcdc;
      .question-text {
        padding: 0;
      }
    }
    &__validation {
      clear: both;
      font-size: 13.5px;
      color: #d9534f;
      width: 100%;
      text-align: left;
      span {
        margin-top: 0.5em;
        display: inline-block;
      }
    }
    strong {
      font-weight: 700;
    }
    ul,
    ol {
      margin-left: 1em;
    }
    p,
    li {
      font-size: 1em;
    }
    form {
      margin: 1em -1.7em -1.7em;
    }
    a {
      word-break: break-word;
      word-wrap: break-word;
      color: $brand-primary;
    }
    img.ql-image {
      display: block;
      width: 100%;
      margin-bottom: 1em;
    }
  }
  .message--failed {
    opacity: 0.5;
  }
  .message--minimal {
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    padding-top: 0 !important;
    .btn {
      border-radius: 3px;
      padding: 0.5em 3em 0.5em;
      color: #fff;
      text-decoration: none;
      font-size: 1.85em;
      width: 100%;
      text-align: center;
      display: block;
      white-space: normal;
    }
  }
  .message--exception {
    background-color: #fff;
    h4 {
      color: $error;
      font-size: 1em;
      svg,
      strong {
        @include inline-block;
      }
      svg {
        fill: $error;
        margin-right: 0.2em;
        position: relative;
        top: -2px;
      }
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  .message--checkbox,
  .message--radio {
    background: none;
    padding: 0 !important;
    box-shadow: none;
    border: 0;
    margin: 0;
    .question-text {
      position: relative;
      background-color: #f1f1f1;
      color: #333;
      border-radius: 8px;
      margin-bottom: 1.2em;
    }
    fieldset {
      margin: 0;
      label {
        font-size: 1.85em;
        color: $brand-primary;
        text-align: center;
        display: block;
        margin: 0;
        position: relative;
        cursor: pointer;
        padding: 0.75em 1.6em 0.65em;
        border-radius: 7px;
        background: white;
        border: 1px solid;
        font-weight: normal;
        margin-bottom: 0.6em;
        word-break: break-word;
        word-wrap: break-word;
        white-space: normal;
        svg {
          position: absolute;
          z-index: 1;
          left: 0.75em;
          top: 50%;
          transform: translateY(-50%);
          fill: rgba($black, 0.15);
          @include wh(12px, 12px);
        }
        i {
          margin-right: 0.5em;
          min-width: 1.3em;
          flex-shrink: 0;
          text-align: center;
        }
      }
    }
    .multiple_choice,
    .likert,
    .gdpr {
      width: 100%;
      margin: 1em 0 0 0 !important;
    }
    fieldset.likert,
    fieldset.radio,
    fieldset.multiple_checkbox {
      &.horizontal {
        width: 113%;
      }
    }
    .multiple-choice-answer {
      display: block;
      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
      &.right {
        text-align: right;
      }
    }
    .form-actions {
      margin-bottom: 1em;
      .btn {
        padding: 0.75em 1.6em 0.75em;
        line-height: 1;
        border-radius: 7px;
      }
    }
    input[type="checkbox"],
    input[type="radio"] {
      display: none;
    }
    input[type="checkbox"]:checked + label,
    input[type="radio"]:checked + label,
    input[type="checkbox"]:checked + .validationMessage + label,
    input[type="radio"]:checked + .validationMessage + label {
      svg {
        fill: $brand-primary;
        path {
          stroke-width: 2;
        }
      }
    }
    input:disabled + label {
      cursor: not-allowed;
    }
    input:checked:disabled + label {
      background-color: transparent;
    }
  }
  .message--checkbox {
    fieldset {
      label {
        padding-left: 2.4em;
      }
    }
  }
  .answered .message--checkbox,
  .answered .message--radio,
  .message--checkbox form.answered,
  .message--radio form.answered {
    fieldset.vertical {
      input:checked:last-of-type + label {
        border-radius: 0 0 8px 8px;
      }
    }
  }
  .message--radio[data-answer-layout="horizontal"],
  .message--checkbox[data-answer-layout="horizontal"] {
    & + .skip--question {
      width: 113%;
    }
    .fieldset-inner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      label {
        flex: 0 auto;
        padding: 0.7em 1.2em;
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 1%;
        &:not(:last-child) {
          margin-right: 0.6em;
        }
      }
    }
    .multiple-choice-answer {
      display: flex;
      align-items: center;
      text-align: left;
    }
    @include mq(max, $mq-md) {
      form.answered {
        .fieldset-inner {
          label:last-of-type {
            border-radius: 0 0 8px 8px;
          }
        }
      }
    }
  }
  .message--checkbox[data-answer-layout="horizontal"] {
    .fieldset-inner {
      label {
        padding-left: 2em;
      }
    }
  }
  .message--radio,
  .message--schedule {
    ul {
      list-style: none;
      margin-left: 0;
    }
  }
  .message--schedule {
    width: 100%;
    background-color: #fff;
    padding: 2.5em 3.2em 1.7em !important;
    form {
      margin-top: 2em;
      margin-left: -3.2em;
      margin-right: -3.2em;
    }
    div.timezone {
      margin: 1.5em 3.2em 3.2em;
      .pulldown {
        display: inline-block;
        margin: 0 auto;
        width: 100%;
      }
      .pulldown-inner,
      .pulldown-select {
        height: auto;
        padding-left: 0.3125em;
        padding-right: 0.95em;
      }
      select {
        font-size: 1.4em;
      }
      .selected-timezone {
        display: block;
        margin: 0 auto;
        font-size: 1em;
        color: $black-light;
      }
    }
    .day-select {
      position: relative;
      text-align: center;
      margin-bottom: 1.6em;
      margin-right: 3.2em;
      margin-left: 3.2em;
      a {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        svg {
          fill: $brand-primary;
        }
      }
      a.previous {
        left: 0;
      }
      a.next {
        right: 0;
      }
      a.disabled {
        opacity: 0.15;
      }
      .day-label {
        color: $black-light;
        font-size: 1.85em;
        @include uppercase;
      }
    }
    .day-scroll {
      position: relative;
      &.day-scroll--active {
        &:before {
          position: absolute;
          z-index: 2;
          content: "";
          left: -1em;
          right: -1em;
          top: 0;
          height: 1em;
          background-image: linear-gradient(rgba(#fff, 1), rgba(#fff, 0));
          pointer-events: none;
        }
        &:after {
          position: absolute;
          z-index: 2;
          content: "";
          left: -1em;
          right: -1em;
          bottom: 0;
          height: 1em;
          background-image: linear-gradient(rgba(#fff, 0), rgba(#fff, 1));
          pointer-events: none;
        }
      }
    }
    .day-scroll--active {
      div.times {
        padding-bottom: 1.75em;
        max-height: 20em;
        overflow: auto;
        > span {
          font-size: 1.4em;
          font-weight: 500;
          text-transform: uppercase;
          margin-top: 1em;
          margin-bottom: 1em;
          display: block;
        }
      }
    }
    div.times,
    fieldset.fallback {
      margin: 0;
      padding-top: 1.5em;
      margin-top: 1em;
      label {
        font-size: 1.85em;
        border-radius: 2px;
        border: 1px solid $brand-primary;
        padding: 0.5em 0 0.3em;
        position: relative;
        cursor: pointer;
        text-align: center;
        margin: 0 0 0.25em;
        background-color: #fff;
        color: $brand-primary;
        font-weight: 400;
        display: block;
        max-width: 99%;
        small {
          font-size: 70%;
        }
      }
      input[type="checkbox"],
      input[type="radio"] {
        display: none;
      }
      label.selected {
        background-color: $brand-primary;
        color: #fff;
      }
      input:disabled + label {
        cursor: not-allowed;
        opacity: 0.25;
      }
      input:checked:disabled + label {
        opacity: 1;
      }
    }
    fieldset.fallback {
      padding-top: 0;
      margin-bottom: -1em;
    }
    fieldset.textarea {
      margin: 1em 0 2em;
    }
    a.show-more-times {
      display: block;
      text-align: center;
      text-decoration: none;
      color: $black-lighter;
      padding: 1em;
      font-size: 1.3em;
    }
    fieldset.propose-different-time {
      margin: 0 3.2em 1.6em;
      label {
        font-size: 1.6em;
        margin-bottom: 0;
        color: #000;
        font-weight: 500;
      }
      textarea {
        font-size: 1.85em;
        border: none;
        border-radius: 0 !important;
        padding-left: 0;
        padding-right: 0;
        max-width: none;
        resize: none;
        height: auto;
        background-color: transparent;
        border-bottom: 1px solid $black;
      }
    }
    fieldset.capture-email-address {
      margin: 1em 3.2em 1.6em;
      label {
        font-size: 1.6em;
        margin-bottom: 0;
        color: #000;
        font-weight: 500;
      }
      input[type="email"] {
        font-size: 1.85em;
        border: none;
        border-radius: 0 !important;
        padding: 0.8em 0 0.6em;
        max-width: none;
        resize: none;
        height: auto;
        background-color: transparent;
        border-bottom: 1px solid $black;
      }
    }
    .schedule-demo__confirmation {
      text-align: center;
      padding: 1.6em;
      margin-top: -3.2em;
      position: relative;
      &.summary-panel {
        padding-top: 3.4em;
        .avatar-wrap {
          margin: 0 auto;
          max-width: 4.3em;
          position: relative;
          .check {
            position: absolute;
            z-index: 2;
            left: 0;
            top: 0;
            background-color: $blue;
            color: #fff;
            width: 1.6em;
            height: 1.6em;
            line-height: 1.6em;
            border-radius: 100px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .avatar {
          @include avatar(4.3em, 1.6em, 100px);
        }
      }
      .heading {
        text-transform: uppercase;
        font-size: 1.4em;
        margin: 0 2em 1.5em;
        font-weight: 500;
      }
      .img--calendar {
        margin: 0 auto;
        max-width: 5em;
      }
      .chosen-time {
        font-size: 1.6em;
        margin-top: 1em;
        margin-bottom: 1em;
        color: #000;
        font-weight: 500;
      }
      .summary {
        margin-top: 1em;
        text-align: center;
        font-size: 1.6em;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
        hyphens: none;
      }
      .link--back {
        position: absolute;
        left: 1em;
        top: 1em;
        font-size: 1.6em;
        text-decoration: none;
        cursor: pointer;
        color: $black;
        &:hover {
          color: $brand-green;
        }
      }
      .link--change {
        text-transform: uppercase;
        font-size: 1.4em;
        color: $black-light;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: $brand-green;
        }
      }
    }
  }
  .message--lead-typing {
    background-color: #ffffff !important;
  }
  .message--typing {
    span {
      height: 4px;
      width: 4px;
      float: left;
      margin: 0 1px;
      background-color: $black-light;
      display: block;
      border-radius: 50%;
      opacity: 0.4;
      @for $i from 1 through 3 {
        &:nth-of-type(#{$i}) {
          animation: 1s blink infinite ($i * 0.3333s);
        }
      }
    }
  }
  .message--textarea,
  .message--text-input {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0 !important;
    .question-text {
      float: left;
      display: inline-block;
      background-color: #f1f1f1;
      color: #333;
      border-radius: 8px;
      margin-bottom: 0.875em;
    }
    form {
      clear: left;
      float: left;
      margin: 0;
      width: 100%;
      position: relative;
    }
    fieldset {
      margin: 0;
    }
    textarea,
    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="tel"] {
      font-size: 16px;
      border-radius: 5px;
      padding: 0.9em 3.5em 0.8em 1em;
      max-width: none;
      resize: none;
      height: auto;
      max-height: 35em;
      background: none;
      border: 1px solid #d5d5d5;
      &:focus {
        border-color: $black;
      }
    }
    input[type="submit"],
    button[type="submit"] {
      position: absolute;
      right: 0.35em;
      width: auto;
      border-radius: 5px;
      padding: 0;
      width: 2.85em;
      svg {
        fill: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        margin: 0;
      }
      span {
        display: none;
      }
    }
  }
  .message--textarea {
    input[type="submit"],
    button[type="submit"] {
      bottom: 0.35em;
      height: 2.4em;
      span {
        display: none;
      }
    }
    textarea {
      min-height: 2.8em;
    }
    input[type="number"] {
      &::-webkit-inner-spin-button {
        -webkit-appearance: inner-spin-button;
        opacity: 1;
      }
    }
    &.textarea--wide {
      textarea {
        max-height: none;
        min-height: 8em;
        padding-right: 0.8em;
        border-radius: 5px 5px 0 0;
      }
      .btn {
        border-radius: 0 0 5px 5px;
      }
      input[type="submit"],
      button[type="submit"] {
        position: relative;
        width: 100%;
        bottom: 0;
        left: auto;
        right: auto;
        span {
          display: block;
        }
        svg {
          display: none;
        }
      }
    }
  }
  .message--text-input {
    input[type="submit"],
    button[type="submit"] {
      top: 0.28em;
      bottom: 0.28em;
    }
  }
  .message--white {
    background: white;
  }
  .message--dropdown {
    .pulldown {
      margin: 0 1.5em;
    }
    .pulldown-inner {
      height: 4.4em;
    }
    select {
      font-size: 1.85em;
      height: 2.5em;
    }
  }
  .message--answer.skipped {
    form {
      display: none;
    }
  }
  .message--recognise-user {
    form {
      position: relative;
    }
    .recognised {
      position: absolute;
      left: -4.5em;
      width: 3em;
      height: 3em;
      top: 1em;
      border: 0.2em solid $brand-green;
      border-radius: 3em;
      .icon {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -40%);
        svg {
          fill: $brand-green;
          @include wh(1.6em);
        }
      }
    }
  }
  .message--fileupload,
  .message--fileuploaded {
    .filename {
      white-space: normal;
      word-break: break-word;
      word-wrap: break-word;
    }
  }
  .message--fileupload {
    input[type="file"] {
      display: none;
    }
    .btn--upload-input {
      text-align: center;
      display: block;
      border: 1px dashed #ddd;
      color: $black-light;
      padding: 1em;
      font-size: 1.6em;
      @include uppercase;
      font-weight: 500;
      cursor: pointer;
      @include transition;
      margin-bottom: 0.5em;
      &:hover {
        border-color: $black;
        color: $black;
      }
    }
    .form-inner {
      padding: 1.5em;
    }
    .btn--add-another {
      border-color: $black-lighter;
      color: $black-light;
      background-color: #fff !important;
    }
    .btn--replace {
      background-color: $grey;
      border-color: #d2d2d2;
      background-color: #fff !important;
      color: $black;
      font-weight: 500;
      border-radius: 2px;
      font-size: 1.3em;
    }
    .btn--remove {
      background-color: $red !important;
      border-color: $red;
      color: #fff;
      font-weight: 500;
      border-radius: 2px;
      font-size: 1.3em;
    }
    .ready-to-upload {
      text-align: center;
      padding: 2em 1.5em;
      border-radius: 4px;
      border: 1px solid #ddd;
      background-color: #fff;
      margin-bottom: 2em;
      i {
        font-size: 4em;
      }
      .filename {
        font-size: 1.4em;
        margin: 1em 0;
      }
      .btn {
        margin: 0.25em;
      }
      .validationMessage {
        display: block;
        background-color: #fff;
        color: $error;
        font-size: 1.6em;
        padding: 0;
        margin-bottom: 1em;
        text-align: center;
      }
    }
  }
  .message--fileuploaded {
    .file-uploaded {
      text-align: center;
      padding: 1.5em;
      i {
        font-size: 4em;
      }
      .filename {
        font-size: 1.6em;
        margin-top: 1em;
      }
      .btn {
        margin-top: 1em;
      }
    }
    progress {
      display: block;
      width: 100%;
      margin-top: 0.5em;
    }
    progress[value] {
      -webkit-appearance: none;
      appearance: none;
      height: 0.6em;
    }
    progress[value]::-webkit-progress-bar {
      background-color: rgba(#000, 0.2);
      border-radius: 20px;
    }
    progress[value]::-webkit-progress-value {
      background-color: #fff;
      border-radius: 20px;
    }
  }
  .message--notes {
    .question-text {
      background: #fefae7;
      margin-bottom: 0;
      &__note {
        color: #f7be54;
        font-style: italic;
        margin-top: 0.5em;
        margin-bottom: 0;
        font-size: 1.3em;
      }
    }
  }
  .tooltip__bubble {
    width: 20em;
    white-space: normal;
    border-radius: 5px;
    word-break: normal;
    overflow-wrap: normal;
    word-wrap: normal;
    hyphens: none;
    font-size: 1em;
  }
  .tooltip__content {
    font-size: 1.6em;
  }
  .question-text {
    padding: 1.7em;
    .question {
      font-size: 1.85em;
    }
    .description:not(:empty) {
      margin-top: 0.5em;
      font-size: 1.4em;
      font-weight: 400;
    }
  }
  form.disabled,
  form.answered {
    .form-actions {
      display: none;
    }
    label {
      pointer-events: none;
    }
  }
  fieldset.form-actions {
    svg {
      display: none;
    }
  }
  input[type="submit"],
  button[type="submit"],
  button[type="button"] {
    font-size: 1.85em;
    border-radius: 0 0 7px 7px;
    border-color: transparent;
    background-color: $brand-primary;
    color: #fff;
    padding: 0.75em 2em 0.5em;
    width: 100%;
    text-align: center;
    display: block;
    &.btn__choose-time {
      &:disabled {
        opacity: 0.3;
        pointer-events: none;
        cursor: not-allowed;
        background-color: transparent !important;
        color: $black-lighter;
        border-top-color: #dcdcdc;
      }
    }
  }
  .btn-resume-conversation {
    font-size: 1em;
    border-radius: 0 0 8px 8px;
    border-color: transparent;
    border-top-color: #dcdcdc;
    background-color: transparent;
    color: $brand-primary;
    padding: 0.75em 2em;
    width: 100%;
    text-align: center;
    display: block;
  }
  .day-select-control,
  .day-scroll {
    display: none;
    position: relative;
    &.active {
      display: block;
    }
  }
  .chat--notification {
    background-color: white;
    border-top: 1px dashed #ddd;
    margin: 1.6em 0;
    clear: both;
    word-break: break-word;
    word-wrap: break-word;
  }
  .chat--notification-last {
    border-bottom: 1px dashed #ddd;
  }
  .chat--notification__join-leave {
    padding: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .avatar {
      @include avatar(22px, 12px, 22px);
      margin-right: 1em;
      min-width: 22px;
    }
    .text {
      font-size: 1.6em;
      color: $black-light;
      &.chat--notification__date {
        font-weight: 500;
        font-size: 13px;
        color: rgba(24, 46, 89, 0.5);
        text-align: center;
        line-height: 18px;
        padding: 4px 18px 4px;
        background: rgba(24, 46, 89, 0.1);
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
        border-radius: 13px;
      }
      strong {
        font-weight: 700;
      }
    }
  }
}

.message--agent-takeover {
  background-color: #fff;
  transition: 1s;
  position: relative;
  border-top: 1px solid #eaeaea;
  .continually-conversation-footer.hidden &,
  .continually-conversation-footer.hidden-live-chat-object & {
    pointer-events: none;
    transform: translateY(100%);
    opacity: 0;
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 1em 0;
  }
  .offline-message {
    order: 1;
    width: 100%;
    display: none;
    padding: 1.5em 0;
    text-align: center;
    position: absolute;
    bottom: 101%;
    z-index: 99;
    background: rgba(255, 255, 255, 0.8);
    p {
      color: $black-light;
      font-size: 1.4em;
      margin: 0;
    }
  }
  textarea {
    flex: 1;
    order: 2;
    margin-right: 1em;
    margin-left: 0.5em;
    font-size: 16px;
    border: none;
    border-radius: 1.6em;
    resize: none;
    padding: 0.8em 1em 0.7em 1em;
    max-height: 10em;
    overflow: hidden;
    overflow-y: auto;
    -webkit-appearance: none;
    font-family: $font-family-base;
    &:focus + .offline-message {
      display: block;
    }
  }
  button {
    order: 3;
    background-color: $brand-green;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    margin-right: 1.6em;
    i {
      font-size: 1.8em;
      margin-left: -0.1em;
    }
  }
  button:disabled {
    background-color: $grey !important;
  }
}

// Animation
.continually-chat-team,
.continually-chat {
  .post--animated {
    animation-name: fadeInUp;
    animation-duration: 0.25s;
    animation-fill-mode: both;
  }
}

// Hide multiple avatars on multiple messages from the same person
.continually-chat-team,
.continually-chat {
  .post {
    margin-bottom: 0 !important;
    &--private {
      .message {
        background: #fefbe9;
        &--image {
          position: relative;
          overflow: visible;
          margin-bottom: 1em;
          padding: 1.5em;
          border: 1px solid #dcdcdc;
          img {
            border-radius: 6px;
          }
        }
      }
      .post__info {
        display: block;
        clear: both;
        text-align: left;
        color: #ccc;
        margin-top: -0.3em;
        margin-right: 0.2em;
      }
    }
    &__automation-btn {
      font-size: 1.1em;
      &.btn--grey {
        background-color: #e5e5e5;
        background-image: none;
        color: #4a4a4a;
        border: 1px solid #e5e5e5;
        box-shadow: none;
        padding-left: 1.4em;
        padding-right: 1.4em;
        &:hover {
          background-color: darken(#e5e5e5, 5%);
        }
      }
    }
  }
  .post--admin {
    & + .post--admin {
      .author {
        opacity: 0;
        display: none;
        pointer-events: none;
      }
    }
  }
  .post--user {
    & + .post--user {
      .author {
        opacity: 0;
        display: none;
        pointer-events: none;
      }
    }
    .flex.clear-both {
      justify-content: flex-end;
    }
  }
}

// Rich media

.continually-chat-team,
.continually-chat {
  .message .media-container {
    margin-top: 1em;
    a.rich-media-card {
      margin-top: 0;
    }
  }
  .message--video,
  .message--image,
  .message--link {
    padding: 0 !important;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  .message--image {
    background-color: transparent;
    border-radius: 8px;
    overflow: hidden;
    img {
      display: inline-block;
    }
    .zoomable {
      cursor: zoom-in;
    }
  }
  .message--link {
    font-size: 1.7em;
    margin-bottom: 0.706em;
    background-color: transparent;
    width: 100%;
    a.rich-media-card {
      margin-top: 0;
    }
    .btn {
      font-size: 1em;
    }
  }
  .message--loading {
    float: right;
    display: inline-block;
    background-color: #f1f1f1;
    color: #333;
    border-radius: 8px;
    padding: 1em 1.5em;
    @include italic;
  }
  .message--video {
    width: 100%;
    border-radius: 0;
    background: transparent;
    .media-container {
      margin-top: 0;
      iframe,
      video {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }
  .message--audio {
    width: 100%;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .continually-audio {
    display: flex;
    align-items: center;
    &--show-volume-range {
      .continually-audio__duration,
      .continually-audio__download {
        display: none;
      }
      .continually-audio__volume-range {
        display: inline-block;
      }
    }
    &__progress {
      flex: 1 auto;
      margin: 0 1em;
      .custom-progress {
        display: block;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        -webkit-appearance: none;
        appearance: none;
        height: 7px;
        border-radius: 16px;
        background: #eaeaea;
        &--blue {
          background: rgba(#878787, 0.26);
          .custom-progress__bar {
            background: $blue;
          }
        }
        &__bar {
          border-radius: 16px;
          position: relative;
          height: 100%;
          max-width: 100%;
        }
      }
    }
    &__btn {
      font-size: 1.5em;
      margin: 0 0.1em;
      i {
        color: $black;
      }
    }
    &__duration {
      font-size: 1.2em;
      margin-right: 1em;
    }
    &__volume-range {
      display: none;
      cursor: pointer;
      /*removes default webkit styles*/
      -webkit-appearance: none;
      /*fix for FF unable to apply focus style bug */
      border: none;
      background: none;
      /*required for proper track sizing in FF*/
      width: 50px;
      background: linear-gradient(to right, $black 0%, $black 50%, #d5d5d5 50%, #d5d5d5 100%);
      border-radius: 3px;
      font-size: 14px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      width: 50px;
      height: 6px;
      border: none;
      border-radius: 3px;
    }

    input[type="range"]::-moz-range-track {
      width: 50px;
      height: 6px;
      border: none;
      border-radius: 3px;
    }
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: 1px solid $black;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: #fff;
      margin-top: -0.3em;
    }
    input[type="range"]:focus {
      outline: none;
    }
    input[type="range"]::-moz-range-thumb {
      border: 1px solid $black;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      background: #fff;
    }

    /*hide the outline behind the border*/
    input[type="range"]:-moz-focusring {
      outline: none;
      outline-offset: -1px;
    }

    input[type="range"]::-moz-focus-outer {
      border: 0;
    }

    input[type="range"]::-ms-track {
      width: 50px;
      height: 6px;

      /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
      background: transparent;

      /*leave room for the larger thumb to overflow with a transparent border */
      border-color: transparent;
      border-width: 6px 0;

      /*remove default tick marks*/
      color: transparent;
    }
    input[type="range"]::-ms-fill-lower {
      background: rgba($black, 0.5);
      border: none;
      border-radius: 10px;
    }
    input[type="range"]::-ms-fill-upper {
      border-radius: 10px;
      background: rgba($black, 0.5);
      border: none;
    }
    input[type="range"]::-ms-thumb {
      border: 1px solid $black;
      @include wh(2em, 2em);
      border-radius: 50%;
      background: #fff;
    }
  }

  a.external-url {
    font-size: 0.8em;
    margin-top: 0.6em;
    display: inline-block;
    color: $black-light;
    text-decoration: none;
    &:hover {
      color: $black;
    }
  }

  a.rich-media-card.rich-media-card--js-preview {
    width: 100%;
    max-width: none;
    font-size: 1.7em;
    margin-top: 0;
  }
}

// Shared with conversation builder preview on object
a.rich-media-card {
  font-size: 1em;
  background-color: #fff;
  border: 1px solid #e1e8ed;
  border-radius: 3px;
  display: block;
  margin-top: 1em;
  @include clearfix;
  text-decoration: none;
  color: $black !important;
  @include transition(border-color);
  &:hover {
    border-color: $brand-primary;
    .rich-media-card__summary h2 {
      text-decoration: underline;
    }
  }
  &:focus {
    outline: none;
  }
  .rich-media-card__figure {
    background-color: #fff;
    background-size: cover;
    background-position: 50%;
    border-radius: 3px 3px 0 0;
    @include aspect-ratio(1200, 630);
    background-position: 50%;
    position: relative;
  }
  .rich-media-card__summary {
    padding: 1.2em;
    h2 {
      font-size: 1.3em;
      font-weight: 500;
      margin: 0 0 0.25em;
      word-break: normal;
      overflow-wrap: normal;
      word-wrap: normal;
      hyphens: none;
    }
    p.desc {
      font-size: 1em;
      line-height: 1.3;
      margin: 0 0 0.25em;
    }
    .site-name {
      @include inline-block;
      color: $black-light;
      font-size: 0.9em;
      margin: 1em 1em 1em 0;
      img,
      .label {
        @include inline-block;
      }
      img {
        margin-right: 0.5em;
        max-width: 1.2em;
        display: inline-block;
      }
    }
    .btn--cta {
      @include inline-block;
      font-size: 0.8em !important;
      margin: 1em 1em 1em 0;
    }
  }
}

.media-container--video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  min-width: 13em;
}

.media-container iframe,
.media-container video {
  width: 100%;
  height: 100%;
}

.media-container--video iframe,
.media-container--video video {
  position: absolute;
  width: 100%;
  height: 100%;
}

.continually-chat .emoji {
  margin-bottom: 0;
}

.add-attendee {
  font-size: 1.6em;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
  font-weight: 500;
  a {
    color: $brand-primary !important;
    text-decoration: underline;
  }
}

.bot-rating-item {
  .fieldset-inner {
    label {
      padding: 0.4em 0.3em !important;
      align-items: center;
      margin-right: 0.4em !important;
      &:last-of-type {
        margin-right: 0 !important;
      }
      i {
        margin-bottom: 0.4em;
      }
    }
  }
}
