.body--branding {
  .link--branding {
    a {
      @include inline-block;
      font-size: 0.8em;
      padding: 0.5em 1em;
      background-color: #f9f9f9;
      border-radius: 5px;
      border: 1px solid #ddd;
      font-family: $font-family-monospace;
      color: #141414;
      text-decoration: none;
      span {
        margin-right: 1em;
      }
      span,
      i {
        @include inline-block;
      }
    }
  }
  .branding-config {
    @include clearfix;
    flex: 1;
    fieldset.file {
      figure {
        position: relative;
        display: inline-block;
        background-color: $white;
        background-repeat: repeat;
        &.file__sidebar-logo-wrap {
          border: 1px solid #b3b3b3;
          width: 50px;
          text-align: center;
          padding-left: 9px;
          padding-right: 9px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      input[type="file"] {
        @include offscreen;
      }
      .btn--upload-input {
        text-align: center;
        display: inline-block;
        border: 1px dashed $black-lighter;
        color: $black-lighter;
        padding: 1.5em;
        font-size: 1.4em;
        cursor: pointer;
        margin-bottom: 0.5em;
        @include transition;
        @include uppercase;
        @include medium;
        &:hover {
          border-color: $black;
          color: $black;
        }
        i {
          font-size: 2.4em;
          margin-bottom: 0.2em;
        }
        span {
          display: block;
        }
      }
    }
    fieldset.file--logo {
      img {
        max-height: 100px;
      }
    }
    fieldset.file--cover-image {
      img {
        max-height: 6em;
      }
    }
    .selected-cover-image {
      position: relative;
      display: block;
      background-color: $white;
      background-repeat: repeat;
      text-align: center;
      img {
        max-height: 15em;
        display: inline-block;
      }
    }
    fieldset.file--secondary-image {
      img {
        max-height: 3em;
      }
    }
    fieldset.btn-icon {
      input[type="radio"] {
        @include offscreen;
        &:checked + label,
        &:checked + .validationMessage + label {
          background-color: #fff;
        }
      }
      .options {
        @include clearfix;
        label {
          float: left;
          padding: 0.7em 0.8em 0.5em;
          display: inline-block;
          border: 1px solid #ddd;
          border-right: none;
          cursor: pointer;
          font-size: 1.4em;
          &:first-of-type {
            border-radius: 3px 0 0 3px;
          }
          &:last-of-type {
            border-radius: 0 3px 3px 0;
            border-right: 1px solid #ddd;
          }
        }
      }
    }
    .btngroup {
      margin: $spacer-y 0;
      a {
        font-size: 1.4em;
        display: inline-block;
        margin-right: $spacer-x;
        color: $red;
        font-weight: 500;
      }
    }
    h3 {
      @include regular;
      font-size: 1.8em;
    }
  }
  .branding-preview {
    flex: 0.3 auto;
    &__sidebar {
      margin-top: 16.7em;
      width: 37em;
      position: relative;
      margin-left: auto;
      box-shadow: 0 -1px 7px 0 rgba(0, 0, 0, 0.2);
      border-radius: 0 0 5px 5px;
      pointer-events: none;
      &--header {
        border-radius: 5px;
        .continually-conversation-header-team {
          background-image: linear-gradient(135deg, #6b6ad2 0%, #1b50d5 100%);
          margin-bottom: 7em;
        }
      }
      .continually-container {
        position: static;
        font-size: 80%;
      }
      .continually-conversation-body {
        min-height: 100px;
      }
      .continually-conversation-footer-branding {
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      .icon {
        max-width: 30px;
        margin-bottom: 10px;
        img {
          max-width: 100%;
          max-height: 30px;
        }
      }
      .title {
        font-family: $font-family-sans-serif;
        font-size: 21px;
        color: #fff;
        margin-bottom: 5px;
      }
      .desc {
        font-family: $font-family-sans-serif;
        font-size: 16px;
        color: rgba(#fff, 0.7);
      }
      .continually-close-button {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.15);
        color: #fff;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
        border-radius: 0 4px 0 4px;
        transition: all 0.2s ease-in-out;
        svg {
          width: auto;
          height: auto;
        }
      }
    }
    &__email {
      margin-top: 8em;
      width: 37em;
      margin-left: auto;
      height: 180px;
      background: #fafafa;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 200px;
        max-height: 60px;
      }
    }
    .caption {
      width: 24em;
      margin-left: auto;
      margin-top: 1em;
    }
  }
}
