body.body--choose-template {
  @include mq(max, $mq-md) {
    background: white;
    min-height: auto;
    height: 100%;
    .main,
    .app--content,
    .choose-template,
    .choose-template__container,
    .choose-template__sidebar,
    .select-template {
      display: flex;
      flex-direction: column;
      flex: 1 auto;
      height: 100%;
    }
  }
}

.choose-template {
  &__header {
    h1 {
      @include regular;
      font-size: 2.8em;
      color: $black;
      margin: 0.2em 0 0.33em;
    }
    h2 {
      @include regular;
      color: #585858;
      font-size: 1.8em;
      margin-bottom: 1em;
      line-height: 1.38;
    }
  }
  &__header-in {
    text-align: center;
    margin-bottom: 4em;
    @include mq(min, $mq-md) {
      width: 34em;
      text-align: left;
      margin-bottom: 0;
    }
  }
  &__header,
  &__container {
    padding-right: 2.5em;
    padding-left: 2.5em;
    @include mq(min, $mq-md) {
      display: flex;
      padding-right: $spacer * 2;
      padding-left: $spacer * 2;
      margin-left: auto;
      margin-right: auto;
      max-width: 1640px;
    }
  }
  &__container {
    position: relative;
    @include mq(max, $mq-md) {
      padding-right: 0;
    }
  }
  &__preview {
    display: none;
  }
  .skip-to-dashboard {
    margin-top: 2em;
    margin-bottom: 2em;
    margin-left: auto;
    color: #838383;
    font-size: 1.6em;
    font-weight: 500;
    display: none;
    @include mq(min, $mq-md) {
      margin-top: 0;
      margin-bottom: 0;
      display: block;
    }
    &:hover {
      color: $black;
    }
  }
}

.choose-template--onboarding {
  padding-top: 3.2em;
  @include mq(min, $mq-md) {
    .choose-template__sidebar {
      width: 28em;
      margin-right: 2em;
    }
    .choose-template__preview {
      flex: 1;
      display: block;
    }
  }
  .continually-sidebar {
    background-color: #fff;
    position: absolute;
    z-index: 3;
    top: 1.6em;
    right: 1.6em;
    bottom: 1.6em;
    border-radius: 5px;
    box-shadow: 0 -1px 14px 0 rgba(0, 0, 0, 0.2);
    animation-name: cxappear;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    width: 0;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    &[data-cx-open="true"] {
      overflow: auto;
      opacity: 1;
      width: 100%;
      @include mq(min, 450px) {
        width: 370px;
      }
    }
    &--mobile {
      position: absolute;
      height: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      box-shadow: 0 -1px 14px 0 rgba(0, 0, 0, 0.2);
      @include mq(min, $mq-md) {
        display: none;
      }
      .continually-timeline {
        .message {
          input,
          textarea,
          select {
            font-size: 16px !important;
          }
        }
      }
    }
    .refresh-btn {
      position: absolute;
      z-index: 99;
      left: 50%;
      transform: translateX(-50%);
      bottom: 6.5em;
    }

    .conversation-preview-feedback {
      display: none;
      a {
        display: block;
      }
    }
  }
  .choose-template__sidebar {
    @include mq(min, $mq-md) {
      margin-bottom: 3.2em;
    }
    .btn-wrap {
      display: block;
      margin-bottom: 1em;
    }
    p.embed-code-hint {
      margin: 1.5em auto;
      max-width: 13em;
      text-align: center;
      font-size: 1.4em;
    }
    .customise-this-conversation {
      background-color: transparent;
    }
  }
  .select-template {
    position: relative;
    overflow: hidden;
    @include mq(min, $mq-md) {
      overflow: visible;
    }
    &__title {
      display: none;
      @include mq(min, $mq-md) {
        display: block;
      }
    }
    &__buttons {
      display: block;
      overflow: visible;
      @include mq(max, $mq-md) {
        --n: 1;
        flex: 1 auto;
        display: flex;
        width: 100%; // fallback
        width: calc(var(--n) * 100%);
        transform: translateX(calc(var(--i, 0) * (-18% - 3em)));
      }
    }
    &__button {
      margin-bottom: 1em;
      text-decoration: none;
      background-color: rgba(#fff, 0.4);
      border-radius: 3px;
      color: $black;
      position: relative;
      @include transition;
      display: flex;
      flex-direction: column;
      flex: 1 0 245px;
      margin-right: 3em;
      max-width: 18%;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      @include mq(min, $mq-md) {
        display: block;
        margin-right: 0;
        border: 1px solid rgba(black, 0.5);
        max-width: none;
      }
      &.active {
        .select-template__button-in {
          align-items: center;
          border: 1px solid $blue-secondary;
          border-radius: 5px;
          @include mq(min, $mq-md) {
            align-items: flex-start;
            border: none;
          }
        }
        .select-template__start-btn {
          @include mq(max, $mq-md) {
            display: block;
          }
        }
        .content {
          .desc {
            display: block;
          }
        }
        .btn-wrap {
          display: block;
        }
      }
      &.active,
      &:hover {
        background-color: #fff;
        border: none;
        outline: none;
        @include mq(min, $mq-md) {
          border: 1px solid black;
          box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
        }
      }
      .icon {
        flex: 1;
        text-align: center;
        margin-bottom: 2em;
        @include mq(min, $mq-md) {
          margin-right: 1.6em;
          margin-bottom: 0;
        }
        figure {
          img {
            max-height: 5em;
          }
        }
      }
      .content {
        flex: 4;
        min-height: 10em;
        margin-bottom: 1em;
        @include mq(min, $mq-md) {
          min-height: auto;
        }
      }
      .content .name {
        font-size: 2em;
        @include mq(min, $mq-md) {
          font-size: 1.6em;
        }
        @include medium;
      }
      .content .desc {
        font-size: 1.8em;
        margin-top: 1em;
        @include mq(min, $mq-md) {
          margin-top: 0.3em;
          display: none;
          font-size: 1.4em;
        }
      }
      .btn-wrap {
        margin-bottom: 0;
        padding: 0 1em 1em;
        margin-top: auto;
        @include mq(min, $mq-md) {
          display: none;
          margin-top: 0;
        }
      }
    }
    &__button-in {
      padding: 2em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      text-align: center;
      border: 1px solid rgba(black, 0.5);
      margin-bottom: 6em;
      @include mq(min, $mq-md) {
        flex-direction: row;
        text-align: left;
        border: none;
        margin-bottom: 0;
      }
    }
    &__preview-btn {
      background: rgba($blue-secondary, 0.07);
      font-size: 1.8em;
      color: $blue-secondary;
      width: 100%;
      &:hover {
        color: white;
        background: $blue;
      }
    }
    &__start-btn {
      @include mq(max, $mq-md) {
        padding: 1em 1.6em 0.9em;
        font-size: 1.8em;
        display: none;
      }
    }
    .object-tip {
      width: 100%;
      font-style: italic;
      padding-left: 1.5em;
      padding-right: 1.5em;
      display: none;
      @include mq(min, $mq-md) {
        display: block;
      }

      li {
        font-size: 1em;
        margin-left: 1em;
      }
    }
  }
  .ui-browser--url-input {
    position: relative;
    .browser-chrome {
      height: auto;
      background-color: $blue-dark;
      border-radius: 5px 5px 0 0;
      .add-website {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        padding: 1em 1.5em;
        border-right: 1px solid rgba(#979797, 0.2);
        .label {
          font-size: 1.6em;
          color: #fff;
          flex-shrink: 0;
          @include medium;
          @include inline-block;
          margin-right: 1em;
          @include font-smoothing;
        }
        form {
          position: relative;
          flex: 1;
          @include inline-block;
          background-color: #fff;
          border-radius: 4px;
          fieldset {
            @include inline-block;
            margin: 0;
            width: 100%;
          }
          input {
            border-color: #fff;
            max-width: none;
            padding-right: 3.6em;
            &:focus {
              border-color: $brand-primary;
              & + .helper--enter-website {
                display: none;
              }
            }
          }
          .btn {
            position: absolute;
            z-index: 1;
            top: 0.5em;
            right: 0.5em;
            bottom: 0.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 1.6em;
            }
          }
        }
        .helper--enter-website {
          position: absolute;
          z-index: 2;
          top: 100%;
          width: 24em;
          text-align: center;
          padding-top: 10em;
          pointer-events: none;
          svg {
            position: absolute;
            left: -2em;
            top: 2em;
            @include wh(10.2em, 4.6em);
            transform: rotate(-70deg);
          }
          p {
            @include caveat;
            font-size: 2.4em;
            margin: 0;
            line-height: 1.2;
          }
        }
      }
    }
  }
  .ui-browser__inner {
    height: 80vh;
    overflow: hidden;
    position: relative;
    background: white;
    background-size: cover;
    .website-screenshot {
      background-position: 0 0;
      background-size: cover;
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    .continually-button-container {
      position: absolute;
      z-index: 2;
      bottom: 20px;
      right: 20px;
    }
    .continually-button {
      display: flex;
      align-items: center;
      width: 62px;
      height: 62px;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.2);
      transition: transform 0.2s;
      background-color: $brand-primary;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .continually-button-icon {
      width: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
    .continually-close-button {
      position: absolute;
      z-index: 4;
      top: 0;
      right: 0;
      background-color: rgba(#000, 0.1);
      color: #fff;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 1.4em;
      border-radius: 0 0 0 5px;
      &:hover {
        background-color: rgba(#000, 0.2);
      }
    }
  }
  .conversation-js-preview {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .validationMessage {
      @include offscreen;
    }
  }
  .screenshot__loading {
    position: absolute;
    z-index: 1;
    @include ltrb0;
    background-color: #fff;
  }
  .screenshot__loading__inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .spinner {
      @include spinner(3em, 2px);
    }
  }
  @include mq(min, $mq-md) {
    @include mq(max-height, $mq-mbp) {
      padding-bottom: 9em;
      .choose-template__action {
        pointer-events: none;
        position: fixed;
        z-index: $zindex-sticky;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1.6em;
        background-color: rgba(#f7f7f7, 0.5);
        text-align: center;
        a {
          @include inline-block;
          width: auto;
          pointer-events: auto;
        }
      }
    }
  }
}

#modal-change-template.modal {
  text-align: center;
  @include mq(min, $mq-sm) {
    width: 440px;
    top: 20%;
    padding: 4em;
  }
}
