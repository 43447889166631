.lc-live-visitors {
  &__header {
    position: sticky;
    top: 0;
    background: white;
    z-index: 4;
  }
  &__search {
      border: 1px solid #ccc;
      min-width: 4.8em;
      min-height: 4.4em;
      border-radius: 4px;
      &.active {
          padding-right: 5em;
          border-color: $black;
          .lc-live-visitors__search-input {
              display: inline-block;
          }
      }
      button {
          border: none;
      }
  }
  &__search-input[type=search] {
      display: none;
      border: none;
  }
  &__table {
    width: 100%;
    &.loading {
      &::before {
        bottom: 0;
        z-index: 3;
      }
      &::after {
        position: fixed;
        top: 65%;
      }
    }
    thead {
      position: sticky;
      top: 8em;
      z-index: 3;
      tr {
        background: #f3f3f3;
      }
      th {
        padding: 1em;
        font-size: 1.6em;
      }
    }
    tbody {
      td {
        padding: 1em;
        font-size: 1.6em;
        vertical-align: middle;
        .flag {
          font-size: 0.6em;
        }
      }
    }
  }
  &__msg-flyout {
    position: fixed;
    z-index: 6;
    right: 0;
    top: $header-height;
    background: white;
    height: 100%;
    width: 40em;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  &__msg-flyout-header {
    background: #f4f9ff;
    border-left: 0.8em solid #3a7cce;
    padding: 2em;
    .avatar {
      @include avatar(3em);
    }
  }
  &__visitors-page {
    @include truncate;
    max-width: 15em;
  }
  .message--agent-takeover,
  .wysiwyg-wrapper {
    height: 100%;
  }
  .emoji-menu {
    right: 100%;
    top: 0;
  }
  .emoji-wysiwyg-editor {
    border: none;
    min-height: 100% !important;
    height: 100%;
    max-height: 100%;
    padding: 1em;
  }
  .ql-toolbar {
    border-bottom: 1px solid #ccc;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
  .lc-chat__send {
    background: $brand-primary;
    width: auto;
    height: auto;
    &:hover {
      background: $brand-primary;
    }
  }
  .lc-conversation__img {
    .avatar {
      @include avatar(1.8em, 1em, 100px);
    }
  }
  .lc-flyout {
    right: 50%;
    top: 5em;
    left: auto;
    bottom: auto;
  }
}
