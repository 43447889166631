body[data-overlay="visible"] {
  overflow: hidden !important;
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
}

.modal-wrap {
  background-color: rgba(white, 0.85);
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: auto;
  z-index: $zindex-modal;
  &--onboarding {
    background-color: rgba(black, 0.7);
  }
  &.modal-wrap--dark {
    background-color: rgba(black, 0.85);
    .modal {
      background-color: transparent;
    }
    a.close-modal {
      position: absolute;
      right: 1em;
      top: 1em;
      font-size: 2em;
      color: #fff;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  &.modal-wrap--on-top {
    z-index: $zindex-modal + 1;
  }
}

.modal {
  position: absolute;
  top: 1em;
  left: 1em;
  right: 1em;
  box-shadow: 0 2px 14px 0 rgba(#000, 0.25);
  border-radius: 0 0 5px 5px;
  background-color: white;
  border-top: 0.4em solid $brand-primary;
  padding: 3em;
  @include transition;
  @include mq(min, $mq-sm) {
    width: 440px;
    transform: translateX(-50%);
    left: 50%;
    top: 5%;
    right: auto;
    padding: 4em;
  }
  @include mq(min, $mq-md) {
    width: 700px;
    padding: 6em;
  }
  &.modal--sm {
    text-align: center;
    @include mq(min, $mq-sm) {
      width: 440px;
      left: 50%;
      top: 20%;
      right: auto;
      padding: 4em;
    }
  }

  &.modal--confirmation {
    text-align: left;
    padding: 0;
    border-top: none;
    @include mq(min, $mq-sm) {
      width: 480px;
      left: 50%;
      top: 20%;
      right: auto;
    }
    .modal-body {
      padding: 3em 3em 2em;
      @include mq(min, $mq-sm) {
        padding: 4em 4em 2em;
      }
      h3 {
        @include medium;
        font-size: 2.4em;
      }
      .identifier {
        font-size: 2em;
        @include medium-italic;
        color: $black-light;
      }
    }
    &-upgrade {
      @include mq(min, $mq-sm) {
        width: 520px;
      }
      @include mq(min, $mq-md) {
        width: 720px;
      }
      .modal-body {
        padding: 4em;
        @include mq(min, $mq-sm) {
          padding: 6em;
        }
      }
    }
    .modal-footer {
      text-align: center;
      padding: 2em 3em;
      border-top: 1px solid #ddd;
      @include mq(min, $mq-sm) {
        padding: 2em 4em;
        &--sm {
          padding-left: 3em;
          padding-right: 3em;
        }
      }
      .btn {
        margin-left: 0.5em;
      }
      &--align-left {
        text-align: left;
        .btn {
          margin-left: 0;
        }
      }
    }
    .loading-interstitial {
      position: absolute;
      z-index: 9;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(#fff, 0.95);
    }

    .account {
      margin: 3em auto;
      max-width: 30em;
      display: flex;
      align-items: center;
      justify-content: center;
      .avatar {
        @include avatar(3.6em);
        margin-right: 1em;
        flex-shrink: 0;
      }
      .name {
        text-align: left;
        font-size: 1.7em;
      }
    }
  }
  &.modal--embed-code {
    border-radius: 0;
    @include mq(min, $mq-sm) {
      width: 680px;
      .modal-body {
        padding-bottom: 4em;
      }
      .embed-code {
        pre {
          white-space: pre-wrap;
        }
      }
    }
    .close-modal {
      color: $black;
    }
    code {
      color: $blue-secondary;
      background: #eeeeee;
    }
    h2 {
      font-size: 2.8em;
      font-weight: 500;
      margin-bottom: 0.5em;
    }
  }
  &.modal--right {
    right: 0;
    left: auto;
    transform: none;
    top: 0;
    border-radius: 0;
    max-width: 474px;
    padding: 3.5em;
    border: none;
    .close-modal {
      right: 3.5em;
      top: 3.5em;
      color: #979797;
    }
  }
  &.modal--quick-replies {
    width: 90%;
    @include mq(min, 840px) {
      width: 840px;
      top: 10%;
    }
    h2 {
      font-weight: 500;
    }
    .modal__text {
      max-width: 600px;
    }
    .modal-body {
      @include mq(min, $mq-sm) {
        padding-top: 6em;
        padding-left: 8em;
        padding-right: 8em;
      }
    }
  }

  &.modal--video {
    padding: 0;
    border-top: none;
    top: 5%;
    @include mq(min, $mq-lg) {
      width: 900px;
    }
    @include mq(min, $mq-xl) {
      width: 1000px;
    }
    @include mq(min, $mq-xxl) {
      width: 1200px;
    }
    .modal-body {
      padding: 0;
    }
  }

  &.modal--targeting-rule {
    border: none;
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
    @include mq(min, $mq-sm) {
      top: 20%;
    }
    .modal-header {
      background-color: #484848;
      color: #fff;
      padding: 2.5em 3em;
      position: relative;
      .heading {
        font-size: 2.1em;
      }
      .close-modal,
      .modal-back {
        color: #fff;
        &:hover {
          opacity: 0.5;
        }
        i {
          font-size: 2.5em;
        }
      }
    }
    .modal-body {
      padding: 2.5em 3em;
    }
    .modal-footer {
      text-align: center;
      padding: 0 3em 2.5em;
    }
  }

  &.modal--dark-overlay-transparent {
    background-color: transparent;
    border-top: none;
    box-shadow: none;
    color: white;
    @include mq(min, $mq-sm) {
      width: 480px;
    }
    p {
      padding-bottom: 1em;
    }
    a {
      color: white;
      cursor: pointer;
    }
  }

  &.modal--align-center {
    top: 50%;
    transform: translateY(-50%);
    @include mq(min, $mq-sm) {
      transform: translate(-50%, -50%);
    }
  }
  &.modal--thanks {
    border-radius: 0;
    @include mq(min, $mq-sm) {
      top: 10%;
    }
    @include mq(min, $mq-md) {
      width: 90%;
      max-width: 1110px;
      margin-bottom: 3em;
    }
    .close-modal {
      z-index: 1;
      color: white;
      &:hover {
        color: inherit;
      }
    }
  }

  &.modal--automation {
    @include mq(min, $mq-sm) {
      width: 80%;
      max-width: 800px;
    }

    .modal-footer {
      background: #f8f8f8;
    }
  }

  h2,
  h3 {
    margin-bottom: 1em;
    @include light;
  }
  a.close-modal,
  a.modal-back {
    position: absolute;
    right: 1.6em;
    top: 1.6em;
    text-decoration: none;
    color: $black-lighter;
    @include transition;
    i {
      font-size: 2em;
    }
    &:hover {
      color: $black;
    }
  }
  a.modal-back {
    right: auto;
    left: 1.6em;
  }

  &__text-muted {
    font-weight: 500;
    font-style: italic;
    color: #aaa;
  }

  &__form {
    margin-top: 3em;
  }

  &__text {
    &--sm {
      font-size: 1.4em;
    }
    &--muted {
      font-size: 1.3em;
      color: #c0c0c0;
    }
    &--italic {
      @include italic;
    }
    &--danger {
      color: $error;
      @include medium;
      .far {
        margin-right: 0.3em;
      }
    }
  }
}

.modal-wrap--chatuser-step-2 {
  @include mq(max, $mq-md) {
    display: none !important;
  }
}
