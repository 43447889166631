.flyout-wrapper {
  position: relative;
  &--input {
    input {
      padding-right: 3em;
    }
    .flyout.flyout--variables {
      max-height: 454px;
      right: -3em;
      left: auto;
      transform: none;
      &::before,
      &::after {
        transform: none;
        right: 10%;
      }
    }
  }
}

.flyout-hover {
  &:hover {
    .flyout {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.flyout {
  opacity: 0;
  pointer-events: none;
  min-width: 30em;
  max-width: 50em;
  position: absolute;
  z-index: $zindex-max;
  background: #fff;
  color: $black;
  border: 1px solid #d0d0d0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 2em;
  top: calc(100% + 1.3em);
  left: 50%;
  transform: translateX(-50%);
  &.flyout-active {
    animation: showFlyout 0.1s ease;
    opacity: 1;
    pointer-events: auto;
  }
  &:after,
  &:before {
    right: 50%;
    transform: translateX(50%);
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    bottom: calc(100% - 1px);
    border-color: rgba(#fff, 0);
    border-bottom-color: #fff;
    border-width: 10px;
  }
  &:before {
    bottom: 100%;
    border-color: rgba(#d0d0d0, 0);
    border-bottom-color: #d0d0d0;
    border-width: 10px;
  }
  &.flyout--no-animation {
    animation: none;
  }
  &.flyout--variables {
    max-height: 457px;
    left: 50%;
    transform: translateX(-50%);
    animation: none;
    min-width: 35em;
    padding: 2.5em 2.5em 5em;
    .flyout-pane {
      padding-top: 1em;
      margin: -2em -2.5em 0;
      overflow-x: auto;
      overflow-y: scroll;
      max-height: 400px;
    }
    .flyout-pane--create-variable {
      position: relative;
      padding-top: 4em;
      .flyout-pane--back {
        position: absolute;
        left: 1.5em;
        top: 1em;
        color: $black-light;
        &:hover {
          color: $brand-green;
        }
        i {
          font-size: 2em;
        }
      }
    }
    h3 {
      font-size: 1.6em;
      @include medium;
      border-bottom: 1px solid #ddd;
      padding: 1em 1.4em;
      margin: 0;
    }
    .your-variables {
      margin-bottom: 2.4em;
      &:empty {
        display: none;
      }
    }
    .captured-variables {
      .label {
        display: block;
        margin-bottom: 0.5em;
        font-size: 1.4em;
      }
      .pulldown {
        width: 100%;
      }
    }
    .custom-variables--details {
      padding: 1em 1.5em;
      label {
        font-size: 1.4em;
        margin-bottom: 0.5em;
        @include regular;
      }
      input[type="text"] {
        font-size: 1.4em !important;
      }
    }
    .variable-row {
      padding: 1em 1.5em;
      font-size: 1.4em;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      // &:last-child {
      //   border-bottom: 0;
      // }
      .custom-variables--actions {
        text-align: right;
        span {
          font-size: 1em;
          @include inline-block;
          margin-left: 0.3em;
          @include regular;
          text-decoration: underline;
          &:hover {
            color: $brand-primary;
          }
        }
      }
    }
    .fieldset-button {
      padding: 1em 1.5em;
      margin: 1.6em 0;
    }
  }
  &--static {
    position: static;
    transform: none;
  }
  &--no-arrow {
    &:before,
    &:after {
      content: none;
    }
  }
  &__add-fields {
    background: #f6f6f6;
    padding: 1.7em 1em;
    text-align: center;
    display: block;
    margin-left: -2.5em;
    margin-right: -2.5em;
    position: relative;
    z-index: 1;
    border-radius: 0 0 4px 4px;
    border-bottom: 1px solid #d5d5d5;
    a {
      color: $blue-secondary;
    }
  }
}

.flyout-search {
  $self: &;
  padding: 0;
  border: 1px solid #c8c8c8;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
  z-index: 3;
  pointer-events: none;
  top: 150%;
  bottom: auto;
  left: 50%;
  transition: 0.2s;
  @include mq(min, $mq-md) {
    top: auto;
    bottom: -3em;
    left: 100%;
    transform: none;
  }
  &:before,
  &:after {
    content: none;
  }
  &.flyout-active {
    animation: none;
    .flyout-saving-tags & {
      &:before {
        position: absolute;
        z-index: 2;
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(#fff, 0.9);
        left: 0;
        top: 0;
        border: none;
        margin: 0;
        transform: none;
      }
      &:after {
        position: absolute;
        z-index: 3;
        left: calc(50% - 15px);
        top: calc(50% - 15px);
        @include spinner;
      }
    }
  }
  &--tags {
    width: 35em;
    #{$self}__btn {
      color: #8d8d8d;
    }
    #{$self}__list {
      padding: 1em 0;
      max-height: 31em;
    }
    #{$self}__item {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
  }
  &--snippets {
    width: 38em;
    #{$self}__actions {
      display: none;
    }
    #{$self}__item {
      cursor: pointer;
      &:not(:last-child) {
        border-bottom: 1px solid #cecfcf;
      }
      &:hover {
        background-color: #fafafa;
        #{$self}__actions {
          display: flex;
        }
      }
    }
    #{$self}__btn {
      & + & {
        margin-left: 1em;
      }
    }
  }
  &__list {
    list-style-type: none;
    overflow: hidden;
    overflow-y: auto;
    max-height: 24em;
  }
  &__item {
    padding: 1.5em 2em;
    font-size: 1em;
    display: flex;
    align-items: center;
  }
  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 1.5em 1.5em 0.4em 1.5em;
    p {
      font-size: 1.4em;
      font-weight: 500;
    }
    .btn {
      width: auto;
      height: auto;
      color: white;
      padding: 0.5em 1em;
      line-height: 1;
      font-size: 1.4em;
    }
  }
  &__search {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    label {
      display: flex;
      align-items: center;
      margin: 0;
      padding-left: 2em;
      font-size: 1em;
      &:hover {
        background: transparent;
      }
    }
    i {
      font-size: 1.4em;
      color: #b3b3b3;
    }
    input {
      width: 100%;
      border: none !important;
      font-size: 1.4em;
      height: auto !important;
      padding: 1em 1em 0.8em !important;
    }
  }
  &__empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 2.5em;
    p {
      font-size: 1.4em;
    }
    a {
      color: $brand-primary !important;
    }
  }
  &__name {
    font-size: 1.4em;
    font-weight: normal;
    margin-bottom: 0.5em;
    @include truncate();
  }
  &__reply {
    font-size: 1.2em;
    color: #8a8a8a;
    margin-bottom: 0;
    @include truncate();
  }
  &__actions {
    align-items: center;
    margin-left: auto;
    display: flex;
  }
  &__text {
    margin-right: 1em;
    flex: 1 auto;
  }
  &__item {
    .flyout-search__tag {
      margin-right: 1em;
      flex: 1 auto;
      background: #fafafa;
      border: 1px solid #d0d0d0;
      border-radius: 4px;
      padding: 1em 1.5em 0.8em 1em;
      cursor: pointer;
      input[type="text"] {
        width: 100%;
        line-height: 1;
        height: auto !important;
        font-size: 1.4em !important;
        background: transparent;
        border: none;
        padding: 0 !important;
        &[readonly] {
          pointer-events: none;
        }
      }
      i {
        display: none;
      }
      &--active {
        background: $blue;
        border-color: $blue;
        display: flex;
        align-items: center;
        color: white;
        i {
          display: inline-block;
        }
        input {
          color: white;
        }
      }
    }
  }
  &__btn {
    background: transparent;
    border: none;
    font-size: 1.2em;
    margin-right: 0;
    cursor: pointer;
  }
}
