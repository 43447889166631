.body--opportunity {
  background-color: #f1f1f1;
  .modal--confirmation {
    @include mq(max, $mq-sm) {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.padding-container {
  padding: 0 4.2em;
}

.opportunity-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2.5em;
  min-height: 6em;
  margin-bottom: 1em;
  margin-top: 1em;
  @include mq(min, $mq-md) {
    border-bottom: 1px solid #dbdbdb;
    margin-bottom: 2em;
    padding-left: 4.2em;
    padding-right: 4.2em;
  }
  &__left {
    a {
      padding: 0;
      color: $black;
      font-size: 2em;
      @include mq(min, $mq-md) {
        font-size: 1.6em;
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
  }
  &__loading {
    display: flex;
    align-items: center;
    .loading {
      width: 20px;
      height: 20px;
      &:before {
        background: transparent;
      }
      &:after {
        width: 20px;
        height: 20px;
        top: auto;
      }
    }
    span {
      font-size: 1.6em;
      color: #979797;
      margin-left: 1.3em;
    }
  }
  &__count {
    font-size: 2em;
    @include mq(min, $mq-md) {
      font-size: 1.6em;
      margin-right: 1em;
    }
  }
  &__pagination {
    display: flex;
    a {
      padding: 0;
      margin-left: 1em;
      line-height: 2.3em;
      @include mq(max, $mq-md) {
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cg transform='translate(-412 -91)' fill='none' fill-rule='evenodd'%3E%3Cpath fill='%234A4A4A' d='M412 96l10-5v10z'/%3E%3Crect stroke='%23CFCFCF' x='399.5' y='78.5' width='35' height='35' rx='4'/%3E%3C/g%3E%3C/svg%3E");
        border: 1px solid #cfcfcf;
        border-radius: 4px;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        width: 3.6em;
        height: 3.6em;
        &:hover {
          background-color: lightgray;
        }
        &:not(:first-child) {
          background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='10px' viewBox='0 0 10 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3ETriangle Copy%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Designs-(defualt)-Copy-45' transform='translate(-454.000000, -91.000000)'%3E%3Cpolygon id='Triangle-Copy' fill='%234A4A4A' transform='translate(459.000000, 96.000000) scale(-1, 1) rotate(-90.000000) translate(-459.000000, -96.000000) ' points='459 91 464 101 454 101'%3E%3C/polygon%3E%3Crect id='Rectangle-Copy-4' stroke='%23CFCFCF' x='441.5' y='78.5' width='35' height='35' rx='4'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
      }
      &:first-child {
        padding-right: 1em;
        border-right: 1px solid #d8d8d8;
      }
      span {
        display: none;
        @include mq(min, $mq-md) {
          display: inline-block;
        }
      }
    }
  }
}

.opportunity-header {
  display: none;
  margin-bottom: 1.6em;
  position: relative;
  z-index: 3;
  @include mq(min, $mq-md) {
    display: block;
  }
  .kick {
    @include uppercase;
    @include medium;
    color: $black-light;
    font-size: 1.2em;
    margin-bottom: 0.75em;
    .flag {
      font-size: 0.9em;
      margin-left: 0.5em;
      @include regular;
      @include vertical-align;
      position: relative;
      top: -2px;
    }
  }
  h1 {
    font-size: 4.8em;
    margin: 0;
    small {
      font-size: 50%;
      color: $black-light;
    }
    @include truncate;
    max-width: 75%;
  }
  h2 {
    font-size: 1.3em;
    margin: 0.5em 0 0;
    color: $black-light;
    @include regular;
  }
  .actions {
    margin-top: 1em;
    @include mq(min, $mq-sm) {
      position: absolute;
      z-index: 1;
      right: 0;
      top: $spacer * 2;
      margin: 0;
    }
    .btn,
    form {
      display: inline-block;
      margin: 0 0.1em;
    }
  }
}

.opportunity-container {
  max-width: 156em;
  margin-left: auto;
  margin-right: auto;
  .tab-pane {
    padding-bottom: 2em;
    @include mq(min, $mq-md) {
      display: block !important;
    }
  }
  .page-tabs {
    margin-left: -4.2em;
    margin-right: -4.2em;
    background: #ececec;
    padding-top: 3em;
    &__item {
      font-size: 16px;
      a {
        padding-bottom: 0.5em;
      }
    }
  }
  .appointment-table {
    font-size: 15px;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    td {
      &:first-child {
        padding-left: 1.2em;
      }
      &:last-child {
        padding-right: 1.2em;
      }
      .leads-profile-btn-icon {
        font-size: 1.1em;
        margin-right: 0.3em;
      }
    }
  }
}

.opportunity-body {
  &__col-1 {
    .opportunity-panel {
      .panel--body {
        margin-left: -1.6em;
        margin-right: -1.6em;
        .about--row {
          padding-left: 1.6em;
          padding-right: 1.6em;
        }
      }
    }
  }
  &__conversations {
    flex: 1 auto;
    @include mq(min, $mq-lg) {
      max-width: 60%;
      width: 0;
    }
  }
  .col-1 {
    .opportunity-panel + h3 {
      margin-top: 2em;
    }
  }
  .col-2 {
    flex-wrap: wrap;
    .panel--title {
      font-size: 16px;
      @include mq(min, $mq-md) {
        font-size: 1.8em;
      }
    }
  }
  .appointment-and-conv-activity {
    width: 100%;
    order: -1;
  }
  @include mq(min, $mq-md) {
    display: flex;
    .col-1 {
      width: 35em;
      margin-right: 3.2em;
    }
    .col-2 {
      flex: 1 auto;
      display: flex;
      align-items: flex-start;
    }
  }
  @include mq(min, $mq-lg) {
    position: relative;
    .col-1 {
      width: 26em;
    }
    .col-2 {
      flex-wrap: nowrap;
    }
    .appointment-and-conv-activity {
      width: 26em;
      margin-left: 3.2em;
      order: 0;
    }
  }
  @include mq(min, $mq-xl) {
    .col-1 {
      width: 35em;
    }
    .appointment-and-conv-activity {
      width: 35em;
    }
  }
  @include mq(min, $mq-xxl) {
    .col-1 {
      width: 35em;
    }
    .appointment-and-conv-activity {
      width: 45em;
    }
  }
  .opportunity--lead-activity {
    text-align: center;
    display: block;
    margin: 0 0 2em 0;
    font-size: 1.6em;
    @include medium;
  }
  .col {
    margin-bottom: 1.6em;
  }
  .opportunity-panel {
    background-color: #fff;
    border-radius: 6px;
    margin-bottom: 1.6em;
    padding: 1.6em;
    overflow: hidden;
    &.loading {
      &:before {
        top: 0;
        left: 0;
        z-index: 3;
      }
      &:after {
        left: calc(50% - 15px);
        top: calc(50% - 15px);
      }
    }
    &--transparent {
      margin-bottom: 1.6em;
      padding: 1.6em;
    }
    &--user {
      @include mq(max, $mq-md) {
        .panel--section--contact-card {
          display: none;
        }
        .panel--section--about {
          .panel--header {
            display: none;
          }
        }
      }
    }
    &--mobile {
      margin-left: -4.2em;
      margin-right: -4.2em;
      margin-bottom: 0;
      padding-bottom: 0;
      background: transparent;
      box-shadow: none;
      border: none;
      .panel--section--contact-card {
        border-radius: 0;
        display: block;
        overflow: visible;
      }
      .panel--section--about {
        display: none;
      }
      .lead-block-dropdown {
        color: black;
        text-align: left;
      }
      @include mq(min, $mq-md) {
        display: none;
      }
    }
    &--user,
    &--company {
      overflow: visible;
      .about--row {
        display: block;
        align-items: baseline;
        .td {
          position: relative;
          &--loading {
            &:after {
              content: "";
            }
          }
          &--ok {
            &:before {
              content: "\f00c";
            }
          }
          &:after {
            width: 1.2em;
            height: 1.2em;
            border-radius: 50%;
            transform: translateZ(0);
            border: 1px solid #000;
            border-left: 1px solid transparent;
            animation: spinner 0.5s infinite linear;
            right: 0;
            top: 0;
            position: absolute;
          }
          &:before {
            right: 0;
            top: 0.3em;
            position: absolute;
            font-size: 1.2em;
            color: $green;
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
          }
        }
        input {
          resize: none;
          height: 1.5em;
          border: none;
          border-bottom: 1px solid transparent;
          padding: 0;
          border-radius: 0;
          &:focus {
            border-bottom: 1px dashed #bbb;
          }
        }
        textarea {
          resize: none;
          max-height: 10em;
          border: none;
          width: 90%;
          border-bottom: 1px solid transparent;
          padding: 0;
          overflow: hidden;
          border-radius: 0;
          &:focus {
            border-bottom: 1px dashed #bbb;
          }
        }
        .loading-interstitial {
          display: none;
        }
      }
    }
    &__out {
    }
    .about--row--variables {
      .th {
        float: none !important;
        width: 100% !important;
        padding-right: 0 !important;
      }
      .td {
        float: none !important;
        width: 100% !important;
      }
    }
    .about--row {
      position: relative;
      padding-bottom: 0;
      border-bottom: 1px solid #ddd;
      margin-bottom: 1.6em;
      @include clearfix;
      &:last-of-type,
      &--last {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      &--last {
        margin-bottom: -1.5em;
      }
      &--variables {
        .th {
          word-break: break-word;
          word-wrap: break-word;
          overflow-wrap: break-word;
        }
      }
      &--center {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-bottom: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        display: flex;
        span {
          color: $brand-green;
        }
      }
      .flyout {
        font-size: 10px;
      }
      .remove-variable {
        position: absolute;
        width: 20px;
        height: 20px;
        top: -5px;
        right: 0px;
        cursor: pointer;
        font-size: 1.1em;
      }
      .th {
        float: left;
        width: 33%;
        padding-right: 1em;
        @include medium;
        font-size: 15px;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        padding-bottom: 0.5em;
      }
      .td {
        float: left;
        width: 67%;
        font-size: 15px;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        &__tags {
          margin-bottom: 1em;
          border-bottom: 1px solid #ddd;
          font-size: 10px;
          margin-right: -1.6em;
        }
      }
      a {
        color: $black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      .btn-link {
        padding: 0;
      }
    }

    &__bold-text {
      font-weight: 500;
      font-size: 1.5em;
    }
    .panel--body {
      position: relative;
    }
    .custom-select {
      position: absolute;
      width: 100%;
      bottom: 0.5em;
      max-height: 10em;
      overflow-x: hidden;
      overflow-y: auto;
      &__option {
        &:hover {
          background: $blue;
          color: white;
        }
      }
    }
  }
  .panel--section--contact-card {
    border-radius: 6px 6px 0 0;
    background-color: $black;
    color: #fff;
    text-align: center;
    margin: -1.6em -1.6em 0;
    padding: 3.2em;
    position: relative;
    overflow: hidden;
    .opportunity-avatars {
      position: relative;
      z-index: 2;
      margin: 0 auto;
      display: inline-block;
    }
    .avatar {
      @include avatar(7.5em, 3.6em, 100px);
      border-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(black, 0.5);
      &:not(.avatar--initials) {
        background-color: #fff;
      }
    }
    .avatar--company {
      @include avatar(2.8em, 1em, 100px);
      position: absolute;
      right: 0;
      bottom: -0.5em;
      z-index: 2;
    }
    .user-details {
      position: relative;
      z-index: 2;
      margin-top: 1.6em;
      span {
        font-size: 2em;
        display: inline-block;
        margin: 0 0.1em;
        @include mq(min, $mq-md) {
          font-size: 1.6em;
        }
      }
      span.name + span.company:before,
      span.role + span.location:before {
        content: "•";
        display: inline-block;
        margin: 0 0.3em 0 0;
      }
      &.name-company {
        span {
          font-size: 2.5em;
          @include medium;
          word-break: break-word;
          word-wrap: break-word;
          overflow-wrap: break-word;
          @include mq(min, $mq-md) {
            font-size: 2em;
          }
        }
      }
    }
    .social-profiles {
      margin-top: 1em;
      a {
        display: inline-block;
        margin: 0 0.2em;
        padding: 0.5em 0.5em 0.2em;
        line-height: 1;
        background-color: red;
        border-radius: 2px;
        svg {
          fill: #fff;
        }
        &.linkedin {
          background-color: $linkedin;
        }
        &.twitter {
          background-color: $twitter;
        }
      }
    }
  }
  .panel--header {
    position: relative;
    margin-left: -1.6em;
    margin-right: -1.6em;
    margin-bottom: 1.6em;
    padding: 1.5em 1.6em 1.3em;
    background-color: #f8f7f6;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
    &:after,
    &:before {
      top: 100%;
      left: 2.2em;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
    &:after {
      border-color: rgba(248, 247, 246, 0);
      border-top-color: #f8f7f6;
      border-width: 0.6em;
      margin-left: -0.6em;
    }
    &:before {
      border-color: rgba(227, 227, 227, 0);
      border-top-color: #e3e3e3;
      border-width: 0.7em;
      margin-left: -0.7em;
    }
    .panel--title {
      margin: 0;
    }
  }
  .panel--title {
    @include medium;
    font-size: 16px;
    small {
      float: right;
      color: $black-light;
      font-size: 75%;
      @include regular;
      display: inline-block;
      margin-top: 0.2em;
      margin-left: 0.3em;
    }
  }
  .panel--section--behaviour {
    .behaviour--row {
      padding-bottom: 1.6em;
      border-bottom: 1px solid #ddd;
      margin-bottom: 1.6em;
      @include clearfix;
      &:last-of-type {
        border-bottom: none;
      }
      .th {
        float: left;
        width: 40%;
        padding-right: 1em;
        @include medium;
        font-size: 1.5em;
        color: #9e9e9e;
        white-space: nowrap;
        svg {
          @include wh(0.8em);
          fill: #9e9e9e;
          display: inline-block;
          margin-right: 0.2em;
        }
      }
      .td {
        float: left;
        width: 60%;
        font-size: 1.5em;
        @include clearfix;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
      }
      .page-visited {
        font-size: 0.75em;
        display: block;
        float: left;
        padding: 0.5em 1em;
        line-height: 1;
        margin-right: 0.4em;
        margin-bottom: 0.4em;
        background-color: rgba(#d8d8d8, 0.25);
        border: 1px solid #ddd;
        border-radius: 100px;
      }
      a {
        color: $black;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .panel--section--qualification {
    margin-top: 1.6em;
    position: relative;
    .qualification__progress {
      margin-bottom: 2em;
      h3 {
        @include medium;
        font-size: 2.4em;
        margin-bottom: 0.5em;
      }
    }
    .qualification__progress__status {
      height: 11px;
      border-radius: 10px;
      background-color: #d8d8d8;
      margin-bottom: 1.3em;
      position: relative;
      overflow: hidden;
      border: 1px solid #fff;
      box-shadow: 0 0 4px 0 rgba(196, 196, 196, 0.5);
    }
    .qualification__progress__value {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: $brand-primary;
      border-radius: 10px;
    }
    .qualification__progess__steps {
      position: absolute;
      right: 0;
      top: 0;
      @include medium;
      font-size: 1.4em;
      color: #a6a6a6;
    }
    .qualification__information {
      margin-bottom: 1em;
      span {
        @include inline-block;
        &.icon {
          margin-right: 0.5em;
          @include circle(2.2em);
          line-height: 2.2em;
          background-color: #d8d8d8;
          color: #fff;
          @include bold;
          border: 2px solid #fff;
          box-shadow: 0 1px 2px 0 rgba(#c2c2c2, 1);
          svg {
            display: none;
            fill: #fff;
            position: relative;
            @include wh(1em);
          }
        }
      }
      .information {
        @include inline-block;
        .information--title {
          font-size: 1.2em;
          color: $black-light;
        }
        .information--value {
          @include medium;
          font-size: 1.4em;
        }
      }
      &.information--compete {
        span.icon {
          background-color: $brand-primary;
          svg {
            display: inline-block;
          }
        }
      }
    }
  }
  .opportunity-panel--conversation {
    padding: 0;
    overflow: visible;
    margin-bottom: 3em;
    .conversation-header {
      padding: 1.8em 2.4em;
      position: relative;
      &[data-state="collapsed"] {
        border-radius: 6px;
        &:hover {
          background-color: darken(#fff, 2%);
        }
        & + .conversation-body {
          height: 0;
          overflow: hidden;
          min-height: auto;
          padding: 0;
        }
        & ~ .conversation-footer {
          height: 0;
          overflow: hidden;
          padding: 0;
          box-shadow: none;
          border: none;
        }
        .conversation-header__row + .conversation-header__rows {
          display: none;
        }
        time {
          color: $black-light;
        }
        .conversation-header__text {
          color: $black;
        }
        .collapse-icon i {
          color: $black-light;
        }
      }

      &[data-state="expanded"] {
        background-color: $black;
        color: #fff;
        border-radius: 6px 6px 0 0;
        time {
          color: $black-light;
        }
        h3 {
          color: #fff;
        }
        .conversation-header__top {
          border-color: rgba(#eee, 0.3);
        }
        .conversation-time {
          color: white;
        }
        .conversation-header__text {
          color: #fff;
        }
        .collapse-icon {
          transform: rotate(180deg);
          transform-origin: center;
        }
      }
      &__top {
        border-bottom: 1px solid #eeeeee;
        margin-left: -2.4em;
        margin-right: -2.4em;
        padding: 0em 2.4em 1em 2.4em;
        .conversation-time {
          font-size: 1.5em;
          margin-bottom: 0.5em;
          &:hover {
            .show-on-hover {
              display: inline-block;
            }
            .hide-on-hover {
              display: none;
            }
          }
          .show-on-hover {
            display: none;
          }
        }
      }
      time {
        @include inline-block;
        font-size: 1.2em;
      }
      .flag {
        @include inline-block;
        & + .flag {
          margin-left: 0.5em;
        }
      }
      h3 {
        margin: 0;
        @include bold;
        font-size: 1.8em;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
      }
      &__row {
        display: flex;
        align-items: center;
        font-size: 1.5em;
        &:not(:last-of-type) {
          margin-bottom: 0.8em;
        }
      }
      &__label {
        @include medium;
        flex-basis: 20%;
        flex-shrink: 0;
        margin-right: 1em;
      }
      &__text {
        text-decoration: none;
        font-size: inherit;
        max-width: 35em;
        @include break-urls;
        .avatar {
          border-radius: 20px;
          width: 3em !important;
          height: 3em !important;
          & + .avatar {
            margin-left: -1em;
          }
          img {
            width: 3em !important;
            height: 3em !important;
          }
        }
      }
      &__delete {
        color: $red;
      }
      .collapse-icon {
        cursor: pointer;
        position: absolute;
        z-index: 1;
        right: 50%;
        bottom: 0;
        @include transition;
        i {
          font-size: 3em;
        }
      }
    }
    .conversation-body {
      background: white;
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      min-height: 380px;
      @include transition;
    }

    .conversation-footer {
      background: white;
      padding: 1.75em 3em;
      border-top: 1px solid #cfcfcf;
      box-shadow: 0px -3px 3px 0px rgba(0, 0, 0, 0.05);
      &__header {
        color: #757575;
        p:last-child {
          color: #5e5e5e;
        }
      }
      &__send {
        padding: 1em 0;
      }
      .wysiwyg-wrapper {
        position: relative;
        > button {
          position: absolute;
          right: 6px;
          bottom: 6px;
          background: #43d5c1;
          border-radius: 15px;
          padding: 0.4em 1.2em;
          font-size: 1.4em;
          margin: 0;
          i {
            margin-left: 0.2em;
            font-size: 1em;
          }
        }
        textarea {
          display: none;
        }
        .emoji-menu {
          left: 0em;
          top: 100%;
        }
        .emoji-wysiwyg-editor {
          margin-bottom: 0;
          outline: none;
          min-height: 7em;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          font-size: 1.5em;
        }
        .ql-toolbar {
          border-top: 0;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          height: 4.3em;
          align-items: center;
          display: flex;
        }
        .ql-emoji {
          cursor: pointer;
        }
      }
    }
    .continually-timeline {
      padding: 3.2em;
      @include clearfix;
      .opportunity--lead-activity {
        clear: both;
        margin-top: 1em;
      }
    }
    .continually-chat-team,
    .continually-chat {
      font-size: 90%;
      position: absolute;
      overflow: hidden;
      overflow-y: auto;
      width: 100%;
      .post--user {
        padding-right: 4.8em;
        .message__content {
          white-space: pre-line;
        }
      }
      .post {
        &--user {
          .message {
            &:not(.message--video) {
              [data-role="item-status"] {
                color: white;
              }
            }
          }
        }
      }
      .message {
        &--image {
          ~ .flex [data-role="item-status"] {
            margin-left: 0;
          }
        }
        &--fileuploaded {
          margin-bottom: 3em;
          i {
            font-size: 2.5em;
          }
          .filename {
            margin: 0;
          }
          .file-uploaded {
            padding: 0;
          }
          .file-download {
            position: absolute;
            top: calc(100% + 0.5em);
            left: 50%;
            transform: translate(-50%);
            white-space: nowrap;
            font-size: 1.4em;
          }
        }
      }
    }
    .message__content {
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
      hyphens: auto;
      .rich-media-card__summary {
        max-width: 20em;
      }
    }
    [data-role="item-status"] {
      align-self: flex-end;
      margin-left: 1em;
      margin-bottom: 0.1em;
      text-align: right;
    }
  }
  .opportunity-panel--conversation--unread {
    .conversation-header[data-state="collapsed"] {
      background-color: lighten(#fdf9de, 3%);
      &:hover {
        background-color: lighten(#fdf9de, 1%);
      }
    }
  }
  .opportunity-panel--company {
    .company-bio {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ddd;
      padding: 0 1.6em 1.6em;
      margin: 0 -1.6em 3.2em;
      .avatar {
        @include avatar(6.4em, 4em, 100px);
        border-color: #fff;
        box-shadow: 0 0 5px 0 rgba(#737373, 0.5);
        margin-right: 2em;
      }
      .bio {
        flex: 1;
        font-size: 1.4em;
      }
    }
  }
  .opportunity-panel--appointment {
    .appointment {
      &__header {
        margin: 0 -1.6em;
        padding: 0em 1em 1em 1em;
        border-bottom: 1px solid #f1f1f1;
      }
      .time {
        font-size: 1.8em;
        margin-bottom: 0.25em;
        strong {
          @include bold;
        }
      }
      .icon {
        margin-right: 2em;
        margin-left: 1em;
        position: relative;
        svg {
          fill: $black;
          @include wh(3em);
        }
        .check {
          position: absolute;
          left: -0.8em;
          top: -0.9em;
          background-color: $brand-primary;
          border: 2px solid #fff;
          @include circle(1.8em);
          line-height: 1.4em;
          &--pending {
            background-color: #d0d0d0;
          }
          &--cancelled {
            background-color: $red;
          }
          svg {
            fill: #fff;
            position: relative;
            top: 0.05em;
            @include wh(0.7em);
          }
        }
      }
      .appointment-details {
        margin-left: -1.6em;
        margin-right: -1.6em;
        p {
          line-height: 1.2;
          font-size: 15px;
          margin-left: 1.4em;
          margin-right: 1.4em;
        }
        .object-tip {
          border-radius: 5px;
          background: rgba($blue-secondary, 0.1%);
          border-color: $blue-secondary;
          color: $blue-secondary;
        }
      }
    }
  }
  .opportunity-panel--activity {
    overflow: auto;
    max-height: 51em;
    padding: 2em 3em 2em 2em;
    background: url("../images/opportunities/activity-timeline-bg.png");
    .opportunity--progress {
      .step {
        .meta {
          color: $black-light;
          font-size: 1.3em;
        }
      }
    }
    .conversation-activity {
      &__header {
        margin-bottom: 2em;
        &:not(:first-child) {
          .conversation-activity__controls {
            display: none;
          }
        }
      }
      &__steps {
        padding-left: 4em;
        margin-left: 2em;
        margin-top: 1em;
        border-left: 2px solid #ededed;
      }
      &__date {
        display: inline-block;
        color: $black;
        font-size: 1.6em;
        text-transform: none;
        &:not(:first-child) {
          margin-top: 1em;
        }
      }
      .label {
        font-size: 1em;
        span {
          font-size: 1.4em;
          color: #333;
          margin-bottom: 0.5em;
          margin-top: 0.3em;
          display: inline-block;
        }
      }
      .step {
        border-color: #ededed;
        padding-left: 2.5em;
        margin-bottom: 0;
        padding-bottom: 3em;
        &:first-child:last-child {
          border: none;
        }
        &--inner {
          padding-left: 5em;
          margin-left: 0;
          margin-top: -1em;
          &:last-child,
          & + .step--inner:last-child {
            border-color: transparent;
          }
          .label {
            time {
              left: 1em;
            }
          }
        }
        time {
          background: white;
          font-size: 1.2em;
          color: rgba($blue-secondary, 0.7);
          left: -1.5em;
          padding-bottom: 0.5em;
          padding-top: 0.5em;
        }
        small {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          font-size: 1.3em;
          &,
          a {
            color: #333333;
            opacity: 0.7;
          }
          &:hover {
            overflow: visible;
            white-space: normal;
          }
        }
      }
      &__controls {
        .btn {
          font-size: 1em;
          padding: 0.65em 0.8em;
          line-height: 1;
          border-radius: 4px;
          i {
            font-size: 1.4em;
          }
        }
      }
    }
  }
  .pagination {
    .page-item {
      margin-right: 0.5em;
      &:not(.active):hover {
        background: #ddd;
      }
    }
  }
}

.lead-block-dropdown {
  display: inline-block;
  position: relative;
  z-index: 1;
  &:hover,
  &.active {
    .lead-block-dropdown__in {
      display: block;
    }
  }
  &__in {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    width: 26em;
    background: #ffffff;
    border: 1px solid #c8c8c8;
    border-radius: 4px;
    @include mq(min, $mq-md) {
      width: 22em;
    }
  }
  &__item {
    padding: 1.5em 1.5em 0.3em 1.8em;
    cursor: pointer;
    &:hover {
      background: #e5e5e5;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #e5e5e5;
    }
    p {
      font-size: 1em;
    }
    strong {
      font-size: 14px;
    }
    span {
      font-size: 12px;
    }
  }
}
.leads-profile-btn-icon {
  font-weight: 500;
  text-decoration: none;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  background: transparent;
  cursor: pointer;
  transition: 0.2s background;
  &:hover {
    background: #ddd;
    text-decoration: none;
  }
}
