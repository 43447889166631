.localisation-form {
  max-width: 110em;

  &__title {
    padding-bottom: 1em;
    border-bottom: 1px solid #efefef;
  }
}

.localisation-row {
  margin-bottom: 6em;
  max-width: 80em;
  &.mb-0 {
    margin-bottom: 0;
    max-width: none;
    align-items: center;
    .sub-label {
      display: inline-block;
    }
    .preview {
      max-width: 50em;
      flex-grow: 1;
    }
  }
  &:first-child {
    .sub-label {
      margin-top: 0;
    }
  }

  .appointment-panel__title {
    border-bottom: 0;
  }

  @include mq(min, $mq-md) {
    display: flex;
    justify-content: space-between;
  }
  &--table {
    td + td {
      padding-left: 5em;
    }
  }

  &__error {
    color: $red;
    text-align: center;
    margin-top: 0.5em;
    font-size: 1.4em;
  }

  fieldset {
    flex: 1;

    @include mq(min, $mq-md) {
      margin-right: 2em;
    }

    input {
      margin-bottom: 0.5em;
    }

    .sub-label {
      margin-top: 1em;
    }
  }

  .preview {
    user-select: none;
    pointer-events: none;
    max-width: 50%;

    @include mq(min, $mq-md) {
      padding-top: 3.4em;
    }

    .form.textarea {
      border: 1px solid #d5d5d5;
      padding: 0.6em;
      padding-left: 1.4em;
      border-radius: 5px;
      min-width: 24em;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .placeholder-text {
        font-size: 1.6em;
        color: $black-light;
        line-height: 1;
        overflow: hidden;
        white-space: nowrap;
        width: 10em;
      }

      .button {
        border-radius: 5px;
        padding: 1em;
        background-color: $brand-green;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: #fff;
        }
      }
    }

    .form.live-chat-input {
      min-width: 34em;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .placeholder-text {
        border: 1px solid #eaeaea;
        border-radius: 1.6em;
        resize: none;
        padding: 0.8em 1em 0.7em 1em;
        font-size: 1.6em;
        color: $black-light;
        line-height: 1;
        overflow: hidden;
        white-space: nowrap;
        width: 18em;
      }

      .button {
        border-radius: 10em;
        padding: 1em;
        background-color: $brand-green;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }

    .message {
      min-width: 24em;
      max-width: 28em;
      background-color: #f9f9f9;
      border: 1px solid #dcdcdc;
      box-shadow: 0 0 4px 0 rgba(206, 206, 206, 0.25);
      color: #7a7a7a;
      border-radius: 8px;
      padding: 1.5em;
      overflow: hidden;

      &.message--confirm-appt {
        min-width: 28em;
      }

      &.message--appt {
        background-color: #fff;
      }

      & + .message {
        margin-top: 3.2em;
      }

      .message-text {
        font-size: 1.6em;
      }

      .question-text {
        font-size: 1.6em;
        margin-bottom: 0.5em;
      }

      .pulldown {
        margin: 0;
        width: 100%;
        font-size: 70%;
      }

      .button {
        background-color: $brand-green;
        margin: 1.5em -1.5em -1.5em;
        color: #fff;
        text-align: center;
        padding: 1em 1.5em;

        .placeholder-text {
          font-size: 1.5em;
          @include medium;
        }
      }

      .upload-button {
        text-align: center;
        margin: 1.6em 0;
        display: block;
        border: 1px dashed #ddd;
        @include medium;

        .placeholder-text {
          font-size: 1.6em;
          padding: 1em;
          color: #9b9b9b;
          @include uppercase;
        }
      }

      .more-button {
        text-align: center;
        padding: 1em 1.5em;
        border-color: #cfcfcf;
        color: #9b9b9b;
        background-color: #fff;
        font-size: 1em;

        .placeholder-text {
          font-size: 1.3em;
          @include medium;
        }
      }

      .day-select-control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1.6em 0;

        .day-label {
          @include uppercase;
          font-size: 1.6em;
          color: $black-light;
        }

        .previous,
        .next {
          svg {
            fill: $brand-green;
          }
        }

        .previous {
          opacity: 0.15;
        }
      }

      .time-slot {
        border: 1px solid $brand-green;
        color: $brand-green;
        font-size: 1.5em;
        border-radius: 2px;
        text-align: center;
        padding: 0.5em 0.5em 0.3em;
        margin-bottom: 0.25em;
      }

      .propose-time {
        text-align: center;
        margin-top: 2em;

        .placeholder-text {
          color: $black-lighter;
          font-size: 1.2em;
        }
      }

      .confirm-title {
        text-align: center;
        @include uppercase;
        font-size: 1.3em;
        color: $black-light;
        @include medium;
        margin-bottom: 1.5em;
      }

      .confirm-img {
        margin: 0 auto 1.5em;
        max-width: 5em;
      }

      .confirm-chosen-time {
        margin-bottom: 0.8em;
        text-align: center;
        color: #000;
        @include medium;
        font-size: 1.4em;
      }

      .confirm-change {
        margin-bottom: 1em;
        text-align: center;
        text-decoration: underline;
        text-transform: uppercase;
        font-size: 1.3em;
        color: $black-light;

        .placeholder-text {
          text-decoration: underline;
        }
      }

      .confirm-email-q {
        margin-bottom: 0.8em;
        color: #000;
        @include medium;
        font-size: 1.4em;
      }

      .confirm-email {
        font-size: 1.4em;
        padding-bottom: 0.4em;
        border-bottom: 1px solid $black;
        margin-bottom: 1em;
        color: $black-light;
      }

      .confirm-summary {
        margin-bottom: 0.8em;
        text-align: center;
        color: $black-light;
        @include medium;
        font-size: 1.4em;
      }

      .avatar-wrap {
        margin: 0 auto 1.5em;
        max-width: 4.3em;
        position: relative;

        .avatar {
          @include avatar(4.3em, 1.6em, 100px);
        }

        .check {
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          background-color: $blue;
          color: #fff;
          width: 1.6em;
          height: 1.6em;
          line-height: 1.6em;
          border-radius: 100px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .offline-message {
      max-width: 34em;
      padding: 1.5em 0.5em;
      border-top: 1px dashed #e0e0e0;
      text-align: center;

      .placeholder-text {
        color: #9b9b9b;
        font-size: 1.4em;
        margin: 0;
        line-height: 1.4;
      }
    }

    .live-chat-message {
      max-width: 34em;
      padding: 1.2em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px dashed #e0e0e0;

      .avatar {
        @include avatar(22px, 12px, 22px);
        margin-right: 1em;
        min-width: 22px;
      }

      .placeholder-text {
        font-size: 1.6em;
        color: #9b9b9b;
      }
    }
  }
}
