// This will be shared by the edit lead gen forms and the actual lead gen forms

.continually-embedded-form {
    font-family: $font-family-sans-serif;
    border-radius: 4px;
    background-color: #fff;
    padding: 5em;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
    &.lead-capture-failure { display: block; }
    .benefits { display: none; }
    &[data-benefits="true"] {
        .benefits { display: block; }
        .form { max-width: none; }
        @include mq(min, $mq-md) {
            display: flex;
            .form, .benefits {
                flex: 1;
                width: 0;
            }
            .benefits {
                order: -1;
                margin-right: 2em;
                @include mq(min, $mq-lg) { margin-right: 4em; }
            }
        }
    }

    .form {
        padding: 3em 0;
        max-width: 440px;
        margin: 0 auto;
        h2 {
            margin-bottom: 1em;
        }
        .footer {
            background-color: #f4f4f4;
            text-align: center;
            padding: .5em;
            border-top: 1px solid #d1d1d1;
            margin-left: -3em;
            margin-right: -3em;
            margin-top: 3em;
            figure {
                max-width: 1.4em;
                margin: 0 auto;
                img {
                    opacity: 0.4;
                }
            }
        }
    }

    .benefits {
        position: relative;
        @include mq(min, $mq-sm) { padding-top: 7em; }
        h3 {
            font-family: $font-family-sans-serif;
            font-weight: 500;
            font-size: 2.8em;
        }
        p {
            font-size: 1.8em;
            line-height: 1.3;
        }
        li {
            font-size: 1.8em;
            margin-bottom: .5em;
        }
        ul.checks {
            list-style: none;
            li {
                position: relative;
                padding-left: 1.6em;
                svg {
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: .1em;
                    @include wh(16px,16px);
                    fill: $brand-primary;
                }
            }
        }
    }

    .continually-lead-capture-form {
        border-radius: 4px;
        background-color: #fdfdfd;
        border: 1px solid #d1d1d1;
        padding: 3em 3em 0 3em;
        .introduction {
            text-align: center;
            margin-bottom: 1em;
            margin-top: -3em;
            .avatar {
                @include avatar(64px,3.2em,64px);
                margin-bottom: -1em;
                transform: translateY(-50%);
            }
            .quote {
                font-weight: 400;
                font-size: 1.9em;
                &.hello {
                    font-style: italic;
                    font-size: 1.5em;
                }
                .emoji {
                    display: inline-block;
                    margin-right: .5em;
                    font-style: normal !important;
                }
            }
        }
        h3 {
            .body--docs & { font-family: $font-family-sans-serif; }
            font-weight: 500;
            font-size: 1.6em;
            line-height: 1.2;
            text-align: center;
            margin: 0 auto 1em;
            max-width: 17em;
        }
    }

    // FORM ELEMENTS
    .continually-lead-capture-form {
        fieldset {
            margin: 0 0 1em;
            input {
                font-family: $font-family-sans-serif !important;
                max-width: none;
            }
            .btn {
                font-family: $font-family-sans-serif !important;
            }
        }
        form.processing {
            position: relative;
            &:before {
                content: "";
                background: rgba(#fdfdfd,.9);
                position: absolute;
                z-index: 1;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
            }
            &:after {
                @include spinner;
                position: absolute;
                z-index: 2;
                left: 50%;
                top: 50%;
                margin-left: -15px;
                margin-top: -15px;
            }
        }
        p.booking-hint {
            text-align: center;
            font-size: 1.2em;
            color: $black-light;
        }
    }

    .powered-by {
        text-align: center;
        font-size: 1.2em;
        color: #8c8c8c;
        margin: 1em 0;
        a {
            color: #8c8c8c !important;
            text-decoration: none !important;
        }
    }

    // ERROR
    .lead-capture-failure {
        text-align: center;
        padding-bottom: 0;
        .emoji { font-size: 4em; }
        h3 {
            font-weight: 500;
            font-size: 2.4em;
            margin-bottom: $spacer;
        }
        a {
            color: $brand-primary;
        }
        p {
            margin: 0 auto 1em;
            max-width: 16em;
            &:last-of-type {
                margin-bottom: 8em;
            }
        }
    }

    label {
        &.hidden {
            @include offscreen;
        }
    }


}

.DraftEditor-editorContainer {
    * {
        font-family: $font-family-sans-serif !important;
    }
    li {
        font-size: 1em !important;
    }
}