// Choose a plan

.header--upgrade {
  align-items: center;
  justify-content: space-between;
}

.header--upgrade__breadcrumb {
  user-select: none;
  .step {
    @include inline-block;
    margin: 0 0.5em;
    font-size: 1.7em;
    color: $black-light;
    &.step--current {
      @include medium;
      color: $black;
    }
    a {
      color: $black-light;
      text-decoration: none;
      &:hover {
        color: $black;
        text-decoration: underline;
      }
    }
  }
  .arrow {
    @include inline-block;
    margin: 0 0.25em;
    font-size: 1.7em;
    color: $black-light;
  }
}

.section--upgrade-banner {
  text-align: center;
  background-image: linear-gradient(90deg, #1848e6 0%, #00dcb8 100%);
  color: #fff;
  padding-top: 8em;
  padding-bottom: 8em;
  h2 {
    font-size: 2.7em;
    @include medium;
    @include font-smoothing;
    margin-bottom: 0.3em;
  }
  p.lead {
    font-size: 1.7em;
    @include medium;
    @include font-smoothing;
    margin-bottom: 2em;
  }
}

.section--pricing-tabs {
  text-align: center;
  transform: translateY(-100%);
  .btn--pricing-tab {
    @include medium;
    @include font-smoothing;
    display: inline-block;
    border: 2px solid #fff;
    border-bottom: none;
    padding: 2em 6em;
    text-decoration: none;
    color: #fff;
    .label {
      font-size: 1.6em;
    }
    &:hover {
      color: rgba(#fff, 0.5);
    }
    &.active {
      background-color: #fff;
      color: $black;
    }
  }
  .btn--pricing-tab--annual {
    border-top-left-radius: 8px;
    border-right: none;
  }
  .btn--pricing-tab--monthly {
    border-top-right-radius: 8px;
    border-left: none;
  }
}

.pricing-plans-section {
  padding-top: 6em;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 2em;
  text-align: center;
  .container {
    width: 100%;
    max-width: 1390px;
  }
  .cols {
    margin-bottom: 3.2em;
    border-radius: 4px;
    @include mq(min, $mq-md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .col {
    border: 1px solid #e1e1e1;
    padding: 2.7em 2.5em;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 2em;
    @include mq(max, 850px) {
      margin-bottom: 4.5em;
    }
    @include mq(min, $mq-md) {
      flex: 1 auto;
      margin-left: 1em;
      margin-right: 1em;
    }
  }
  .cols--plans {
    margin-top: 2em;
    margin-left: -1.5em;
    margin-right: -1.5em;
    .plan {
      flex-basis: auto;
      width: 32em;
      max-width: 50em;
      position: relative;
      // margin-left: 0;
      &--recommended {
        border-color: $blue;
        position: relative;
        border-radius: 0 0 5px 5px;
        &:before {
          content: "Recommended";
          position: absolute;
          bottom: 100%;
          left: -1px;
          right: -1px;
          text-transform: uppercase;
          background: $blue;
          color: white;
          height: 3em;
          text-align: center;
          font-size: 1.2em;
          font-weight: 500;
          line-height: 1;
          border-radius: 6px 6px 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &__page-views-range {
        cursor: pointer;
        @include inline-block;
        /*removes default webkit styles*/
        -webkit-appearance: none;
        /*fix for FF unable to apply focus style bug */
        border: none;
        background: none;
        /*required for proper track sizing in FF*/
        width: 100%;
        max-width: 200px;
        border-radius: 7.5px;
        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 7px;
          background: rgba(black, 0.08);
          border: none;
          border-radius: 3px;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          border: 4px solid $blue-secondary;
          @include wh(2em, 2em);
          border-radius: 50%;
          background: #fff;
          margin-top: -0.7em;
        }
        &:focus {
          outline: none;
        }
        &::-moz-range-track {
          width: 100%;
          height: 7px;
          background: rgba(black, 0.08);
          border: none;
          border-radius: 3px;
        }
        &::-moz-range-thumb {
          border: 4px solid $blue-secondary;
          @include wh(2em, 2em);
          border-radius: 50%;
          background: #fff;
          box-sizing: border-box;
        }

        /*hide the outline behind the border*/
        &:-moz-focusring {
          outline: none;
          outline-offset: -1px;
        }

        &::-moz-focus-outer {
          border: 0;
        }

        &::-ms-track {
          width: 100%;
          height: 7px;
          /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
          background: transparent;
          /*leave room for the larger thumb to overflow with a transparent border */
          border-color: transparent;
          border-width: 7px 0;
          /*remove default tick marks*/
          color: transparent;
        }
        &::-ms-fill-lower {
          background: $blue-secondary;
          border: none;
          border-radius: 10px;
        }
        &::-ms-fill-upper {
          border-radius: 10px;
          background: rgba(black, 0.08);
          border: none;
        }
        &::-ms-thumb {
          border: 4px solid $blue-secondary;
          @include wh(2em, 2em);
          border-radius: 50%;
          background: #fff;
          box-sizing: border-box;
        }
        &::-ms-tooltip {
          display: none;
        }
      }
      &__step-btn {
        border: 1px solid $blue-secondary;
        border-radius: 4px;
        background: transparent;
        line-height: 1;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 2em;
        cursor: pointer;
        &[disabled] {
          opacity: 0.35;
        }
        svg {
          width: auto;
          height: auto;
        }
      }
      &__input-number {
        font-size: 1em;
        border: 0;
        padding: 0;
        text-align: center;
        border-radius: 0;
        border-bottom: 1px dashed;
        -moz-appearance: textfield;
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
    .col {
      .bundle {
        margin-bottom: 3.6em;
        min-height: 51em;
      }
      header {
        padding: 0em 1em 1.4em 1em;
        position: relative;
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 1.5em;
        margin-left: -2.5em;
        margin-right: -2.5em;
        h4 {
          @include medium;
          font-size: 2.4em;
          color: $blue-dark;
          margin-bottom: 0.3em;
          text-align: center;
        }
      }
      .features {
        padding: 2.4em 0;
        .feature {
          margin: 0.4em 0;
          display: flex;
          align-items: center;
          figure {
            flex-grow: 0;
            margin-right: 0.8em;
            width: 2.2em;
          }
          span {
            flex: 1;
            font-size: 1.3em;
            @include uppercase;
            @include medium;
            a {
              text-decoration: none;
              color: inherit;
            }
          }
        }
        .feature--desc {
          color: $black-light;
          margin: 1em 0 0;
          p {
            font-size: 1.6em;
          }
        }
      }
      .price {
        text-align: center;
        padding: 2em 1.6em;
        @include mq(min, $mq-md) {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .centered {
          min-height: 7.4em;
        }

        .flag {
          position: absolute;
          background-color: $blue;
          color: #fff;
          font-size: 1.2em;
          padding: 0.5em 0.8em 0.3em;
          top: 15%;
          right: 0;
          border-radius: 2px 0 0 2px;
          transform: translate(0, -100%);
          @include medium;
          @include font-smoothing;
        }
        .rrp {
          @include light;
          font-size: 2.5em;
          position: relative;
          line-height: 1;
          display: inline-block;
          border-bottom-color: $blue !important;
          margin-bottom: 0.5em;
          margin-left: -0.5em;
          &:after {
            position: absolute;
            z-index: 1;
            content: "";
            border-bottom: 1px solid;
            left: 0;
            right: 0;
            top: 42%;
          }
        }
        sup {
          @include light;
          font-size: 3em;
          top: -0.7em;
          left: -0.1em;
          margin-left: -0.5em;
          color: #22314d;
          position: relative;
        }
        .value {
          @include light;
          font-size: 5.2em;
          color: #22314d;
          &--sm {
            font-size: 4.2em;
          }
        }
        .supp {
          @include bold;
          @include uppercase;
          font-size: 1.2em;
          line-height: 1.25;
          color: rgba(#22314d, 0.25);
          span {
            display: block;
          }
        }
      }
      .cta {
        text-align: center;
        margin-bottom: 2.5em;
        p {
          font-size: 1.2em;
          color: #b3b3b3;
          text-align: center;
          line-height: 1.75;
          margin-top: 0.5em;
        }
      }
      .plan-info {
        text-align: center;
        margin-top: 1em;
        p:first-child {
          color: $blue-dark;
          margin-bottom: 0.5em;
        }
        p:last-child {
          opacity: 0.71;
          font-size: 1.4em;
          color: $blue-dark;
          text-align: center;
          margin-bottom: 0;
        }
      }
      .btn--primary {
        display: inline-block;
        border-color: transparent;
        background-color: transparent;
        &:hover {
          color: white;
        }
      }
      .btn--primary,
      .btn--orange {
        padding: 0.7em 3em 0.6em;
        border-radius: 26.5px;
        transition: 0.2s;
      }
    }
    .plan--essentials {
      .btn--primary {
        background-color: $blue;
        &:hover {
          background-color: darken($blue, 7%);
        }
      }
    }

    .plan--business {
      .feature {
        color: $blue;
      }
      .btn--primary {
        border-color: $blue;
        color: $blue;
        &:hover {
          background: $blue;
        }
      }
      .value {
        font-size: 4.3em;
      }
    }
    .plan--enterprise {
      flex: 1;
      padding: 4em 1em;
      background-color: rgba(#4287d7, 0.03);
      header {
        border: 0;
        margin: 0;
        padding: 0;
        h4 {
          margin: 0;
        }
      }
      .bundle {
        justify-content: center;
        margin-bottom: 0;
      }
      .price {
        padding-top: 1em;
        sup {
          color: $brand-red;
        }
        .value {
          color: $brand-red;
          font-size: 4.5em;
        }
        .supp {
          color: rgba($brand-red, 0.5);
          &--from {
            min-height: auto;
            margin-bottom: 0.8em;
          }
        }
      }
      .feature {
        color: $brand-red;
      }
      .btn--primary {
        border-color: $brand-red;
        color: $brand-red;
        &:hover {
          background-color: $brand-red;
        }
      }
    }
  }
  .cols--features {
    ul {
      margin: 0;
    }
    li {
      @include font-smoothing;
      list-style: none;
      margin: 0;
      padding: 0.6em 0 0.4em;
      font-size: 1.8em;
      color: $black-light;
      strong {
        @include medium;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid #eee;
      }
    }
  }
  h3 {
    font-weight: 500;
    margin-bottom: 0.5em;
  }
}

.section--upgrade-faqs {
  padding-top: 14em;
  padding-bottom: 14em;
  h3 {
    text-align: center;
    font-size: 3.6em;
    @include mq(min, $mq-sm) {
      font-size: 4.8em;
    }
    @include light;
    color: $blue-dark;
  }
  .faqs {
    margin-top: 6.4em;
    @include mq(min, $mq-md) {
      columns: 2;
      column-gap: 6.4em;
    }
  }
  .faq {
    break-inside: avoid;
    .q {
      @include medium;
      @include uppercase;
      font-size: 1.5em;
      color: $blue-dark;
      margin-bottom: 0.5em;
    }
    .a {
      p {
        font-size: 2em;
        @include mq(min, $mq-md) {
          font-size: 1.8em;
        }
        color: $black-light;
        @include font-smoothing;
        line-height: 1.618;
        margin-bottom: 1.618em;
      }
      a {
        color: $brand-blue;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.section--billing {
  @include mq(min, $mq-md) {
    display: flex;
  }
  aside {
    flex: 0 0 42em;
    background-color: #f3f3f3;
  }
  main {
    flex: 1;
    position: relative;
  }
}

// Billing

.body--upgrade {
  &:not(.body--upgrade-billing) {
    .header-coupon-banner {
      display: block;
    }
  }
  .header--app {
    .header-back-btn {
      display: inline-flex;
    }
    &__actions {
      .arrow-down2,
      .header--app__user__panel {
        display: none;
      }
    }
  }
  .main--app {
    @include mq(min, $mq-md) {
      display: flex;
    }
    flex: 1;
  }
  .app--content {
    flex: 1;
    position: relative;
  }
  .powered-by-stripe {
    display: inline-block;
    margin-left: 0.2em;
  }
}

.body--upgrade-billing {
  .header--app {
    &__branding {
      display: none;
    }
    .header-back-btn {
      display: inline-flex;
    }
  }
}

.aside {
  &--upgrade {
    flex: 0 0 42em;
    background-color: #f3f3f3;
    padding: 3em 2em;
    &-billing {
      @include mq(min, $mq-md) {
        padding: 4em 5.2em;
      }
    }
    h3 {
      font-size: 2.2em;
      @include medium;
    }
    .selected-plan {
      padding: 2.2em 3.5em 3em;
      color: #fff;
      background-image: linear-gradient(129deg, #303dc8 0%, #4c7fff 100%);
      border: 1px solid #e1e1e1;
      border-radius: 6px 6px 0 0;
      .plan-title {
        font-size: 2.4em;
        @include medium;
        @include font-smoothing;
        margin-left: -1.5em;
        margin-right: -1.5em;
        margin-bottom: 1em;
        padding-left: 1.5em;
        padding-right: 1.5em;
        padding-bottom: 0.8em;
        border-bottom: 1px solid #e9e9e9;
      }
      .plan-desc {
        font-size: 1.6em;
        color: rgba(#fff, 0.68);
        @include medium;
        @include font-smoothing;
        margin-bottom: 0.4em;
      }
      .plan-price {
        position: relative;
        sup {
          @include light;
          font-size: 3em;
        }
        .value {
          font-size: 4em;
          @include light;
          @include font-smoothing;
        }
      }
      .plan-freq {
        color: white;
        font-size: 1.8em;
      }
    }
  }
  &__header {
    margin: -3em -2em 5em -2em;
    padding: 2.5em 3.4em;
    background: #646464;
    color: white;
    h5 {
      margin-bottom: 0.3em;
      font-weight: 400;
      display: flex;
      align-items: center;
      .flag {
        margin-left: 0.6em;
        font-size: 0.5em;
      }
    }
    p {
      font-size: 1.4em;
      margin-bottom: 0;
      span {
        font-weight: 500;
      }
    }
  }
  &__content {
    padding: 0 2em;
    h2 {
      font-weight: 400;
      .flag {
        font-size: 0.375em;
        margin-left: 0.6em;
      }
    }
  }
}

.plan-info {
  &__progress {
    margin-bottom: 3.5em;
    em {
      font-size: 1.5em;
      font-style: italic;
      color: #8c8c8c;
      margin-right: 0.5em;
    }
  }
  &__text {
    color: #646464;
  }
  &__number {
    font-weight: 500;
    font-size: 1.3em;
    color: #6e6e6e;
    line-height: 1.1;
    margin-right: 0.5em;
  }
  &__apps {
    margin-top: 4em;
    p {
      display: flex;
      align-items: center;
      &:before {
        content: "\f00c";
        color: $brand-primary;
        margin-right: 0.7em;
        font-family: "Font Awesome 5 Pro";
        font-size: 0.8em;
        min-width: 12px;
      }
      &.color-red {
        &:before {
          content: "\f023";
          color: inherit;
          font-weight: 900;
        }
      }
    }
  }
  p {
    color: #646464;
    span {
      margin-right: 0.5em;
      color: #646464;
    }
  }
  .flag {
    font-size: 0.7em;
    background-color: $blue;
    color: white;
    opacity: 0.29;
    i {
      font-size: 0.8em;
    }
  }
}

.upgrade-img {
  min-height: 162px;
  max-width: 652px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../images/upgrade/plans-img.jpg);
  color: white;
  text-align: center;
}

.billing--select-a-plan {
  .option {
    position: relative;
    &:last-of-type {
      label {
        border-bottom: none;
        border-radius: 0 0 3px 3px;
      }
    }
  }
  label {
    display: block;
    padding: 1.6em 10em 1.6em 5em;
    background-color: rgba(#fff, 0.6);
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    .price {
      font-size: 1.4em;
      margin-bottom: 0.2em;
      color: $black;
    }
    .frequency {
      font-size: 1.2em;
      color: $black-light;
    }
    .flag {
      position: absolute;
      right: 1.6em;
      @include vertical-align;
      background-color: $blue;
      border-radius: 2px;
      font-size: 1.2em;
      @include bold;
      letter-spacing: 0;
      @include font-smoothing;
    }
  }
  input[type="radio"] {
    position: absolute;
    left: 1em;
    @include vertical-align;
    font-size: 1.6em;
  }
  input[type="radio"]:checked + label {
    background-color: #fff;
    .price {
      color: $brand-blue;
    }
  }
}

.billing--payment-form {
  max-width: 47em;
  label {
    @include uppercase;
    font-size: 1.4em;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="url"],
  input[type="tel"],
  input[type="search"],
  input[type="number"],
  input[type="time"],
  textarea {
    border: 2px solid #ddd;
    border-radius: 2px;
    padding: 1em 1em 0.8em;
    background-color: #fff;
    color: $black;
    line-height: 1.15;
    font-size: 1.5em;
    max-width: none;
  }
  @include mq(min, $mq-md) {
    .colgroup {
      display: flex;
      justify-content: space-between;
      .col {
        width: 30%;
        width: calc(33.33% - 1.6em);
      }
    }
  }
  .pulldown {
    width: 100%;
    .pulldown-inner {
      border: 2px solid #ddd;
      border-radius: 2px;
    }
  }
  .StripeElement {
    background-color: white;
    padding: 1.5em 1em;
    border: 2px solid #ddd;
    border-radius: 2px;
  }
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  #card-errors {
    color: $error;
    @include medium;
    margin-top: 1em;
    font-size: 1.4em;
  }

  .form--processing__overlay {
    display: none;
  }

  &.form--processing {
    position: relative;
    .form--processing__overlay {
      display: block;
      position: absolute;
      z-index: 10;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(#fff, 0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      .spinner {
        @include spinner(3.2em, 2px);
        margin: 0 auto 1em;
      }
      .message {
        margin-bottom: 0;
        @include italic;
        font-size: 1.3em;
      }
    }
  }
}

.billing--payment-form-errors {
  max-width: 47em;
  background-color: #efefef;
  padding: 2em;
  margin-bottom: 3.2em;
  border-radius: 4px;
  p {
    i {
      margin-right: 0.3em;
    }
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}

// Confirmation

.upgrade-success-message {
  background-color: $brand-blue;
  background-image: url(../images/shared/thanks-flow.jpg);
  background-size: cover;
  background-position: 50% 100%;
  text-align: center;
  padding: 9.8em 3.2em 6em 3.2em;
  position: relative;
  color: #fff;
  margin-bottom: 7em;
  @include font-smoothing;
  h3 {
    font-size: 3.6em;
    margin-bottom: 0.5em;
    @include medium;
  }
  p {
    font-size: 2em;
    margin-bottom: 2em;
    &.help {
      font-size: 1.4em;
    }
  }
  .emoji {
    margin-bottom: 0.2em;
    font-size: 9.9em;
  }
  a {
    color: #fff;
  }
  .close-message {
    position: absolute;
    z-index: 1;
    right: 1.6em;
    top: 1.6em;
    &:hover {
      opacity: 0.5;
    }
    i {
      font-size: 2em;
    }
  }
}

// Opportunities

.page-view-limit {
  padding: 1.2em 1.2em 1.6em;
  .panel {
    background-color: #f2f2f2;
    padding: 3.2em 3em 2em;
    position: relative;
    @include transition(padding);
    h3 {
      @include medium;
      font-size: 1.9em;
      margin-bottom: 0;
    }
    p {
      max-width: 36em;
    }
    .collapsed-content {
      padding-top: 1em;
      overflow: hidden;
      max-height: 1000px;
      @include transition(max-height);
    }
    .collapse-panel {
      cursor: pointer;
      position: absolute;
      z-index: 1;
      transform: translateY(-50%);
      @include transition;
      right: 3em;
      top: 3em;
      color: $black-light;
      text-decoration: none;
      transform: translateY(-50%) rotate(180deg);
      transform-origin: center;
      &:hover {
        color: $black;
      }
      i {
        font-size: 3em;
      }
    }
    &.collapsed {
      padding: 1.6em 3em 0.2em;
      .collapsed-content {
        max-height: 0;
        pointer-events: none;
      }
      .collapse-panel {
        top: 2.5em;
        transform: translateY(-50%);
      }
    }
  }
}

.modal#modal-page-views {
  border: none;
  padding: 0;
  border-radius: 2px;
  a.close-modal {
    color: #fff;
  }
  .modal-header {
    padding: 4em;
    border-radius: 2px 2px 0 0;
    color: #fff;
    background-image: linear-gradient(129deg, #303dc8 0%, #4c7fff 100%);
    text-align: center;
    h2 {
      @include medium;
      font-size: 2.7em;
      margin-bottom: 0.3em;
      @include font-smoothing;
    }
    p {
      font-size: 1.8em;
      margin-bottom: 0;
      @include font-smoothing;
    }
  }
  .modal-body {
    .plan-comparison {
      padding: 1.6em 4em;
      display: flex;
      margin-bottom: 3.2em;
      background-color: #fcfcfc;
      position: relative;
      .arrow {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 35%;
        transform: translateX(-50%);
      }
      .plan {
        flex: 1;
        text-align: center;
        padding: 1.6em 2em;
      }
      .flag {
        display: inline-block;
        font-size: 1.4em;
        margin-bottom: 2em;
      }
      .count {
        @include light;
        font-size: 3.2em;
      }
      .unit {
        @include medium;
        font-size: 1.4em;
      }
      ul {
        margin: 1.6em auto;
        text-align: left;
        max-width: 18em;
        li {
          font-size: 1.4em;
        }
      }
    }
    .cta {
      text-align: center;
      padding: 0 4em 4em;
      p {
        margin-left: auto;
        margin-right: auto;
        max-width: 23em;
      }
    }
  }
}

.plan-features {
  margin: 0;
  padding-left: 1em;
  padding-right: 0;
  text-align: left;
  &__title {
    color: #5e5e5e;
    font-weight: 500;
    font-size: 1.6em;
    margin-bottom: 1em;
  }
  li {
    font-size: 1.6em;
    color: #757575;
    display: flex;
    align-items: baseline;
    line-height: 1.5;
    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Pro";
      color: $blue;
      font-size: 0.9em;
      margin-right: 0.5em;
    }
  }
}

.price-tabs {
  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__btn {
    font-size: 1.4em;
    text-align: center;
    line-height: 1;
    padding: 0.8em 2.5em 0.6em 1.6em;
    color: #6d6d6d;
    background-color: #ececec;
    border-radius: 18px;
    border: 0;
    text-transform: uppercase;
    cursor: pointer;
    & + button {
      margin-left: -2em;
      padding-left: 2.8em;
      padding-right: 1.6em;
    }
    & + .active {
      padding-left: 1.6em;
      padding-right: 1.6em;
    }
    &.active {
      background: #44d0bd;
      color: #ffffff;
      position: relative;
      z-index: 1;
      padding-right: 1.6em;
    }
  }
}

.plan-lg-container {
  @include mq(min, $mq-md) {
    max-width: 83em;
  }
  &__left {
    flex: 2.3 auto;
  }
  &__right {
    flex: 1 auto;
  }
}

.testimonial-carousel {
  background-color: #fff;
  padding: 5em 3.2em 10em;
  text-align: center;
  border: 1px solid #dadada;
  box-shadow: none;
  @include mq(min, $mq-md) {
    max-width: 83em;
  }
  li {
    font-size: 1em;
  }
  .stars {
    margin-bottom: 2.8em;
    svg {
      width: 131px;
      height: 18px;
    }
  }

  .custom-navigation {
    .flex-control-nav {
      width: auto;
      left: 0;
      right: 0;
      bottom: 4.6em;
    }
    .flex-control-paging li {
      a {
        background-color: #ccc;
      }
      a.flex-active {
        background-color: $brand-blue;
      }
    }
    a.flex-prev,
    a.flex-next {
      @include mq(max, $mq-sm) {
        display: none;
      }
      position: absolute;
      z-index: 3;
      top: 50%;
      transform: translateY(-50%);
      font-size: 3.2em;
      text-decoration: none;
      @include transition;
      color: $black-lighter;
      &:hover {
        color: $black-light;
      }
    }
    a.flex-prev {
      left: 1em;
    }
    a.flex-next {
      right: 1em;
    }
  }
  figure {
    max-width: 6em;
    margin: 0 auto 1.6em;
    border-radius: 3em;
    overflow: hidden;
    img {
      display: block;
    }
  }
  .author {
    @include italic;
    color: #818181;
    font-size: 2.7em;
    margin-bottom: 1em;
    @include font-smoothing;
  }
  blockquote {
    color: #818181;
    p {
      font-size: 1.8em;
      @include italic;
      max-width: 36em;
      margin: 0 auto;
      @include font-smoothing;
      line-height: 1.37;
    }
  }
}

.plan-integrations {
  margin-left: -2.5em;
  margin-right: -2.5em;
  &__list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 30px 10px 0;
    border-top: 1px solid #e2e2e2;
  }
  &__item {
    margin-left: 2em;
    margin-right: 2em;
    margin-bottom: 4em;
    height: 4.8em;
    font-size: 1em;
    img {
      height: auto;
    }
  }
}
