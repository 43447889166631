.embed-code {
  pre {
    font-size: 1.3em;
    line-height: 1.8;

    td & {
      font-size: 0.9em;
    }

    border-radius: 3px;
    background-color: $grey;
    padding: 2em;

    td & {
      padding: 1em;
    }

    overflow: auto;
    -moz-tab-size: 2;
    -o-tab-size: 2;
    tab-size: 2;
    -ms-word-break: normal;
    word-break: normal;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }
  &--light {
    pre {
      font-size: 1.3em;
      padding: 0.5em 1em;
      display: inline-block;
    }
  }
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
.code {
  &--grey {
    background-color: rgba(0, 0, 0, 0.1);
    color: $black;
  }
}

li .embed-code {
  font-size: 1em;
  pre {
    font-size: 0.875em;
  }
}
