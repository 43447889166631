@keyframes alert {
  0% {
    top: -15em;
  }
  100% {
    top: 0;
  }
}

@keyframes closealert {
  0% {
    top: 0;
  }
  100% {
    top: -15em;
  }
}

.alert {
  padding: 2em 4em 2em 2em;
  text-align: center;
  position: fixed;
  z-index: $zindex-alert;
  left: 0;
  right: 0;
  top: 0;
  animation: alert 0.3s 1 ease-in;
  a {
    color: $black;
    &:hover {
      color: rgba($black, 0.5);
    }
  }
}

.alert--auto-close {
  animation: alert 0.5s 0s 1 ease-in backwards,
    closealert 0.5s 4s 1 ease-in forwards;
}

.alert__close {
  position: absolute;
  right: 1em;
  @include vertical-align;
  border: none;
  background-color: transparent;
  padding: 0;
  color: inherit;
  line-height: 1;
  cursor: pointer;
  padding: 1em;
  &:hover {
    svg {
      opacity: 0.5;
    }
  }
  &--persistent {
    fill: white;
    z-index: 2;
  }
  svg {
    @include wh(2em);
  }
}

.alert__message {
  @include inline-block;
  @include regular;
  font-size: 1.8em;
  // text-shadow: 0 1px 0 rgba($black,0.33);
  & + .btn {
    margin-left: 1em;
  }
}

.alert--brand {
  background-image: linear-gradient(145deg, #1848e6 0%, #00dcb8 100%);
  color: $white;
  button svg {
    fill: $white;
  }
  a {
    color: $white;
    &:hover {
      color: rgba($white, 0.5);
      border-color: rgba($white, 0.5);
    }
  }
  .btn--link {
    text-decoration: underline;
  }
}

.alert--success {
  background-color: #d1f1c4;
  color: $black;
  button svg {
    fill: $black;
  }
}

.alert--info {
  background-color: #d9edf7;
  color: $black;
  button svg {
    fill: $black;
  }
}

.alert--warning {
  background-color: #fcf8e3;
  color: $black;
  button svg {
    fill: $black;
  }
}

.alert--danger,
.alert--error {
  background-color: $error;
  color: $white;
  button svg {
    fill: $white;
  }
  hr {
    border-top-color: #fff;
  }
  a {
    color: $white;
    &:hover {
      color: rgba($white, 0.5);
    }
  }
}

.persistent-alert {
  position: relative;
  background-image: linear-gradient(to right, #0050e8 10%, $brand-green);
  border-bottom: 1px solid rgba(#fff, 0.05);
  color: #fff;
  text-align: center;
  padding: 1.2em;
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url(../images/repeating/persistent-alert.png);
  }
  &--warning,
  &--danger {
    &:before {
      content: none;
    }
    .btn {
      border-radius: 4px;
      margin: 0 1em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      text-decoration: none;
    }
  }
  &--warning {
    background-image: linear-gradient(136deg, #f2994a 0%, #f2c94c 100%);
  }
  &--danger {
    background-image: linear-gradient(-48deg, #f57a51 0%, #d11330 100%);
  }
  p {
    font-size: 1.5em;
    margin: 0;
    position: relative;
    z-index: 2;
    a {
      color: #fff;
      @include medium;
      text-decoration: underline;
      &:hover {
        color: rgba(#fff, 0.8);
        text-decoration: none;
      }
    }
  }
}

.alert--onboarding-conv-builder {
  padding: 1.5em 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title,
  .content,
  button {
    position: static;
    flex-grow: 0;
  }
  .title {
    font-size: 2em;
  }
  .alert__message {
    font-size: 1.5em;
  }
  button {
    top: auto;
    right: auto;
    transform: translateY(0);
  }
}

a.close-persistent-alert {
  position: absolute;
  right: 1.2em;
  top: 1.5em;
  text-decoration: none;
  color: $black-lighter;
  @include transition;
}