.labs-grid {
    @include mq(min, $mq-md) {
        display: flex;
        flex-wrap: wrap;
    }
    a {
        display: flex;
        align-items: center;
        margin-right: 3.2em;
        margin-bottom: 3.2em;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 2em;
        color: $black;
        text-decoration: none;
        @include mq(min, $mq-md) { width: calc(50% - 3.2em); }
        figure {
            margin-right: 2em;
            width: 12em;
        }
        .copy {
            flex: 1;
            h4 {
                font-size: 1.8em;
                margin: 0;
            }
            p {
                font-size: 1.5em;
                margin: 0.5em 0 0;
            }
        }
    }
}