body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main--app {
  position: relative;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: $spacer;
  padding-left: $spacer;
  padding-top: $spacer;
  padding-bottom: $spacer;
  max-width: 100%;
  @include clearfix;
  // @include mq(min, $mq-sm) { width: 540px; }
  @include mq(min, $mq-md) {
    width: 720px;
  }
  @include mq(min, $mq-lg) {
    width: 960px;
  }
  @include mq(min, $mq-xl) {
    width: 1140px;
  }
  @include mq(min, $mq-xxl) {
    width: 1340px;
  }
}

.container-xs,
.container--xs {
  max-width: 400px;
}
.container-sm,
.container--sm {
  max-width: 540px;
}
.container-md,
.container--md {
  max-width: 720px;
}
.container-lg,
.container--lg {
  max-width: 960px;
}
.container-xl,
.container--xl {
  max-width: 1140px;
}
.container-xxl,
.container--xxl {
  max-width: 1340px;
}
.container-fluid,
.container--fluid {
  width: 100%;
}

.container-left {
  margin-left: 0;
  margin-right: 0;
}

.slice {
  position: relative;
  padding-top: ($spacer * 2);
  padding-bottom: ($spacer * 2);
}

.slice-header,
.slice--header {
  text-align: center;
  p.lead {
    color: $black-light;
    margin: 0 auto $spacer * 2;
    strong {
      color: $black;
      @include medium;
    }
  }
}
