.pulldown {
  &--autowidth {
    flex-basis: auto;
    max-width: 50%;
  }
}

.pulldown--sm,
.pulldown--short,
.pulldown--tight,
.pulldown--minimal,
.pulldown--faux-input {
  @include inline-block;
}

.pulldown-inner {
  height: 2.5em;
  position: relative;
  // margin-bottom: 1.5em;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  &.error {
    border: 1px solid $error !important;
  }
}

.pulldown-select {
  display: inline-block;
  height: 2.5em;
  padding-left: 1em;
  padding-right: 3.125em;
  position: relative;
  width: 100%;
  margin: 0;
  background: none;
  border: 1px solid transparent;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6em;
  cursor: pointer;
  &::-ms-expand {
    display: none;
  }
  &:focus {
    outline: none;
  }
  &[disabled] {
    background: #f6f6f6;
    height: 100%;
  }
}

.pulldown-arrow {
  display: block;
  overflow: visible;
  pointer-events: none;
  position: absolute;
  z-index: 2;
  right: 0.75em;
  @include vertical-align;
  &:after {
    content: " ";
    display: block;
    width: 22px;
    height: 22px;
    background-size: 22px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiBoZWlnaHQ9IjQ4IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSI0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gICAgPHBhdGggZD0iTTcgMTBsNSA1IDUtNXoiLz4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==);
  }
}

.pulldown--inline {
  @include inline-block;
  .pulldown-inner {
    margin-bottom: 0;
  }
  .pulldown-arrow {
    right: 0;
  }
}

.pulldown--sm {
  .pulldown-inner {
    height: 3.23em;
  }
  .pulldown-select {
    padding-left: 1em;
    padding-right: 2.4em;
    // height: 2.1em;
    height: 2.5em;
  }
}

.pulldown--short {
  .pulldown-inner {
    height: 4.6em;
  }
  .pulldown-select {
    padding-left: 1em;
    padding-right: 2.4em;
    height: 2.8em;
  }
}

.pulldown--tags {
  z-index: auto !important;
  .pulldown-inner {
    padding-top: 0.5em;
    padding-left: 0.8em;
    min-width: 20em;
    min-height: 4.5em;
    padding-right: 2.5em;
    max-width: 40em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
  }
  .pulldown-placeholder {
    display: inline-block;
    color: #aaa;
    margin-left: 1em;
  }
  .flag--tag {
    font-size: 14px;
  }
  .flyout-search {
    left: 0;
    min-width: 100%;
    &__search {
      border-top: 0;
      input[type="search"] {
        font-size: 1.4em !important;
      }
    }
    &__tag {
      margin-right: 0 !important;
    }
  }
}

.pulldown--tight {
  .pulldown-inner,
  .pulldown-select {
    height: 1.75em;
    padding-left: 0.3125em;
    padding-right: 0.95em;
  }
  .pulldown-select {
    font-size: 1.3em;
  }
}

.pulldown--medium {
  .pulldown-inner,
  .pulldown-select {
    height: 2.1875em;
  }
}

.pulldown--minimal {
  .pulldown-inner {
    background-color: transparent;
    border: none;
    margin: 0;
    height: auto;
  }
  .pulldown-select {
    padding: 0.125em 2em 0.25em 0.25em;
  }
}

.pulldown--faux-input {
  .pulldown-inner {
    background-color: transparent;
    border: none;
    border-bottom: 1px dashed $gray-500;
    margin: 0;
    height: auto;
  }
  .pulldown-select {
    padding: 0.125em 2em 0.25em 0.25em;
  }
  .pulldown-arrow {
    opacity: 0.2;
  }
}

.pulldown--inline {
  .pulldown-inner {
  }
  .pulldown-select {
    font-size: inherit;
  }
}

.pulldown:hover .pulldown-arrow:after {
  color: #333;
}

.pulldown--grey {
  position: absolute !important;
  z-index: 1;
  right: 1em;
  top: 0.8em;
  .pulldown-inner {
    border-color: #cecece;
    background: #e6e6e6;
    color: black;
  }
  .pulldown-select {
    color: black !important;
    font-weight: 500;
    padding-left: 0.5em;
  }
}
