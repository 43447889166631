.tooltip-container {
  position: relative;
}

.tooltip {
  $tooltip: &;
  position: absolute;
  z-index: $zindex-tooltip;
  opacity: 0;
  pointer-events: none;
  &--show {
    animation: showTooltip 0.1s ease;
    opacity: 1;
    pointer-events: auto;
  }
  &--top {
    bottom: 99%;
    bottom: calc(100% - 1px);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    &:after {
      top: calc(100% - 1em);
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(#000, 0);
      border-top-color: #000;
      border-width: 6px;
      margin-left: -6px;
    }
    .tooltip__bubble {
      margin-bottom: 1em;
    }
  }
  &--bottom {
    top: 150%;
    top: calc(100% + 1.6em);
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    -webkit-font-smoothing: antialiased;
    &:after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: #000;
      border-width: 6px;
      margin-left: -6px;
    }
  }
  &--bottom-right {
    left: auto;
    transform: none;
    right: 0;
    &:after {
      left: 90%;
    }
  }
  &--arrow-left {
    right: auto;
    transform: none;
    left: 0;
    &:after {
      right: 90%;
      left: auto;
    }
  }
  &--right {
    left: 100%;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    &:after {
      top: 50%;
      right: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-right-color: #000;
      border-width: 6px;
      margin-top: -6px;
    }
  }
  &--left {
    right: 100%;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    &:after {
      top: 50%;
      left: 100%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-left-color: #000;
      border-width: 6px;
      margin-top: -6px;
    }
  }
  &--white {
    &.tooltip--left {
      right: calc(100% - 1.2em);
      &:after {
        border-left-color: white;
      }
    }
    &.tooltip--right {
      left: calc(100% - 1.2em);
      &:after {
        border-right-color: white;
      }
    }
    .tooltip__heading {
      font-weight: 500;
    }
    .tooltip__bubble {
      background-color: white;
      color: black !important;
    }
    .tooltip__content {
      font-weight: 400;
      strong {
        font-weight: 500;
      }
    }
  }
  &--light {
    &#{$tooltip}--right {
      left: calc(100% + 3em);

      &:after {
        box-shadow: 2px 2px 4px -2px rgba(0, 0, 0, 0.2);
        transform: rotate(135deg);
        right: calc(100% - 10px);
        border: none;
        width: 20px;
        height: 20px;
        z-index: 10;
        background: white;
        border-radius: 0.1px; // fix safari shadow bug
      }
      @include mq(max, 810px) {
        .body--reset &,
        .body--auth & {
          top: calc(100% + 12em);
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          margin-bottom: 2em;
          &:after {
            top: auto;
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-bottom-color: #fffabd;
            border-width: 10px;
            margin-left: -1px;
            background: transparent;
            border-radius: none;
            transform: none;
            box-shadow: none;
          }
        }

        .body--reset & {
          top: calc(100% + 2em);
        }
      }
    }
    &:after {
      border-width: 10px;
      margin-top: -10px;
    }
    #{$tooltip}__bubble {
      padding: 0;
      background-color: white;
      color: black !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }
    #{$tooltip}__heading {
      background-color: #fffabd;
      padding: 0.7em;
      margin-bottom: 0;
      font-size: 1.4em;
      @include medium;
    }
    #{$tooltip}__content {
      padding: 1em 1.5em;
      @include regular;
    }
    li {
      font-size: 1em;
      margin-left: 1em;
    }
  }

  &__heading {
    font-size: 17px;
    margin-bottom: 0.5em;
    @include bold;
    @include font-smoothing;
  }
  &__content {
    font-size: 14px;
    @include medium;
    @include font-smoothing;
    p {
      font-size: inherit;
    }
    a {
      color: #fff;
    }
  }

  &__bubble {
    border-radius: 3px;
    background: #000;
    color: white !important;
    font-size: 1em;
    padding: 0.8em 1.2em;
    text-transform: none !important;
    text-align: center;
    white-space: nowrap;

    &--panel {
      white-space: normal;
      width: 30em;
      padding: 1.5em;
      border-radius: 5px;
      box-shadow: 0 0 9px 0 rgba(39, 39, 39, 0.5);
      text-align: left;
    }
    &--bot-builder {
      text-align: center;
      width: auto;
      padding-left: 2em;
      padding-right: 2em;
      white-space: nowrap;
    }
  }
}

a,
div,
span,
.tooltip-container {
  &:hover > .tooltip {
    animation: showTooltip 0.1s ease;
    opacity: 1;
    pointer-events: auto;
  }
}

.flyout-active:hover {
  & > .tooltip {
    opacity: 0;
    pointer-events: none;
  }
}

// Is this needed??

.ui-tooltip {
  border-radius: 3px;
  background: #333;
  color: white !important;
  padding: 8px 12px;
  text-transform: none !important;
  @include regular;
  font-size: 1.2em !important;
  font-weight: 400 !important;
  position: fixed;
  z-index: $zindex-tooltip;
  max-width: 200px;
  text-align: center;
}
.ui-tooltip-arrow {
  width: 12px;
  height: 12px;
  overflow: hidden;
  position: absolute;
  left: 50%;
  bottom: 0;
  text-align: left;
  margin-left: -6px;
  &:after {
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(51, 51, 51, 0);
    border-width: 6px;
  }
}
.ui-tooltip-arrow.top {
  top: -12px;
  &:after {
    border-bottom-color: #333;
  }
}
.ui-tooltip-arrow.bottom {
  bottom: -12px;
  &:after {
    border-top-color: #333;
  }
}
.ui-tooltip-arrow.left {
  left: 2em;
}
.ui-tooltip-arrow.right {
  right: 2em;
}
