#modal-discovery-question {
    .host {
        margin-bottom: 4em;
        .avatar {
            @include inline-block;
            @include avatar(88px,5.2em);
            margin-right: $spacer;
        }
        .bio {
            @include inline-block;
            @include medium;
        }
        .name {
            font-size: 2.5em;
        }
        .role {
            font-size: 2.1em;
            color: $black-light;
        }
    }
    .starter-question {
        p {
            font-size: 2.4em;
            @include italic;
            strong {
                @include medium-italic;
            }
        }
    }
    .btn {
        font-size: 2.8em;
        svg {
            @include wh(0.72em, 0.65em);
            fill: $brand-primary;
        }
    }
}