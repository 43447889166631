.dashboard--team-members {
    padding: 2em;
    @include mq(min, $mq-sm) {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 2em;
        justify-items: stretch;
        align-items: stretch;
    }
    .dashboard__panel--members {
        grid-row: span 2;
    }
}