.body--getting-started {
  background-color: #f1f1f1;
  &[data-modal="visible"] {
    overflow: hidden;
  }
  @include mq(min, $mq-md) {
    .getting-started__main {
      @include clearfix;
    }
    .getting-started__aside {
      float: left;
    }
    .getting-started__content {
      float: left;
      max-width: 75em;
    }
  }
  .getting-started-back-link {
    display: none;
  }
}

.body--getting-started-mobile {
  .header--app {
    &__branding {
      display: none;
      @include mq(min, $mq-md) {
        display: inline-block;
      }
    }
    .getting-started-back-link {
      align-self: center;
      font-size: 2.15em;
      text-decoration: none;
      margin-left: 1em;
      display: inline-block;
      @include mq(min, $mq-md) {
        display: none;
      }
    }
  }
}

.header--getting-started {
  background-image: linear-gradient(145deg, #1848e6 0%, #00dcb8 100%);
  color: #fff;
  text-align: center;
  padding: 5em 3em;
  @include mq(min, $mq-md) {
    padding-left: 0;
    padding-right: 0;
  }
  h1 {
    @include medium;
    font-size: 3em;
    margin-bottom: 0.5em;
    @include mq(min, $mq-md) {
      font-size: 3.6em;
      margin-bottom: 0.25em;
    }
  }
  h2 {
    font-size: 2.1em;
    margin: 0;
    @include regular;
    @include mq(max, $mq-md) {
      line-height: 1.4;
    }
  }
}

.getting-started {
  &__main {
    margin-top: 2em;
    @include mq(max, $mq-md) {
      margin: 0;
    }
  }
  &__bot-section {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    padding: 5em 3em 3em;
    margin-bottom: 2.5em;
    &--sm {
      padding: 2em 2.5em;
      align-items: center;
      margin-bottom: 2em;
    }
    &--sidebar {
      padding-top: 3em;
      padding-right: 5em;
    }
    &--link {
      color: $black;
      text-decoration: none;
      &:hover {
        text-decoration: none;
        border-color: $black;
        color: $black;
      }
    }
  }
  &__bot-section-col {
    flex: 1 50%;
    &:last-child {
      align-self: center;
      &:not(.text-center) {
        text-align: right;
      }
    }
  }
  &__bot-section-preview {
    width: auto;
    height: auto;
  }
  &__bot-section-message {
    p {
      background: #f3f3f3;
      border-radius: 7px;
      padding: 0.8em;
      min-width: 20em;
    }
    .avatar {
      width: 2.8em !important;
      height: 2.8em !important;
      line-height: 2.8em;
    }
  }
  &__bot-section-img {
    margin-bottom: -3em;
  }
  &__message {
    padding: 0.8em 1em;
    border-radius: 5px;
    background: #f3f3f3;
  }
  &__faq {
    padding-top: 2em;
  }
}

.getting-started__post-header {
  margin: 1.3em 0;
  @include mq(min, $mq-md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .link {
    color: $black-light;
    font-size: 1.4em;
  }
}

.getting-started__progess__steps {
  @include inline-block;
  @include medium;
  font-size: 1.6em;
  color: $black-light;
}

.getting-started__progress__status {
  @include inline-block;
  height: 9px;
  width: 17.4em;
  border-radius: 10px;
  background-color: #9b9797;
  margin-left: 1em;
  position: relative;
  overflow: hidden;
}

.getting-started__progress__value {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: $brand-primary;
  border-radius: 10px;
}

.getting-started__aside {
  margin-bottom: 3.2em;
  @include mq(min, $mq-md) {
    width: 28em;
    margin-right: 5em;
    margin-bottom: 0;
  }
  nav {
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    margin-bottom: 3.5em;
    &:last-of-type {
      margin-bottom: 2.5em;
    }
  }
  h4 {
    font-size: 1.8em;
    color: #555555;
    font-weight: 400;
  }
  &--mobile {
    margin-bottom: 4.5em;
    h4 {
      color: #333333;
    }
    .getting-started__aside-link {
      background: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
      border-radius: 6px;
      margin-bottom: 1em;
      padding: 1.55em;
      .icon {
        width: 1.8em;
        height: 1.8em;
        margin-right: 0.8em;
        .fa-check {
          font-size: 1em;
          line-height: 1.8em;
        }
      }
      &--other {
        .icon {
          background: none;
          width: auto;
          height: auto;
          i {
            display: inline-block;
            color: $black;
            font-size: 1.6em;
          }
        }
      }
    }
    nav {
      background: none;
      box-shadow: none;
      margin-bottom: 8.5em;
    }
  }
}

.getting-started__content {
  position: relative;
  background: #fff;
  border-radius: 3px;
  padding: 4em 2.5em;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  @include mq(min, $mq-md) {
    width: calc(100% - 33em);
    margin-bottom: 3.2em;
    padding-left: 4em;
    padding-right: 4em;
  }
  h2 {
    @include regular;
    font-size: 2.8em;
  }

  h2.install {
    @include medium;
    font-size: 2.8em;
    margin-bottom: 0.5em;
  }
  h3 {
    @include medium;
    font-size: 2em;
  }
  .btn-group {
    display: block;
    margin-bottom: $spacer-y * 2;
  }
  &--medium-headings {
    h2 {
      @include medium;
    }
  }
  .half-width {
    .text-medium {
      margin-bottom: 0.5em;
    }
  }
  .sidebar-preview {
    margin-top: 8em;
    width: auto;
    margin-left: 0;
    font-size: 80%;
  }
  .widget-caption {
    font-style: italic;
    font-weight: 400;
    color: #9b9b9b;
    text-align: center;
    font-size: 1.5em;
    margin-top: 1em;
  }
  .unconnected-apps__list {
    .app__action {
      margin-left: auto;
      margin-top: 1em;
      .btn {
        border: none;
        padding: 0;
        color: $blue;
      }
    }
    .app__row {
      align-items: center;
      margin-left: 0;
      margin-right: 0;
      text-decoration: none;
      @include mq(max, $mq-md) {
        position: relative;
        padding: 3em 3em 3em 9em;
        display: block;
        .app__icon {
          position: absolute;
          left: 2em;
          top: 50%;
          transform: translateY(-50%);
        }
        .app__info {
          margin-left: 0;
        }
      }
    }
    .app__info {
      margin-left: 1em;
    }
    .app__desc {
      display: none;
    }
    .app__name {
      margin-bottom: 0;
      .flag--opacity {
        display: none;
      }
    }
  }
  .connected-apps__list {
    margin-left: 0;
    margin-right: 0;
    .app-panel {
      margin-left: 0;
      @include mq(inside, $mq-md, $mq-lg) {
        flex-basis: 100%;
        margin-left: 0;
        margin-right: 0;
      }
      @include mq(inside, $mq-lg, $mq-xl) {
        width: 47%;
      }
      @include mq(min, $mq-xl) {
        width: calc(33.33% - 1.5em);
      }
    }
    .app__desc {
      display: none;
    }
    .app__prefs,
    .app__action {
      .btn {
        font-size: 1.6em;
        padding: 0;
        background: none;
        border: none;
        text-decoration: none;
        color: $blue;
        font-weight: 500;
        &:after {
          content: " →";
          font-family: sans-serif;
        }
      }
    }
    .app__action {
      margin-top: 1.5em;
    }
    .app__name {
      span {
        margin-top: 0.5em;
        vertical-align: baseline;
      }
    }
  }
  .popular-apps {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .app__row {
      flex-basis: 100%;
    }
    .app__action {
      a {
        font-size: 1.6em;
        padding: 0;
        background: none;
        border: none;
        text-decoration: none;
        color: $blue;
        &:after {
          content: " →";
          font-family: sans-serif;
        }
      }
    }
  }
  .avatar {
    img {
      object-fit: cover;
    }
  }
  .smartvideo-player {
    width: 100% !important;
  }
}

.getting-started__aside-link {
  display: block;
  position: relative;
  @include medium;
  padding: 1em;
  font-size: 1.6em;
  text-decoration: none;
  color: $black;
  border-bottom: 1px solid rgba(#979797, 0.2);
  white-space: nowrap;
  .tooltip--right {
    display: none;
    @include mq(min, $mq-md) {
      left: calc(100% + 0.5em) !important;
      display: block;
    }
  }
  &:last-of-type {
    border: none;
  }
  &[href*="install"]:not(.getting-started__aside-link--complete) {
    .icon {
      .fa-ellipsis-h {
        display: inline-block;
        line-height: 2;
        @include mq(min, $mq-md) {
          line-height: 1.5;
        }
      }
    }
  }
  span {
    @include inline-block;
    &.icon {
      margin-right: 0.5em;
      @include circle(1.5em);
      line-height: 1.5em;
      background-color: #d8d8d8;
      color: #fff;
      @include bold;
      i {
        display: none;
      }
    }
  }
  &--complete {
    span.icon {
      background-color: $blue-secondary;
      i.fa-check {
        display: inline-block;
        font-size: 0.8em;
        position: relative;
        line-height: 2em;
      }
    }
  }
  &:first-child {
    border-radius: 6px 6px 0 0;
  }
  &:last-child {
    border-radius: 0 0 6px 6px;
  }
  &:hover {
    text-decoration: none;
    color: $black;
    background-color: rgba(216, 216, 216, 0.4);
  }
  &.active {
    background-color: rgba(#d8d8d8, 0.2);
  }
  .fa-exclamation-triangle {
    margin-left: 1em;
  }
}

.getting-started__content--conversation {
  & > section:not(:last-of-type) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 3.2em;
    margin-bottom: 3.2em;
  }
  h4 {
    font-size: 1.8em;
    margin-bottom: 0.33em;
    @include regular;
  }
  .quiet {
    color: $black-light;
  }
  .edit-link {
    color: $black;
    font-size: 0.9em;
  }
}

.getting-started__content--conversation__preview {
  @include mq(min, $mq-sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @include mq(min, $mq-md) {
    display: block;
  }
  @include mq(min, $mq-lg) {
    display: flex;
  }
  .conversation-preview {
    flex: 1;
    margin: 0 3.2em;
    pointer-events: none;
    user-select: none;
  }
  .content {
    flex: 1;
  }
  .conversation-js-preview {
    font-size: 75% !important;
    height: 65em;
    box-shadow: 0 -1px 14px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    .continually-conversation-header-team,
    .continually-conversation-header {
      border-radius: 5px 5px 0 0;
    }
    .continually-close-button {
      display: flex;
      position: absolute;
      right: 0;
      top: 0;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: rgba(0, 0, 0, 0.1);
      color: #fff;
      border-radius: 0 5px 0 5px;
      justify-content: center;
    }
    textarea,
    input[type="text"],
    input[type="email"],
    input[type="tel"] {
      font-size: 1.8em !important;
    }
  }
}

.getting-started__content--conversation__widget {
  .widget {
    margin-bottom: 1.6em;
    .heading {
      justify-content: center;
    }
  }
  .continually-message-container {
    user-select: none;
    display: flex;
    align-items: flex-end;

    &.pos-left {
      .continually-avatar {
        margin-left: 0;
        margin-right: 1.6em;
        order: -1;
      }
    }
    .avatar {
      @include avatar(4.8em, 2.5em, 50%);
      border-color: transparent;
      background-color: #fff;
    }
    .continually-avatar {
      margin-left: 1.6em;
    }
    .continually-message-bubble {
      color: #fff;
      background-color: $brand-primary;
      box-shadow: 0 0 4px 0 rgba(#000, 0.25);
      border-radius: 8px;
      min-width: 22em;
      max-width: 26em;
      &.continually-message-bubble--image {
        border-radius: 10px 10px 8px 8px;
        .continually-message-image {
          // margin-top: -8px;
          border-radius: 8px 8px 0 0;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
          }
        }
        .continually-message-text {
          padding-top: 1.2625em;
        }
      }
    }
    .continually-message-text {
      font-size: 1.4em;
      padding: 1.5625em;
      position: relative;
      z-index: 2;
      color: #fff;
      line-height: 1.3;
      font-family: $font-family-sans-serif;
      strong {
        font-family: $font-family-sans-serif;
        font-weight: 700;
        letter-spacing: -0.03em;
      }
      em {
        font-family: $font-family-sans-serif;
        font-style: italic;
      }
      strong em,
      em strong {
        font-family: $font-family-sans-serif;
        font-weight: 700;
        font-style: italic;
      }
      a:not(.rich-media-card) {
        color: #fff;
      }
      .media-container--video {
        @include mq(min, $mq-md) {
          min-width: 10em;
        }
        @include mq(min, $mq-xl) {
          min-width: 13em;
        }
      }
      .rich-media-card__summary {
        h2 {
          @include medium;
          font-size: 13px;
        }
        p.desc,
        .site-name {
          @include regular;
          font-size: 11px;
        }
      }
      .ql-image {
        display: block;
        margin: 5px 0;
      }
    }
  }
  .continually-message-container--link {
    .continually-message-link {
      display: block;
      border-radius: 8px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0 0 8px 0 rgba(#000, 0.25);
      text-decoration: none;
      margin-bottom: 1em;
      max-width: 26em;
      .continually-message-link-image {
        display: block;
        width: 100%;
      }
      .continually-message-link-title {
        padding: 16px;
        font-family: $font-family-sans-serif;
        font-weight: bold;
        line-height: 1.3;
        color: #333;
        font-size: 16px;
        line-height: 1.3;
      }
    }
  }
  .continually-message-container--video {
    iframe {
      left: 0;
    }
    .continually-message-video {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 1em;
      max-width: 26em;
      iframe,
      video {
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }
  .continually-button {
    display: flex;
    align-items: center;
    transition: transform 0.2s;
    background-color: $brand-primary;
    &.continually-button--i {
      width: 62px;
      height: 62px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .continually-button-icon {
        width: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }
    &.continually-button--it {
      display: inline-flex;
      border-radius: 2em;
      padding: 1em 1.6em;
      max-width: 100%;
      .continually-button-icon {
        color: #fff;
        margin-right: 1em;
        width: 24px;
      }
      .continually-button-text {
        margin: 0;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &.continually-button--t {
      display: inline-block;
      border-radius: 2em;
      max-width: 100%;
      .continually-button-text {
        margin: 0.75em 1.6em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .continually-button-text {
    color: #fff;
    line-height: 1;
    font-size: 1.6em;
    font-family: $font-family-sans-serif;
    font-weight: 700;
  }
  .continually-autostart-container {
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    &.pos-left {
      .continually-avatar {
        margin-right: 1.6em;
        margin-left: 0;
        order: -1;
      }
      .continually-autostart-message {
        &--multiple_choice,
        &--gdpr {
          .continually-autostart-actions[data-answer-layout="horizontal"] {
            justify-content: flex-start;
            margin-right: -30px;
            margin-left: 0;
            .continually-autostart-actions__option,
            .continually-autostart-actions__check {
              margin-left: 0;
              &:not(:last-child) {
                margin-right: 0.8em;
              }
            }
          }
        }
      }
    }
    .avatar {
      @include avatar(4.8em, 2.5em, 50%);
      border-color: transparent;
      background-color: #fff;
    }
    .continually-avatar {
      margin-left: 1.6em;
    }
    .continually-autostart-message {
      &--multiple_choice,
      &--gdpr {
        & + .avatar {
          bottom: auto;
          top: 0;
        }
        .continually-message-bubble {
          margin-bottom: 1em;
          border-radius: 8px;
        }
        .continually-message-text {
          padding: 17.5px 20px;
        }
        .continually-autostart-actions {
          background: none;
          border: none;
          &[data-answer-layout="horizontal"] {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-end;
            max-width: 290px;
            margin-left: -30px;
            .continually-autostart-actions__option {
              padding-left: 15px;
              padding-right: 15px;
            }
            .continually-autostart-actions__option,
            .continually-autostart-actions__check {
              margin-left: 0.8em;
            }
            .continually-autostart-actions__send {
              width: 86.25%;
            }
          }
        }
      }
    }
    .continually-autostart-message--message,
    .continually-autostart-message--video,
    .continually-autostart-message--link {
      .continually-message-bubble {
        border-radius: 8px;
      }
    }
    .continually-autostart-message--image {
      .continually-message-bubble {
        border-radius: 0 0 8px 8px;
      }
      .continually-message-text {
        padding-top: 1.0625em;
      }
    }
    .continually-message-bubble {
      background-color: $brand-primary;
      // box-shadow: 0 0 4px 0 rgba(#000,0.25);
      border-radius: 8px 8px 0 0;
      min-width: 22em;
      max-width: 26em;
    }
    .continually-message-text {
      font-size: 1.4em;
      padding: 1.5625em;
      position: relative;
      z-index: 2;
      color: #fff;
      line-height: 1.3;
      font-family: $font-family-sans-serif;
      strong {
        font-family: $font-family-sans-serif;
        font-weight: 700;
        letter-spacing: -0.03em;
      }
      em {
        font-family: $font-family-sans-serif;
        font-style: italic;
      }
      strong em,
      em strong {
        font-family: $font-family-sans-serif;
        font-weight: 700;
        font-style: italic;
      }
      a:not(.rich-media-card) {
        color: #fff;
      }
      .media-container--video {
        @include mq(min, $mq-md) {
          min-width: 10em;
        }
        @include mq(min, $mq-xl) {
          min-width: 13em;
        }
      }
      .rich-media-card__summary {
        h2 {
          @include medium;
          font-size: 14px;
        }
        p.desc,
        .site-name {
          @include regular;
          font-size: 12px;
        }
      }
    }
    .continually-message-video {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 1em;
      max-width: 26em;
      iframe,
      video {
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
    .continually-message-image {
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .continually-message-link {
      display: block;
      border-radius: 8px;
      overflow: hidden;
      background-color: #fff;
      box-shadow: 0 0 8px 0 rgba(#000, 0.25);
      text-decoration: none;
      margin-bottom: 1em;
      max-width: 26em;
      .continually-message-link-image {
        display: block;
        width: 100%;
      }
      .continually-message-link-title {
        padding: 1em;
        font-family: $font-family-sans-serif;
        font-weight: bold;
        line-height: 1.3;
        color: #333;
        font-size: 1.6em;
        line-height: 1.3;
      }
      &:hover {
        .continually-message-link-title {
          text-decoration: underline !important;
        }
      }
    }
    .continually-message--gdpr {
      padding: 0 !important;
      .rich-media-card {
        background: transparent;
        border: none !important;
        margin-top: 0;
        &:hover {
          border-color: none;
        }
        .rich-media-card__summary {
          h2 {
            color: #333 !important;
            font-size: 16px !important;
            margin: 0;
          }
        }
        .desc,
        .site-name {
          display: none !important;
        }
        &__figure {
          background-color: transparent;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 0;
          border: none;
        }
        &__summary {
          padding: 1em !important;
        }
      }
    }

    .continually-autostart-actions {
      background-color: #fff;
      border-radius: 0 0 8px 8px;
      border: 1px solid #ddd;
      min-width: 22em;
      max-width: 26em;
      &__fa {
        margin-right: 0.5em;
        min-width: 1.3em;
      }
    }
    .continually-autostart-actions--single-btn {
      display: flex;
      align-items: center;
      padding: 2em;
    }
    .continually-autostart-actions--multiple-btn {
      .continually-autostart-actions__send {
        text-align: center;
        padding: 1em;
      }
    }
    .continually-autostart-actions__label {
      color: #747474;
      font-size: 1.4em;
      flex: 1;
      font-family: $font-family-sans-serif;
    }
    .continually-autostart-actions__icon {
      flex-grow: 0;
      margin-left: 1em;
    }
    .continually-autostart-actions--file-upload {
      padding: 1em 1.2em;
    }
    .continually-autostart-actions__upload-box {
      background: #f4f4f4;
      border: 1px dashed #afafaf;
      border-radius: 3px;
      text-align: center;
      padding: 1.5em;
      .continually-autostart-actions__label {
        text-transform: uppercase;
        font-weight: 500;
      }
    }
    .continually-autostart-actions--dropdown {
      padding: 1em 1.2em;
    }
    .continually-autostart-actions__pulldown {
      background: #ffffff;
      border: 1px solid #afafaf;
      border-radius: 3px;
      padding: 1.5em;
      padding-right: 3.6em;
      position: relative;
      &:after {
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-33%);
        right: 1.6em;
        content: " ";
        border: solid transparent;
        height: 0;
        width: 0;
        pointer-events: none;
        border-color: rgba($black, 0);
        border-top-color: $black;
        border-width: 0.5em;
      }
    }
    .continually-autostart-actions--appt-btn {
      padding: 1em;
    }
    .continually-autostart-actions__btn {
      background: #ffffff;
      border: 1px solid #afafaf;
      border-radius: 3px;
      padding: 1.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      .continually-autostart-actions__icon
        + .continually-autostart-actions__label {
        margin-left: 0.66em;
      }
    }
    .close-continually-message-button {
      position: absolute;
      left: -26px;
      top: 0;
      background-color: #9b9b9b;
      color: #fff;
      font-weight: 700;
      line-height: 1;
      @include wh(16px);
      padding: 4px;
      border-radius: 50%;
      cursor: pointer;
      box-shadow: 0 0 8px 0 rgba(#000, 0.2);
      &:hover {
        opacity: 0.75;
      }
      img {
        display: block;
        @include wh(8px);
      }
    }
  }
  .hidden-appearance {
    .heading {
      margin-bottom: 1em;
      i {
        @include inline-block;
        margin-right: 0.3em;
        font-size: 2em;
        color: $black-light;
      }
      .label {
        @include inline-block;
        @include medium;
        font-size: 1.4em;
      }
    }
    p {
      color: $black-light;
      font-size: 1.2em;
    }
  }
}

.getting-started__content--conversation__targeting {
  .tags {
    margin-bottom: 1.6em;
  }
  .tag code {
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: #9b9b9b;
  }
}

.getting-started__content--install {
  .embed-code {
    border: 1px solid darken($grey, 10%);
  }
  a:not(.btn) {
    color: $black;
  }
  a.btn--wordpress {
    margin-bottom: 1.6em;
    @include regular;
    border-radius: 2px;
    padding-top: 1.2em;
    padding-bottom: 1.1em;
    i {
      font-size: 1.4em;
      @include inline-block;
      margin-right: 0.2em;
    }
  }
  .useful-links {
    list-style: none;
    margin-bottom: 3.2em;
    li {
      margin-bottom: 0.5em;
    }
    a {
      text-decoration: none;
      span {
        text-decoration: underline;
      }
    }
  }
  hr {
    margin-bottom: 3.2em;
  }
}

.getting-started__install-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-top: 2.4em;
  padding-bottom: 2.4em;
  @include mq(max, $mq-md) {
    flex-direction: column;
    .install-button {
      flex: 1 auto;
      width: 100%;
      display: flex;
      align-items: center;
      margin: 1em 0;
      figure {
        padding: 2.6em;
        margin-right: 2.5em;
        border-radius: 6px 0 0 6px;
        border-right: 1px solid #ddd;
        border-bottom: 0;
      }
    }
  }
  &--no-border {
    border-bottom: 0;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  &--no-border-padding {
    border-bottom: 0;
    padding-top: 2em;
    padding-bottom: 1em;
  }
  p {
    flex-grow: 1;
  }
  .install-button {
    flex-grow: 0;
  }
}

.install-button {
  width: 19em;
  min-height: 6.2em;
  margin-right: 3em;
  text-align: center;
  color: $black;
  text-decoration: none;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 6px;
  margin-bottom: 1em;
  @include transition;
  &:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }
  &:first-child {
    figure {
      background: #36388e;
    }
  }
  &:nth-child(2) {
    figure {
      background: #20a0ab;
    }
  }
  &--white {
    figure {
      background: white !important;
      border: 1px solid #dedede !important;
      i {
        color: black !important;
      }
    }
  }
  &--wordpress {
    figure {
      background: #44c2d0;
    }
  }
  figure {
    padding: 2.2em;
    border-radius: 6px 6px 0 0;
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;
    border-bottom: 1px solid #dedede;
    i {
      color: white;
      font-size: 3em;
    }
    img {
      object-fit: contain;
    }
  }
  .label {
    font-size: 1.6em;
    @include medium;
    display: block;
    padding: 1.5em 0;
    color: #333333;
    i {
      margin-left: 0.2em;
    }
  }
}

.embed-code--links {
  display: flex;
  align-items: baseline;
  .btn {
    font-size: 1.6em;
  }
}

.getting-started__content--teammates__table {
  position: relative;
  > p {
    max-width: 38em;
  }
  .pending-invitations {
    .col-actions {
      padding-right: 0;
    }
  }
  table {
    margin-bottom: $spacer-y * 6;
  }
  .scrollable-table {
    &:after {
      content: none;
    }
    overflow: hidden;
    > div {
      overflow: hidden;
    }
  }
  .table--team-members {
    margin-top: 4em;
    tr {
      td {
        vertical-align: middle;
        white-space: normal !important;
        word-break: break-word;
        word-wrap: break-word;
        overflow-wrap: break-word;
        padding-right: 0;
        @include mq(min, $mq-lg) {
          white-space: nowrap;
        }
        &,
        &.col-avatar {
          padding-top: 1.15em;
          padding-bottom: 0.9em;
        }
      }
    }
    .col-actions {
      white-space: nowrap !important;
      display: none;
      &.col-actions--view {
        display: table-cell;
        color: $blue;
        text-decoration: none;
        padding: 0;
      }
      &__remove {
        display: none;
      }
    }
    .col-avatar {
      display: none;
      @include mq(min, $mq-lg) {
        display: table-cell;
      }
    }
  }
  .filter-search {
    display: none;
  }
}

.getting-started__content-save-needed {
  float: right;
  position: relative;
  width: calc(100% - 33em);
}
.getting-started__content--calendar {
  margin-bottom: 3.2em;
  .calendar-setting {
    padding-left: 0;
    padding-right: 0;
    border: none;
  }
  .add-account {
    margin-top: 2em;
  }
  .links {
    margin-top: 2em;
    border-top: 1px solid #bfbfbf;
    padding-top: 4em;
    a {
      color: #000;
      font-family: $font-family-sans-serif;
      font-weight: 400;
      font-style: normal;
      padding-right: 2.4em;
      line-height: 1.7;
      font-size: 1.5em;
    }
  }
  form {
    margin-top: 3em;
  }
  .note {
    margin-top: -1em;
  }
  .check {
    display: inline-block;
    background-color: #43d5c1;
    width: 1.2em;
    height: 1.2em;
    line-height: 1.1;
    text-align: center;
    border-radius: 1em;
    border: 2px solid #f2f2f2;
  }
  .check i {
    color: #fff;
    font-size: 0.6em;
    position: relative;
    top: -0.1em;
  }
  .success-connected {
    display: inline-block;
  }
  .calendar-connected {
    background-color: #f3f3f3;
    border-radius: 4px;
    text-align: center;
    padding: 2.8em 2em;
    position: relative;
    margin-bottom: 1.6em;

    .no-calendar-icon {
      font-size: 6em !important;
    }
    .actions {
      margin-top: 1em;
    }
    .copy {
      text-align: left;
    }
    .check {
      position: absolute;
      left: 50%;
      top: 0;
      background-color: $brand-primary;
      width: 3em;
      height: 3em;
      line-height: 3.6;
      text-align: center;
      border-radius: 3em;
      border: 2px solid #f2f2f2;
      margin-left: -1.5em;
      margin-top: -1.5em;
      i {
        color: #fff;
        font-size: 1.4em;
        position: relative;
        top: -0.1em;
      }
    }
    .help {
      margin-bottom: 0;
      font-size: 1.6em;
      a {
        color: $black;
      }
    }
  }
  .calendar-motivation__header {
    position: relative;
    text-align: center;
    justify-content: space-between;
    align-items: center;
    @include mq(min, $mq-sm) {
      display: flex;
    }
    @include mq(min, $mq-md) {
      display: block;
    }
    @include mq(min, $mq-xl) {
      display: flex;
    }
    .copy {
      flex: 1;
      @include mq(min, $mq-sm) {
        margin-right: 3.2em;
      }
      @include mq(min, $mq-md) {
        margin-right: 0;
      }
      @include mq(min, $mq-xl) {
        margin-right: 3.2em;
      }
    }
    h2 {
      font-size: 2.1em;
      @include regular;
      max-width: 16em;
      margin: 0 auto 1em;
    }
    p {
      &.take-your-pick {
        span {
          display: inline-block;
          margin-right: 0.25em;
        }
      }
    }
    .btn {
      margin-right: 0.5em;
      padding-left: 1em;
      padding-right: 1em;
      font-size: 1.3em;
      @include mq(min, $mq-xl) {
        font-size: 1.5em;
      }
    }
    .continually-chat-team,
    .continually-chat {
      font-size: 80%;
      width: 35em;
      margin: 0 auto;
      .post--admin {
        padding-left: 0;
        animation-duration: 0.5s;
        animation-fill-mode: both;
        animation-name: fadeInUp;
      }
      .question-text div {
        font-size: 1.6em;
        @include medium;
      }
      .pulldown {
        display: block !important;
      }
      select:disabled {
        color: $black;
      }
      a,
      label,
      select {
        pointer-events: none;
        cursor: default !important;
      }
      .avatar {
        border-color: $black;
      }
      .name {
        color: #fff !important;
      }
    }
  }
}

.calendar-motivation__benefits {
  background-color: #182f5b;
  color: #fff;
  text-align: center;
  h3 {
    font-size: 2.8em;
    @include regular;
    position: relative;
    padding-bottom: 0.75em;
    margin-bottom: 0.75em;
    &:after {
      content: "";
      border-top: 1px solid rgba(#979797, 0.33);
      position: absolute;
      top: 100%;
      left: 50%;
      width: 60px;
      margin-left: -30px;
    }
  }
  .benefits {
    display: flex;
    flex-wrap: wrap;
  }
  .benefit {
    width: 50%;
    margin-bottom: 2em;
    i {
      font-size: 2.4em;
      margin-bottom: 0.5em;
      display: inline-block;
    }
    p {
      margin: 0 auto;
      max-width: 21em;
      font-size: 1.4em;
    }
  }
}

.in-situ-preview {
  @include mq(max, $mq-md) {
    h5 {
      text-align: center;
    }
    .flex {
      flex-direction: column;
    }
    input[type="url"] {
      font-size: 16px;
      padding: 0.95em;
      border-radius: 5px !important;
      margin-bottom: 0.5em;
      & + .btn {
        font-size: 16px;
        padding: 0.9em;
        border-radius: 5px;
      }
    }
  }
  h3 {
    @include regular;
    font-size: 1.8em;
  }
  .preview-link {
    em {
      @include inline-block;
      font-size: 1.6em;
      margin-right: 0.5em;
    }
    a {
      @include inline-block;
      font-size: 1.4em;
      padding: 0.5em 1em;
      background-color: #f9f9f9;
      border-radius: 5px;
      border: 1px solid #ddd;
      font-family: $font-family-monospace;
      color: #141414;
      text-decoration: none;
      span,
      i {
        @include inline-block;
      }
    }
  }
  figure + figcaption {
    margin-top: 1em;
  }
}

.getting-started__content--live-chat {
  margin-bottom: 3.2em;
  .live-chat-setting--toggle {
    margin-bottom: 4em;
  }
  .toggle-live-chat {
    margin-right: 1em;
  }
  .label {
    @include medium;
    font-size: 1.6em;
    margin-bottom: 0.8em;
  }
  .slack-status {
    margin-top: 3.2em;
    margin-bottom: 3.2em;
    padding: 1.6em 2.4em;
    border-radius: 4px;
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    figure {
      max-width: 4.8em;
      text-align: center;
      position: relative;
      span {
        @include medium;
        display: block;
        margin-top: 0.6em;
        font-size: 1.1em;
      }
      .tick--connected {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        background-color: $brand-primary;
        border: 1px solid #fff;
        width: 2em;
        height: 2em;
        line-height: 2.5;
        text-align: center;
        border-radius: 50%;
        margin-left: -0.5em;
        margin-top: -0.5em;
        i {
          font-size: 1em;
          color: #fff;
          position: relative;
          top: -0.1em;
        }
      }
    }
    .details {
      flex: 1;
      margin: 0 2em;
      p {
        margin: 0;
      }
    }
  }

  .live-chat-setting-action {
    display: none;
  }
}

.getting-started__content--live-chat--features {
  background-color: $blue-dark;
  color: #fff;
  section {
    &:not(:last-of-type) {
      margin-bottom: 3.2em;
    }
    @include mq(min, $mq-sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .copy {
        flex: 1;
      }
      figure {
        width: calc(55% - 4em);
      }
      &.img--left {
        .copy {
          order: 2;
        }
        figure {
          order: 1;
          margin-right: 4em;
        }
      }
      &.img--right {
        figure {
          margin-left: 4em;
        }
      }
    }
    @include mq(min, $mq-md) {
      display: block;
      figure {
        width: 100%;
        margin: 0 !important;
      }
    }
    @include mq(min, $mq-lg) {
      display: flex;
      figure {
        width: auto;
      }
      &.img--left {
        figure {
          margin-right: 4em !important;
        }
      }
      &.img--right {
        figure {
          margin-left: 4em !important;
        }
      }
    }
  }
}

.calendar-save-sticky {
  float: left;
  width: calc(100% - 33em);
}

.enable-calendar-availability {
  display: inline-block;
  font-size: inherit !important;
  margin: 0 !important;
}

.video-guides {
}

.beacon-container {
  [aria-hidden="true"] {
    display: block;
  }
}

.getting-started-mobile-modals {
  .modal {
    top: 50%;
    transform: translateY(-50%);
    padding-top: 3em;
    .modal-body {
      > h2,
      > p {
        text-align: center;
      }
    }
    .embed-code--links,
    .btn-wrap {
      width: 100%;
      display: block;
      .btn--black {
        width: 100%;
        margin: 2em 0;
        margin-top: 2em;
      }
    }
    .close-modal i {
      font-size: 3em;
    }
  }
}

.getting-started-mobile-teammate {
  display: flex;
  align-items: flex-start;
  padding: 1.5em;
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin-bottom: 1.5em;
  min-height: 94px;
  &.teammate-profile--pending {
    padding-left: 6.5em;
  }
  &__text {
    padding-top: 10px;
  }
  div {
    font-size: 12px;
  }
  .col-actions {
    margin-left: auto;
    div {
      font-size: 9px;
    }
    .btn {
      padding: 0;
    }
    &__pulldown {
      width: 9em;
      font-size: 12px !important;
    }
  }
  .col-avatar {
    margin-right: 1em;
  }
}

.col-actions__pulldown {
  display: none;
  position: absolute;
  right: 1em;
  top: 100%;
  background: #ffffff;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  z-index: 1;
  &.active {
    display: inline-block;
  }
}
.col-actions__pulldown-item {
  font-weight: 500;
  padding: 1em;
  line-height: 1;
  display: block;
  color: $black;
  &:hover {
    background: #f5f5f5;
  }
  &:first-child {
    border-bottom: 1px solid #e5e5e5;
  }
}
.modal--video-guides {
  @include mq(max, $mq-md) {
    width: 100%;
  }
}
.modal.modal--invited {
  @include mq(max, $mq-sm) {
    top: 5em;
    .modal-body {
      padding: 5em 2em;
    }
  }
  @include mq(min, $mq-md) {
    width: 640px;
    border-radius: 0;
    .modal-body {
      padding: 4em;
    }
  }
  h2 {
    margin-bottom: 0.5em;
    font-weight: 500;
  }

  .form {
    max-width: 350px;
    margin: auto;
  }
}

.getting-started-how {
  .counter-circle {
    width: 25px;
    height: 25px;
    background: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: #ffffff;
    border-radius: 50%;
    margin-right: 1em;
    flex-shrink: 0;
  }
}
