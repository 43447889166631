.conversation-js-preview {
  position: relative;
  font-size: 80% !important;
  background-color: #fff;
  .continually-conversation-header-team,
  .continually-conversation-header {
    position: relative;
    .inline-edit {
      textarea {
        display: block;
        margin: 0 auto;
        border: none;
        border-radius: 0;
        padding: 0;
        width: 100%;
        max-width: 100%;
        font-size: 1.75em;
        @include medium;
        resize: none;
        min-height: 1.2em;
        overflow: hidden;
        background-color: transparent;
        border-bottom: 1px dashed transparent;
        &:hover {
          border-bottom-color: rgba(#fff, 0.4);
        }
        &:focus {
          border-bottom-color: #fff;
        }
      }
      .validationMessage {
        color: $error;
        background-color: transparent;
        display: block;
        font-size: 1.3em;
        padding: 0.3em 0;
        border-top: 1px dashed $error;
        text-align: center;
        margin: 0;
      }
    }
    .inline-edit--intro {
      textarea {
        color: #fff;
      }
    }
    .inline-edit--desc {
      textarea {
        color: rgba(#fff, 0.7);
      }
    }
  }
  .conversation-header__host {
    position: relative;
    .btn--change-author {
      position: relative;
      display: inline-block;
      &:hover:after {
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        bottom: 0;
        content: "Change";
        background-color: rgba($black, 0.9);
        color: #fff;
        padding: 0.3em 0.2em;
        font-size: 1.3em;
      }
    }
  }
  .continually-timeline {
    .validationMessage {
      //@include offscreen;
    }
  }
}

.conversation-preview-feedback {
  text-align: center;
  padding: 1.6em;
  background-color: #fff5c1;
  > p {
    margin: 0;
    font-size: 1.4em;
    @include medium;
    color: #909090;
    position: relative;
    a {
      color: #909090;
    }
  }
}
