.body--emails-notifications {}

.notifications-wrap {
    max-width: 85em;
}

.section--emails-notifications {
    fieldset {
        margin-bottom: 4.8em;
        li {
            font-size: 1.4em;
            margin-bottom: 0.5em !important;
        }
    }
    h2 {
        @include uppercase;
        @include bold;
        font-size: 1.5em;
        padding-bottom: 0.3em;
        margin-bottom: 0.8em;
        border-bottom: 1px solid #ddd;
    }
    .app__row {
        border-bottom: none;
    }
    .slack-notifications {
        .slack-prefs-link {
            font-size: 1.4em;
            text-decoration: none;
            span {
                text-decoration: underline;
            }
        }
    }
}

.weekly-roundup-settings {
    .pulldown {
        margin-left: 0.5em;
        margin-right: 0.5em;
    }
    fieldset {
        margin: 2em 0 0;
        label {
            font-size: 1.4em;
            margin-bottom: 0.75em;
        }
    }
    .availability--days {
        display: flex;
        input[type=checkbox] {
            @include offscreen;
            &:checked + label {
                background-color: $brand-primary;
                color: #fff;
            }
        }
        label {
            span { line-height: 1; }
            font-size: 1.8em;
            width: 2.2em;
            height: 2.2em;
            display: flex;
            justify-content: center;
            align-items: center;
            @include medium;
            color: $black-lighter;
            border: 1px solid #ddd;
            border-right: none;
            cursor: pointer;
            &:first-of-type {
                border-radius: 4px 0 0 4px;
            }
            &:last-of-type {
                border-radius: 0 4px 4px 0;
                border-right: 1px solid #ddd;
            }
        }
    }
}

.notifications-section {
    border: 1px solid #ddd;
    padding: 2em;
    border-radius: 1em;
}