.body--activity-report {
  background: #f8f8f8;
}
.btn--top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.table--team-members,
.table--sub-accounts {
  margin-top: $spacer-y;
  .col-actions {
    padding-top: 8px;
  }
  .col-avatar {
    .avatar {
      @include avatar(1.8em, 0.9em);
      line-height: 2.2;
    }
  }
  .link--switch-account {
    color: $black;
    @include medium;
    @include font-smoothing;
    text-decoration: none;
    &:hover {
      color: $brand-green;
    }
  }
  .link--remove-account {
    color: $black;
    font-size: 0.9em;
    &:hover {
      color: $brand-green;
    }
  }
  .select-td {
    padding-top: 0;
    padding-bottom: 0;
    select {
      background: transparent;
      cursor: pointer;
      padding-top: 0.85em;
      padding-bottom: 0.85em;
      padding-right: 1em;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%234a4a4a'><polygon points='0,0 100,0 50,50'/></svg>")
        no-repeat;
      background-size: 11px;
      background-position: 100% 18px;
    }
  }
}

.table--sub-accounts {
  tr {
    cursor: pointer;
    &:hover {
      td {
        color: $brand-primary;
      }
      .link--switch-account,
      .link--sign-in {
        color: $brand-primary;
      }
    }
    td {
      vertical-align: middle !important;
    }
  }
  .col-actions--remove {
    border: none;
    background: white !important;
  }
  .col-avatar {
    .avatar {
      @include avatar(2.4em, 1.2em, 2.4em);
    }
  }
  .link {
    &--switch-account {
      font-weight: 400;
    }
    &--sign-in {
      color: $blue;
    }
    &--remove-account {
      color: $red;
      &:hover {
        color: $red;
      }
    }
  }
}

.table--sub-account {
  .col-avatar {
    max-width: 2em !important;
  }
  td {
    &:nth-child(2) {
      max-width: 5em;
    }
    &:nth-child(3) {
      max-width: 7em;
    }
  }
}

.modal--invite-members {
  @include mq(min, $mq-sm) {
    width: 468px;
    padding: 6em 4em;
  }
  h3 {
    text-align: center;
  }
  form {
    fieldset {
      input[type="email"] {
        max-width: none;
      }
    }
    label {
      text-align: left;
      font-size: 1.4em;
      color: #828282;
      @include regular;
      margin-bottom: 0.5em;
    }
  }
}

#modal-add-sub-account.modal {
  @include mq(min, $mq-sm) {
    width: 560px;
    padding: 6em 4em;
  }
  h3 {
    margin-bottom: 0.4em;
  }
  form {
    margin-top: 2em;
    fieldset {
      input[type="text"],
      input[type="email"] {
        max-width: none;
      }
    }
    label {
      text-align: left;
      font-size: 1.4em;
      color: #828282;
      @include regular;
      margin-bottom: 0.5em;
    }
  }
  .form-actions {
    text-align: center;
  }
}

#modal-switch-account.modal {
  h3 {
    margin-bottom: 0.5em;
  }
  .modal-body {
    text-align: center;
  }
  .account {
    margin: 3em auto;
    max-width: 30em;
    display: flex;
    align-items: center;
    justify-content: center;
    .avatar {
      @include avatar(3.6em);
      margin-right: 1em;
      flex-shrink: 0;
    }
    .name {
      text-align: left;
      font-size: 1.7em;
    }
    span {
      font-size: 1.6em;
    }
  }
}

.invitations__blank-state {
  text-align: center;
  padding: 5em 2em;
  border: 1px dashed #d3d3d3;
  border-radius: 2px;
  p {
    font-size: 1.6em;
  }
}

.pending-invitations {
  padding: 2em;
  background: #fdfcec;
  border: 1px solid #f4f0b7;
  border-radius: 5px;
  margin-bottom: 3.2em;
  h4 {
    font-size: 1.6em;
    @include medium;
  }
}
.btn--modal-remove-member {
  font-size: 1.3em;
}
.add-new-members {
  margin-top: -0.4em;
  margin-right: 1em;
}
.teammate-profile {
  cursor: pointer;
  &--has-disabled {
    cursor: not-allowed;
    td {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        opacity: 0.3;
      }
    }
    &:hover {
      > td,
      > th {
        background: #fefefe !important;
      }
    }
  }
  &--pending {
    background: #fdfcec;
  }
}
.label-activate-teammember {
  display: inline-block;
}
fieldset.owner-invites-member {
  margin-bottom: 0.5em;
}

.team-seats {
  margin-bottom: 2em;
  .helper-panel {
    background-image: url(../images/docs/subaccounts-bg.jpg);
  }
  &__progress {
    p {
      margin-bottom: 0;
    }
    i {
      color: #c3c3c3;
      &:first-child {
        margin-right: 0.5em;
      }
      &:last-child {
        margin-left: 0.5em;
      }
    }
  }
  ul {
    margin-left: 2em;
    margin-bottom: 2em;
    li {
      line-height: 1.5;
    }
  }
  p {
    i {
      color: #c3c3c3;
      &:first-child {
        margin-right: 0.5em;
      }
      &:last-child {
        margin-left: 0.5em;
      }
    }
  }
}

.activity-report {
  &__header.header--company {
    background: #2e2e2e;
    padding: 5em;
    margin-top: -3em;
    margin-left: -4em;
    margin-bottom: 2em;
    h1 {
      color: white;
      margin-bottom: 0;
      font-size: 2.8em;
      font-weight: 400;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 3.5em;
  }
  &__section {
    flex: 1 50%;
    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(205, 205, 205, 0.5);
    border-radius: 6px;
    &:first-child:not(:last-child) {
      margin-right: 3em;
    }
  }
  &__title,
  &__text {
    font-size: 2.4em;
    margin: 0;
    padding: 1.25em 1.5em;
    line-height: 1;
  }
  &__title {
    border-bottom: 1px solid #e0e0e0;
    font-weight: 500;
    color: #333333;
    i {
      font-size: 0.7em;
    }
  }
  &__text {
  }
  &__table {
    width: 100%;
    td {
      font-size: 1.5em;
      border: 1px solid #e0e0e0;
      border-top: 0;
      border-left: 0;
      padding: 1.2em 2.4em;
      width: 50%;
      line-height: 1;
      &:last-child {
        border-right: 0;
      }
      .avatar,
      .avatar img {
        width: 30px !important;
        height: 30px !important;
        border-radius: 50% !important;
        line-height: 30px !important;
      }
      .avatar {
        margin-right: 1em;
      }
    }
    &--rating {
      td {
        width: 33%;
      }
    }
  }
  &__choose-date {
    color: white;
    span {
      font-size: 1.6em;
    }
    > .btn {
      background: rgba(#555555, 0.62);
      padding: 0;
      width: 1.875em;
      height: 1.875em;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }
    .flyout {
      padding: 2em 1.5em;
      background: #f5f5f5;
      min-width: 31em;
      right: 0;
      left: auto;
      transform: none;
      border: none;
      z-index: 99;
      label {
        font-weight: 400;
        margin-bottom: 0.5em;
      }
      input {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
      }
    }
  }
}

.bot-avatars {
  display: flex;
  align-items: center;
  .avatar-wrap {
    cursor: pointer;
    i.fa-check-circle {
      position: absolute;
      right: -0.4em;
      top: -0.2em;
      z-index: 5;
      font-size: 1.2em;
    }
    .avatar {
      @include avatar(40px);
    }
  }
}
