.calendar-setting {
  &--border {
    border: 1px solid #afafaf;
    margin-top: 1em;
  }
  p.help {
    margin-top: 1em;
    font-size: 1.4em;
  }
  .calendar-connected {
    background-color: #f2f2f2;
    border-radius: 4px;
    padding: 2.8em;
    position: relative;
    display: flex;
    align-items: center;
    figure {
      flex-grow: 0;
      margin-right: 3.2em;
      @include mq(max, $mq-md) {
        margin: 0 auto 1.6em;
        max-width: 6em;
      }
      position: relative;
      .calendar-icon {
        font-size: 6em;
        @include mq(min, $mq-md) {
          font-size: 8em;
        }
        color: $black;
      }
    }
    .copy {
      flex: 1;
    }
    .check {
      position: absolute;
      left: -1em;
      top: -1em;
      background-color: $brand-primary;
      width: 3em;
      height: 3em;
      line-height: 3.6;
      text-align: center;
      border-radius: 3em;
      border: 2px solid #f2f2f2;
      i {
        color: #fff;
        font-size: 1.4em;
        position: relative;
        top: -0.1em;
      }
    }
    p {
      font-size: 1.8em;
      margin-bottom: 0.3em;
    }
    .help {
      margin-bottom: 0;
      font-size: 1.6em;
      a {
        color: $black;
      }
    }
    .form-inline {
      display: inline;
      margin: 0;
      button {
        display: inline;
        @include regular;
        color: $black;
        text-decoration: underline;
        line-height: inherit;
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
      }
    }
  }
  .pulldown-select {
    color: $brand-primary;
    @include medium;
    color: $black;
    padding-right: 2.4em;
  }
  .pulldown-arrow {
    right: 1em;
    &:after {
      opacity: 0.3;
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-size: 10px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTE2IDUuNWwtMi0yLTYgNi02LTYtMiAyIDggOCA4LTh6Ij48L3BhdGg+PC9zdmc+);
    }
  }
  .inline-word {
    font-size: 1.4em;
    display: inline-block;
    margin: 0 0.5em;
  }
  .textarea-col {
    textarea {
      padding-right: 4em;
    }
    @include mq(min, $mq-md) {
      display: flex;
      textarea {
        margin-right: 1.6em;
        flex: 1;
        max-width: 100%;
      }
      p {
        flex: 1;
        max-width: 30em;
      }
    }
  }
  input[type="text"] {
    padding-right: 4em;
  }
  .js-show-flyout {
    position: absolute;
    right: 4em;
    border: 1px solid #ddd;
    padding: 0.9em 1em;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1em;
    color: $black;
    span {
      font-size: 14px;
    }
    .flyout {
      padding-bottom: 0.5em;
    }
  }
  &__preview-imgs {
    img {
      box-shadow: 0 2px 14px rgba(#9b9b9b, 0.5);
    }
  }
}

.calendar-setting fieldset,
fieldset.calendar-setting {
  margin-bottom: 3.2em;
}

.calendar-setting-header {
  margin: 3.2em 0;
  padding-bottom: 1.6em;
  border-bottom: 1px solid #efefef;
  h3 {
    font-size: 2em;
    @include medium;
    margin-bottom: 0.33em;
  }
  h4 {
    font-size: 1.6em;
    @include regular;
    margin: 0;
  }
}

@include mq(min, $mq-md) {
  .calendar-setting-fieldset-columns {
    display: flex;
    fieldset {
      margin-right: 3.2em;
    }
  }
}

.calendar-setting-action {
  margin-top: $spacer * 2;
}

.calendar-setting--availability {
  .availability--days {
    display: flex;
    input[type="checkbox"] {
      @include offscreen;
      &:checked + label {
        background-color: $brand-primary;
        color: #fff;
      }
    }
    label {
      span {
        line-height: 1;
      }
      font-size: 1.8em;
      width: 2.2em;
      height: 2.2em;
      display: flex;
      justify-content: center;
      align-items: center;
      @include medium;
      color: $black-lighter;
      border: 1px solid #ddd;
      border-right: none;
      cursor: pointer;
      margin-bottom: 0;
      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }
      &:last-of-type {
        border-radius: 0 4px 4px 0;
        border-right: 1px solid #ddd;
      }
    }
  }
  .pulldown {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

.btn-group {
  .btn {
    margin: 1em 2em 0 0;
  }
}

.btn-calendar {
  border-color: #dddddd;
  background-color: #fff;
  color: $black;
  padding: 0.7em 1em;
  width: 230px;
  font-size: 1.8em;
  text-align: left;
  @include mq(min, $mq-md) {
    padding: 0.9em;
  }
  &:hover {
    background-color: darken(#fff, 2.5%);
  }
  .icon {
    position: relative;
    img {
      width: 48px;
      height: 48px;
    }
  }
  svg {
    fill: #d83b01;
    width: 48px;
    height: 48px;
  }
}

.btn-office365-calendar {
  border-color: #104c91;
  background-color: #0072c7;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: darken(#0072c7, 10%);
  }
  svg {
    position: relative;
    top: -1px;
    fill: #fff;
    @include wh(1.2em, 1.43em);
  }
}

.onboarding-panel--calendar {
  .three-col {
    margin-top: 3em;
    .col {
      text-align: center;
      svg {
        fill: $black;
        margin-bottom: 1em;
        @include wh(3.2em, 3.2em);
      }
      h4 {
        @include regular;
        font-size: 1.8em;
      }
      p {
        font-size: 1.4em;
      }
    }
  }
}

.calendar-settings-modal {
  .modal-body {
    img {
      @include mq(min, $mq-md) {
        max-height: 90vh;
      }
    }
  }
}
