#modal-email-embed-instructions.modal {
  h2 {
    margin-bottom: 0.5em;
  }
  form {
    margin-top: 3.2em;
  }
  label {
    margin-top: 0.6em;
  }
  input {
    width: 100%;
    max-width: none;
  }
  textarea {
    width: 100%;
    max-width: none;
    color: #9e9d9d;
    resize: none;
    &:focus {
      color: $black;
    }
  }
}
