.branded-footer {
    border-top: 1px solid rgba(#DFDFDF,.5);
    background-color: #f1f1f1;
    margin-top: 10em;
    padding-top: 4em;
    padding-bottom: 8em;
    text-align: center;
    .brand {
        margin-bottom: 1em;
        svg {
            @include wh(5.3em,6.2em);
            fill: $black-light;
        }
    }
    h3 {
        @include bold;
        font-size: 4em;
        line-height: 1;
        margin-bottom: .1em;
    }
    h4.tagline {
        @include bold-italic;
        font-size: 2em;
        span { display: block; }
    }
}