.create-opportunity {
    .slice--header {
        p.lead {
            max-width: 40em;
        }
    }
    .email-meta {
        padding: 1em;
        p {
            margin: 0;
            font-size: 1.4em;
            &:not(:last-of-type) {
                margin-bottom: .5em;
            }
        }
        p.to {
            color: $black-light;
            span {
                color: $black;
            }
        }
        p.subject {
            @include medium;
            font-size: 1.6em;
        }
    }
    .caption {
        text-align: center;
        @include italic;
        font-size: 1.6em;
        margin: 1em 0;
        color: $black-light;
    }
}

.slice--create-opportunity {
    background-color: #f7f7f7;
    h3 {
        @include medium;
        margin-bottom: $spacer * 2;
    }
    .gc {
        @include mq(min, $mq-lg) {
            display: -ms-grid;
            -ms-grid-template-columns: 1fr 1fr;
            -ms-grid-column-gap: 4em;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 4em;
        }
    }
    .gi {
        margin-bottom: 2em;
        @include mq(min, $mq-lg) { margin-bottom: 0; }
    }
}

.create-opportunity-email {
    padding: 2em;
    background-color: #f1f1f1;
    position: relative;
    .content {
        background-color: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 3px;
        padding: 2em;
    }
    .draft {
        position: absolute;
        right: 1.8em;
        top: 2em;
        color: $red;
        border: 3px solid $red;
        display: inline-block;
        padding: .5em 1em;
        font-size: 2em;
        @include bold;
        @include uppercase;
        transform: rotate(10deg);
    }
    .host {
        margin-bottom: 3.6em;
        .avatar {
            @include avatar(88px,4em);
            margin-right: 20px;
            color: #7f7f7f;
        }
        .bio {
            @include inline-block;
        }
        .name {
            font-size: 2.5em;
            margin-bottom: 0;
            @include medium;
        }
        .role {
            font-size: 2.1em;
            @include regular;
            color: $black-light;
            margin-bottom: 0;
        }
    }
    p {
        font-size: 2em;
        @include italic;
        strong {
            @include bold-italic;
        }
    }
    .starter-question {
        margin-bottom: 3em;
    }
    .btn {
        margin-bottom: .5em;
    }
}