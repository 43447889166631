.aside--install-settings {
    background-color: #f3f3f3;
    position: relative;
    li {
        list-style: none;
        margin-bottom: 0.5em;
        padding: 0.4em 2em;
        &.active {
            background-color: #fff;
        }
    }
}

.nav--install-settings__section {
    &:not(:first-of-type) { border-top: 1px solid rgba(#979797,.2); }
    padding: 3em 0;
}

.nav--install-settings__sub-title {
    @include medium;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.6em;
    margin-left: 1.25em;
    margin-right: 1.25em;
}

.nav--install-settings__link {
    display: inline-block;
    font-size: 1.4em;
    color: $black-light;
    text-decoration: none;
    text-transform: none;
    position: relative;
    &:hover {
        text-decoration: underline;
        color: $black;
    }
}

.btn--labs {
    font-size: 1.3em;
    @include mq(max-height, 600px) {
        margin-left: 1.6em;
    }
    @include mq(min-height, 600px) {
        position: absolute;
        bottom: 1.6em;
        left: 50%;
        transform: translateX(-50%);
    }
}