table {
  background-color: transparent;
}
th {
  text-align: left;
}
.table {
  width: 100%;
  max-width: 100%;
  &--padding {
      td {
        padding: 1em;
      }
    }
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 0.5em;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid rgba(#dfdfdf, 0.5);
  font-size: 1.8em;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-top: 1px solid #dfdfdf;
  color: $black-light;
  font-size: 1.6em;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #dddddd;
}
.table .table {
  background-color: #fff;
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 5px;
}
.table-bordered {
  border: 1px solid #dddddd;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #dddddd;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f9f9f9;
}
.table-hover {
  > tbody {
    > tr {
      &:hover {
        > td,
        > th {
          background-color: #f5f5f5;
        }
      }
    }
  }
}
table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column;
}
table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell;
}
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: #f5f5f5;
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}
.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}
.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}
.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}
.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.scrollable-table {
  .table {
    & > thead > tr > th,
    & > tbody > tr > th,
    & > tfoot > tr > th,
    & > thead > tr > td,
    & > tbody > tr > td,
    & > tfoot > tr > td {
      white-space: nowrap;
    }
  }
}

// From http://www.456bereastreet.com/archive/201309/responsive_scrollable_tables/
.scrollable-table {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  &.has-scroll {
    position: relative;
    overflow: hidden;
    &:after {
      position: absolute;
      top: 0;
      left: 100%;
      width: 50px;
      height: 100%;
      border-radius: 10px 0 0 10px / 50% 0 0 50%;
      box-shadow: -5px 0 10px rgba(0, 0, 0, 0.05);
      content: "";
    }
    & > div {
      overflow-x: auto;
    }
  }
  // & > div::-webkit-scrollbar {
  //     height: 1em;
  // }
  // & > div::-webkit-scrollbar-track {
  //     // box-shadow: 0 0 2px rgba(0,0,0,0.15) inset;
  //     background: #f0f0f0;
  // }
  // & > div::-webkit-scrollbar-thumb {
  //     border-radius: 6px;
  //     background: #ccc;
  // }
}

table.table-mini {
  width: auto;
}

a.col-sort,
span.col-sort {
  position: relative;
  color: $black-light;
  text-decoration: none;
  th.align-right & {
    margin-right: 1.2em;
  }
  &:after {
    position: absolute;
    right: -1.2em;
    @include vertical-align;
    content: "";
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    opacity: 0.3;
  }
  &.sort--desc:after {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTEyLjUgMTBsLTQuNS00LjUtNC41IDQuNWg5eiI+PC9wYXRoPjwvc3ZnPg==);
  }
  &.sort--asc:after {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTMuNSA2bDQuNSA0LjUgNC41LTQuNWgtOXoiPjwvcGF0aD48L3N2Zz4=);
  }
}

a.col-sort:hover {
  color: $black;
  &:after {
    opacity: 1;
  }
}

.col-actions {
  text-align: right;
  form,
  .btn {
    @include inline-block;
  }
}

.table-notifications {
  width: 100%;
  th {
    font-size: 1.2em;
    width: 6.5em;
    font-weight: 500;
    text-align: center;
    padding: 1.45em 0.5em;
    vertical-align: middle;
    color: $black;
    &:first-child {
      color: rgba($black, .5);
      text-transform: uppercase;
      padding: 0;
      text-align: left;
    }
  }
  td {
    font-size: 1.6em;
    padding: 1.25em;
    border-top: 1px solid #dadada;
    border-bottom: 1px solid #dadada;
    .continually-checkbox {
      justify-content: center;
    }
    &:first-child {
      width: 23em;
      border-left: 1px solid #dadada;
    }
    &:last-child {
      border-right: 1px solid #dadada;
    }
    &:nth-child(n + 2) {
      text-align: center;
      vertical-align: middle;
      width: 13.6em;
      font-size: 1em;
    }
  }
  &--sm {
    th {
      padding-top: 1.1em;
      padding-bottom: 1.1em;
    }
    td {
      &:first-child {
        width: 20em;
      }
    }
  }
}

.table-app-errors {
  width: 100%;
  border-collapse: separate;
  tbody {
    tr {
      cursor: pointer;
    }
  }
  th {
    font-size: 1.4em;
    color: #bbb;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0.5em 2em;
  }
  tr {
    &:hover {
      td {
        overflow: visible;
        white-space: normal;
        text-overflow: unset;
      }
    }
    &.error-unread {
      background: #fdf9de;
    }
    &:first-child {
      td {
        border-bottom: 0;
      }
      td:first-child {
        border-top-left-radius: 5px;
      }
      td:last-child {
        border-top-right-radius: 5px;
      }
    }
    &:last-child {
      td {
        border-bottom: 1px solid #dadada;
      }
      td:first-child {
        border-bottom-left-radius: 5px;
      }
      td:last-child {
        border-bottom-right-radius: 5px;
      }
    }
  }
  td {
    font-size: 1.4em;
    padding: 2em;
    border-top: 1px solid #dadada;
    @include truncate;
    &:first-child {
      border-left: 1px solid #dadada;
    }
    &:last-child {
      border-right: 1px solid #dadada;
      width: 50%;
      max-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
