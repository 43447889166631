.todo {
    padding: 1em;
    border: 3px dashed $red;
    font-size: 1.3em;
}


.pull-right {
    position: absolute;
    z-index: 1;
    right: $spacer;
    top: 0;
}

.wtf {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9;
    padding: 1em;
    background-color: $black;
    color: $white;
    max-width: 300px;
    .fa {
        margin-right: 4px;
    }
    p {
        font-size: 1.2em;
        &:last-of-type {
            margin: 0;
        }
    }
}

form[data-state="static"] {
    [data-state="editing"],
    .js-datepicker {
        @include offscreen;
    }
}

form[data-state="editing"] {
    [data-state="static"] {
        @include offscreen;
    }
}

textarea.js-auto-size {
    box-sizing: border-box;
    height: auto;
    min-height: 31px;
    overflow-x: hidden; /* for Firefox (issue #5) */
}

#stream {
  width: 35%;
  position: fixed;
  top: 8em;
  right: 1em;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

// Still needed?

.banner {
    background-color: $brand-primary;
    border: 1px solid $brand-primary;
    color: $white;
    position: relative;
    padding: 1.6em;
    text-align: center;
    .message {
        font-size: 1.6em;
        line-height: 1.4;
    }
    a { color: $white; }
}

.banner-success {}
.banner-info {
    background-color: transparent;
    border: 1px solid $brand-primary;
    color: $black;
    a { color: $brand-primary; }
}
.banner-warning {}
.banner-danger {
    background-color: $error;
    border: 1px solid $error;
}