.body--docs {
  .main--app {
    display: flex;
    flex: 1 auto;
  }
  aside {
    flex: 0 0 20em;
    @include mq(min, $mq-sm) {
      flex: 0 0 22em;
    }
  }
  .app--content {
    flex: 1;
    position: relative;
  }
}

.app--content__docs {
  position: relative;
  max-width: 120em;
  padding: 3em 2em;
  @include mq(min, $mq-md) {
    padding: 3em 4em;
  }
  header {
    position: relative;
    margin-bottom: $spacer-y * 3;
    &.billing-header {
      margin-bottom: 0;
    }
  }
  h1 {
    font-size: 3em;
    @include medium;
    margin-bottom: 0.75em;
  }
  h2 {
    font-size: 2em;
    @include medium;
    margin-bottom: 0.75em;
  }
  p.caption {
    text-align: center;
    @include italic;
    color: $black-light;
    font-size: 1.5em;
  }
  .page-actions {
    @include mq(min, $mq-md) {
      position: absolute;
      z-index: 1;
      right: 4em;
      top: 3em;
    }
    .btn {
      @include mq(max, $mq-md) {
        margin-left: 0.5em;
      }
      @include mq(min, $mq-md) {
        margin-left: 0.5em;
      }
    }
  }
  .embed-code {
    position: relative;
    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
      padding-right: 5em;
    }
  }
  .embed-code--links {
    margin-bottom: 3.2em;
  }
  .ui-mockup {
    margin: 0 0 ($spacer-y * 2) 0;
    & + p.caption {
      margin-top: -($spacer-y);
    }
  }
  .useful-links {
    list-style: none;
    margin: 3.2em 0;
    li {
      margin-bottom: 0.5em;
    }
    a {
      text-decoration: none;
      color: $black;
      span {
        text-decoration: underline;
      }
    }
  }
  .info-panel {
    max-width: 62em;
  }
}

.install-continually--button__intro {
  @include mq(min, $mq-md) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 4em;
  }
  @include mq(min, $mq-lg) {
    grid-template-columns: 3fr 1fr;
  }
  .ui-preview--continually-button {
    margin-top: 0;
    @include mq(max, $mq-md) {
      margin: ($spacer-y * 2) auto;
      max-width: 30em;
    }
    img {
      opacity: 0.4;
    }
  }
  #continually-btn {
    position: absolute;
    z-index: 2;
    bottom: 0.75em;
    right: 0.75em;
    z-index: 1;
    background-color: $brand-primary;
    color: #fff;
    display: inline-block;
    line-height: 1.25;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.4em 1em;
    font-size: 1em;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 600;
  }
}

.form--customer-global-config {
  margin: ($spacer-y * 3) 0;
  @include mq(min, $mq-sm) {
    display: flex;
    .col {
      flex: 1;
      padding-right: 6em;
    }
  }
  fieldset.file {
    figure {
      position: relative;
      display: inline-block;
      background-image: url(../images/docs/transparent-bg.png);
      background-repeat: repeat;
    }
  }
  fieldset.file--logo {
    img {
      max-height: 100px;
    }
  }
  fieldset.file--cover-image {
    img {
      max-height: 300px;
    }
  }
  fieldset.custom-css {
    textarea {
      width: 100%;
      max-width: none;
      font-family: $font-family-monospace;
    }
  }
  .btngroup {
    margin: $spacer-y 0;
    a {
      font-size: 1.3em;
      display: inline-block;
      margin-right: $spacer-x;
      color: $black-light;
      &:hover {
        color: $brand-primary;
      }
    }
  }
}

// Customise lead gen form
.preview--lead-gen-from .ui-browser__inner {
  background-color: #fff;
  position: relative;
  padding: 2em;
  @include mq(min, $mq-sm) {
    padding: 2em 4em;
  }
  @include mq(min, $mq-md) {
    padding: 2em 6em;
  }
  @include mq(min, $mq-lg) {
    padding: 4em 8em;
  }
  @include mq(min, $mq-xl) {
    padding: 6em 10em;
  }
  &:before {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    background-color: rgba(#000, 0.4);
  }
  .fake-website {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }
  .continually-embedded-form {
    position: relative;
    z-index: 3;
  }
}

.ui-browser {
  .fake-website {
    header {
      background-color: #333;
      padding: 1em 2em;
      @include clearfix;
      figure {
        float: left;
        svg {
          @include wh(32px, 32px);
          fill: #fff;
        }
      }
      nav {
        float: right;
        a {
          color: rgba(#fff, 0.4);
          font-size: 1.2em;
          text-decoration: none;
          @include inline-block;
          margin-left: 0.5em;
          margin-top: 0.5em;
        }
      }
    }
    main {
      section {
        padding: 8em 2em;
        background-size: cover;
        text-align: center;
      }
      .h1 {
        font-size: 6em;
      }
      .btn {
        background-color: $blue;
        border-color: $blue;
      }
      .one {
        color: #fff;
        background-color: #333;
        background-image: url(../images/docs/fitapp-one-bg.jpg);
      }
      .two {
        div {
          @include inline-block;
          margin: 0 5em;
          svg {
            display: block;
            margin: 0 auto 2em;
            @include wh(64px, 64px);
          }
          .title {
            font-size: 2em;
          }
        }
      }
      .three {
        background-color: #333;
        background-image: url(../images/docs/fitapp-two-bg.jpg);
      }
    }
    footer {
      padding: 1em;
    }
  }
}

.preview--landing-page {
  .container {
    width: auto !important;
  }
}

.choose-custom-color {
  display: flex;
  justify-content: space-between;
  .col {
    flex: 1;
    position: relative;
  }
  h5 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5em;
  }
  .swatch-wrapper {
    top: 100%;
    &--last {
      top: -1em;
      left: 20em;
    }
  }
}

.choose-custom-color__sidebar-preview {
  position: relative;
  width: 41em;
  height: 66em;
  font-size: 90%;
  border-radius: 5px;
  box-shadow: 0 0 4px 0 #cecece;
  margin-left: auto;
  // overflow: hidden;
  & ~ .widget-caption {
    margin-left: auto;
  }
  .continually-conversation-header-team,
  .continually-conversation-header,
  .continually-conversations-list-header {
    margin: 0;
  }
  .continually-chat-team,
  .continually-chat {
    padding: 1em 1.8em 3em 1.2em;
    overflow: hidden;
    overflow-y: auto;
    @include clearfix;
    .post--logic-full {
      width: calc(100% + 7em);
      padding: 1em 0;
      margin-left: -3em;
      margin-right: -3em;
      max-width: none;
      p {
        font-size: 1.6em;
      }
    }
  }
  .message--agent-takeover {
    margin-top: auto;
  }
}

.widget-new-message-alert {
  position: absolute;
  z-index: 1;
  bottom: calc(100% + 2em);
  left: 0;
  right: 0;
  text-align: center;
  .pill {
    display: inline-block;
    font-family: $font-family-sans-serif;
    padding: 6px 16px;
    color: #fff;
    background-color: #f45b5c;
    border-radius: 20px;
    box-shadow: 0 -1px 14px 0 rgba(0, 0, 0, 0.2);
    .label {
      font-size: 12px;
      @include uppercase;
    }
  }
}

form#widget-settings-submit {
  fieldset.color {
    input.js-color-picker,
    input.js-color-picker-message {
      max-width: 120px;
    }
    .input-group {
      + .input-group {
        margin-top: 1em;
        margin-bottom: 2em;
      }
    }
  }
}

.widget-caption {
  max-width: 26em;
  margin: 1.6em 0;
  &--full-width {
    max-width: none;
  }
}

.widget-browser {
  width: 100%;
  height: 365px;
  margin-left: 2em;
  min-width: 500px;
}

.continually-conversations-list-header {
  padding: 4em 2em 2em;
  position: relative;
  border-radius: 5px 5px 0 0;
  .sidebar-preview__tab-content[data-tab="conversation"] & {
    padding-top: 2em;
    padding-left: 6em;
    padding-right: 9em;
  }
  .continually-widget_back_link {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    padding: 12px 12px 4px 12px;
    color: #fff;
    cursor: pointer;
    @include transition;
    line-height: 1;
    user-select: none;
    img {
      @include inline-block;
    }
    .continually-widget_back_link {
      @include inline-block;
      margin-left: 10px;
      @include uppercase;
      font-family: $font-family-sans-serif;
      font-size: 12px;
    }
  }
  .icon {
    max-width: 32px;
    margin-bottom: 10px;
    img {
      max-width: 100%;
    }
  }
  .title {
    font-family: $font-family-sans-serif;
    font-size: 21px;
    color: #fff;
    margin-bottom: 5px;
  }
  .desc {
    font-family: $font-family-sans-serif;
    font-size: 16px;
    color: rgba(#fff, 0.7);
  }
}

.continually-conversations-list-main {
  overflow: hidden;
  overflow-y: auto;
  .continually-conversation-item {
    padding: 20px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    @include clearfix;
    position: relative;
    .continually-conversation-item__avatar {
      float: left;
      width: 48px;
      height: 48px;
      line-height: 48px;
      border-radius: 48px;
      line-height: 48px;
      position: relative;
      overflow: hidden;
      text-align: center;
      @include inline-block;
      background-color: #fff;
      img {
        display: block;
        position: relative;
        z-index: 2;
        max-width: none;
        width: 48px;
        height: 48px;
      }
    }
    .continually-conversation-item__info {
      float: right;
      width: calc(100% - 64px);
      padding-top: 6px;
      font-family: $font-family-sans-serif;
      .cx-name {
        font-size: 14px;
        color: $black;
        margin-bottom: 4px;
      }
      .cx-message-text {
        font-size: 12px;
        color: $black-light;
        @include truncate;
      }
      .cx-meta {
        position: absolute;
        z-index: 1;
        top: 10px;
        right: 20px;
      }
      .cx-live {
        background-color: #56e255;
        border-radius: 2px;
        line-height: 1;
        padding: 3px 6px;
        color: #fff;
        @include uppercase;
        font-weight: 700;
        @include font-smoothing;
        border-radius: 2px;
        line-height: 1;
        font-size: 10px;
        @include inline-block;
      }
      .cx-updated {
        @include inline-block;
        margin-left: 8px;
        font-size: 10px;
        text-transform: uppercase;
        color: $black-light;
      }
    }
    .continually-conversation-item__unread {
      float: right;
      margin-top: 10px;
      margin-left: 10px;
      background-color: #f45b5c;
      color: #fff;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 700;
      padding: 0 4px;
      min-width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      @include font-smoothing;
    }
  }
}

.continually-conversations-list-footer {
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.sidebar-preview-wrap {
  position: sticky;
  top: 16em;

  &--save-shown {
    top: 21.5em;
  }
  .sidebar-preview {
    max-height: 75vh;
    @include mq(max-height, 750px) {
      .continually-conversations-list-footer {
        bottom: 0;
      }
    }
  }
}

.sidebar-preview {
  transition: width 0.2s;
  &--scale {
    position: absolute;
    bottom: -9em;
    left: calc(79% - 170px);
    transform: scale(0.45);
    background: white;
    @include mq(max, 1200px) {
      left: calc(85% - 170px);
      bottom: -8.5em;
    }
  }
  &--left {
    left: calc(17% - 170px);
  }
  &--thick {
    width: 45.5em;
  }
  &__tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
  }
  &__tab {
    text-align: center;
    border: 1px solid #c0c0c0;
    border-radius: 3px 0 0 3px;
    background: #d4d4d4;
    @include medium;
    &:last-child {
      border-radius: 0 3px 3px 0;
    }
    &--active {
      background: white;
      border-color: black;
    }
    button {
      background: transparent;
      font-size: 0.8em;
      color: $black;
      text-transform: uppercase;
      border: none;
      padding: 0.7em 2em 0.5em 2em;
      line-height: 1;
      cursor: pointer;
    }
  }
  &__tab-content {
    display: none;
    flex-direction: column;
    height: 100%;
  }
}

// .continually-conversations-list-footer {
//     flex: 0;
//     text-align: center;
//     padding: 20px;
//     .btn--start-new-conversation {
//         font-family: $font-family-sans-serif;
//         display: inline-block;
//         line-height: 1.25;
//         text-align: center;
//         text-decoration: none;
//         white-space: nowrap;
//         vertical-align: middle;
//         user-select: none;
//         border: 1px solid transparent;
//         background-color: #fff;
//         padding: .6em 1.6em;
//         border-radius: 50px;
//         cursor: pointer;
//         font-size: 1.5em;
//     }
// }

.live-chat-setting {
  .radio {
    .pulldown-select {
      font-size: 1em;
    }
  }
  .toggle-wrap {
    input[type="checkbox"] {
      display: none;

      &:checked + .toggle-automatic-reply {
        div {
          background-color: $green;
          left: calc(100% - 2.2em);
        }
      }
    }
  }
}
