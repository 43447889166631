.body--admin--single-user {
    background-color: #f1f1f1;
    .main--app {
        padding: 4.2em;
    }
}

// USER PROFILE

.user-body {
    @include mq(min, $mq-md) {
        & > .container { display: flex; }
        .col-1 {
            flex: 1;
            margin-right: 3.2em;
        }
        .col-2 { flex: 1; }
    }
    .col { margin-bottom: 1.6em; }
    .user--panel {
        background-color: #FFF;
        border: 1px solid #E3E3E3;
        box-shadow: 0 0 4px 0 rgba(223,215,215,0.50);
        border-radius: 6px;
        margin-bottom: 1.6em;
        padding: 1.6em;
        overflow: hidden;
        .about--row {
            padding-bottom: 1.6em;
            border-bottom: 1px solid #ddd;
            margin-bottom: 1.6em;
            @include clearfix;
            &:last-of-type {
                border-bottom: none;
                margin-bottom: 0;
            }
            .th {
                float: left;
                width: 33%;
                padding-right: 1em;
                @include medium;
                font-size: 1.5em;
            }
            .td {
                float: left;
                width: 67%;
                font-size: 1.5em;
                word-break: break-word;
                word-wrap: break-word;
                hyphens: auto;
            }
            a {
                color: $black;
                text-decoration: none;
                &:hover { text-decoration: underline; }
            }
            .help-icon {
                display: inline-block;
                margin-left: 3px;
                position: relative;
                i {
                    font-size: 0.8em;
                    pointer-events: none;
                }
            }
        }
    }
    .user--panel--transparent {
        margin-bottom: 1.6em;
        padding: 1.6em;
    }
    .panel--section--contact-card {
        background-color: $black;
        color: #fff;
        text-align: center;
        margin: -1.6em -1.6em 0;
        padding: 3.2em;
        position: relative;
        overflow: hidden;
        .opportunity-avatars {
            position: relative;
            z-index: 2;
            margin: 0 auto;
            display: inline-block;
        }
        .avatar {
            @include avatar(7.5em, 3.6em, 100px);
            border-color: #fff;
            box-shadow: 0 2px 4px 0 rgba(black,0.50);
            background-color: #fff;
        }
        .avatar--company {
            @include avatar(2.8em, 1em, 100px);
            position: absolute;
            right: 0;
            bottom: -0.5em;
            z-index: 2;
            background-color: #fff;
        }
        .user-details {
            position: relative;
            z-index: 2;
            margin-top: 1.6em;
            span {
                font-size: 1.6em;
                display: inline-block;
                margin: 0 0.1em;
            }
            span.name + span.company:before,
            span.role + span.location:before {
                content: "•";
                display: inline-block;
                margin: 0 0.3em 0 0;
            }
            &.name-company {
                span {
                    font-size: 2em;
                    @include medium;
                }
            }
        }
        .social-profiles {
            margin-top: 1em;
            a {
                display: inline-block;
                margin: 0 0.2em;
                padding: 0.5em 0.5em 0.2em;
                line-height: 1;
                background-color: red;
                border-radius: 2px;
                svg { fill: #fff; }
                &.linkedin { background-color: $linkedin; }
                &.twitter { background-color: $twitter; }
            }
        }
        .btn--become {
            margin-top: 1em;
        }
    }
    .panel--header {
        position: relative;
        margin-left: -1.6em;
        margin-right: -1.6em;
        margin-bottom: 1.6em;
        padding: 1.5em 1.6em 1.3em;
        background-color: #F8F7F6;
        border-top: 1px solid #E3E3E3;
        border-bottom: 1px solid #E3E3E3;
        &:after, &:before {
            top: 100%;
            left: 2.2em;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        &:after {
            border-color: rgba(248, 247, 246, 0);
            border-top-color: #F8F7F6;
            border-width: 0.6em;
            margin-left: -0.6em;
        }
        &:before {
            border-color: rgba(227, 227, 227, 0);
            border-top-color: #E3E3E3;
            border-width: 0.7em;
            margin-left: -0.7em;
        }
        .panel--title { margin: 0; }
    }
    .panel--title {
        @include medium;
        font-size: 1.6em;
        small {
            float: right;
            color: $black-light;
            font-size: 75%;
            @include regular;
            display: inline-block;
            margin-top: .2em;
            margin-left: .3em;
        }
    }
    .panel--section--behaviour {
        .behaviour--row {
            padding-bottom: 1.6em;
            border-bottom: 1px solid #ddd;
            margin-bottom: 1.6em;
            @include clearfix;
            &:last-of-type {
                border-bottom: none;
            }
            .th {
                float: left;
                width: 40%;
                padding-right: 1em;
                @include medium;
                font-size: 1.5em;
                color: #9E9E9E;
                white-space: nowrap;
                svg {
                    @include wh(0.8em);
                    fill: #9E9E9E;
                    display: inline-block;
                    margin-right: 0.2em;
                }
            }
            .td {
                float: left;
                width: 60%;
                font-size: 1.5em;
                @include clearfix;
                word-break: break-word;
                word-wrap: break-word;
                hyphens: auto;
            }
            .page-visited {
                font-size: 0.75em;
                display: block;
                float: left;
                padding: 0.5em 1em;
                line-height: 1;
                margin-right: 0.4em;
                margin-bottom: 0.4em;
                background-color: rgba(#D8D8D8,0.25);
                border: 1px solid #ddd;
                border-radius: 100px;
            }
            a {
                color: $black;
                text-decoration: none;
                &:hover { text-decoration: underline; }
            }
            &.about--bio {
                .th, .td {
                    float: none;
                    padding-right: 0;
                    width: 100%;
                }
                .th {
                    margin-bottom: 1em;
                }
            }
        }
    }
    .panel--section--qualification {
        margin-top: 1.6em;
        position: relative;
        .qualification__progress {
            margin-bottom: 2em;
            h3 {
                @include medium;
                font-size: 2.4em;
                margin-bottom: 0.5em;
            }
        }
        .qualification__progress__status {
            height: 11px;
            border-radius: 10px;
            background-color: #D8D8D8;
            margin-bottom: 1.3em;
            position: relative;
            overflow: hidden;
            border: 1px solid #FFF;
            box-shadow: 0 0 4px 0 rgba(196,196,196,0.50);
        }
        .qualification__progress__value {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            background-color: $brand-primary;
            border-radius: 10px;
        }
        .qualification__progess__steps {
            position: absolute;
            right: 0;
            top: 0;
            @include medium;
            font-size: 1.4em;
            color: #A6A6A6;
        }
        .qualification__information {
            margin-bottom: 1em;
            span {
                @include inline-block;
                &.icon {
                    margin-right: 0.5em;
                    @include circle(2.2em);
                    line-height: 2.2em;
                    background-color: #D8D8D8;
                    color: #fff;
                    @include bold;
                    border: 2px solid #fff;
                    box-shadow: 0 1px 2px 0 rgba(#C2C2C2,1);
                    svg {
                        display: none;
                        fill: #fff;
                        position: relative;
                        @include wh(1em);
                    }
                }
            }
            .information {
                @include inline-block;
                .information--title {
                    font-size: 1.2em;
                    color: $black-light;
                }
                .information--value {
                    @include medium;
                    font-size: 1.4em;
                }
            }
            &.information--compete {
                span.icon {
                    background-color: $brand-primary;
                    svg {
                        display: inline-block;
                    }
                }
            }
        }
    }
    .user--panel--conversation {
        .panel--body {
            padding-top: 1.6em;
        }
        .continually-chat-team, .continually-chat {
            font-size: 80%;
        }
    }
    .user--panel--company {
        .company-bio {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ddd;
            padding: 0 1.6em 1.6em;
            margin: 0 -1.6em 3.2em;
            .avatar {
                @include avatar(6.4em,4em,100px);
                border-color: #fff;
                box-shadow: 0 0 5px 0 rgba(#737373,0.50);
                margin-right: 2em;
            }
            .bio {
                flex: 1;
                font-size: 1.4em;
            }
        }
    }
    .user--panel--appointment {
        .appointment {
            display: flex;
            .icon {
                margin-right: 2em;
                margin-left: 1em;
                position: relative;
                svg {
                    fill: $black;
                    @include wh(4.8em);
                }
                .check {
                    position: absolute;
                    left: -1em;
                    top: -1em;
                    @include circle(2.2em);
                    line-height: 2.2em;
                    background-color: $brand-primary;
                    border: 2px solid #fff;
                    svg {
                        fill: #fff;
                        position: relative;
                        top: 0.1em;
                        @include wh(1em);
                    }
                }
            }
            .appointment-details {
                .time {
                    font-size: 1.8em;
                    margin-bottom: 0.25em;
                    strong { @include bold; }
                }
                p {
                    line-height: 1.2;
                    color: $black-light;
                }
            }
        }
    }
    .user--panel--activity {
        .opportunity--progress {
            margin-left: 0.8em;
            margin-top: 2em;
            .step {
                position: relative;
                padding-bottom: 2.5em;
                border-left: 4px solid $brand-primary;
                padding-left: 1.5em;
                &:before {
                    position: absolute;
                    z-index: 1;
                    left: -2px;
                    top: 0;
                    transform: translateX(-50%);
                    @include circle(14px);
                    content: "";
                    background-color: $brand-primary;
                    border: 2px solid $brand-primary;
                }
                &.step--complete {
                    &.step--current {
                        border-left-style: dotted;
                    }
                }
                &.step--future {
                    border-left-style: dotted;
                    &:before {
                        background-color: #fff;
                    }
                }
                &.step:last-of-type {
                    padding-bottom: 0;
                    border-left-color: transparent;
                }
                .label {
                    @include medium;
                    font-size: 1.5em;
                }
                time {
                    display: block;
                    color: $black-light;
                    font-size: 1.3em;
                }
                .meta {
                    color: $black-light;
                    font-size: 1.3em;
                }
            }
        }
    }
}

// END USER PROFILE


.nav--super-admin-tabs {
    text-align: center;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    a {
        padding: 1em;
    }
}

.dashboard__panel--super-admin-company {
    .dashboard__panel__body {
        @include mq(min, $mq-sm) {
            display: grid;
            grid-template-columns: 150px 1fr;
            margin: -1.6em;
        }
        @include mq(min, $mq-md) {
            grid-template-columns: 200px 1fr;
        }
        @include mq(min, $mq-lg) {
            grid-template-columns: 250px 1fr;
        }
    }
    .dashboard__panel__nav {
        @include mq(max, $mq-sm) {
            border-bottom: 1px solid #f1f1f1;
            padding: 1.6em;
        }
        @include mq(min, $mq-sm) {
            border-right: 1px solid #f1f1f1;
            padding: 3.2em 1.6em;
        }
        a {
            display: block;
            font-size: 1.4em;
            padding: 0.5em;
            border-radius: 2px;
            margin-bottom: 0.2em;
            text-decoration: none;
            color: $black-light;
            &:hover {
                color: $brand-primary;
            }
            &.active {
                background-color: rgba($brand-primary,0.1);
                @include medium;
                color: $brand-primary;
            }
        }
    }
    .dashboard__panel__main {
        padding: 3.2em;
        h2 {
            @include medium;
            margin-bottom: 1em;
        }
        .instructions {
            li {
                font-size: 1em;
            }
        }
        .embed-code {
            margin-bottom: 3.2em;
        }
    }
}


form.super-admin {
    margin-bottom: $spacer * 3;
    .modal & { margin-bottom: 0; }
    h3 {
        margin-top: 2em;
        margin-bottom: .5em;
        font-size: 2.4em;
    }
    fieldset {
        textarea {
            min-height: 60vh;
            max-width: none;
            font-family: $font-family-monospace;
        }
    }
}

.dashboard--billing-admin {
    .flex-wrap {
        @include mq(min, $mq-md) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        margin: 0.8em;
    }
    .dashboard__panel {
        margin: 0.8em;
    }
    .dashboard__panel--stripe {
        flex: 2;
    }
    .dashboard__panel--conversations {
        flex: 1;
        h4 {
            @include medium;
            font-size: 1.6em;
        }
        .conversation {
            margin-bottom: 4.8em;
        }
        ul {
            margin: 1em 0 0 2em;
        }
    }
}