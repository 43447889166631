.body--promo {}

.header--promo {
    background-image: url('../images/shared/flow.png');
    background-size: cover;
    background-position: 50% 100%;
    color: #fff;
    text-align: center;
    height: 1.6em;
    @include mq(min, $mq-xs) { height: 24em; }
}

.promo-message {
    text-align: center;
    padding: 3.2em;
    @include mq(min, $mq-xs) {
        margin: -12em auto 0;
        width: 100%;
        max-width: 50em;
        border-radius: 3px;
        background-color: #fff;
        box-shadow: 0 2px 14px 0 rgba(0,0,0,0.30);
    }
    @include mq(min, $mq-md) { padding: 4.8em; }
    @include mq(min, $mq-lg) { padding: 6em; }
    h2 {
        font-size: 2.8em;
        @include regular;
        margin: 0 auto 0.3em;
        max-width: 12em;
    }
    p.lead {
        font-size: 1.8em;
        color: $black-light;
    }
    form {
        margin: 0 auto;
        max-width: 30em;
    }
    fieldset {
        text-align: left;
        margin-bottom: 1.5em;
        &.check label.check {
            margin-bottom: 0;
        }
    }
    .btn-wrap {
        display: block;
    }
    .btn {
        padding-top: .9em;
        padding-bottom: .8em;
    }
    .btn--link {
        margin-top: 1em;
    }
}