body.continually-lb-disable-scrolling {
  overflow: hidden;
}

.continuallyLightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: block;
  .body--preview & {
    min-height: 100%;
  }
}

.continuallyLightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 9999999999;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none;
}

.continuallyLightbox .continually-lb-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.continuallyLightbox .continually-lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  max-width: 80vw;
  max-height: 80vh;
  object-fit: contain;
  cursor: zoom-out;
}

.continuallyLightbox a img {
  border: none;
}

.continually-lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  /* Background color behind image.
     This is visible during transitions. */
  max-width: 80vw;
  max-height: 80vh;
}

.continually-lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.continually-lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continually-lb-cancel {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  content: "";
  transform: translateZ(0);
  border: 2px solid #aaa;
  border-left: 2px solid transparent;
  animation: spinner 0.5s infinite linear;
  margin: 0 auto;
  display: block;
}

.continually-lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.continually-lb-container > .nav {
  left: 0;
}

.continually-lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.continually-lb-prev,
.continually-lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}

.continually-lb-nav a.continually-lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=")
    left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition: opacity 0.6s;
}

.continually-lb-nav a.continually-lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.continually-lb-nav a.continually-lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=")
    right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  transition: opacity 0.6s;
}

.continually-lb-nav a.continually-lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.continually-lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.continually-lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.continually-lb-data {
  padding: 0 4px;
  color: #ccc;
}

.continually-lb-data .continually-lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.continually-lb-data .continually-lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.continually-lb-data .continually-lb-caption a {
  color: #4ae;
}

.continually-lb-data .continually-lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.continually-lb-data .continually-lb-close {
  display: block;
  width: 20px;
  height: 20px;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAMAAAC6CgRnAAAAPFBMVEX///8AAAD9/f2CgoKAgIAAAAAAAAAAAABLS0sAAAAAAACqqqqqqqq6urpKSkpISEgAAAC7u7u5ubn////zbsMcAAAAE3RSTlMASv6rqwAWS5YMC7/AyZWVFcrJCYaKfAAAAHhJREFUeF590kkOgCAQRFEaFVGc+/53FYmbz6JqBbyQMFSYuoQuV+iTflnstI7ssLXRvMWRaEMs84e2uVckuZe6knL0hiSPObXhj6ChzoEkIolIIpKIO4joICAIeDd7QGIfCCjOKe9HEk8mnxpIAup/F31RPZP9fAG3IAyBSJe0igAAAABJRU5ErkJggg==")
    top right no-repeat;
  background-size: contain;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  transition: opacity 0.2s;
  background-size: contain;
  position: absolute;
  right: -3em;
}

.continually-lb-data .continually-lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
