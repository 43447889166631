.lc-app {
  &--mobile {
    flex-direction: column;
    .lc-sidebar {
      flex-direction: row;
      width: 100%;
      flex-basis: auto;
      padding: 0;
      overflow: visible;
      background: #ffffff;
      box-shadow: 0 -7px 12px 0px rgba(0, 0, 0, 0.05);
      z-index: 1;
      &--loading {
        pointer-events: none;
      }
      &__section {
        margin: 0;
        width: 33%;
        color: #858585;

        &:hover,
        &--expanded {
          color: $brand-primary;
          .lc-sidebar__section-title {
            background: none;
          }
        }
      }
      &__section-title {
        justify-content: center;
        flex-direction: column;
        color: inherit;
        align-items: center;
        padding: 0;
        padding-top: 11px;
        padding-bottom: 10px;
        line-height: 1;
        height: 100%;
        font-size: 12.75px;
        @include retina(3) {
          font-size: 15px;
        }
        @supports (padding-bottom: max(0px)) {
          padding-bottom: max(10px, env(safe-area-inset-bottom));
        }
        i {
          margin-bottom: 8px;
          margin-right: 0;
          color: inherit;
          font-size: 14px;
          @include retina(3) {
            font-size: 16px;
          }
        }
        &:after {
          content: none;
        }
      }
    }
    .lc-inbox {
      overflow: hidden;
      overflow-y: auto;
      border: 0;
      position: relative;
      -webkit-overflow-scrolling: touch;
      flex: 1 auto;
      transform: translateZ(0);
      backface-visibility: hidden;
      &__empty {
        align-items: center;
        justify-content: center;
      }
      &__in {
        flex-shrink: 0;
        @supports (margin-top: max(0px)) {
          margin-top: calc(-1 * max(10px, env(safe-area-inset-top)));
        }
        &:before {
          position: sticky;
          z-index: 4;
          width: 3.2em;
          height: 3.2em;
          border-radius: 50%;
          border: 1px solid #000;
          border-left: 1px solid transparent;
          animation: spinner 0.5s infinite linear;
          left: calc(50% - 1.6em);
          top: calc(50% + 1.6em);
          margin-top: -3.2em;
        }
        &:after {
          background: rgba(255, 255, 255, 0.5);
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 3;
          border: none;
          animation: none;
          border-radius: 0;
        }
      }
      &__header {
        background: #182e59;
        color: white;
        border-bottom: 0;
        min-height: auto;
        padding-left: 25px;
        padding-right: 25px;
        margin-bottom: -1px;
        position: relative;
        z-index: 11;
        transform: translate3d(0, 0, 0);
        @supports (padding-top: max(0px)) {
          padding-top: max(600px, var(--sticky-top));
        }
        @supports (margin-top: max(0px)) {
          margin-top: -580px;
        }
        h5 {
          color: white;
          margin-bottom: 0;
          font-size: 17.85px;
          @include retina(3) {
            font-size: 21px;
          }
          i {
            font-size: 16.5px;
            @include retina(3) {
              font-size: 19px;
            }
          }
        }
        p {
          opacity: 0.5;
          font-size: 12px;
          @include retina(3) {
            font-size: 14px;
          }
        }
      }
      &__filter {
        background: #f0f0f0;
        padding: 8px 18px;
        text-align: right;
      }
      .dropdown-wrap {
        .toggle-dropdown-options {
          background: transparent;
          span {
            font-size: 13.6px;
            @include retina(3) {
              font-size: 16px;
            }
          }
        }
        &__select {
          right: 0;
          left: auto;
        }
      }
    }
    .lc-modal {
      .modal {
        margin-top: var(--safe-area-inset-t);
      }
    }
    .lc-conversation {
      &:not(.lc-conversation--unread) {
        background: white;
      }
      &__assign {
        margin-right: 0.5em;
      }
      &__avatar.avatar {
        width: 40px !important;
        height: 40px !important;
        flex-shrink: 0;
      }
      &__img {
        .avatar {
          width: 25px !important;
          height: 25px !important;
        }
      }
      &__time {
        font-size: 12px;
        @include retina(3) {
          font-size: 14px;
        }
      }
      &__header {
      }
      &__badge {
        font-size: 12px;
        @include retina(3) {
          font-size: 14px;
        }
      }
      &__author {
        font-weight: 500;
        font-size: 14px;
        @include retina(3) {
          font-size: 16px;
        }
      }
      &__text {
        padding-left: 0;
        font-size: 12px;
        @include retina(3) {
          font-size: 14px;
        }
      }
      &__offscreen-btn {
        color: white;
        font-size: 16px;
        height: 100%;
        position: absolute;
        align-items: center;
        width: 25%;
        left: -25%;
        display: flex;
        justify-content: center;
        opacity: 0.5;
        transition: 0.5s ease-out;
        i {
          font-size: 0.8em;
          margin-right: 0.5em;
        }
      }
    }
    .lc-conversation-close {
      background: $blue;
    }
    .lc-conversation-reopen {
      background: $brand-primary;
    }
    .lc-lead {
      background: white;
      flex-grow: 2;
      -webkit-overflow-scrolling: touch;
      flex-basis: auto;
      padding-top: 0;
      .lead-panel {
        background: white;
        border: none;
        &--activity {
          .lead-panel__body {
            overflow-y: auto;
          }
        }

        &__title {
          padding: 1em 0;
          border-radius: 0;
          margin: 0;
          background: none;
          padding-bottom: 15px;
          border-bottom: 1px solid #ddd;
          font-size: 15px;
          @include retina(3) {
            font-size: 18px;
          }
          &:after {
            content: none;
          }
        }
        &__body {
          height: auto;
          padding: 2em 0;
          overflow: visible;
        }
        .th,
        .td,
        &__bold-text {
          font-size: 14px;
          @include retina(3) {
            font-size: 16px;
          }
        }
        .flyout-search--tags {
          transform: none;
        }
      }
    }
    .lc-chat {
      flex: 1;
      height: 100%;
      &--settings {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        overflow-y: auto;
        .settings-section {
          overflow: hidden;
          overflow-y: auto;
          flex: 1;
          padding-left: 26px;
          padding-right: 24px;
          padding-top: 30px;
        }
      }
      &__header {
        background: #182e59;
        display: block;
        border-bottom: 0;
        min-height: auto;
        padding-left: 20px;
        padding-right: 30px;
        padding-bottom: 10px;
        @supports (padding-top: max(0px)) {
          padding-top: max(10px, var(--safe-area-inset-t));
        }
        &--settings {
          padding-bottom: 13px;
          color: white;
          @supports (padding-top: max(0px)) {
            padding-top: max(14px, var(--safe-area-inset-t));
          }
        }
        h5 {
          margin-bottom: 0;
          font-size: 17.85px;
          @include retina(3) {
            font-size: 21px;
          }
          i {
            font-size: 16px;
            @include retina(3) {
              font-size: 19px;
            }
          }
        }
      }
      &__header-top {
        display: flex;
        align-items: center;
      }
      &__back {
        margin-right: 15px;
        svg {
          width: auto;
          height: auto;
        }
      }
      &__author {
        color: #fafafa;
        font-size: 14px;
        @include retina(3) {
          font-size: 16px;
        }
      }
      &__avatar {
        margin-right: 10px;
        .avatar {
          width: 30px !important;
          height: 30px !important;
        }
      }
      &__device {
        font-size: 12px;
        @include retina(3) {
          font-size: 14px;
        }
      }
      &__message-status {
        min-width: 205px;
        top: -2.5em;
        line-height: 1;
        padding: 7.5px 9px 5px 9px;
        align-items: end;
        justify-content: center;
        font-size: 12px;
        @include retina(3) {
          font-size: 14px;
        }
      }
      .continually-timeline {
        &--mobile-font-lg {
          .message__content {
            font-size: 15px !important;
            @include retina(3) {
              font-size: 18px !important;
            }
          }
        }
      }
      .continually-chat {
        .post {
          &--private {
            .post__info {
              text-align: right;
            }
          }
        }
        .message {
          [data-role="item-status"] {
            align-self: flex-end;
            margin-left: 1em;
            margin-bottom: 0;
          }
        }
        .message__content {
          font-size: 12.75px;
          word-break: break-word;
          word-wrap: break-word;
          overflow-wrap: break-word;
          @include retina(3) {
            font-size: 15px;
          }
        }
        .post__info,
        [data-role="item-status"],
        .chat--notification__join-leave .text {
          font-size: 11px;
          @include retina(3) {
            font-size: 13px;
          }
        }
      }
      .continually-conversation-body {
        font-size: 100%;
      }
      .continually-conversation-footer {
        padding-left: 0;
        padding-right: 0;
        @supports (padding-bottom: env(safe-area-inset-bottom)) {
          padding-bottom: env(safe-area-inset-bottom, 0px);
        }
      }
      .ql-toolbar {
        border: none;
        padding: 0 15px 10px;
        min-height: 47px;
        &__label {
          padding: 10px 10px 5px 10px;
          line-height: 1;
          display: inline-block;
          &:hover {
            background: transparent;
          }
          > span {
            display: inline-block;
          }
          i {
            font-size: 19px;
            color: #333;
          }
          @include mq(max, $mq-md) {
            padding-left: 7px;
            padding-right: 7px;
            i {
              font-size: 16px;
            }
          }
        }
        &__counter {
          top: -5px;
          line-height: 1.2;
        }
      }
      .ql-formats {
        align-items: baseline;
        > .ql-toolbar__label:first-child {
          padding-left: 0;
        }
      }
      .ql-emoji {
        display: none;
      }
      .ql-container.emoji-wysiwyg-editor {
        border-bottom: 0;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        padding: 10px 15px;
        min-height: 45px;
        font-size: 12px;
        @include retina(3) {
          font-size: 14px;
        }
      }
      .lc-chat__send {
        position: absolute;
        right: 15px;
        bottom: 6px;
        border-radius: 50%;
        padding: 9px 11px 11px 9px;
        margin: 0;
        z-index: 2;
        justify-content: center;
        display: flex;
        background: $brand-primary;
        color: white;
        i {
          margin: 0;
          font-size: 14px;
          @include retina(3) {
            font-size: 16px;
          }
        }
      }
    }
    .lc-commands,
    .lc-snippets {
      &__flyout {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.34);
        border-radius: 20px 20px 0 0;
        transition: all 0.3s linear;
        transform: translateY(100%);
        border: none;
        padding-top: 30px;
        &.flyout-active {
          transform: translateY(0%);
          animation: none;
        }
      }
    }
    .lc-flyout {
      &__header {
        padding-left: 0;
        p {
          font-size: 2.2em;
        }
      }
      &__search {
        border: 1px solid #d8d8d8;
        margin-bottom: 15px;
        input {
          font-size: 12px;
          @include retina(3) {
            font-size: 14px;
          }
        }
        i {
          font-size: 11px;
          @include retina(3) {
            font-size: 13px;
          }
        }
      }
      &__list {
        margin-bottom: 12px;
        max-height: none;
        overflow: visible;
      }
      &__item {
        padding: 15px 20px;
        background: #ffffff;
        border: 1px solid #dadada;
        border-radius: 4px;
        margin-bottom: 1em;
      }
      &__name {
        font-size: 12.75px;
        @include retina(3) {
          font-size: 15px;
        }
      }
      &__reply {
        font-size: 10.2px;
        margin: 0;
        @include retina(3) {
          font-size: 12px;
        }
      }
      &--tags {
        .lc-flyout__content {
          max-height: 40em;
          overflow: auto;
        }
        .flyout-search__item {
          margin-bottom: 1em;
          padding: 0;
          font-size: 1.5em;
        }
      }
    }
    .lc-snippets {
      &__flyout {
        padding: 25px 20px;
      }
      &__content {
        overflow: hidden;
        overflow-y: auto;
        max-height: 24em;
        &--lg {
          max-height: 42em;
        }
      }
    }
    .lc-commands {
      &__flyout {
        padding: 35px 20px;
      }
      &__text {
        color: #838588;
        font-size: 14px;
        @include retina(3) {
          font-size: 16px;
        }
      }
      &__content {
        max-height: 372px;
        overflow: hidden;
        overflow-y: auto;
        -webkit-overflow-scroll: touch;
      }
      &__list {
        margin-left: 0;
        margin-right: 0;
      }
      &__item {
        border: 1px solid #cecfcf;
        margin: 13px 0;
        border-radius: 4px;
        font-size: 12px;
        @include retina(3) {
          font-size: 14px;
        }
      }
      &__heading {
        color: $black;
        font-size: 14px;
        @include retina(3) {
          font-size: 16px;
        }
      }
      &__content {
        p {
          font-size: 12px;
          @include retina(3) {
            font-size: 14px;
          }
        }
      }
      .tooltip {
        display: none !important;
      }
      &__btn {
        color: #838588;
        font-size: 14px;
        @include retina(3) {
          font-size: 16px;
        }
      }
    }
    .lc-typing {
      position: absolute;
      left: 0;
      top: -1.5em;
      padding: 0 15px;
      color: #000;
      margin-top: 0;
      z-index: 2;
      font-size: 11px;
      background: transparent;
      @include retina(3) {
        font-size: 13px;
      }
    }
    .lc-settings {
      padding: 30px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #e3e3e3;
      &:first-child {
        padding-top: 0;
      }
      h3 {
        font-weight: 500;
        color: #545454;
        margin-bottom: 4px;
        border-bottom: 0;
        padding-bottom: 0;
        font-size: 14px;
        @include retina(3) {
          font-size: 16px;
        }
        a {
          color: inherit;
        }
      }
      p {
        color: #5e5e5e;
        letter-spacing: 0;
        margin-bottom: 0;
        font-size: 14px;
        @include retina(3) {
          font-size: 16px;
        }
      }
      &--last {
        border-bottom: 0;
      }
      &--no-border {
        border-bottom: 0;
        padding: 9px 0;
      }
      &__btn {
        background: white;
        border: 1px solid #ddd;
        font-size: 1.6em;
        min-height: 40px;
        padding: 5px 1em;
        border-radius: 8px;
        margin-right: 0.6em;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:last-child {
          font-size: 2em;
        }
        &--active {
          border-color: $black;
        }
      }
    }
    .lc-uploaded-file {
      position: absolute;
      font-size: 1.2em;
      left: 0;
      bottom: calc(4em + max(0em, env(safe-area-inset-bottom)));
      align-items: center;
      z-index: 3;
    }
  }
}
