.install-options--switcher {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #979797;
    margin: 6.4em 0 3.2em;
}

.install-options--switcher__link {
    display: block;
    text-align: center;
    text-decoration: none;
    margin: 0 1em;
    @include mq(min, $mq-md) {
        margin: 0 2em;
    }
    figure {
        border-radius: 2px;
        border: 3px solid transparent;
        margin-bottom: 2.4em;
        max-width: 19em;
    }
    h4 {
        font-size: 1.9em;
        @include regular;
        color: rgba($black, 0.5);
        margin-bottom: 1em;
    }
    &:hover {
        text-decoration: none;
    }
    &.active {
        position: relative;
        &:after,
        &:before {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        &:after {
            border-color: rgba(255, 255, 255, 0);
            border-top-color: #ffffff;
            border-width: 16px;
            margin-left: -16px;
        }
        &:before {
            border-color: rgba(151, 151, 151, 0);
            border-top-color: #979797;
            border-width: 17px;
            margin-left: -17px;
        }
        figure {
            border-color: $brand-primary;
        }
        h4 {
            @include bold;
            color: $black;
        }
    }
}

.install-options {
    &__section {
        & + & {
            margin-top: 8em;
        }
        h5 {
            border-bottom: 1px solid #d8d8d8;
            padding-bottom: 0.5em;
        }
    }
    &__validation {
        p {
            i {
                min-width: 16px;
            }
            color: #757575;
            margin-bottom: 0.5em;
        }
    }
    ol.instructions {
        margin: ($spacer-y * 2) 0;
        margin-left: 2em;
        li {
            margin-bottom: 2em;
            padding-left: 0.5em;
            p {
                font-size: 1em;
            }
            pre {
                font-size: 0.9em;
            }
        }
    }
}
