.body--error {
    .header--app__auth {
        @include invisible;
    }
    .error-message {
        padding-top: 3.2em;
        text-align: center;
    }
    .embed-code pre {
        white-space: normal;
    }
}

.header--error {
    background-image: url('../images/shared/flow.png');
    background-size: cover;
    background-position: 50% 100%;
    color: #fff;
    text-align: center;
    padding: 6em 0;
    p.lead { margin: 0; }
}